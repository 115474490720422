import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { message } from "antd";
import logo from "../../Assets/Images/exitfund.svg";
import sidebarItems from "../../Constants/sidebarItems";
import { checkPermission } from "../../Redux/Actions/StartupsActions";
import { checkRejectedPermission } from "../../Redux/Actions/RejectedStartupsActions";
import { checkTrackingPermission } from "../../Redux/Actions/TrackingActions";
import { checkApprovedPermission } from "../../Redux/Actions/ApprovedStartupsActions";
import { clearAllPageToken, checkAccessPermission } from "../../Utils/helper";

function Sidebar({
  userData,
  checkPermission,
  checkRejectedPermission,
  checkTrackingPermission,
  checkApprovedPermission,
}) {
  const path = useLocation();
  const navigate = useHistory();
  const [newSlideItem, setNewSlideItem] = useState(sidebarItems);

  // useEffect to update sidebar items when user data changes.
  useEffect(() => {
    // Update sidebar items when user data is available
    if (userData) {
      setNewSlideItem(
        sidebarItems.map((data) => ({
          ...data,
          // Calculate permissions for each sidebar item based on user data
          Permissions: checkAccessPermission(data, userData?.permissions),
        }))
      );
    }
  }, [userData]);

  /**
   * Handles the permission check and navigation for a specific item.
   * @param {Object} item - Sidebar item.
   * @param {string} item.route - Item route.
   * @param {boolean} item.Permissions - Item permissions.
   */
  const navButtonClick = async (item) => {
    const permissionChecks = {
      "/waitlist": checkPermission,
      "/rejected": checkRejectedPermission,
      "/tracking": checkTrackingPermission,
      "/approved": checkApprovedPermission,
    };
    const permissionCheckFunction = permissionChecks[item.route];
    if (permissionCheckFunction) {
      await permissionCheckFunction(navigate);
    } else {
      if (!item.Permissions) {
        message.error(
          `You don't have appropriate permission to access ${item?.label}`,
          2
        );
        navigate.push("/welcome");
      } else {
        navigate.push(item.route);
      }
    }
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-header">
        <img src={logo} className="logo-icon" alt="logo" />
      </div>
      {newSlideItem &&
        newSlideItem?.map((item, i) => (
          <div
            className="items-container"
            key={i}
            onClick={() => {
              clearAllPageToken();
              navButtonClick(item);
            }}
          >
            <div
              key={item?.route}
              className={
                path.pathname === item.route ||
                path.pathname?.split("/")[1] === item.route.split("/")[1]
                  ? "item-wrapper selected"
                  : "item-wrapper"
              }
            >
              <div className="item-icon">{item.Icon}</div>
              <div className="item-title">{item.label}</div>
            </div>
          </div>
        ))}
    </div>
  );
}

const mapStateToProps = (state) => ({ userData: state.Auth.userData });

const mapDispatchToProps = (dispatch) => {
  return {
    checkPermission: (navigate) => dispatch(checkPermission(navigate)),
    checkRejectedPermission: (navigate) =>
      dispatch(checkRejectedPermission(navigate)),
    checkTrackingPermission: (navigate) =>
      dispatch(checkTrackingPermission(navigate)),
    checkApprovedPermission: (navigate) =>
      dispatch(checkApprovedPermission(navigate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
