import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import "./AddNewBlog.scss";
import {
  Upload,
  Button,
  Col,
  Row,
  Input,
  message,
  Popover,
  Empty,
  Spin,
  Modal,
  DatePicker,
  Select,
  Popconfirm,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CloseOutlined, CopyOutlined, UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../../../Utils/api";
import {
  addNewBlog,
  addMarktingMediaData,
  getMarktingMediaData,
} from "../../../Redux/Actions/MarketingActions";
import TextArea from "antd/lib/input/TextArea";
import {
  range,
  dummyRequest,
  IMAGE_SIZE_LIMIT,
  IMAGE_SIZE_ERR,
  getTinyMCEApiKey,
} from "../../../Utils/helper.js";
import moment from "moment";
import userProfile from "../../../Assets/Images/user-profile.svg";
import camera from "../../../Assets/Images/camera.svg";
import upload from "../../../Assets/Images/upload.svg";
import saved from "../../../Assets/Images/saved.svg";
import Delete from "../../../Assets/Images/delete.svg";

const AddNewBlog = ({
  mediaData,
  getMarktingMediaData,
  addMarktingMediaData,
  addNewBlog,
  history,
  addBlogLoading,
}) => {
  const { mediaDetails } = mediaData;
  const editorRef = useRef(null);
  const [authorProfilePicLink, setAuthorProfilePicLink] = useState();
  const [authorProfilePicfile, setAuthorProfilePicfile] = useState();
  const [bannerLink, setBannerLink] = useState();
  const [bannerImageFile, setBannerImageFile] = useState();
  const [blogDetails, setBlogDetails] = useState({});
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [editotValue, setEditorValue] = useState("");
  const [savedMedia, setSaveMedia] = useState({});
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [content, setContent] = useState();
  const [errors, setErrors] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  // Fetch marketing media data when the component mounts.
  useEffect(() => {
    getMarktingMediaData();
  }, []);

  /**
   * Update the state with media details when the 'mediaDetails' prop changes.
   *
   * @param {Object} mediaDetails - The details of marketing media.
   */
  useEffect(() => {
    setSaveMedia(mediaDetails);
  }, [mediaDetails]);

  /**
   * Handle the selection of an author's profile picture.
   *
   * @param {File} file - The selected author's profile picture file.
   */
  const onProfileSelect = (file) => {
    setAuthorProfilePicfile(file);
  };

  /**
   * Validate the blog details and form inputs for completeness.
   *
   * @returns {boolean} - Returns true if the form is valid; otherwise, false.
   * @description - Check and validate various fields for the blog details
   */
  const validateForm = () => {
    let isValid = true;
    let errObj = {};
    if (!blogDetails?.author_first_name) {
      errObj.author_first_name = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.author_last_name) {
      errObj.author_last_name = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.blog_title) {
      errObj.blog_title = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.sub_title) {
      errObj.sub_title = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.blog_category) {
      errObj.blog_category = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.summary) {
      errObj.summary = "This field is required";
      isValid = false;
    }
    if (
      content == "<p><br></p>" ||
      content == '<p><br data-mce-bogus="1"></p>'
    ) {
      errObj.format = "This field is required";
      isValid = false;
    }
    if (!bannerLink) {
      errObj.bannerLink = "Banner is required";
      isValid = false;
    }

    setErrors(errObj);
    if (!isValid) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
    return isValid;
  };

  // Upload the author's profile picture to the server.
  const onUploadAuthorProfile = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", authorProfilePicfile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setAuthorProfilePicLink(res?.data?.url);
        setAuthorProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Profile picture uploaded successfully");
      })
      .catch((e) => {
        setAuthorProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Profile picture upload failed..");
      });
  };

  /**
   * Selecting a banner image file.
   *
   * @param {File} file - The selected banner image file.
   */
  const onBannerSelect = (file) => {
    if (file && errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "";
      setErrors(erroObj);
    }
    if (!file && !errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "This field is required";
      setErrors(erroObj);
    }
    setBannerImageFile(file);
  };

  /**
   * Define disabled time ranges for the schedule publish modal.
   *
   * @param {moment} current - The currently selected date and time.
   * @returns {Object} - Object with disabledHours and disabledMinutes functions.
   */
  const disabledRangeTime = (current) => {
    setSchedulePublishTime(current);
    const time = new Date();
    const selectedDate = moment(current).date() || new Date();
    const currDate = new Date().getDate();
    if (!current || selectedDate === currDate) {
      return {
        disabledHours: () => range(0, time.getHours()),
        disabledMinutes: () => range(0, time.getMinutes()),
      };
    }
  };

  //  Upload the selected banner image to the server.
  const onUploadBanner = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", bannerImageFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setBannerLink(res?.data?.url);
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Banner uploaded successfully");
      })
      .catch((e) => {
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Banner upload failed..");
      });
  };

  /**
   * Update a field in the blog details and handle validation errors.
   *
   * @param {string} field - The name of the field to update.
   * @param {string} value - The new value to assign to the field.
   */
  const onBlogFieldUpdate = (field, value) => {
    setBlogDetails({ ...blogDetails, [field]: value });

    if (value && errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "";
      setErrors(erroObj);
    }
    if (!value && !errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "This field is required";
      setErrors(erroObj);
    }
  };

  //  Upload media details to the server and add them to the state.
  const onUploadMedia = () => {
    if (!mediaName || !mediaFile) {
      message.error("Please enter valid media details..", 2);
      return;
    }
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", mediaFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        addMarktingMediaData({
          marketing_saved_media: { ...savedMedia, [mediaName]: res.data?.url },
        });

        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setUploadMediaLoading(false);
        message.error("Media upload failed..");
      });
  };

  /**
   * Close the schedule publish modal and reset the schedule publish time.
   */
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    setSchedulePublishTime();
  };

  /**
   * Publish a blog post, either as a draft or a published post.
   *
   * @param {boolean} isDraft - True if the post should be saved as a draft, false if it should be published.
   * @description- Check and handle validation for blog post fields and publishing as draft.
   */
  const onPublishBlog = (isDraft) => {
    if (
      (Object.values(blogDetails).some((item) => item == "" || !item) ||
        Object.values(blogDetails).length < 6 ||
        !bannerLink ||
        bannerLink === "" ||
        !editotValue ||
        editotValue == "") &&
      !isDraft
    ) {
      message.error("Please complete missing blog fields", 2);

      return;
    } else if (
      (isDraft && blogDetails?.blog_title === "") ||
      !blogDetails?.blog_title
    ) {
      message.error("Add the blog title to save the draft");
      return;
    }
    let mediasInfo = {};
    medias.map((item) => {
      mediasInfo[item?.name] = item?.url;
      return item;
    });
    let data = {
      type: "blog",
      banner_image: bannerLink,
      profile_pic: authorProfilePicLink,
      title: blogDetails?.blog_title?.trimEnd(),
      subtitle: blogDetails?.sub_title?.trimEnd(),
      summary: blogDetails?.summary?.trimEnd(),
      media: mediasInfo,
      category: blogDetails?.blog_category,
      author_first_name: blogDetails?.author_first_name?.trimEnd(),
      author_last_name: blogDetails?.author_last_name?.trimEnd(),
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      body: content,
    };
    addNewBlog(data)
      .then(() => {
        schedulePublishTime
          ? message.success("Blog Scheduled for Publishing")
          : isDraft
          ? message.success("Draft saved")
          : message.success("Published");
        setIsDraft(false);
        history.push("/marketing");
      })
      .catch((e) => {
        message.error(e || "Add Blog Failed..", 2);
      });
  };

  //  Modal for uploading media files and set their names.
  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => {
        setUploadMediaModalOpen(false);
        setMediaFile();
        setMediaName();
      }}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={() => onUploadMedia()}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  /**
   * Delete a media item from the list of saved media.
   *
   * @param {string} key - The key of the media item to be deleted.
   */
  const onDeleteMedia = (key) => {
    delete savedMedia[key];
    addMarktingMediaData({ marketing_saved_media: { ...savedMedia } });
  };

  /**
   * Render a list of saved media items and provide options for copying or deleting them.
   */
  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>

      {Object.keys(savedMedia)?.map((item) => (
        <Row gutter={8} align="middle">
          <Col span={6}>
            <span className="media-name">{item}</span>
          </Col>
          <Col span={14}>
            <span className="media-url">
              {mediaDetails[item]?.slice(0, 50) + "..."}
            </span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={mediaDetails[item]}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
          <Col span={2}>
            <span onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                onConfirm={() => onDeleteMedia(item)}
                okText="Yes"
                cancelText="No"
                className="delete-popconfirm "
                okButtonProps={{ style: { marginRight: "12px" } }}
              >
                <img src={Delete} alt="trash" style={{ cursor: "pointer" }} />
              </Popconfirm>
            </span>
          </Col>
        </Row>
      ))}
      {Object.keys(mediaDetails)?.length === 0 && (
        <Empty description={"No Saved Media"} />
      )}
    </div>
  );

  /**
   * Trigger a function to fetch marketing media data from the server.
   */
  const onSavedMedia = () => {
    getMarktingMediaData();
  };

  /**
   * Modal for selecting a publishing schedule for the blog post.
   */
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => onPublishBlog(false)}
      className="schedule-publish-modal"
      okButtonProps={{ loading: addBlogLoading }}
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={true}
          format={"MM-DD-YYYY HH:mm:ss"}
          defaultValue={
            schedulePublishTime
              ? moment(
                  moment(schedulePublishTime).format("YYYY-MM-DDTHH:mm:ss.SSSS")
                )
              : null
          }
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          disabledTime={(current) => disabledRangeTime(current)}
        />
      </div>
    </Modal>
  );

  return (
    <div className="add-blog-container">
      <div className="top-add">
        <h6> Add New Blog </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            loading={isDraft && addBlogLoading}
            onClick={() => {
              setErrors("");
              setIsDraft(true);
              onPublishBlog(true);
            }}
          >
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => {
              validateForm() && setOpenschedulePublishModal(true);
            }}
          >
            Schedule Publish
          </Button>
          <Button
            className="publish-btn"
            loading={!isDraft && addBlogLoading}
            onClick={() => {
              validateForm() && onPublishBlog(false);
            }}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="add-blog-wrap">
        {uploadLoading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={4}>
              <div className="profile-icon">
                {authorProfilePicLink ? (
                  <img
                    src={authorProfilePicLink || userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : authorProfilePicfile ? (
                  <img
                    src={URL.createObjectURL(authorProfilePicfile)}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : (
                  <img
                    src={userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                )}

                <Upload
                  customRequest={dummyRequest}
                  onChange={async (file) => {
                    if (file?.file?.status === "uploading") {
                      if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                        message.error(IMAGE_SIZE_ERR, 2);
                        return;
                      }
                      onProfileSelect(file.file.originFileObj);
                    }
                  }}
                  accept="image/png,image/jpeg"
                >
                  <div className="camera-icon-wrap">
                    <img
                      src={camera}
                      alt="profile"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Upload>
              </div>
              {authorProfilePicfile && (
                <Button
                  className="upload-profile-btn"
                  onClick={() => onUploadAuthorProfile()}
                >
                  Upload
                </Button>
              )}
            </Col>
            <Col span={14}>
              <Row>
                <Col span={6} offset={12}>
                  <Popover
                    content={savedMediaContent}
                    placement={"bottomRight"}
                    trigger={"click"}
                  >
                    <Button
                      className="upload-btn"
                      icon={<img src={saved} alt="saved" />}
                      onClick={onSavedMedia}
                    >
                      Saved Media
                    </Button>
                  </Popover>
                </Col>
                <Col span={6}>
                  <Button
                    className="upload-btn"
                    onClick={() => setUploadMediaModalOpen(true)}
                    icon={<img src={upload} alt="upload" />}
                  >
                    Upload Media
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="upload-banner">
                  {bannerLink ? (
                    <>
                      <div className="banner-image-wrap">
                        <img src={bannerLink} alt="banner" />
                      </div>
                      {!bannerImageFile && (
                        <Upload
                          customRequest={dummyRequest}
                          onChange={async (file) => {
                            if (file?.file?.status === "uploading") {
                              if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                                message.error(IMAGE_SIZE_ERR, 2);
                                return;
                              }
                              onBannerSelect(file?.file?.originFileObj);
                            }
                          }}
                          accept="image/*"
                        >
                          <Button className="upload-banner-btn">
                            Re-Upload Banner
                          </Button>
                        </Upload>
                      )}
                    </>
                  ) : bannerImageFile ? (
                    <>
                      <div className="banner-image-wrap">
                        <img
                          src={URL.createObjectURL(bannerImageFile)}
                          alt="banner"
                        />
                      </div>
                    </>
                  ) : (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={async (file) => {
                        if (file?.file?.status === "uploading") {
                          if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                            message.error(IMAGE_SIZE_ERR, 2);
                            return;
                          }
                          onBannerSelect(file?.file?.originFileObj);
                        }
                      }}
                      accept="image/*"
                    >
                      <div className="upload-inner">
                        <span>
                          <UploadOutlined /> Upload Banner Image
                        </span>
                      </div>
                    </Upload>
                  )}
                </div>

                <div>
                  <div>
                    {showValidation && (
                      <div style={{ color: "red" }}>{errors["bannerLink"]}</div>
                    )}
                  </div>
                  <div className="desc">Banner should be 1073px by 576px</div>
                </div>
                {bannerImageFile && (
                  <Button
                    className="upload-banner-btn"
                    onClick={() => onUploadBanner()}
                  >
                    Upload Banner
                  </Button>
                )}
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Author First Name</label>

                    <Input
                      value={blogDetails?.author_first_name || ""}
                      placeholder={"First name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "author_first_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.author_first_name?.length || 0
                        }/15`}</p>
                      }
                    />

                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["author_first_name"]}
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Author Last Name</label>
                    <Input
                      value={blogDetails?.author_last_name}
                      placeholder={"Last name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "author_last_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.author_last_name?.length || 0
                        }/15`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["author_last_name"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Blog Title</label>
                    <Input
                      value={blogDetails?.blog_title}
                      placeholder={"Title"}
                      maxLength={47}
                      className="inputs-box"
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "blog_title",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.blog_title?.length || 0
                        }/47`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["blog_title"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Subtitle</label>
                    <Input
                      value={blogDetails?.sub_title}
                      placeholder={"Subtitle"}
                      className="inputs-box"
                      maxLength={55}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "sub_title",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.sub_title?.length || 0
                        }/55`}</p>
                      }
                    />

                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["sub_title"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="field-box">
                    <label>Category</label>
                    <Select
                      size="large"
                      placeholder="Category"
                      className="selection-option"
                      optionFilterProp="children"
                      showSearch
                      value={blogDetails?.blog_category?.trimStart()}
                      showArrow
                      onChange={(value) =>
                        onBlogFieldUpdate("blog_category", value)
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {[
                        "Educational",
                        "News",
                        "Interview",
                        "Startup",
                        "Venture Capital",
                      ]?.map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["blog_category"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Short Summary</label>
                    <TextArea
                      rows={3}
                      maxLength={217}
                      showCount
                      bordered={false}
                      value={blogDetails?.summary}
                      placeholder={"Short summary"}
                      className="inputs-box"
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "summary",
                          e.target.value?.trimStart()
                        )
                      }
                    />

                    {showValidation && (
                      <span style={{ color: "red" }}>{errors["summary"]}</span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Editor
                apiKey={getTinyMCEApiKey()}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(a, b) => {
                    setEditorValue(a);
                    const content = b.getContent({ format: "raw" });
                    setContent(content);
                    if (
                      content != "<p><br></p>" &&
                      content != '<p><br data-mce-bogus="1"></p>' &&
                      errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "";
                      setErrors(erroObj);
                    }
                    if (
                      (content == "<p><br></p>" ||
                        content == '<p><br data-mce-bogus="1"></p>') &&
                      !errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "This field is required";
                      setErrors(erroObj);
                    }
                  }}
                  init={{
                    height: 500,
                    selector: "textarea",
                    plugins:
                      "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc link",
                    menubar: "file edit view insert format tools table help",
                    toolbar:
                      "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                    toolbar_mode: "wrap",
                    block_formats:
                      "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    setup: function (editor) {
                      editor.addShortcut(
                        "alt+shift+1",
                        "H1 is Permanently Disabled",
                        function () {
                          editor.execCommand();
                        }
                      );
                    },
                  }}
                  value={editotValue}
                />
                {showValidation && (
                  <span style={{ color: "red" }}>{errors["format"]}</span>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {uploadMediaModal}
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addBlogLoading: state.Marketing.addBlogLoading,
    mediaData: state.Marketing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewBlog: (data) => dispatch(addNewBlog(data)),
    addMarktingMediaData: (data) => dispatch(addMarktingMediaData(data)),
    getMarktingMediaData: () => dispatch(getMarktingMediaData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewBlog);
