import * as actionTypes from './ActionTypes'
import axios from '../../Utils/api'

// Action to get video lectures data for a specific Learn Series based on 'id' and 'data'.
export const getLearnSeriesVideoLecturesData=(id,data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN_SERIES_VIDEO_LECTURES_DATA_INIT
        })
        await axios.post("admin/all-videos",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_VIDEO_LECTURES_DATA_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_VIDEO_LECTURES_DATA_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to toggle the action of a video lecture (enable or disable) based on 'data' and 'id'.
export const toggleVideoLectureAction=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_INIT
        })
        await axios.post("admin/disable-video",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_SUCCESS,
                payload:res.data,
                videoId:data?.video_id,
                toggleState:data?.is_active
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to delete a video lecture based on 'data' and 'id'.
export const deleteVideoLecture=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.DELETE_LEARN_SERIES_VIDEO_LECTURE_INIT
        })
        await axios.post("admin/delete-video",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.DELETE_LEARN_SERIES_VIDEO_LECTURE_SUCCESS,
                payload:res.data,
                videoId:data?.video_id,
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.DELETE_LEARN_SERIES_VIDEO_LECTURE_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get the list of chapters for a specific Learn Series based on 'id' and 'data'.
export const getChaptersList=(id,data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_INIT
        })
        await axios.post("admin/list-chapters",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to add a new video lecture to a Learn Series based on 'data' and 'id'.
export const addNewVideoLecture=(data,id,onSuccess)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.ADD_LEARN_SERIES65_VIDEO_LECTURE_INIT
        })
        await axios.post("admin/add-video",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.ADD_LEARN_SERIES65_VIDEO_LECTURE_SUCCESS,
                payload:res.data
            });
            onSuccess(res.data)
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.ADD_LEARN_SERIES65_VIDEO_LECTURE_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get details of a video lecture based on 'data' and 'id'.
export const getVideoLectureDetails=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_INIT
        })
        await axios.post("admin/video-lecture",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to edit a video lecture based on 'data' and 'id'.
export const editVideoLecture=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.EDIT_LEARN_SERIES_VIDEO_LECTURE_INIT
        })
        await axios.post("admin/edit-video",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.EDIT_LEARN_SERIES_VIDEO_LECTURE_SUCCESS,
                payload:res.data,
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.EDIT_LEARN_SERIES_VIDEO_LECTURE_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}