import React, { useEffect, useState } from "react";
import "./ProfileDrawer.scss";
import { connect } from "react-redux";
import { FormOutlined } from "@ant-design/icons";
import { Button, Drawer, message, Spin, Upload, Select, Tag } from "antd";
import profile from "../../Assets/Images/user-profile.svg";
import camera from "../../Assets/Images/camera.svg";
import {
  updateProfile,
  updateProfilePic,
} from "../../Redux/Actions/AuthActions";
import TextArea from "antd/lib/input/TextArea";
import { capitalize, getSector } from "../../Utils/helper";
import Location from "../Location/Location";

const ProfileDrawer = ({
  showDrawer,
  closeDrawer,
  userData,
  updateProfile,
  loading,
  updateProfilePic,
}) => {
  const { Option } = Select;
  const [isEdit, setIsEdit] = useState(false);
  const [bioText, setBioText] = useState(userData?.bio?.trim() || "");
  const [profilePic, setProfilePic] = useState(null);
  const [profileFile, setProfileFile] = useState(null);
  const [profilePicBtn, setProfilePicBtn] = useState(false);
  const [location, setLocation] = useState("");
  const [sectors, setSectors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [profileImageLink, setProfileImageLink] = useState("");
  const [ableToAdd, setAbleToAdd] = useState(false);

  // Fetch user data when the component mounts
  useEffect(() => {
    setLocations([...userData?.location_interests]);
    setSectors([...userData?.sector_interests]);
    setProfileImageLink(userData?.profile_pic);
  }, [userData]);

  // Update profile picture when a new file is selected
  useEffect(() => {
    if (profileFile) {
      updateProfilePicture();
    }
  }, [profileFile]);

  // Function to handle profile update - called when user click 'Update Button'
  const onUpdate = () => {
    let data = {
      bio: bioText?.trim() || null,
      sector_interests: sectors,
      location_interests: locations,
      profile_pic: profileImageLink,
    };
    setBioText(bioText?.trim() || "");
    updateProfile(data)
      .then(() => {
        setIsEdit(false);
        message.success("Profile Updated Succesfully", 2);
      })
      .catch((e) => {
        message.error(e || "Profile Update Failed", 2);
      });
  };

  // Function to handle profile picture update
  const onUpdateProfileImage = () => {
    let data = {
      profile_pic: profileImageLink,
    };
    updateProfile(data)
      .then(() => {
        message.success("Profile Picture Updated succesfully", 2);
        setProfilePicBtn(false);
      })
      .catch((e) => {
        message.error(e || "Profile picture Update Failed..", 2);
        setProfilePicBtn(false);
      });
  };

  // Function to handle profile picture selection
  const onProfileSelect = (file) => {
    setProfilePic(URL.createObjectURL(file));
    setProfileFile(file);
  };

  // Function to update profile picture in the backend
  const updateProfilePicture = () => {
    let profileData = new FormData();
    profileData.append("file_obj", profileFile);
    profileData.append("type", "image");
    profileData.append("save_field", "profile_pic");
    updateProfilePic(profileData)
      .then(() => {
        setProfilePicBtn(true);
        setProfilePic();
      })
      .catch((e) => {
        setProfilePic();
      });
  };

  // Function to add a location to the user's interests
  const onAddLocation = () => {
    if (ableToAdd) {
      let locationValue = location?.trim();
      if (locationValue?.length === 0) {
        message.error("Please enter valid location");
        setLocation("");
        return;
      }
      if (locations?.includes(locationValue)) {
        message.error("You have already added this location.");
        return;
      }
      let locationsValue = [...locations]?.concat(location);
      setLocations(locationsValue);
      setLocation("");
    } else {
      message.error("Enter a valid location.");
    }
  };

  // Function to display the user's profile picture
  const handleProfilePicture = () => (
    <img
      src={profilePic || userData?.profile_pic || profile}
      alt="profile"
      style={{ borderRadius: "50%" }}
      width="100px"
      height="100px"
    />
  );

  // Function to reset the state when closing the drawer
  const resetState = () => {
    closeDrawer();
    setIsEdit(false);
    setBioText(userData?.bio?.trim() || "");
    setProfilePic();
    setProfilePicBtn(false);
  };
  return (
    <Drawer width={"450px"} visible={showDrawer} onClose={() => resetState()}>
      <div className="profile-container">
        <div className="profile-wrapper">
          <div className="edit-profile-icon">
            <FormOutlined
              style={{ cursor: "pointer", color: "#425A85" }}
              onClick={() => setIsEdit(true)}
            />
          </div>
          {loading ? (
            <Spin />
          ) : (
            <div className="profile-icon">
              {handleProfilePicture()}
              {!profilePic && (
                <Upload
                  onChange={(file) => onProfileSelect(file.file.originFileObj)}
                  accept="image/png,image/jpeg"
                >
                  <div className="camera-icon-wrap">
                    <img
                      src={camera}
                      alt="profile"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Upload>
              )}
            </div>
          )}
          {profilePicBtn && (
            <Button
              className="update-profile"
              onClick={() => onUpdateProfileImage()}
            >
              Update Profile
            </Button>
          )}
          <div className="name-and-role">
            <span className="user-name">
              {capitalize(userData.first_name + "_" + userData.last_name)}
            </span>
            <span className="user-role">{capitalize(userData?.position)}</span>
          </div>
          <div className="pos-and-invite">
            <div className="user-pos">
              <p className="user-pos-key">Role</p>
              <p className="user-pos-value">{userData.role}</p>
            </div>
            <div className="user-invite">
              <p className="user-pos-key">Invite Code</p>
              <p className="user-pos-value">
                {userData.invite_code.toLowerCase()}
              </p>
            </div>
          </div>
          <div className="email-wrap">
            <span className="email-title">Email</span>
            <span className="email-value">{userData.email}</span>
          </div>
          <div className="interests">
            <span className="label">Sector Interests : </span>
            {!isEdit ? (
              <div className="highlight-group">
                {userData?.sector_interests?.length === 0 && (
                  <span className="highlight">All</span>
                )}
                {userData?.sector_interests?.map((item, key) => (
                  <span className="highlight" key={key}>
                    {getSector(item)}
                  </span>
                ))}
              </div>
            ) : (
              <Select
                mode="multiple"
                allowClear
                className="selection-option multiple-select-sectors"
                placeholder="Please select sectors"
                defaultValue={userData?.sector_interests}
                onChange={(value) => setSectors(value)}
                // onChange={(value) => onChangeField("sector_interests", value)}
              >
                {Object.keys(getSector()).map((item, key) => {
                  return (
                    <Option value={item}>{{ ...getSector() }[item]}</Option>
                  );
                })}
              </Select>
            )}
          </div>
          <div className="interests">
            <span className="label">Location Interests : </span>
            {!isEdit ? (
              <div className="highlight-group">
                {userData?.location_interests?.length === 0 && (
                  <span className="empty-value">N/A</span>
                )}
                {userData?.location_interests?.map((item, key) => (
                  <span className="highlight" key={key}>
                    {item}
                  </span>
                ))}
              </div>
            ) : (
              <div>
                <div className="add-location">
                  <Location
                    location={location}
                    setLocation={(value) => setLocation(value?.trimStart())}
                    multipleLocations
                    setAbleToAdd={setAbleToAdd}
                    ableToAdd={ableToAdd}
                  />
                  <Button onClick={() => onAddLocation()}>Add</Button>
                </div>
                <div className="locations">
                  {locations?.map((item, key) => (
                    <Tag
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        setLocations([...locations]?.filter((l) => l !== item));
                      }}
                      key={key}
                    >
                      {item}
                    </Tag>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="email-wrap">
            <span className="email-title">Bio</span>
            {isEdit ? (
              <>
                <TextArea
                  maxLength="180"
                  rows={4}
                  className="bioInput"
                  onChange={(e) => {
                    setBioText(e.target.value?.trimStart());
                  }}
                  onFocus={(e) =>
                    e.currentTarget.setSelectionRange(
                      e.currentTarget.value.length,
                      e.currentTarget.value.length
                    )
                  }
                  autoFocus
                  spellCheck={false}
                  placeholder="I have 5 years of experience in venture and am based in New York City, having received my MBA from Columbia University. I am interested in proptech and B2B SAAS."
                  value={bioText}
                />
                <Button className="bioUpdateBtn" onClick={() => onUpdate()}>
                  Update
                </Button>
              </>
            ) : (
              <span className="email-value">
                {userData.bio === null || userData.bio.trim() === ""
                  ? "N/A"
                  : userData.bio}
              </span>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.Auth.error,
    message: state.Auth.message,
    userData: state.Auth.userData,
    loading: state.Auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (data) => dispatch(updateProfile(data)),
    updateProfilePic: (data) => dispatch(updateProfilePic(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDrawer);
