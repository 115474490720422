import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, message, Row, Spin } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import * as examActions from "../../../../../Redux/Actions/ReadinessExamActions";
import edit from "../../../../../Assets/Images/edit.svg";
import deleteIcon from "../../../../../Assets/Images/delete.svg";
import "./ReadinessExamDetail.scss";

const ReadinessExamDetail = ({
  match,
  examDetails,
  deleteExam,
  getExamDetails,
  history,
  loading,
}) => {
  const examId = match.params.examId;
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [question, setquestion] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState();

  // Fetches exam details.
  useEffect(() => {
    let data = {
      exam_id: examId,
    };
    getExamDetails(data, user?.id);
  }, []);

  // Sets the initial question when examDetails change.
  useEffect(() => {
    examDetails &&
      examDetails?.questions?.length > 0 &&
      setquestion(examDetails?.questions[0]);
  }, [examDetails]);

  /**
   * Deletes an exam and handles success or error cases.
   * @description - Calls the deleteExam function and handles success and error cases.
   */
  const onDeleteExam = () => {
    let data = {
      exam_id: examId,
      delete: true,
    };
    deleteExam(data, user?.id)
      .then(() => {
        message.success("Deleted");
        history.push({
          pathname: `/education/learn-investing/exams`,
          state: { searchPlaceholder: "Search Readiness Exam", back: true },
        });
      })
      .catch((e) => {
        message.error(e || "Failed to delete");
      });
  };

  /**
   * Converts seconds into a formatted time display.
   * @param {number} d - The total number of seconds.
   * @returns {JSX.Element} A formatted time display.
   */
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return (
      <div className="time-wrap">
        <div>
          <p className="value">{("0" + h)?.slice(-2)} : </p>
        </div>
        <div>
          <p className="value">{("0" + m)?.slice(-2)} : </p>
        </div>
        <div>
          <p className="value">{("0" + s)?.slice(-2)} </p>
        </div>
      </div>
    );
  };

  return (
    <div className="exam-preview-container">
      <div className="preview-header">
        <h6>Readiness Exam</h6>
        <div className="header-right">
          <p
            onClick={() =>
              history.push(`/education/learn-series65/exam-edit/${examId}`)
            }
          >
            <img src={edit} alt="add" />
            <span>Edit</span>
          </p>
          <p onClick={() => onDeleteExam()}>
            <img src={deleteIcon} alt="add" />
            <span>Delete</span>
          </p>
        </div>
      </div>
      <div className="exam-preview-wrap">
        {loading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={5}>
              <div className="left-section">
                <h4>Exam Time</h4>
                <div className="time-details">
                  <span>{secondsToHms(examDetails?.time)}</span>
                </div>
              </div>
            </Col>
            <Col span={18} offset={1} className="content-wrap">
              <div className="question-wrap">
                <div className="question-content">
                  <span className="question">{question?.content}</span>
                </div>
                <div className="options">
                  <Row gutter={[24, 24]}>
                    {question?.options?.map((option) => (
                      <Col span={12}>
                        <div
                          onClick={() => setSelectedOption(option)}
                          className={`option ${
                            selectedOption === option
                              ? option === question?.correct_options
                                ? "correct-option"
                                : "incorrect-option"
                              : ""
                          }`}
                        >
                          <span>{option}</span>
                        </div>
                        {selectedOption === option && (
                          <div className="ans-message">
                            {selectedOption !== question?.correct_options ? (
                              <>
                                <CloseOutlined className="wrong-icon" />
                                <span className="wrong">Wrong Answer</span>
                              </>
                            ) : (
                              <>
                                <CheckOutlined className="right-icon" />
                                <span className="right">Correct Answer</span>
                              </>
                            )}
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                </div>
                {selectedOption &&
                  selectedOption !== question?.correct_options && (
                    <div className="wrong-ans-wrap">
                      <span className="label">Wrong Answer Explaination</span>
                      <div className="expl-content">
                        <span>{question?.explanation}</span>
                      </div>
                    </div>
                  )}
                {examDetails?.questions?.length > 1 && (
                  <div className="pre-next-wrap">
                    {questionIndex !== 0 && (
                      <div
                        className="prev-que"
                        onClick={() => {
                          setquestion(
                            examDetails?.questions[questionIndex - 1]
                          );
                          setQuestionIndex(questionIndex - 1);
                          setSelectedOption();
                        }}
                      >
                        <ArrowLeftOutlined />
                        <span className="title">Previous Question</span>
                      </div>
                    )}
                    {questionIndex !== examDetails?.questions?.length - 1 && (
                      <div
                        className="prev-que next-que"
                        onClick={() => {
                          setquestion(
                            examDetails?.questions[questionIndex + 1]
                          );
                          setQuestionIndex(questionIndex + 1);
                          setSelectedOption();
                        }}
                      >
                        <span className="title">Next Question</span>
                        <ArrowRightOutlined />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ReadinessExam.loading,
    examDetails: state.ReadinessExam.examDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamDetails: (data, id) =>
      dispatch(examActions.getExamDetails(data, id)),
    deleteExam: (data, id) => dispatch(examActions.deleteExam(data, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReadinessExamDetail);
