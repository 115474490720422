// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  adminData: [],
  exportData: [],
  loading: false,
  emailNotifications: {},
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADMIN_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ADMIN_DATA_SUCCESS:
      return {
        ...state,
        adminData: action.payload,
        loading: false,
      };
    case actionTypes.GET_ADMIN_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.PASSWORD_CHANGE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.PASSWORD_CHANGE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_ADMIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: state.adminData.filter(
          (item) => item.email !== action.payload
        ),
      };
    case actionTypes.DELETE_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TOGGLE_ADMIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOGGLE_ADMIN_SUCCESS:
      let admins = [...state.adminData];
      let ind = admins.findIndex((item) => item.email === action.payload.email);
      let admin = { ...admins[ind] };
      admin.is_active = action.payload.is_active;
      admins[ind] = admin;
      return {
        ...state,
        loading: false,
        adminData: admins,
      };
    case actionTypes.TOGGLE_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CHANGE_PERMISSION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHANGE_PERMISSION_SUCCESS:
      let perAdmins = [...state.adminData];
      let perInd = perAdmins.findIndex(
        (item) => item.email === action.payload.email
      );
      let perAdmin = { ...perAdmins[perInd] };
      let perP = { ...perAdmin.permissions, ...action.payload.permissions };
      perAdmin.permissions = perP;
      perAdmins[perInd] = perAdmin;

      return {
        ...state,
        loading: false,
        adminData: perAdmins,
      };
    case actionTypes.CHANGE_PERMISSION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_ADMIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: state.adminData.concat({ ...action.payload }),
      };
    case actionTypes.ADD_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_ADMIN_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adminData: state.adminData.map((item) => {
          if (item?.id === action?.adminId) {
            return {
              ...action.payload,
            };
          } else {
            return { ...item };
          }
        }),
      };
    case actionTypes.EDIT_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_EXPORT_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        exportData: action.payload.data || [],
      };
    case actionTypes.GET_EXPORT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        exportData: [],
      };
    case actionTypes.GET_EMAIL_NOTIFICATIONS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EMAIL_NOTIFICATIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action?.payload?.data || {},
      };
    case actionTypes.GET_EMAIL_NOTIFICATIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        emailNotifications: {},
      };
    case actionTypes.SET_EMAIL_NOTIFICATIONS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_EMAIL_NOTIFICATIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        emailNotifications: action?.payload?.data
      };
    case actionTypes.SET_EMAIL_NOTIFICATIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
