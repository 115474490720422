import { Button, Input, message, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from "react-redux";
import "./CoInvestorForm.scss"
import uploadIcon from "../../../Assets/Images/uploadNew.svg"
import trashIcon from "../../../Assets/Images/trashIcon.svg"
import plus from "../../../Assets/Images/plus.svg"
import { dummyRequest } from '../../../Utils/helper';
import { addCoInvestorName, removeCoInvestor, uploadPortfolioCoInvestorImage } from '../../../Redux/Actions/PortfolioActions';

const CoInvestorForm = ({ addPortfolioData, setUploading, errors, setErrors, uploadPortfolioCoInvestorImage, removeCoInvestor, addCoInvestorName }) => {
    const [coInvestors, setCoInvestors] = useState([
        { co_investor_image: null, co_investor_name: '', co_investor_logoFile: null },
    ]);


    useEffect(() => {
        // Ensure coInvestors array matches addPortfolioData.co_investors length
        if (addPortfolioData.co_investors && addPortfolioData.co_investors.length > coInvestors.length) {
            const newCoInvestors = [...coInvestors];
            while (newCoInvestors.length < addPortfolioData.co_investors.length) {
                newCoInvestors.push({ co_investor_image: null, co_investor_name: '', co_investor_logoFile: null });
            }
            setCoInvestors(newCoInvestors);
        }
    }, [addPortfolioData.co_investors, coInvestors]);


    const handleAddCoInvestor = () => {
        const lastCoInvestor = coInvestors[coInvestors.length - 1];
        const hasContent = lastCoInvestor.co_investor_image || lastCoInvestor.co_investor_name;

        if (hasContent) {
            setCoInvestors([...coInvestors, { co_investor_image: null, co_investor_name: '', co_investor_logoFile: null }]);
        } else {
            message.error("Please fill Logo or Name for the last co-investor before adding a new one.", 2);
        }
    };

    const handleDeleteCoInvestor = (index) => {
        const newCoInvestors = [...coInvestors];
        console.log({addPortfolioData})
        if (index === 0 && coInvestors?.length == 1 && coInvestors[0]?.co_investor_image == null && coInvestors[0]?.co_investor_logoFile == null && coInvestors[0]?.co_investor_name == '') {
            return
        }
        /**
         * 
         * TODO: Work in this code is still pending
         * 
         */
        // newCoInvestors.splice(index, 1);
        // setCoInvestors(newCoInvestors);


        // const newErrors = { ...errors };
        // delete newErrors[`co_investor_${index}_image`];
        // delete newErrors[`co_investor_${index}_name`];
        // const updatedErrors = Object.keys(newErrors)
        //     .filter(key => key.startsWith('co_investor_'))
        //     .reduce((acc, key) => {
        //         const [, , oldIndex] = key.split('_');
        //         const newIndex = parseInt(oldIndex, 10) - (index < parseInt(oldIndex, 10) ? 1 : 0);
        //         acc[`co_investor_${newIndex}_image`] = newErrors[key];
        //         acc[`co_investor_${newIndex}_name`] = newErrors[key.replace('image', 'name')];
        //         return acc;
        //     }, {});

        // setErrors({ ...newErrors, ...updatedErrors });
        // removeCoInvestor(index)
    };
    // console.log({ coInvestors, addPortfolioData })


    const onCompanyLogoSelect = async (file, index) => {
        console.log({ file, index })
        const newCoInvestors = [...coInvestors];
        // Update logo property with the selected file
        newCoInvestors[index].co_investor_image = URL.createObjectURL(file);
        newCoInvestors[index].co_investor_logoFile = file

        setCoInvestors(newCoInvestors);

        const newErrors = { ...errors };
        delete newErrors[`co_investor_${index}_image`];
        setErrors(newErrors);
        /**
         * TODO: The API is not getting called
        */
        let coInvestorData = new FormData();
        coInvestorData.append("file_obj", file);
        coInvestorData.append("is_portfolio_co_investor", true);
        setUploading(true);
        uploadPortfolioCoInvestorImage(coInvestorData, index)
            .then((res) => {
                console.log({ res })
                setUploading(false);
                message.success("Co-Investor Image added successfully", 2);
            })
            .catch((errors) => {
                console.log({ errors })
                setUploading(false);
                message.error("Co-Investor Image Upload Failed", 2);
            });

    };

    return (
        <div>
            {coInvestors?.map((coInvestor, index) => (
                <div key={index}>
                    <div className='coInv-section'>
                        <Upload
                            className='logo-uploader'
                            customRequest={dummyRequest}
                            onChange={(file) => onCompanyLogoSelect(file.file.originFileObj, index)}
                            accept="image/png,image/jpeg"
                        >
                            {coInvestor?.co_investor_image ? (
                                <img
                                    src={coInvestor?.co_investor_image}
                                    alt="coInv-Logo"
                                    className="logo-img"
                                />
                            ) : (
                                addPortfolioData?.co_investors?.length > 0 && addPortfolioData?.co_investors[index]?.co_investor_image ? <img
                                    src={addPortfolioData?.co_investors[index]?.co_investor_image}
                                    className="logo-img"
                                    alt="coInv-Logo"
                                /> :
                                    <div className='upload-icon'>
                                        <img src={uploadIcon} alt="uploadIcon" />
                                        <p>Logo</p>
                                    </div>
                            )}
                        </Upload>

                        <Input
                            value={(addPortfolioData?.co_investors?.length > 0 && addPortfolioData?.co_investors[index]?.co_investor_name) || coInvestor.co_investor_name}
                            placeholder={"Investor name"}
                            maxLength={50}
                            className="inputs-box"
                            onChange={(e) => {
                                const newCoInvestors = [...coInvestors];
                                newCoInvestors[index].co_investor_name = e.target.value;
                                addCoInvestorName({
                                    name_index: index,
                                    name_value: e.target.value
                                })
                                setCoInvestors(newCoInvestors);

                                const newErrors = { ...errors };
                                if (e.target.value && newErrors[`co_investor_${index}_name`]) {
                                    delete newErrors[`co_investor_${index}_name`]
                                }
                                setErrors(newErrors);
                            }
                            }
                        />

                        {/* {index > 0 && ( */}
                        <div className='trash-icon' onClick={() => handleDeleteCoInvestor(index)}>
                            <img src={trashIcon} alt='trashIcon' />
                        </div>
                        {/* )} */}
                    </div>
                    <div className='coInv-section'>
                        {errors[`co_investor_${index}_image`] && (
                            <span className="validation-msg">{errors[`co_investor_${index}_image`]}</span>
                        )}
                        {errors[`co_investor_${index}_name`] && (
                            <span className="validation-msg">{errors[`co_investor_${index}_name`]}</span>
                        )}
                    </div>
                </div>
            ))}
            <Button className='add-button' onClick={handleAddCoInvestor}>
                <img src={plus} alt="plus" /> Add Co-Investor</Button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadPortfolioCoInvestorImage: (data, index) =>
            dispatch(uploadPortfolioCoInvestorImage(data, index)),

        removeCoInvestor: (index) => dispatch(removeCoInvestor(index)),
        addCoInvestorName: (data) => dispatch(addCoInvestorName(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoInvestorForm);