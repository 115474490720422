import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  Upload,
  Button,
  Col,
  Row,
  Popover,
  Input,
  message,
  Spin,
  Modal,
  DatePicker,
  Empty,
} from "antd";
import { UploadOutlined, CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { dummyRequest, getTinyMCEApiKey } from "../../../../../Utils/helper";
import axiosInstance from "../../../../../Utils/api";
import * as ChaptersActions from "../../../../../Redux/Actions/ChaptersActions";
import upload from "../../../../../Assets/Images/upload.svg";
import saved from "../../../../../Assets/Images/saved.svg";
import "./EditChapter.scss";

const EditChapter = ({
  match,
  chapterDetail,
  getChapterDetails,
  editChapter,
  loading,
  history,
}) => {
  const chapterId = match.params.chapterId;
  const editorRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [headerLink, setHeaderLink] = useState();
  const [chapterDetails, setChapterDetails] = useState({});
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [headerImageFile, setHeaderImageFile] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [editotValue, setEditorValue] = useState("");
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [content, setContent] = useState();

  // Updates state variables when chapterDetail changes.
  useEffect(() => {
    setHeaderLink(chapterDetail?.header_image);
    setChapterDetails({
      chapter_number: chapterDetail?.chapter_number,
      created_by: chapterDetail?.created_by,
      chapter_title: chapterDetail?.title,
      chapter_subtitle: chapterDetail?.subtitle,
      learning_type: chapterDetail?.learning_type,
    });
    setEditorValue(chapterDetail?.content);
    setSchedulePublishTime(chapterDetail?.publish_date);
  }, [chapterDetail]);

  // Fetches chapter details.
  useEffect(() => {
    const l_data = JSON.parse(localStorage.getItem("learnSeries65EditChapterData"));
    if (l_data && (chapterId == l_data?.chapter_id)) {
      setChapterDetails({
        chapter_number: l_data?.chapter_number,
        created_by: l_data?.created_by,
        chapter_title: l_data?.title,
        chapter_subtitle: l_data?.subtitle,
      })
      setHeaderLink(l_data?.header_image)
      setEditorValue(l_data?.content)
      setContent(l_data?.content)

    } else {
      let data = {
        chapter_id: chapterId,
      };
      getChapterDetails(data, user?.id);
    }
  }, []);

  /**
   * Handles the selection of a header image file.
   * @param {File} file - The selected file.
   */
  const onHeaderSelect = (file) => {
    setHeaderImageFile(file);
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", file);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setHeaderLink(res?.data?.url);
        setHeaderImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Header image uploaded successfully");
      })
      .catch((e) => {
        setHeaderImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Header image upload failed..");
      });
  };

  /**
   * Updates a specific field in the chapter details state.
   * @param {string} field - The field to be updated.
   * @param {any} value - The new value for the field.
   */
  const onChapterFieldUpdate = (field, value) => {
    setChapterDetails({ ...chapterDetails, [field]: value });
  };

  // Handles the upload of media files.
  const onUploadMedia = () => {
    if (!mediaName || !mediaFile) {
      message.error("Please enter valid media details..", 2);
      return;
    }
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", mediaFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.error("Media upload failed..");
      });
  };

  // Closes the schedule publish modal and resets the schedule publish time.
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    setSchedulePublishTime();
  };

  /**
   * Handles publishing or previewing a chapter based on provided parameters.
   * @param {boolean} isDraft - Indicates whether the chapter is a draft.
   * @param {boolean} isPreview - Indicates whether the action is to preview the chapter.
   */
  const onPublishChapter = (isDraft, isPreview = false) => {
    if (
      Object.values(chapterDetails)?.some((item) => item == "" || !item) ||
      Object.values(chapterDetails)?.length < 4
    ) {
      message.error("Please enter valid chapter details...", 2);
      return;
    }
    let data = {
      ...(headerLink && { header_image: headerLink }),
      chapter_number: parseInt(chapterDetails?.chapter_number),
      created_by: chapterDetails?.created_by?.trimEnd(),
      title: chapterDetails?.chapter_title?.trimEnd(),
      subtitle: chapterDetails?.chapter_subtitle?.trimEnd(),
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      content: content,
      chapter_id: chapterId,
      learning_type: chapterDetail?.learning_type,
    };
    editChapter(data, user?.id)
      .then(() => {
        setEditorValue(content);
        message.success("Chapter Edited Successfully");
        localStorage.removeItem("learnSeries65EditChapterData")

        history.push({
          pathname: `/education/learn-series65/chapters`,
          state: { searchPlaceholder: "Search Chapter", back: true },
        });
      })
      .catch((e) => {
        setEditorValue(content);
        message.error(e || "Edit Chapter Failed..", 2);
      });
  };

  // Modal for uploading media files.
  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => {
        setUploadMediaModalOpen(false);
        setMediaFile();
        setMediaName();
      }}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={() => onUploadMedia()}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              customRequest={dummyRequest}
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  // Component displaying saved media content.
  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>
      {medias?.map((item) => (
        <Row gutter={8}>
          <Col span={6}>
            <span className="media-name">{item?.name}</span>
          </Col>
          <Col span={16}>
            <span className="media-url">{item?.url?.slice(0, 50) + "..."}</span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={item?.url}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
        </Row>
      ))}
      {medias?.length === 0 && <Empty description={"No Saved Media"} />}
    </div>
  );

  // Modal for scheduling chapter publishing.
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => onPublishChapter(false)}
      className="schedule-publish-modal"
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={false}
          disabledDate={(current) => current && current.valueOf() < Date.now()}
        />
      </div>
    </Modal>
  );

  return (
    <div className="add-chapter-container">
      <div className="top-add">
        <h6> Edit Chapter </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            // onClick={() => onPublishChapter(true, true)}
            onClick={async () => {
              let chapterDetail = {
                ...(headerLink && { header_image: headerLink }),
                chapter_number: parseInt(chapterDetails?.chapter_number),
                created_by: chapterDetails?.created_by?.trimEnd(),
                title: chapterDetails?.chapter_title?.trimEnd(),
                subtitle: chapterDetails?.chapter_subtitle?.trimEnd(),
                // is_draft: isDraft,
                ...(schedulePublishTime && { publish_date: schedulePublishTime }),
                content: content,
                chapter_id: chapterId,
                learning_type: "series65",
              };
              await localStorage.setItem("learnSeries65EditChapterData", JSON.stringify(chapterDetail))
              history.push(`/education/learn-series65/chapter-preview/${chapterId}`, {
                chapterDetail
              })
            }
            }
          >
            Preview
          </Button>
          <Button className="save-btn" onClick={() => onPublishChapter(true)}>
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => setOpenschedulePublishModal(true)}
          >
            Schedule Publish
          </Button>
          <Button
            className="publish-btn"
            onClick={() => onPublishChapter(false)}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="add-chapter-wrap">
        {uploadLoading || loading ? (
          <Spin />
        ) : (
          <Row gutter={48}>
            <Col span={8}>
              <Row>
                <div className="upload-banner">
                  {headerLink ? (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={(file) =>
                        onHeaderSelect(file.file.originFileObj)
                      }
                      accept="image/*"
                    >
                      <div className="banner-image-wrap">
                        <img src={headerLink} alt="banner" />
                      </div>
                    </Upload>
                  ) : headerImageFile ? (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={(file) =>
                        onHeaderSelect(file.file.originFileObj)
                      }
                      accept="image/*"
                    >
                      <div className="banner-image-wrap">
                        <img
                          src={URL.createObjectURL(headerImageFile)}
                          alt="banner"
                        />
                      </div>
                    </Upload>
                  ) : (
                    <Upload
                      onChange={(file) =>
                        onHeaderSelect(file.file.originFileObj)
                      }
                      accept="image/*"
                    >
                      <div className="upload-inner">
                        <span>
                          <UploadOutlined /> Upload Chapter Header Image
                        </span>
                      </div>
                    </Upload>
                  )}
                </div>
              </Row>
            </Col>
            <Col span={16}>
              <Row>
                <Col span={6} offset={12}>
                  <Popover
                    content={savedMediaContent}
                    placement={"bottomRight"}
                    trigger={"click"}
                  >
                    <Button
                      className="upload-btn"
                      icon={<img src={saved} alt="saved" />}
                    >
                      Saved Media
                    </Button>
                  </Popover>
                </Col>
                <Col span={6}>
                  <Button
                    className="upload-btn"
                    onClick={() => setUploadMediaModalOpen(true)}
                    icon={<img src={upload} alt="upload" />}
                  >
                    Upload Media
                  </Button>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Chapter Number</label>
                    <Input
                      value={chapterDetails?.chapter_number}
                      placeholder={"Chapter number"}
                      className="inputs-box"
                      type="number"
                      onChange={(e) =>
                        onChapterFieldUpdate("chapter_number", e.target.value)
                      }
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Created By</label>
                    <Input
                      value={chapterDetails?.created_by}
                      placeholder={"Created by"}
                      className="inputs-box"
                      onChange={(e) =>
                        onChapterFieldUpdate(
                          "created_by",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Chapter Title</label>
                    <Input
                      value={chapterDetails?.chapter_title}
                      placeholder={"Title"}
                      className="inputs-box"
                      onChange={(e) =>
                        onChapterFieldUpdate(
                          "chapter_title",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Subtitle</label>
                    <Input
                      value={chapterDetails?.chapter_subtitle}
                      placeholder={"Subtitle"}
                      className="inputs-box"
                      onChange={(e) =>
                        onChapterFieldUpdate(
                          "chapter_subtitle",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Content</label>
                    <Editor
                      apiKey={getTinyMCEApiKey()}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onEditorChange={(a, b) => {
                        setEditorValue(a);
                        setContent(b.getContent({ format: "raw" }));
                      }}
                      init={{
                        height: 500,
                        selector: "textarea",
                        plugins:
                          "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc link",
                        menubar:
                          "file edit view insert format tools table help",
                        toolbar:
                          "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                        toolbar_mode: "wrap",
                        block_formats:
                          "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        setup: function (editor) {
                          editor.addShortcut(
                            "alt+shift+1",
                            "H1 is Permanently Disabled",
                            function () {
                              editor.execCommand();
                            }
                          );
                        },
                      }}
                      value={editotValue}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {schedulePublishModal}
      {uploadMediaModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chapterDetail: state.Chapter.chapterDetails,
    loading: state.Chapter.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChapterDetails: (data, id) =>
      dispatch(ChaptersActions.getLearnSeriesChapterDetails(data, id)),
    editChapter: (data, id) =>
      dispatch(ChaptersActions.editLearnSeriesChapter(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChapter);
