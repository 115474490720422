import * as actionTypes from './ActionTypes'
import axios from '../../Utils/api'

// Action to get quizzes data
export const getQuizzesData=(id,data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_EDUCATION_QUIZZES_DATA_INIT
        })
        await axios.post("admin/all-quizzes",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_EDUCATION_QUIZZES_DATA_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_EDUCATION_QUIZZES_DATA_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to toggle quiz action
export const toggleQuizAction=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.TOGGLE_EDUCATION_QUIZ_ACTION_INIT
        })
        await axios.post("admin/disable-quiz",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.TOGGLE_EDUCATION_QUIZ_ACTION_SUCCESS,
                payload:res.data,
                quizId:data?.quiz_id,
                toggleState:data?.is_active
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.TOGGLE_EDUCATION_QUIZ_ACTION_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to delete quiz
export const deleteQuiz=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.DELETE_EDUCATION_QUIZ_INIT
        })
        await axios.post("admin/delete-quiz",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.DELETE_EDUCATION_QUIZ_SUCCESS,
                payload:res.data,
                quizId:data?.quiz_id,
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.DELETE_EDUCATION_QUIZ_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get chapters list
export const getChaptersList=(id,data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_INIT
        })
        await axios.post("admin/list-chapters",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to add a new quiz
export const addNewQuiz=(data,id,onSuccess)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.ADD_QUIZ_INIT
        })
        await axios.post("admin/add-quiz",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.ADD_QUIZ_SUCCESS,
                payload:res.data
            });
            onSuccess(res.data)
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.ADD_QUIZ_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get quiz details
export const getQuizDetails=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_QUIZ_DETAILS_INIT
        })
        await axios.post("admin/quiz",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_QUIZ_DETAILS_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_QUIZ_DETAILS_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to edit quiz
export const editQuiz=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.EDIT_QUIZ_INIT
        })
        await axios.post("admin/edit-quiz",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.EDIT_QUIZ_SUCCESS,
                payload:res.data,
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.EDIT_QUIZ_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}