import React, { useEffect, useState } from 'react'
import "./AddPortfolio.scss"
import { connect, useSelector } from "react-redux";
import pencil from "../../../Assets/Images/pencil.svg"
import { Button, Col, Input, message, Row, Spin, Upload } from 'antd'
import TextArea from 'antd/lib/input/TextArea';
import CoInvestorForm from '../components/CoInvestorForm';
import { dummyRequest } from '../../../Utils/helper';
import { addPortfolioData, uploadPortfolioStartupImage, AddPortfolioApi, addEditPortfolioDetails } from '../../../Redux/Actions/PortfolioActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const { Dragger } = Upload;
const AddPortfolio = (props) => {
    const history = useHistory()
    const [addPortfolioData, setAddPortfolioData] = useState({})
    const [startupImage, setStartupImage] = useState(null);
    const [startupLogoFile, setStartupLogoFile] = useState(null);
    const [uploading, setUploading] = useState(false)
    const [showValidation, setShowValidation] = useState(false);
    const [errors, setErrors] = useState("");

    useEffect(() => {
        props.addEditPortfolioDetails({
            co_investors: []
        })
    }, [])

    useEffect(() => {
        setAddPortfolioData(props?.addPortfolioDetail)
    }, [props?.addPortfolioDetail])
    console.log({ addPortfolioData })
    /**
    * Handles the selection of the startup logo image file.
    * @param {File} file - The selected image file for the startup logo.
    */
    const onCompanyLogoSelect = (file) => {
        setStartupImage(URL.createObjectURL(file));
        setStartupLogoFile(file);
    };

    const hasCoInvestorFields = () => {
        return addPortfolioData.co_investors && addPortfolioData.co_investors.some((coInvestor) => coInvestor.co_investor_name || coInvestor.co_investor_image);
    };

    /**
       * Handle the update of the company  image.
       * @param {File} companyLogoFile - The new company logo image file.
       */
    useEffect(() => {
        if (startupLogoFile) {
            let startupLogoData = new FormData();
            startupLogoData.append("file_obj", startupLogoFile);
            startupLogoData.append("is_portfolio", true);
            setUploading(true);
            props
                .uploadPortfolioStartupImage(startupLogoData)
                .then((res) => {
                    setUploading(false);
                    message.success("Startup Image added successfully", 2);
                    setErrors({
                        ...errors,
                        startup_image: ''
                    })
                })
                .catch((errors) => {
                    setUploading(false);
                    message.error("Startup Image Upload Failed", 2);
                });
        }
    }, [startupLogoFile]);

    /**
     * Handles the change in input field values, updates the startup data, and performs input validation.
     * @param {string} field - The name of the field being changed.
     * @param {any} value - The new value for the field.
     */
    const onChangeField = (field, value) => {
        setAddPortfolioData({ ...addPortfolioData, [field]: value });
        onChangeValidation(value, field);
        props.addPortfolioData(field, value)
    };

    /**
     * Handles the change in input field values and updates error messages accordingly.
     * @param {any} value - The value entered in the input field.
     * @param {string} field - The name of the input field.
     */
    const onChangeValidation = (value, field) => {
        let erroObj = { ...errors };
        if (value && errors[field]) {
            erroObj[field] = "";
        }
        if (!value && !errors[field]) {
            erroObj[field] = "This field is required";
        }

        setErrors(erroObj);
    };

    /**
     * Validates the form data and sets error messages accordingly.
     * @returns {boolean} - Returns true if the form data is valid, otherwise false.
     */
    const validateForm = () => {
        let isValid = true;
        let errObj = {};
        if (!addPortfolioData.startup_image) {
            errObj.startup_image = "Startup Image is required";
            isValid = false;
        }
        if (!addPortfolioData?.startup_name) {
            errObj.startup_name = "This field is required";
            isValid = false;
        }
        if (!addPortfolioData?.description) {
            errObj.description = "This field is required";
            isValid = false;
        }
        if (hasCoInvestorFields()) {
            console.log("1")
            for (let i = 0; i < addPortfolioData.co_investors?.length; i++) {
                const coInvestor = addPortfolioData.co_investors[i];
                if (!coInvestor.co_investor_image) {
                    errObj[`co_investor_${i}_image`] = "Co-Investor Logo is required";
                    isValid = false;
                }
                if (!coInvestor.co_investor_name) {
                    errObj[`co_investor_${i}_name`] = "Co-Investor Name is required";
                    isValid = false;
                }

            }
        }
        console.log({ isValid, errObj })
        setErrors(errObj);
        if (!isValid) {
            setShowValidation(true);
        } else {
            setShowValidation(false);
        }
        return isValid;
    }

    const onSaveStartup = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validateForm()) {
            setUploading(true);
            props.AddPortfolioApi(addPortfolioData,
                res => {
                    message.success(res.data.message, 2);
                    setUploading(false);
                    history?.push("/portfolio");
                }, err => {
                    setUploading(false);
                    message.error(err || "Portfolio Adding Failed", 2);
                })
        }
    }

    return (
        <div className="add-portfolio-container">
            <div className="top-portfolio">
                <h6>Portfolio</h6>
                <div className="portfolio-right">
                    <Button className="save-btn"
                        onClick={onSaveStartup}
                    >
                        Save
                    </Button>
                </div>
            </div>
            {uploading ? (
                <Spin />
            ) : (
                <div className="startup-edit-wrap">
                    <div className='inner-section'>
                        <Row gutter={24}>
                            <Col span={24}>
                                {/* <span className="banner-text">Startup Image</span> */}
                                <div className="logo">
                                    {startupImage ? (
                                        <img
                                            src={startupImage}
                                            alt="Company Logo"
                                            className="logo-img"
                                        />
                                    ) : (
                                        addPortfolioData.startup_image ? <img
                                            src={addPortfolioData?.startup_image}
                                            alt="Company Logo"
                                            className="logo-img"
                                        /> : <p>Add Startup Image</p>


                                    )}
                                    <Upload
                                        customRequest={dummyRequest}
                                        onChange={(file) =>
                                            onCompanyLogoSelect(file.file.originFileObj)
                                        }
                                        accept="image/png,image/jpeg,image/jpg"
                                    >
                                        <div className="edit-logo">
                                            <img src={pencil} alt="Edit" />
                                        </div>
                                    </Upload>
                                </div>
                                {showValidation && (
                                    <span className="validation-msg">
                                        {errors["startup_image"]}
                                    </span>
                                )}
                            </Col>
                            <Col span={24}>
                                <div className="field-box">
                                    <label>Startup Name</label>
                                    <Input
                                    maxLength={50}
                                        value={addPortfolioData?.startup_name}
                                        placeholder={"Startup name"}
                                        className="inputs-box"
                                        onChange={(e) =>
                                            onChangeField(
                                                "startup_name",
                                                e.target.value?.trimStart()
                                            )
                                        }
                                    />
                                    {showValidation && (
                                        <span className="validation-msg">
                                            {errors["startup_name"]}
                                        </span>
                                    )}
                                </div>
                            </Col>
                            <Col span={24}>
                                <div className="description-box">
                                    <label>Description</label>
                                    <TextArea
                                        rows={10}
                                        // maxLength={200}
                                        // showCount
                                        bordered={0}
                                        spellCheck={false}
                                        placeholder={"Description"}
                                        value={addPortfolioData?.description}
                                        className="text-area-box"
                                        onChange={(e) =>
                                            onChangeField(
                                                "description",
                                                e.target.value?.trimStart()
                                            )
                                        }
                                    />{" "}
                                    {showValidation && (
                                        <span className="validation-msg">
                                            {errors["description"]}
                                        </span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='inner-section2'>
                        <h2>Add Co-Investor</h2>
                        <CoInvestorForm addPortfolioData={addPortfolioData} setUploading={setUploading} errors={errors} setErrors={setErrors} />
                    </div>
                </div>
            )}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        addPortfolioDetail: state.Portfolio.portfolioDetails,
        loading: state.Portfolio.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadPortfolioStartupImage: (data) =>
            dispatch(uploadPortfolioStartupImage(data)),
        addPortfolioData: (field, value) =>
            dispatch(addPortfolioData(field, value)),
        AddPortfolioApi: (data, onSuccess, onError) =>
            dispatch(AddPortfolioApi(data, onSuccess, onError)),
        addEditPortfolioDetails: (data) => dispatch(addEditPortfolioDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPortfolio);