// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  marketingData: [],
  totalMarketingCount: 0,
  blogDetails: {},
  podcastDetails: {},
  subscribersData: [],
  totalSubscribersCount: 0,
  loading: false,
  mediaDetails: [],
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BLOG_INIT:
      return {
        ...state,
        addBlogLoading: true,
      };
    case actionTypes.ADD_BLOG_SUCCESS:
      return {
        ...state,
        addBlogLoading: false,
      };
    case actionTypes.ADD_BLOG_FAILED:
      return {
        ...state,
        addBlogLoading: false,
      };
    case actionTypes.ADD_PODCAST_INIT:
      return {
        ...state,
        addPodcastLoading: true,
      };
    case actionTypes.ADD_PODCAST_SUCCESS:
      return {
        ...state,
        addPodcastLoading: false,
      };
    case actionTypes.ADD_PODCAST_FAILED:
      return {
        ...state,
        addPodcastLoading: false,
      };
    case actionTypes.GET_MARKETING_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MARKETING_DATA_SUCCESS:
      return {
        ...state,
        marketingData: action.payload?.marketing_data || [],
        totalMarketingCount: action.payload?.total_count || 0,
        loading: false,
      };
    case actionTypes.GET_MARKETING_DATA_FAILED:
      return {
        ...state,
        marketingData: [],
        totalMarketingCount: 0,
        loading: false,
      };
    case actionTypes.GET_SUBSCRIBERS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SUBSCRIBERS_DATA_SUCCESS:
      return {
        ...state,
        subscribersData: action.payload?.data || [],
        totalSubscribersCount: action.payload?.total || 0,
        loading: false,
      };
    case actionTypes.GET_SUBSCRIBERS_DATA_FAILED:
      return {
        ...state,
        subscribersData: [],
        totalSubscribersCount: 0,
        loading: false,
      };
    case actionTypes.DELETE_SUBSCRIBER_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SUBSCRIBER_DATA_SUCCESS:
      return {
        ...state,
        subscribersData: action.payload?.data || [],
        loading: false,
      };
    case actionTypes.DELETE_SUBSCRIBER_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TOGGLE_MARKETING_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOGGLE_MARKETING_ACTION_SUCCESS:
      return {
        ...state,
        marketingData: state?.marketingData?.map((item) => {
          if (item?.id === action?.marketingId) {
            item.is_disabled = action?.toggleState;
          }
          return item;
        }),
        loading: false,
      };
    case actionTypes.TOGGLE_MARKETING_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_MARKEING_LIBRARY_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MARKEING_LIBRARY_SUCCESS:
      return {
        ...state,
        marketingData: state?.marketingData?.filter(
          (item) => item?.id !== action?.marketingId
        ),
        loading: false,
      };
    case actionTypes.DELETE_MARKEING_LIBRARY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_BLOG_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        blogDetails: action?.payload?.single_data,
        loading: false,
      };
    case actionTypes.GET_BLOG_DETAILS_FAILED:
      return {
        ...state,
        blogDetails: {},
        loading: false,
      };
    case actionTypes.EDIT_BLOG_INIT:
      return {
        ...state,
        editBlogLoading: true,
      };
    case actionTypes.EDIT_BLOG_SUCCESS:
      return {
        ...state,
        editBlogLoading: false,
      };
    case actionTypes.EDIT_BLOG_FAILED:
      return {
        ...state,
        editBlogLoading: false,
      };
    case actionTypes.GET_PODCAST_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PODCAST_DETAILS_SUCCESS:
      return {
        ...state,
        podcastDetails: action?.payload?.single_data,
        loading: false,
      };
    case actionTypes.GET_PODCAST_DETAILS_FAILED:
      return {
        ...state,
        podcastDetails: {},
        loading: false,
      };
    case actionTypes.EDIT_PODCAST_INIT:
      return {
        ...state,
        editPodcastLoading: true,
      };
    case actionTypes.EDIT_PODCAST_SUCCESS:
      return {
        ...state,
        editPodcastLoading: false,
      };
    case actionTypes.EDIT_PODCAST_FAILED:
      return {
        ...state,
        editPodcastLoading: false,
      };
    case actionTypes.ADD_MARKETING_MEDIA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_MARKETING_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_MARKETING_MEDIA_INIT:
      return { ...state, loading: true };
    case actionTypes.GET_MARKETING_MEDIA_SUCCESS:
      return {
        ...state,
        mediaDetails: action?.payload?.marketing_saved_media || [],
        loading: false,
      };
    case actionTypes.GET_MARKETING_MEDIA_FAILED:
      return { ...state, mediaDetails: [], loading: false };
    case actionTypes.EXPORT_SUBSCRIBER_DATA_INIT:
      return {
        ...state,
      };
    case actionTypes.EXPORT_SUBSCRIBER_DATA_SUCCESS:
      return {
        ...state,
        subscribersExportData: action.payload?.data || [],
      };
    case actionTypes.EXPORT_SUBSCRIBER_DATA_FAILED:
      return {
        ...state,
        subscribersExportData: [],
      };
    default:
      return state;
  }
};

export default store;
