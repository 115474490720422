// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  startupTransactions: [],
  totalStartupTransactions: 0,
  transactionStartupDetails: {},
  investorTransactions: [],
  totalInvestorTransactions: 0,
  transactionInvestorDetails: {},
  ExportTransactions: [],
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STARTUP_TRANSACTIONS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_STARTUP_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        startupTransactions: action.payload?.data,
        totalStartupTransactions: action.payload?.total,
      };
    case actionTypes.GET_STARTUP_TRANSACTIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_INVESTOR_TRANSACTIONS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVESTOR_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        investorTransactions: action.payload?.data,
        totalInvestorTransactions: action.payload?.total,
      };
    case actionTypes.GET_INVESTOR_TRANSACTIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_EXPORT_STARTUP_TRANSACTIONS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EXPORT_STARTUP_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        ExportTransactions: action.payload?.data,
      };
    case actionTypes.GET_EXPORT_STARTUP_TRANSACTIONS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        ExportTransactions: [],
      };
    default:
      return state;
  }
};

export default store;
