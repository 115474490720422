import React, { useEffect, useRef, useState } from "react";
import "./EditBlog.scss";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  Upload,
  Button,
  Col,
  Row,
  Input,
  message,
  Popover,
  Empty,
  Spin,
  Modal,
  DatePicker,
  Select,
  Popconfirm,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CloseOutlined, CopyOutlined, UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../../../Utils/api";
import {
  getBlogDetails,
  addMarktingMediaData,
  editBlog,
  getMarktingMediaData,
} from "../../../Redux/Actions/MarketingActions";
import {
  range,
  dummyRequest,
  IMAGE_SIZE_LIMIT,
  IMAGE_SIZE_ERR,
  getTinyMCEApiKey,
} from "../../../Utils/helper";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Delete from "../../../Assets/Images/delete.svg";
import userProfile from "../../../Assets/Images/user-profile.svg";
import camera from "../../../Assets/Images/camera.svg";
import upload from "../../../Assets/Images/upload.svg";
import saved from "../../../Assets/Images/saved.svg";

const EditBlog = ({
  match,
  mediaData,
  getBlogDetails,
  getMarktingMediaData,
  blogDetail,
  addMarktingMediaData,
  editBlog,
  history,
  editBlogLoading,
}) => {
  const blogId = match.params.blogId;
  const { mediaDetails } = mediaData;
  const editorRef = useRef(null);
  const [authorProfilePicLink, setAuthorProfilePicLink] = useState();
  const [authorProfilePicfile, setAuthorProfilePicfile] = useState();
  const [bannerLink, setBannerLink] = useState();
  const [bannerImageFile, setBannerImageFile] = useState();
  const [blogDetails, setBlogDetails] = useState({});
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [editotValue, setEditorValue] = useState("");
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [content, setContent] = useState();
  const [savedMedia, setSaveMedia] = useState({});
  const [errors, setErrors] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [isDraft, setIsDraft] = useState(false);

  // Fetch the details of a blog and marketing media data when the component mounts.
  useEffect(() => {
    getBlogDetails(blogId);
    getMarktingMediaData();
  }, []);

  //  Update the saved media state when mediaDetails changes.
  useEffect(() => {
    setSaveMedia(mediaDetails);
  }, [mediaDetails]);

  /**
   * Process and set the data for a selected blog when the blogDetail changes.
   * @description - Extract media details from the blog and transform them for rendering.
   */
  useEffect(() => {
    let mediasInfo = [];
    Object?.entries(blogDetail?.media || {})?.map((item) => {
      let a = {
        name: item[0],
        url: item[1],
      };
      mediasInfo.push(a);
      return item;
    });
    setAuthorProfilePicLink(blogDetail?.profile_pic);
    setBannerLink(blogDetail?.banner_image);
    setBlogDetails({
      blog_category: blogDetail?.category,
      blog_title: blogDetail?.title,
      blog_subtitle: blogDetail?.subtitle,
      summary: blogDetail?.summary,
      author_first_name: blogDetail?.author?.split(" ")[0],
      author_last_name: blogDetail?.author?.split(" ")[1],
    });
    setSchedulePublishTime(blogDetail?.publish_date);
    setMedias(mediasInfo);
    setEditorValue(blogDetail?.body);
  }, [blogDetail]);

  /**
   * Delete a media item from savedMedia and update marketing_saved_media data.
   * @param {string} key - The key of the media to be deleted.
   */
  const onDeleteMedia = (key) => {
    delete savedMedia[key];
    addMarktingMediaData({ marketing_saved_media: { ...savedMedia } });
  };

  /**
   * Validate the blog form fields and display error messages.
   * @returns {boolean} - Returns true if the form is valid, false if there are validation errors.
   */
  const validateForm = () => {
    let isValid = true;
    let errObj = {};
    if (!blogDetails?.author_first_name) {
      errObj.author_first_name = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.author_last_name) {
      errObj.author_last_name = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.blog_title) {
      errObj.blog_title = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.blog_subtitle) {
      errObj.blog_subtitle = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.blog_category) {
      errObj.blog_category = "This field is required";
      isValid = false;
    }
    if (!blogDetails?.summary) {
      errObj.summary = "This field is required";
      isValid = false;
    }
    if (
      content == "<p><br></p>" ||
      content == '<p><br data-mce-bogus="1"></p>'
    ) {
      errObj.format = "This field is required";
      isValid = false;
    }
    if (!bannerLink) {
      errObj.bannerLink = "Banner is required";
      isValid = false;
    }
    setErrors(errObj);

    if (!isValid) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
    return isValid;
  };

  /**
   * Update the author's profile picture file.
   * @param {File} file - The selected author's profile picture file.
   */
  const onProfileSelect = (file) => {
    setAuthorProfilePicfile(file);
  };

  /**
   * Upload the author's profile picture to the server.
   * @description-This function sends the selected author's profile picture to the server for upload.
   */
  const onUploadAuthorProfile = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", authorProfilePicfile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setAuthorProfilePicLink(res?.data?.url);
        setAuthorProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Profile picture uploaded successfully");
      })
      .catch((e) => {
        setAuthorProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Profile picture upload failed..");
      });
  };
  /**
   * Handle the selection of a banner image file.
   * @param {File} file - The selected banner image file.
   * @description- This function is called when a user selects a banner image file. It also handles validation and error messages.
   */
  const onBannerSelect = (file) => {
    if (file && errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "";
      setErrors(erroObj);
    }
    if (!file && !errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "This field is required";
      setErrors(erroObj);
    }
    setBannerImageFile(file);
  };

  /**
   * Upload the selected banner image to the server.
   * @description This function sends the selected banner image to the server for upload.
   */
  const onUploadBanner = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", bannerImageFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setBannerLink(res?.data?.url);
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Banner uploaded successfully");
      })
      .catch((e) => {
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Banner upload failed..");
      });
  };

  /**
   * Update a specific field within the blog details state.
   * @param {string} field - The field to be updated in the blog details.
   * @param {string} value - The new value to set for the specified field.
   * @description This function updates a specific field within the blog details state and handles error messages.
   */
  const onBlogFieldUpdate = (field, value) => {
    setBlogDetails({ ...blogDetails, [field]: value });
    if (value && errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "";
      setErrors(erroObj);
    }
    if (!value && !errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "This field is required";
      setErrors(erroObj);
    }
  };

  /**
   * Upload a media file to the server.
   * @description This function uploads a media file to the server, updating the medias state and sending data to the server.
   */
  const onUploadMedia = () => {
    if (!mediaName || !mediaFile) {
      message.error("Please enter valid media details..", 2);
      return;
    }
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", mediaFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        addMarktingMediaData({
          marketing_saved_media: { ...savedMedia, [mediaName]: res.data?.url },
        });
        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.error("Media upload failed..");
      });
  };

  /**
   * Close the schedule publish modal.
   * @description This function closes the schedule publish modal and clears the selected schedule publish time.
   */
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    setSchedulePublishTime();
  };

  /**
   * Determine and disable the range of time for schedule publishing.
   * @param {moment.Moment} current - The current date and time.
   * @description This function calculates and disables time ranges based on the current date and time.
   */
  const disabledRangeTime = (current) => {
    setSchedulePublishTime(current);
    const time = new Date();
    const selectedDate = moment(current).date() || new Date();
    const currDate = new Date().getDate();
    if (!current || selectedDate === currDate) {
      return {
        disabledHours: () => range(0, time.getHours()),
        disabledMinutes: () => range(0, time.getMinutes()),
      };
    }
  };

  /**
   * Update a blog and save or publish it.
   * @param {boolean} isDraft - Indicates whether the blog should be saved as a draft.
   * @description This function updates the blog and handles saving or publishing based on the 'isDraft' parameter.
   */
  const onUpdateBlog = (isDraft) => {
    if (
      (Object.values(blogDetails).some((item) => item == "" || !item) ||
        Object.values(blogDetails).length < 6 ||
        !bannerLink ||
        bannerLink === "" ||
        !editotValue ||
        editotValue == "") &&
      !isDraft
    ) {
      message.error("Please complete missing blog fields", 2);
      return;
    } else if (
      (isDraft && blogDetails?.blog_title === "") ||
      !blogDetails?.blog_title
    ) {
      message.error("Blog Title must be required to save draft");
      return;
    }

    let mediasInfo = {};
    medias.map((item) => {
      mediasInfo[item?.name] = item?.url;
      return item;
    });
    let data = {
      banner_image: bannerLink,
      profile_pic: authorProfilePicLink,
      title: blogDetails?.blog_title?.trimEnd(),
      subtitle: blogDetails?.blog_subtitle?.trimEnd(),
      summary: blogDetails?.summary?.trimEnd(),
      media: mediasInfo,
      category: blogDetails?.blog_category,
      author_first_name: blogDetails?.author_first_name?.trimEnd(),
      author_last_name: blogDetails?.author_last_name?.trimEnd(),
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      body: content,
    };
    editBlog(data, blogId)
      .then(() => {
        message.success("Blog Edited Successfully");
        setIsDraft(false);
        history.push("/marketing");
      })
      .catch((e) => {
        message.error(e || "Edit Blog Failed..", 2);
      });
  };

  // Modal for uploading media files.
  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => {
        setUploadMediaModalOpen(false);
        setMediaFile();
        setMediaName();
      }}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={onUploadMedia}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  //  Content for displaying saved media files.
  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>
      {Object.keys(mediaDetails)?.map((item) => (
        <Row gutter={8} align="middle">
          <Col span={6}>
            <span className="media-name">{item}</span>
          </Col>
          <Col span={14}>
            <span className="media-url">
              {mediaDetails[item]?.slice(0, 50) + "..."}
            </span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={mediaDetails[item]}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
          <Col span={2}>
            <span onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                onConfirm={() => onDeleteMedia(item)}
                okText="Yes"
                cancelText="No"
                className="delete-popconfirm "
                okButtonProps={{ style: { marginRight: "12px" } }}
              >
                <img src={Delete} alt="trash" style={{ cursor: "pointer" }} />
              </Popconfirm>
            </span>
          </Col>
        </Row>
      ))}
      {Object.keys(mediaDetails)?.length === 0 && (
        <Empty description={"No Saved Media"} />
      )}
    </div>
  );

  /**
   * Function to retrieve marketing media data.
   * @description This function retrieves marketing media data using the 'getMarktingMediaData()' function.
   */
  const onSavedMedia = () => {
    getMarktingMediaData();
  };

  // Modal for scheduling the blog post.
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => onUpdateBlog(false)}
      className="schedule-publish-modal"
      okButtonProps={{ loading: editBlogLoading }}
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          format={"MM-DD-YYYY HH:mm:ss"}
          defaultValue={
            schedulePublishTime
              ? moment(schedulePublishTime).utc(true).local()
              : null
          }
          showNow={true}
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          disabledTime={(current) => disabledRangeTime(current)}
        />
      </div>
    </Modal>
  );

  return (
    <div className="edit-blog-container">
      <div className="top-add">
        <h6> Edit Blog </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            loading={isDraft && editBlogLoading}
            onClick={() => {
              setErrors("");
              setIsDraft(true);
              onUpdateBlog(true);
            }}
          >
            Save Draft
          </Button>
          <Button
            className="save-btn"
            disable={openschedulePublishModal}
            onClick={() => {
              validateForm() && setOpenschedulePublishModal(true);
            }}
          >
            Schedule Publish
          </Button>
          <Button
            className="publish-btn"
            loading={!isDraft && editBlogLoading}
            onClick={() => {
              validateForm() && onUpdateBlog(false);
            }}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="edit-blog-wrap">
        {uploadLoading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={4}>
              <div className="profile-icon">
                {authorProfilePicLink ? (
                  <img
                    src={authorProfilePicLink || userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : authorProfilePicfile ? (
                  <img
                    src={URL.createObjectURL(authorProfilePicfile)}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : (
                  <img
                    src={userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                )}

                <Upload
                  customRequest={dummyRequest}
                  onChange={async (file) => {
                    if (file?.file?.status === "uploading") {
                      if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                        message.error(IMAGE_SIZE_ERR, 2);
                        return;
                      }
                      onProfileSelect(file.file.originFileObj);
                    }
                  }}
                  accept="image/png,image/jpeg"
                >
                  <div className="camera-icon-wrap">
                    <img
                      src={camera}
                      alt="profile"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Upload>
              </div>
              {authorProfilePicfile && (
                <Button
                  className="upload-profile-btn"
                  onClick={() => onUploadAuthorProfile()}
                >
                  Upload
                </Button>
              )}
            </Col>
            <Col span={14}>
              <Row>
                <Col span={6} offset={12}>
                  <Popover
                    content={savedMediaContent}
                    placement={"bottomRight"}
                    trigger={"click"}
                  >
                    <Button
                      className="upload-btn"
                      icon={<img src={saved} alt="saved" />}
                      onClick={onSavedMedia}
                    >
                      Saved Media
                    </Button>
                  </Popover>
                </Col>
                <Col span={6}>
                  <Button
                    className="upload-btn"
                    onClick={() => setUploadMediaModalOpen(true)}
                    icon={<img src={upload} alt="upload" />}
                  >
                    Upload Media
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="upload-banner">
                  {bannerLink ? (
                    <>
                      {" "}
                      <div className="banner-image-wrap">
                        <img src={bannerLink} alt="banner" />
                      </div>
                      {!bannerImageFile && (
                        <Upload
                          customRequest={dummyRequest}
                          onChange={async (file) => {
                            if (file?.file?.status === "uploading") {
                              if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                                message.error(IMAGE_SIZE_ERR, 2);
                                return;
                              }
                              onBannerSelect(file.file.originFileObj);
                            }
                          }}
                          accept="image/*"
                        >
                          <Button className="upload-banner-btn">
                            Re-Upload Banner
                          </Button>
                        </Upload>
                      )}
                    </>
                  ) : bannerImageFile ? (
                    <div className="banner-image-wrap">
                      <img
                        src={URL.createObjectURL(bannerImageFile)}
                        alt="banner"
                      />
                    </div>
                  ) : (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={async (file) => {
                        if (file?.file?.status === "uploading") {
                          if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                            message.error(IMAGE_SIZE_ERR, 2);
                            return;
                          }
                          onBannerSelect(file.file.originFileObj);
                        }
                      }}
                      accept="image/*"
                    >
                      <div className="upload-inner">
                        <span>
                          <UploadOutlined /> Upload Banner Image
                        </span>
                      </div>
                    </Upload>
                  )}
                </div>
                <div>
                  <div>
                    {showValidation && (
                      <div style={{ color: "red" }}>{errors["bannerLink"]}</div>
                    )}
                  </div>
                  <div className="desc">Banner should be 1073px by 576px</div>
                </div>
                {bannerImageFile && (
                  <Button
                    className="upload-banner-btn"
                    onClick={onUploadBanner}
                  >
                    Upload Banner
                  </Button>
                )}
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Author First name</label>
                    <Input
                      value={blogDetails?.author_first_name}
                      placeholder={"First name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "author_first_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.author_first_name?.length || 0
                        }/15`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["author_first_name"]}
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Author Last Name</label>
                    <Input
                      value={blogDetails?.author_last_name}
                      placeholder={"Last name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "author_last_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.author_last_name?.length || 0
                        }/15`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["author_last_name"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Blog Title</label>
                    <Input
                      value={blogDetails?.blog_title}
                      placeholder={"Title"}
                      className="inputs-box"
                      maxLength={47}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "blog_title",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.blog_title?.length || 0
                        }/47`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["blog_title"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Subtitle</label>
                    <Input
                      value={blogDetails?.blog_subtitle}
                      placeholder={"Subtitle"}
                      className="inputs-box"
                      maxLength={55}
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "blog_subtitle",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          blogDetails?.blog_subtitle?.length || 0
                        }/55`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["blog_subtitle"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <div className="field-box">
                    <label>Category</label>
                    <Select
                      size="large"
                      placeholder="Category"
                      className="selection-option"
                      optionFilterProp="children"
                      showSearch
                      value={blogDetails?.blog_category}
                      showArrow
                      onChange={(value) =>
                        onBlogFieldUpdate("blog_category", value)
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {[
                        "Educational",
                        "News",
                        "Interview",
                        "Startup",
                        "Venture Capital",
                      ]?.map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["blog_category"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Short Summary</label>
                    <TextArea
                      rows={3}
                      maxLength={217}
                      showCount
                      bordered={false}
                      value={blogDetails?.summary}
                      placeholder={"Short summary"}
                      className="inputs-box"
                      onChange={(e) =>
                        onBlogFieldUpdate(
                          "summary",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>{errors["summary"]}</span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Editor
                apiKey={getTinyMCEApiKey()}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(a, b) => {
                    setEditorValue(a);
                    const content = b.getContent({ format: "raw" });
                    setContent(content);
                    if (
                      content != "<p><br></p>" &&
                      content != '<p><br data-mce-bogus="1"></p>' &&
                      errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "";
                      setErrors(erroObj);
                    }
                    if (
                      (content == "<p><br></p>" ||
                        content == '<p><br data-mce-bogus="1"></p>') &&
                      !errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "This field is required";
                      setErrors(erroObj);
                    }
                  }}
                  init={{
                    height: 500,
                    selector: "textarea",
                    plugins:
                      "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc link",
                    menubar: "file edit view insert format tools table help",
                    toolbar:
                      "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                    toolbar_mode: "wrap",
                    block_formats:
                      "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    setup: function (editor) {
                      editor.addShortcut(
                        "alt+shift+1",
                        "H1 is Permanently Disabled",
                        function () {
                          editor.execCommand();
                        }
                      );
                    },
                  }}
                  value={editotValue}
                />
                {showValidation && (
                  <span style={{ color: "red" }}>{errors["format"]}</span>
                )}
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {uploadMediaModal}
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    blogDetail: state.Marketing.blogDetails,
    editBlogLoading: state.Marketing.editBlogLoading,
    mediaData: state.Marketing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogDetails: (id) => dispatch(getBlogDetails(id)),
    addMarktingMediaData: (data) => dispatch(addMarktingMediaData(data)),
    editBlog: (data, id) => dispatch(editBlog(data, id)),
    getMarktingMediaData: () => dispatch(getMarktingMediaData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);
