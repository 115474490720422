import React from "react";
import { connect } from "react-redux";
import { Row, Col, Spin } from "antd";
import "./StartupDetails.scss";
import { getFileNameFromUrl } from "../../../../Utils/helper";

const StartupDetails = (props) => {
  const {
    investor_name,
    startup_name,
    date,
    status,
    amount,
    closing_agreements,
    accredition_method,
  } = props?.location?.state;
  return (
    <div className="transaction-startup-details-container">
      <div className="top-transaction">
        <h6>Transactions</h6>
      </div>
      <div className="startup-details-wrap">
        {props?.loading ? (
          <Spin />
        ) : (
          <>
            <Row gutter={30}>
              <Col span={20}>
                <div className="brief-detail">
                  <Row gutter={24}>
                    <Col span={8}>
                      <div className="box-wrap">
                        <span className="label">Startup Name</span>
                        {startup_name ? (
                          <span className="dark-value">{startup_name}</span>
                        ) : (
                          <span className="value">N/A</span>
                        )}
                      </div>
                      <div className="box-wrap">
                        <span className="label">Investor Name</span>
                        {investor_name ? (
                          <span className="dark-value">{investor_name}</span>
                        ) : (
                          <span className="value">N/A</span>
                        )}
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="box-wrap">
                        <span className="label">Date</span>
                        <span className="value">{date}</span>
                      </div>
                      <div className="box-wrap">
                        <span className="label">Amount</span>
                        <span className="value">{amount}</span>
                      </div>
                      <div className="box-wrap">
                        <span className="label closing-doc-label">
                          Closing Documents
                        </span>
                        <span className="closing-agreements-wrap">
                          {closing_agreements &&
                          closing_agreements?.length !== 0
                            ? closing_agreements?.map((i, index) => {
                                return (
                                  <div className="closing-agreements-link">
                                    <a
                                      href={i}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="filename"
                                      key={index}
                                    >
                                      {getFileNameFromUrl(i)}
                                    </a>
                                  </div>
                                );
                              })
                            : "N/A"}
                        </span>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="box-wrap">
                        <span className="label">Status</span>
                        <div className="pill">
                          <span className="value">{status}</span>
                        </div>
                      </div>
                      <div className="box-wrap">
                        <span className="label">Accreditation Method</span>
                        <span className="value">
                          {accredition_method || "N/A"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default connect(null, null)(StartupDetails);
