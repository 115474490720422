import * as actionTypes from './ActionTypes'
import axios from '../../Utils/api'

// Action to get data for Learn65 learners
export const getLearn65LearnersData=(data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN65_LEARNERS_DATA_INIT
        })
        await axios.post("admin/get-learners",data).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN65_LEARNERS_DATA_SUCCESS,
                payload:res.data?.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN65_LEARNERS_DATA_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to update actions for Learn65 learners
export const updateLearn65LearnerAction=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.SET_LEARN65_LEARNER_ACTION_INIT
        })
        await axios.post("admin/set-learner-action",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.SET_LEARN65_LEARNER_ACTION_SUCCESS,
                payload:res.data,
                learnerId:id,
                toggleState:data?.is_active
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.SET_LEARN65_LEARNER_ACTION_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get details of a Learn65 learner by their ID
export const getLearnerDetailsById=(id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARNER_DETAILS_INIT
        })
        await axios.post("admin/get-single-learner",{},{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARNER_DETAILS_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARNER_DETAILS_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}