// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  examsData: [],
  totalExamsCount: 0,
  examDetails: {},
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EDUCATION_EXAMS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EDUCATION_EXAMS_DATA_SUCCESS:
      return {
        ...state,
        examsData: action.payload?.data || [],
        totalExamsCount: action.payload?.total_count || 0,
        loading: false,
      };
    case actionTypes.GET_EDUCATION_EXAMS_DATA_FAILED:
      return {
        ...state,
        examsData: [],
        totalExamsCount: 0,
        loading: false,
      };
    case actionTypes.TOGGLE_EDUCATION_EXAM_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOGGLE_EDUCATION_EXAM_ACTION_SUCCESS:
      return {
        ...state,
        examsData: state?.examsData?.map((item) => {
          if (item?.id === action?.examId) {
            item.is_active = action?.toggleState;
          }
          return item;
        }),
        loading: false,
      };
    case actionTypes.TOGGLE_EDUCATION_EXAM_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_EDUCATION_EXAM_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_EDUCATION_EXAM_SUCCESS:
      return {
        ...state,
        examsData: state?.examsData?.filter(
          (item) => item?.id !== action?.examId
        ),
        loading: false,
      };
    case actionTypes.DELETE_EDUCATION_EXAM_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_EXAM_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_EXAM_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_EXAM_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        examDetails: action?.payload?.data || {},
        loading: false,
      };
    case actionTypes.GET_EXAM_DETAILS_FAILED:
      return {
        ...state,
        quizDetails: {},
        loading: false,
      };
    case actionTypes.EDIT_EXAM_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_EXAM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_EXAM_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
