import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, message, Pagination, Row, Select, Empty, Spin } from "antd";
import * as quizzesActions from "../../../../../Redux/Actions/quizzesActions";
import pause from "../../../../../Assets/Images/pause.svg";
import play from "../../../../../Assets/Images/play.svg";
import trash from "../../../../../Assets/Images/delete.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import add from "../../../../../Assets/Images/add.svg";
import left from "../../../../../Assets/Images/leftArrow.svg";
import right from "../../../../../Assets/Images/rightArrow.svg";
import quiz from "../../../../../Assets/Images/quizzes.svg";
import userIcon from "../../../../../Assets/Images/user.png";
import clockIcon from "../../../../../Assets/Images/time.png";
import eyeIcon from "../../../../../Assets/Images/eye.png";
import "./Quizzes.scss";
import moment from "moment";

const { Option } = Select;
const Quizzes = ({
  quizzesData,
  totalQuizzesCount,
  searchText,
  getQuizzesData,
  toggleQuizAction,
  deleteQuiz,
  loading,
  history,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [fetchData, setFetchData] = useState(false);

  // Fetches quizzes data with the specified parameters when dependencies change.
  useEffect(() => {
    let data = {
      page_num: page,
      page_size: pageSize,
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
        is_search: true,
        search_text: searchText,
      }),
      learning_type: "series65",
    };
    const timeoutId = setTimeout(() => {
      getQuizzesData(user?.id, data);
    }, 500); // Adjust delay as needed
    return () => clearTimeout(timeoutId);
  }, [page, pageSize, fetchData, searchText]);

  /**
   * Toggles the activation status of a quiz.
   * @param {Event} e - The event object.
   * @param {Object} quiz - The quiz object.
   */
  const onToggleQuizAction = (e, quiz) => {
    e.stopPropagation();
    let data = {
      is_active: !quiz?.is_active,
      quiz_id: quiz?.id,
    };
    toggleQuizAction(data, user?.id)
      .then(() => {
        message.success("Updated..");
      })
      .catch((e) => {
        message.error(e || "Something went wrong..");
      });
  };

  /**
   * Deletes a quiz and updates data on success.
   * @param {Event} e - The event object.
   * @param {string} id - The ID of the quiz to be deleted.
   */
  const onDeleteQuiz = (e, id) => {
    e.stopPropagation();
    let data = {
      quiz_id: id,
      delete: true,
    };
    deleteQuiz(data, user?.id)
      .then(() => {
        setFetchData(!fetchData);
        message.success("Deleted");
      })
      .catch((e) => {
        message.error(e || "Failed to delete");
      });
  };

  /**
   * Navigates to the quiz edit page for the selected quiz.
   * @param {Event} e - The event object.
   * @param {Object} item - The quiz object.
   */
  const onEditQuiz = (e, item) => {
    e.stopPropagation();
    history.push(`/education/learn-series65/quiz-edit/${item?.id}`);
  };

  /**
   * Renders custom elements for pagination navigation.
   * @param {number} current - The current page number.
   * @param {string} type - The type of navigation element (prev, next, page).
   * @param {ReactElement} originalElement - The original element to be rendered.
   * @returns {ReactElement} - The JSX element to be rendered.
   */
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <img className="prev-arrow" src={left} alt="previous" />;
    }
    if (type === "next") {
      return <img className="next-arrow" src={right} alt="next" />;
    }
    return originalElement;
  };

  return (
    <div className="quizzes-container">
      <div className="quizzes-header">
        <h6>Quizzes</h6>
        <div className="header-right">
          <p onClick={() => history.push("/education/learn-series65/add-quiz")}>
            <img src={add} alt="add" />
            <span>Add New</span>
          </p>
          <Select
            defaultValue={10}
            className="selection-menu"
            onChange={(value) => setPagesize(value)}
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
          </Select>
        </div>
      </div>
      <div className="quiz-list-groups">
        {loading ? (
          <Spin />
        ) : (
          quizzesData?.map((item) => (
            <Row
              className="single-list"
              onClick={() =>
                history?.push(
                  // `/education/learn-series65/quiz-preview/${item?.id}`
                  `/education/learn-series65/quiz-detail/${item?.id}`, {
                  searchPlaceholder: "Search Quiz", back: true
                }
                )
              }
            >
              <Col span={1}>
                <div className="icon-box">
                  <img src={quiz} alt="quiz" />
                </div>
              </Col>
              <Col span={16} className="title">
                <h6>{item?.title}
                  {item?.is_draft ? <span className="draftBadge">Draft</span> :
                    item?.publish_date && moment.utc(item?.publish_date).local().isAfter(moment()) ? <span className="publishBadge">
                      <img src={clockIcon} alt="clock" />
                      {`Schedule Publish on: ${moment.utc(item?.publish_date).local().format('D MMMM, YYYY [at] HH:mm:ss')}`}
                    </span> : null}
                </h6>
                <Row gutter={20}>
                  <Col span={7}>
                    <span>Associated with Chapter {item?.chapter_number}</span>
                  </Col>
                  <Col span={16} className="author-details">
                    <span>
                      <img src={userIcon} alt="user" /> By {item?.created_by}
                      <img src={clockIcon} alt="clock" /> {item?.created}
                      <img src={eyeIcon} alt="eye" /> Views :
                      {item?.total_views || 0}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={3} offset={2} className="icon-group">
                <img
                  src={item?.is_active ? pause : play}
                  alt="Pause"
                  onClick={(e) => onToggleQuizAction(e, item)}
                />
                <img
                  src={edit}
                  alt="Edit"
                  onClick={(e) => onEditQuiz(e, item)}
                />
                <img
                  src={trash}
                  alt="Delete"
                  onClick={(e) => onDeleteQuiz(e, item?.id)}
                />
              </Col>
            </Row>
          ))
        )}
      </div>
      {quizzesData?.length === 0 && !loading && (
        <Empty description="No Quizzes" />
      )}
      {totalQuizzesCount > 0 && !loading && (
        <div className="pagination">
          <Pagination
            total={totalQuizzesCount}
            itemRender={itemRender}
            pageSize={pageSize}
            current={page}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    quizzesData: state.Quizzes.quizzesData,
    totalQuizzesCount: state.Quizzes.totalQuizzesCount,
    loading: state.Quizzes.loading,
    searchText: state.Search.searchText,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getQuizzesData: (id, data) =>
      dispatch(quizzesActions.getQuizzesData(id, data)),
    toggleQuizAction: (data, id) =>
      dispatch(quizzesActions.toggleQuizAction(data, id)),
    deleteQuiz: (data, id) => dispatch(quizzesActions.deleteQuiz(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quizzes);
