import React, { useEffect, useState } from "react";
import { message, Table, Popconfirm } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  getSubscriberData,
  deleteSubscriberData,
} from "../../../Redux/Actions/MarketingActions";
import moment from "moment";
import left from "../../../Assets/Images/leftArrow.svg";
import right from "../../../Assets/Images/rightArrow.svg";
import Delete from "../../../Assets/Images/delete.svg";
import "./Subscribers.scss";

const SubscribersTable = ({
  countPerPage,
  searchText,
  getSubscriberData,
  deleteSubscriberData,
  subscribersData,
  loading,
  totalData,
}) => {
  const [page, setPage] = useState(1);
  const [sorterField, setSorterField] = useState();
  const [isDesc, setIsDesc] = useState();

  /**
   * Fetch subscriber data when countPerPage and searchText change.
   * @description - Create a data object with various properties for subscriber data retrieval & Call the 'getSubscriberData' function with the 'data' object as an argument to retrieve subscriber data.
   */
  useEffect(() => {
    setPage(1);
    let data = {
      page_num: 1,
      page_size: countPerPage,
      ...(sorterField && { sort_by: sorterField, descending: isDesc }),
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
          is_search: true,
          search_text: searchText,
        }),
    };
    getSubscriberData(data);
  }, [countPerPage, searchText]);

  /**
   * Function to delete a subscriber data record.
   * @param {Object} record - The subscriber data record to be deleted.
   * @description - Call the 'deleteSubscriberData' function with the 'data' object as an argument to delete the subscriber data.
   */
  const onDeleteRecord = (record) => {
    let data = {
      page_num: page,
      page_size: countPerPage,
      is_delete: true,
      id: record?.id,
      ...(sorterField && { sort_by: sorterField, descending: isDesc }),
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
          is_search: true,
          search_text: searchText,
        }),
    };
    deleteSubscriberData(data)
      .then(() => {
        message.success("deleted");
      })
      .catch((e) => {
        message.error(e || " delete failed..", 2);
      });
  };

  // Columns configuration for the subscriber data table.
  const columns = [
    {
      title: "No.",
      key: "num",
      dataIndex: "num",
      render: (text, record, index) => (
        <span>{(page - 1) * countPerPage + index + 1}</span>
      ),
      width: 110,
    },
    {
      title: "Date",
      dataIndex: "created",
      render: (text, record) => (
        <span>{moment(text).format("MM/DD/YYYY") || "N/A"}</span>
      ),
      sorter: true,
      key: "created",
      width: 300,
    },
    {
      title: "First Name",
      dataIndex: "name",
      render: (text) => <span>{text || "N/A"}</span>,
      sorter: true,
      key: "name",
      width: 300,
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => <span>{text || "N/A"}</span>,
      sorter: true,
      key: "email",
      width: 300,
    },
    {
      title: "Action",
      render: (text, record) => (
        <span onClick={(e) => e.stopPropagation()}>
          <Popconfirm
            placement="topRight"
            title="Sure to delete this Subscriber?"
            onConfirm={() => onDeleteRecord(record)}
            okText="Yes"
            cancelText="No"
            className="delete-popconfirm"
          >
            <img src={Delete} alt="trash" style={{ cursor: "pointer" }} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  /**
   * Function to handle sorting of subscriber data.
   * @param {string} p - The pagination, not used.
   * @param {string} f - The filters, not used.
   * @param {object} sorter - The sorter object that contains sorting information.
   * @param {object} extra - Extra information about the action.
   */
  const onSort = (p, f, sorter, extra) => {
    if (extra.action !== "sort") {
      return;
    }
    if (sorter?.order !== undefined) {
      setSorterField(sorter.field === "name" ? "name" : sorter.field);
      setIsDesc(sorter?.order === "ascend" ? false : true);
      let data = {
        page_num: page,
        page_size: countPerPage,
        sort_by: sorter.field === "name" ? "name" : sorter.field,
        descending: sorter?.order === "ascend" ? false : true,
        ...(searchText !== "" &&
          searchText !== null &&
          searchText !== undefined && {
            is_search: true,
            search_text: searchText,
          }),
      };
      getSubscriberData(data);
    } else {
      setSorterField();
      let data = {
        page_num: page,
        page_size: countPerPage,
        ...(searchText !== "" &&
          searchText !== null &&
          searchText !== undefined && {
            is_search: true,
            search_text: searchText,
          }),
      };
      getSubscriberData(data);
    }
  };
  /**
   * Function to handle page changes in the subscriber data pagination.
   * @param {number} page - The new page number.
   * @param {number} size - The number of items per page, not used.
   */
  const onPageChange = (page, size) => {
    setPage(page);
    let data = {
      page_num: page,
      page_size: countPerPage,
      ...(sorterField && { sort_by: sorterField, descending: isDesc }),
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
          is_search: true,
          search_text: searchText,
        }),
    };
    getSubscriberData(data);
  };

  /**
   * Custom item rendering function for pagination control.
   * @param {number} current - The current page number.
   * @param {string} type - The type of item being rendered ("prev" or "next").
   * @param {ReactElement} originalElement - The original element to be rendered.
   * @returns {ReactElement} - The customized rendering element.
   */
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <img className="prev-arrow" src={left} alt="previous" />;
    }
    if (type === "next") {
      return <img className="next-arrow" src={right} alt="next" />;
    }
    return originalElement;
  };

  const tablePaginationConfig = {
    position: ["bottomLeft"],
    pageSize: countPerPage,
    showSizeChanger: false,
    current: page,
    onChange: onPageChange,
    itemRender: itemRender,
    total: totalData,
  };

  return (
    <div>
      <Table
        dataSource={subscribersData}
        columns={columns}
        loading={loading}
        pagination={tablePaginationConfig}
        onChange={onSort}
        // scroll={{ x: 1200, y: 460 }}
        className="table-container"
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subscribersData: state.Marketing.subscribersData,
    totalData: state.Marketing.totalSubscribersCount,
    searchText: state.Search.searchText,
    loading: state.Marketing.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriberData: (data) => dispatch(getSubscriberData(data)),
    deleteSubscriberData: (data, id) =>
      dispatch(deleteSubscriberData(data, id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscribersTable));
