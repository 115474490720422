import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
  Input,
  message,
  Spin,
  Modal,
  DatePicker,
  Select,
  TimePicker,
} from "antd";
import {
  ClockCircleOutlined,
  MenuOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import * as readinessExamActions from "../../../../../Redux/Actions/ReadinessExamActions";
import moment from "moment";
import userIcon from "../../../../../Assets/Images/user.png";
import trash from "../../../../../Assets/Images/delete.svg";
import play from "../../../../../Assets/Images/play.svg";
import pause from "../../../../../Assets/Images/pause.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import "./AddNewReadinessExam.scss";

const { Option } = Select;
const AddNewReadinessExam = ({
  getChaptersList,
  addNewExam,
  chaptersList,
  history,
  loading,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [examTitle, setExamTitle] = useState("");
  const [examCreator, setExamCreator] = useState("");
  const [examTime, setExamTime] = useState(1800);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ content: "" });
  const [editExamIndex, setEditExamIndex] = useState();
  const [editQuestion, setEditQuestion] = useState();
  const [difficultyLevel, setDifficultyLevel] = useState();
  const [requiredChapters, setRequiredChapters] = useState([]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("learnInvestingAddNewExamData"));
    if (data) {
      setExamTitle(data?.title);
      setExamCreator(data?.created_by);
      setExamTime(data?.time);
      setQuestions(data?.questions);
      setDifficultyLevel(data?.difficulty);
      setRequiredChapters(data?.chapters);
    }
  }, [])

  useEffect(() => {
    // Fetches a list of chapters related to the 'investing' learning type when the component mounts.
    let data = {
      learning_type: "investing",
    };
    getChaptersList(user?.id, data);
  }, []);

  const onCloseSchedulePublishModal = () => {
    //  Closes the schedule publish modal and clears the schedule publish time.
    setOpenschedulePublishModal(false);
    // setSchedulePublishTime();
  };

  /**
   * Initializes a new question with default values for options, correct option, explanation, and active state.
   */
  const onCreateQuestion = () => {
    if (!newQuestion?.content) {
      message.error("Enter Valid question content...");
      return;
    }
    setNewQuestion({
      ...newQuestion,
      options: ["", "", "", ""],
      correct_options: null,
      explanation: "",
      is_active: true,
    });
    setEditQuestion();
    setEditExamIndex();
  };

  /**
   * Saves a new question to the list of questions.
   * @description - Validates the new question's content, options, correct option, and explanation before saving.
   */
  const onSaveQuestion = () => {
    if (
      !newQuestion.content ||
      newQuestion?.options?.some((item) => !item) ||
      (!newQuestion?.correct_options && newQuestion?.correct_options !== 0) ||
      !newQuestion?.explanation
    ) {
      message.error("Enter valid question detail...");
      return;
    }
    setQuestions(
      questions?.concat({
        ...newQuestion,
        correct_options: newQuestion?.options[newQuestion?.correct_options],
      })
    );
    setNewQuestion({ content: "" });
    message.success("Question Added");
  };

  /**
   * Toggles the active state of a question by index.
   * @param {number} index - Index of the question in the questions array.
   */
  const onQuestionActionChange = (index) => {
    const ques = [...questions];
    ques[index]["is_active"] = !ques[index]["is_active"];
    setQuestions(ques);
  };

  /**
   * Deletes a question from the questions array by index.
   * @param {number} index - Index of the question to be deleted.
   */
  const onQuestionDelete = (index) => {
    const ques = [...questions];
    ques?.splice(index, 1);
    setQuestions(ques);
    message.success("Deleted");
  };

  /**
   * Saves edits made to a question in the questions array by index.
   * @description - Validates the edited question's content, options, correct option, and explanation before saving.
   * @param {number} index - Index of the question in the questions array.
   */
  const onEditSave = (index) => {
    if (
      !editQuestion.content ||
      editQuestion?.options?.some((item) => !item) ||
      (!editQuestion?.correct_options && editQuestion?.correct_options !== 0) ||
      !editQuestion?.explanation
    ) {
      message.error("Enter valid question detail...");
      return;
    }
    const ques = [...questions];
    ques[index] = {
      ...editQuestion,
      correct_options: editQuestion?.options[editQuestion?.correct_options],
    };
    setQuestions(ques);
    setEditQuestion();
    setEditExamIndex();
    message.success("Question Edited Successfully");
  };

  /**
   * Checks if the exam data is valid by validating exam title, creator, difficulty level, time, questions, and required chapters.
   * @returns {boolean} - True if the exam data is valid; otherwise, false.
   */
  const checkExamDataValid = () => {
    let isValid = false;
    const isQusetionsValid = questions?.every(
      (item) =>
        item?.content &&
        item?.options?.every((item) => item) &&
        item.correct_options &&
        item?.explanation
    );
    if (
      examCreator &&
      examTitle &&
      difficultyLevel &&
      examTime &&
      questions?.length > 0 &&
      isQusetionsValid
    ) {
      isValid = true;
    }
    return isValid;
  };

  /**
   * Publishes the exam, either as a draft or for preview.
   * @description - Validates the exam data before publishing.
   * @param {boolean} isDraft - True if the exam should be published as a draft; otherwise, false.
   * @param {boolean} isPreview - True if the exam should be opened for preview after publishing; otherwise, false.
   */
  const onPublishExam = (isDraft, isPreview = false) => {
    const isValid = checkExamDataValid();
    if (!isValid) {
      message.error("Enter Valid Exam Details..", 2);
      return;
    }
    let data = {
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      learning_type: "investing",
      title: examTitle?.trimEnd(),
      created_by: examCreator?.trimEnd(),
      time: examTime,
      questions: questions,
      difficulty: difficultyLevel,
      is_active: true,
      chapters: requiredChapters,
    };
    addNewExam(data, user?.id, (data) => {
      isPreview &&
        history.push({
          pathname: `/education/learn-investing/exam-preview/${data?.id}`,
        });
    })
      .then(() => {
        !isPreview && message.success("Exam Added Successfully");
        localStorage.removeItem("learnInvestingAddNewExamData")
        !isPreview &&
          history.push({
            pathname: `/education/learn-investing/exams`,
            state: { searchPlaceholder: "Search Exam", back: true },
          });
      })
      .catch((e) => {
        message.error(e || "Add Exam Failed..", 2);
      });
  };

  /**
   * A modal component for scheduling the exam publish date and time.
   */
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => {
        onPublishExam(false)
        onCloseSchedulePublishModal()
      }}
      className="schedule-publish-modal"
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={false}
          disabledDate={(current) => current && current.valueOf() < Date.now()}
        />
      </div>
    </Modal>
  );

  return (
    <div className="add-exam-container">
      <div className="top-add">
        <h6> Add New Readiness Exam </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            // onClick={() => onPublishExam(true, true)}
            onClick={async() => {
              let examDetails = {
                // is_draft: isDraft,
                ...(schedulePublishTime && { publish_date: schedulePublishTime }),
                learning_type: "investing",
                title: examTitle?.trimEnd(),
                created_by: examCreator?.trimEnd(),
                time: examTime,
                questions: questions,
                difficulty: difficultyLevel,
                is_active: true,
                chapters: requiredChapters,
              };
              await localStorage.setItem("learnInvestingAddNewExamData", JSON.stringify(examDetails))
              history.push("/education/learn-investing/add-exam-preview",{
                examDetails,
                searchPlaceholder: "Search Exam", back: true
              })
            }}
          >
            Preview
          </Button>
          <Button className="save-btn" onClick={() => onPublishExam(true)}>
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => setOpenschedulePublishModal(true)}
          >
            Schedule Publish
          </Button>
          <Button className="publish-btn" onClick={() => onPublishExam(false)}>
            Publish
          </Button>
        </div>
      </div>
      <div className="add-exam-wrap">
        {loading ? (
          <Spin />
        ) : (
          <div>
            <Row gutter={32}>
              <Col span={12}>
                <div className="field-box">
                  <label>Exam Title</label>
                  <Input
                    value={examTitle}
                    placeholder={"Exam title"}
                    className="inputs-box"
                    onChange={(e) => setExamTitle(e.target.value?.trimStart())}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <label>Created By</label>
                  <Input
                    prefix={
                      <img
                        src={userIcon}
                        className="prefix-icon"
                        alt="user-icon"
                      />
                    }
                    value={examCreator}
                    placeholder={"Created By"}
                    className="inputs-box"
                    onChange={(e) =>
                      setExamCreator(e.target.value?.trimStart())
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Row className="timer-wrap">
                  <Col span={3}>
                    <div className="clock-wrap">
                      <ClockCircleOutlined />
                    </div>
                  </Col>
                  <Col span={6}>
                    <span className="time-limit">Set time limit</span>
                  </Col>
                  <Col span={12}>
                    <TimePicker
                      showNow={false}
                      defaultValue={moment("01:30:00", "HH:mm:ss")}
                      size="middle"
                      onChange={(value) => {
                        let totalSeconds =
                          moment(value, "HH:mm:ss").hours() * 60 * 60 +
                          moment(value, "HH:mm:ss").minutes() * 60 +
                          moment(value, "HH:mm:ss").seconds();
                        setExamTime(isNaN(totalSeconds) ? 1800 : totalSeconds);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <Select
                    className="selection-option"
                    placeholder="Select Difficulty Level"
                    value={difficultyLevel}
                    showArrow
                    onChange={(value) => setDifficultyLevel(value)}
                  >
                    <Option key={"Fundamentals"} value={"Fundamentals"}>
                      Fundamentals
                    </Option>
                    <Option key={"Intermediate"} value={"Intermediate"}>
                      Intermediate
                    </Option>
                    <Option key={"Advanced"} value={"Advanced"}>
                      Advanced
                    </Option>
                    <Option key={"Expert"} value={"Expert"}>
                      Expert
                    </Option>
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <Select
                    className="selection-option"
                    placeholder="Select Chapters"
                    optionFilterProp="children"
                    showSearch
                    mode="multiple"
                    value={requiredChapters}
                    showArrow
                    onChange={(value) => setRequiredChapters(value)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {chaptersList?.map((item) => {
                      return (
                        <Option
                          key={item?.id}
                          value={item?.id}
                        >{`Chapter ${item?.chapter_number}`}</Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
            {!newQuestion?.options && (
              <Row>
                <div className="add-que-wrapper">
                  <p className="label">New Question</p>
                  <Input
                    placeholder="Question content"
                    className="inputs-box"
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        content: e.target.value,
                      })
                    }
                  />
                  <Button className="create-btn" onClick={onCreateQuestion}>
                    <PlusOutlined /> Create Question
                  </Button>
                </div>
              </Row>
            )}
            {newQuestion?.options && (
              <Row className="add-que">
                <div className="add-question-content">
                  <div className="question">
                    <div className="left-section">
                      <div className="prefix">
                        <MenuOutlined />
                      </div>
                      <span className="content">{newQuestion?.content}</span>
                    </div>
                    <div className="right-section">
                      <SaveOutlined
                        color="#425a85"
                        size={"18px"}
                        onClick={onSaveQuestion}
                      />
                      <img
                        src={trash}
                        alt="delete"
                        onClick={() => setNewQuestion({ content: "" })}
                      />
                    </div>
                  </div>
                </div>
                <div className="options-wrapper">
                  <div className="correct">
                    <span>Correct</span>
                  </div>
                  {newQuestion?.options?.map((item, index) => {
                    return (
                      <div className="option-wrap">
                        <div className="left-section">
                          <div className="prefix">
                            <MenuOutlined />
                          </div>
                          <Input
                            value={item}
                            onChange={(e) => {
                              const opts = newQuestion?.options;
                              opts[index] = e.target.value?.trimStart();
                              setNewQuestion({ ...newQuestion, options: opts });
                            }}
                            placeholder={`Option ${index + 1}`}
                            maxLength={152}
                          />
                        </div>
                        <div className="right-section">
                          <div
                            className="circle-outer"
                            onClick={() =>
                              setNewQuestion({
                                ...newQuestion,
                                correct_options: index,
                              })
                            }
                          >
                            {index === newQuestion?.correct_options && (
                              <div className="circle-inner"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <TextArea
                    value={newQuestion?.explanation}
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        explanation: e.target.value?.trimStart(),
                      })
                    }
                    rows={3}
                    placeholder="Write answer explanation"
                    className="text-area-box"
                  />
                </div>
              </Row>
            )}
            <Row>
              <div className="questions-list-wrap">
                {questions?.map((question, index) => {
                  return index === editExamIndex ? (
                    <div className="add-que">
                      <div className="add-question-content">
                        <div className="question">
                          <div className="left-section">
                            <div className="prefix">
                              <MenuOutlined />
                            </div>
                            <span className="content">
                              {editQuestion?.content}
                            </span>
                          </div>
                          <div className="right-section">
                            <SaveOutlined
                              color="#425a85"
                              size={"18px"}
                              onClick={() => onEditSave(index)}
                            />
                            <img
                              src={trash}
                              alt="delete"
                              onClick={() => onQuestionDelete(index)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="options-wrapper">
                        <div className="correct">
                          <span>Correct</span>
                        </div>
                        {editQuestion?.options?.map((item, ind) => {
                          return (
                            <div className="option-wrap">
                              <div className="left-section">
                                <div className="prefix">
                                  <MenuOutlined />
                                </div>
                                <Input
                                  value={item}
                                  onChange={(e) => {
                                    const opts = editQuestion?.options;
                                    opts[ind] = e.target.value;
                                    setEditQuestion({
                                      ...editQuestion,
                                      options: opts,
                                    });
                                  }}
                                  placeholder={`Option ${index + 1}`}
                                  maxLength={152}
                                />
                              </div>
                              <div className="right-section">
                                <div
                                  className="circle-outer"
                                  onClick={() =>
                                    setEditQuestion({
                                      ...editQuestion,
                                      correct_options: ind,
                                    })
                                  }
                                >
                                  {ind === editQuestion?.correct_options && (
                                    <div className="circle-inner"></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <TextArea
                          value={editQuestion?.explanation}
                          onChange={(e) =>
                            setEditQuestion({
                              ...editQuestion,
                              explanation: e.target.value,
                            })
                          }
                          rows={3}
                          placeholder="Write answer explanation"
                          className="text-area-box"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="add-question-content">
                      <div className="question">
                        <div className="left-section">
                          <div className="prefix">
                            <MenuOutlined />
                          </div>
                          <span className="content">{question?.content}</span>
                        </div>
                        <div className="right-section">
                          <img
                            src={question?.is_active ? pause : play}
                            alt={question?.is_active ? "pause" : "play"}
                            onClick={() => onQuestionActionChange(index)}
                          />
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => {
                              setEditExamIndex(index);
                              setEditQuestion({
                                ...question,
                                correct_options: question?.options?.findIndex(
                                  (item) => item === question?.correct_options
                                ),
                              });
                            }}
                          />
                          <img
                            src={trash}
                            alt="delete"
                            onClick={() => onQuestionDelete(index)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Row>
          </div>
        )}
      </div>
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.ReadinessExam.loading,
    userData: state.Auth.userData,
    chaptersList: state.VideoLecture.chaptersList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewExam: (data, id, onSuccess) =>
      dispatch(readinessExamActions.addNewExam(data, id, onSuccess)),
    getChaptersList: (id, data) =>
      dispatch(readinessExamActions.getChaptersList(id, data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewReadinessExam);
