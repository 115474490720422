import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  Upload,
  Button,
  Col,
  Row,
  Popover,
  Input,
  message,
  Spin,
  Modal,
  DatePicker,
  Empty,
} from "antd";
import { dummyRequest, getTinyMCEApiKey } from "../../../../../Utils/helper";
import axiosInstance from "../../../../../Utils/api";
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as ChaptersActions from "../../../../../Redux/Actions/ChaptersActions";
import upload from "../../../../../Assets/Images/upload.svg";
import saved from "../../../../../Assets/Images/saved.svg";
import { UploadOutlined, CloseOutlined, CopyOutlined } from "@ant-design/icons";
import "./AddNewChapter.scss";

const AddNewChapter = ({ addNewChapter, history, loading }) => {
  const editorRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [headerLink, setHeaderLink] = useState();
  const [chapterDetails, setChapterDetails] = useState({});
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [headerImageFile, setHeaderImageFile] = useState();
  const [uploadLoading, setUploadLoading] = useState(false);
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [editotValue, setEditorValue] = useState("");
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [content, setContent] = useState();

  /**
   * Function to handle the selection of a header image. It sets the selected file as the header image file,
   * initiates the upload process, and updates the header image link upon successful upload.
   * @param {File} file - The selected header image file.
   */

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("learnInvestingAddChapterData"));
    if (data) {
      setChapterDetails({
        chapter_number: data?.chapter_number,
        created_by: data?.created_by,
        chapter_title: data?.title,
        chapter_subtitle: data?.subtitle,
      })
      setHeaderLink(data?.header_image)
      setEditorValue(data?.content)
      setContent(data?.content)
    }
  }, [])

  const onHeaderSelect = (file) => {
    setHeaderImageFile(file);
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", file);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setHeaderLink(res?.data?.url);
        setHeaderImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Header image uploaded successfully");
      })
      .catch((e) => {
        setHeaderImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Header image upload failed..");
      });
  };

  /**
   * Function to update a specific field in the chapter details.
   * @param {string} field - The field to update in the chapter details.
   * @param {any} value - The new value for the field.
   */
  const onChapterFieldUpdate = (field, value) => {
    setChapterDetails({ ...chapterDetails, [field]: value });
  };

  /**
   * Function to handle the upload of media files. It uploads the selected media file, updates the media list upon success,
   * and displays appropriate messages for success and failure.
   */
  const onUploadMedia = () => {
    if (!mediaName || !mediaFile) {
      message.error("Please enter valid media details..", 2);
      return;
    }
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", mediaFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.error("Media upload failed..");
      });
  };

  /**
   * Function to close the schedule publish modal and clear the scheduled publish time.
   */
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    // setSchedulePublishTime();
  };

  /**
   * Function to publish a chapter, either as a draft or for preview.
   * @description - It validates the chapter details, prepares the data, and makes a request to add the new chapter.
   * @param {boolean} isDraft - Indicates whether the chapter should be published as a draft.
   * @param {boolean} isPreview - Indicates whether the chapter is being published for preview.
   */
  const onPublishChapter = (isDraft, isPreview = false) => {
    if (
      Object.values(chapterDetails)?.some((item) => item == "" || !item) ||
      Object.values(chapterDetails)?.length < 4
    ) {
      message.error("Please enter valid chapter details...", 2);
      return;
    }
    let data = {
      ...(headerLink && { header_image: headerLink }),
      chapter_number: parseInt(chapterDetails?.chapter_number),
      created_by: chapterDetails?.created_by?.trimEnd(),
      title: chapterDetails?.chapter_title?.trimEnd(),
      subtitle: chapterDetails?.chapter_subtitle?.trimEnd(),
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      content: content,
      learning_type: "investing",
    };
    addNewChapter(data, user?.id, (data) => {
      isPreview &&
        history.push(`/education/learn-investing/chapter-preview/${data?.id}`);
    })
      .then(() => {
        !isPreview && message.success("Chapter Added Successfully");
        localStorage.removeItem("learnInvestingAddChapterData")
        !isPreview &&
          history.push({
            pathname: `/education/learn-investing/chapters`,
            state: { searchPlaceholder: "Search Chapter", back: true },
          });
      })
      .catch((e) => {
        message.error(e || "Add Chapter Failed..", 2);
      });
  };

  //  Modal for uploading media files. Allows users to specify media details and upload media.
  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => {
        setUploadMediaModalOpen(false);
        setMediaFile();
        setMediaName();
      }}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={() => onUploadMedia()}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  //  Popup displaying saved media files.
  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>
      {medias?.map((item) => (
        <Row gutter={8}>
          <Col span={6}>
            <span className="media-name">{item?.name}</span>
          </Col>
          <Col span={16}>
            <span className="media-url">{item?.url?.slice(0, 50) + "..."}</span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={item?.url}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
        </Row>
      ))}
      {medias?.length === 0 && <Empty description={"No Saved Media"} />}
    </div>
  );

  //  Modal for scheduling chapter publication with a date and time picker.
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => {
        onPublishChapter(false)
        onCloseSchedulePublishModal()
      }}
      className="schedule-publish-modal"
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={false}
          disabledDate={(current) => current && current.valueOf() < Date.now()}
        />
      </div>
    </Modal>
  );

  return (
    <div className="add-chapter-container">
      <div className="top-add">
        <h6> Add New Chapter </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            // onClick={() => onPublishChapter(true, true)}
            onClick={async () => {
              const chapterDetail = {
                ...(headerLink && { header_image: headerLink }),
                chapter_number: parseInt(chapterDetails?.chapter_number),
                created_by: chapterDetails?.created_by?.trimEnd(),
                title: chapterDetails?.chapter_title?.trimEnd(),
                subtitle: chapterDetails?.chapter_subtitle?.trimEnd(),
                // is_draft: isDraft,
                ...(schedulePublishTime && { publish_date: schedulePublishTime }),
                content: content,
                learning_type: "investing",
              }
              await localStorage.setItem("learnInvestingAddChapterData", JSON.stringify(chapterDetail))
              history.push("/education/learn-investing/add-chapter-preview", {
                chapterDetail,
                searchPlaceholder: "Search Chapter", back: true
              })
            }
            }
          >
            Preview
          </Button>
          <Button className="save-btn" onClick={() => onPublishChapter(true)}>
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => setOpenschedulePublishModal(true)}
          >
            Schedule Publish
          </Button>
          <Button
            className="publish-btn"
            onClick={() => onPublishChapter(false)}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="add-chapter-wrap">
        {uploadLoading || loading ? (
          <Spin />
        ) : (
          <Row gutter={48}>
            <Col span={8}>
              <Row>
                <div className="upload-banner">
                  {headerLink ? (
                    <div className="banner-image-wrap">
                      <img src={headerLink} alt="banner" />
                    </div>
                  ) : headerImageFile ? (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={(file) =>
                        onHeaderSelect(file.file.originFileObj)
                      }
                      accept="image/*"
                    >
                      <div className="banner-image-wrap">
                        <img
                          src={URL.createObjectURL(headerImageFile)}
                          alt="banner"
                        />
                      </div>
                    </Upload>
                  ) : (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={(file) =>
                        onHeaderSelect(file.file.originFileObj)
                      }
                      accept="image/*"
                    >
                      <div className="upload-inner">
                        <span>
                          <UploadOutlined /> Upload Chapter Header Image
                        </span>
                      </div>
                    </Upload>
                  )}
                </div>
              </Row>
            </Col>
            <Col span={16}>
              <Row>
                <Col span={6} offset={12}>
                  <Popover
                    content={savedMediaContent}
                    placement={"bottomRight"}
                    trigger={"click"}
                  >
                    <Button
                      className="upload-btn"
                      icon={<img src={saved} alt="saved" />}
                    >
                      Saved Media
                    </Button>
                  </Popover>
                </Col>
                <Col span={6}>
                  <Button
                    className="upload-btn"
                    onClick={() => setUploadMediaModalOpen(true)}
                    icon={<img src={upload} alt="upload" />}
                  >
                    Upload Media
                  </Button>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Chapter Number</label>
                     <Input
                      value={chapterDetails?.chapter_number}
                      placeholder={"Chapter number"}
                      className="inputs-box"
                      type="text"
                      inputMode="numeric"
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
                        onChapterFieldUpdate("chapter_number", newValue)
                      }
                      }
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Created By</label>
                    <Input
                      value={chapterDetails?.created_by}
                      placeholder={"Created by"}
                      className="inputs-box"
                      onChange={(e) =>
                        onChapterFieldUpdate(
                          "created_by",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Chapter Title</label>
                    <Input
                      value={chapterDetails?.chapter_title}
                      placeholder={"Title"}
                      className="inputs-box"
                      onChange={(e) =>
                        onChapterFieldUpdate(
                          "chapter_title",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Subtitle</label>
                    <Input
                      value={chapterDetails?.chapter_subtitle}
                      placeholder={"Subtitle"}
                      className="inputs-box"
                      onChange={(e) =>
                        onChapterFieldUpdate(
                          "chapter_subtitle",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Content</label>
                    <Editor
                      apiKey={getTinyMCEApiKey()}
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      onEditorChange={(a, b) => {
                        setEditorValue(a);
                        setContent(b.getContent({ format: "raw" }));
                      }}
                      init={{
                        height: 500,
                        selector: "textarea",
                        plugins:
                          "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc link",
                        menubar:
                          "file edit view insert format tools table help",
                        toolbar:
                          "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                        toolbar_mode: "wrap",
                        block_formats:
                          "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        setup: function (editor) {
                          editor.addShortcut(
                            "alt+shift+1",
                            "H1 is Permanently Disabled",
                            function () {
                              editor.execCommand();
                            }
                          );
                        },
                      }}
                      value={editotValue}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {schedulePublishModal}
      {uploadMediaModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.Chapter.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewChapter: (data, id, onSuccess) =>
      dispatch(ChaptersActions.addNewChapter(data, id, onSuccess)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewChapter);
