import { capitalize } from "../../Utils/helper"; //Helper function
// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  investorsData: [],
  loading: false,
  totalInvestors: 0,
  investorDetails: {},
  currentUpdatePosition: 0,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVESTORS_DATA_INIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_INVESTORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        investorsData: action.payload?.data?.map((item) => {
          let date = new Date(item?.created?.$date);
          let mnth = ("0" + (date?.getMonth() + 1))?.slice(-2);
          let day = ("0" + date?.getDate())?.slice(-2);
          let year = date?.getFullYear();
          return {
            ...item,
            status:
              item?.status?.status?.charAt(0)?.toUpperCase() +
              item?.status?.status?.slice(1),
            first_last_name: item?.first_name + " " + item?.last_name,
            signup_date: [mnth, day, year]?.join("-"),
          };
        }),
        totalInvestors: action.payload.total_count,
      };
    case actionTypes.GET_INVESTORS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        startupsData: [],
        totalStartups: 0,
        error: action.payload,
      };
    case actionTypes.DELETE_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        investorsData: action.payload?.data?.map((item) => {
          let date = new Date(item?.created?.$date);
          let mnth = ("0" + (date?.getMonth() + 1))?.slice(-2);
          let day = ("0" + date?.getDate())?.slice(-2);
          let year = date?.getFullYear();
          return {
            ...item,
            status:
              item?.status?.status?.charAt(0)?.toUpperCase() +
              item?.status?.status?.slice(1),
            first_last_name: item?.first_name + " " + item?.last_name,
            signup_date: [mnth, day, year]?.join("-"),
          };
        }),
        totalInvestors: action.payload.total_count,
      };
    case actionTypes.DELETE_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_INVESTORS_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVESTORS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        investorDetails: action.payload?.data,
      };
    case actionTypes.GET_INVESTORS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        investorDetails: {},
      };
    case actionTypes.UPDATE_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_INVESTOR_STATUS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_INVESTOR_STATUS_SUCCESS:
      return {
        ...state,
        investorsData: state?.investorsData?.map((item) => {
          if (item?._id?.$oid === action?.investorId) {
            return {
              ...item,
              status: capitalize(action?.payload?.data?.status?.status),
            };
          } else {
            return item;
          }
        }),
        loading: false,
      };
    case actionTypes.UPDATE_INVESTOR_STATUS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_CURRENT_UPDATE_INVESTOR_POSITION_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_CURRENT_UPDATE_INVESTOR_POSITION_SUCCESS:
      return {
        ...state,
        currentUpdatePosition: action?.payload?.position,
      };
    case actionTypes.GET_CURRENT_UPDATE_INVESTOR_POSITION_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default store;
