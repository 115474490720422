import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Spin } from "antd";
import * as authAction from "./Redux/Actions/AuthActions";
import Navbar from "./Components/Navbar/navbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import RouteConfig from "./RouteConfig";
import "./app.scss";
import "./Container/Rejected/Rejected.scss";
import "./Container/Waitlist/Waitlist.scss";

function App({ adminLogout, history }) {  
  // Get user data from local storage
  const user = JSON.parse(localStorage.getItem("userdata"));

  // Get expiration time from Redux state
  const expTime = useSelector((state) => state.Auth.exp);

  // Automatically log out the user when the session expires
    useEffect(() => {
        let timeout;
    if (expTime) {
      timeout = setTimeout(() => {
        adminLogout().then(() => {
          localStorage.removeItem("exp");
          history.push("/login");
        });
      }, new Date(expTime * 1000) - new Date());
    }
    return () => clearTimeout(timeout);
  }, [expTime]);

  // Function to render routes based on user authentication status
  const renderRoutes = (routeArray) => {
    return (
      <Switch>
        {routeArray.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
        <Redirect to={routeArray[0].path} />
      </Switch>
    );
  };
  return (
    <div className="App">
      <div className="outer-box">
        {/* Render Sidebar if user is authenticated */}
        {user && <Sidebar />}
        <div style={{ flex: "1", overflow: "hidden" }}>
          {/* Render Navbar if user is authenticated */}
          {user && <Navbar />}
          {/* Render authenticated or unauthenticated routes based on user status */}
          {user && (
            <React.Suspense fallback={<Spin />}>
              {renderRoutes(RouteConfig.authenticated)}
            </React.Suspense>
          )}
          {!user && (
            <React.Suspense fallback={<Spin />}>
              {renderRoutes(RouteConfig.unauthenticated)}
            </React.Suspense>
          )}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogout: () => dispatch(authAction.adminLogout()),
  };
};

export default connect(null, mapDispatchToProps)(withRouter(App));
