import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
  Input,
  message,
  Spin,
  Modal,
  DatePicker,
  Select,
  TimePicker,
} from "antd";
import {
  ClockCircleOutlined,
  MenuOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import * as quizActions from "../../../../../Redux/Actions/quizzesActions";
import moment from "moment";
import userIcon from "../../../../../Assets/Images/user.png";
import trash from "../../../../../Assets/Images/delete.svg";
import play from "../../../../../Assets/Images/play.svg";
import pause from "../../../../../Assets/Images/pause.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import "./AddNewQuiz.scss";

const { Option } = Select;
const AddNewQuiz = ({
  getChaptersList,
  addNewQuiz,
  loading,
  chaptersList,
  history,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [quizTitle, setQuizTitle] = useState("");
  const [quizCreator, setQuizCreator] = useState("");
  const [quizTime, setQuizTime] = useState(1800);
  const [chapterId, setChapterId] = useState();
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ content: "" });
  const [editQuizIndex, setEditQuizIndex] = useState();
  const [editQuestion, setEditQuestion] = useState();

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("learnSeries65AddQuizData"));
    if (data) {
      setQuizTitle(data?.title);
      setQuizCreator(data?.created_by);
      setQuizTime(data?.time);
      setChapterId(data?.chapter_id);
      setQuestions(data?.questions);
    }
  }, [])

  // Fetches the list of chapters with the specified learning type.
  useEffect(() => {
    let data = {
      learning_type: "series65",
    };
    getChaptersList(user?.id, data);
  }, []);

  // Closes the schedule publish modal and resets the schedule publish time.
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    // setSchedulePublishTime();
  };

  /**
   * Creates a new question by resetting the newQuestion state.
   * Displays an error message if the question content is not provided.
   */
  const onCreateQuestion = () => {
    if (!newQuestion?.content) {
      message.error("Enter Valid question content...");
      return;
    }
    setNewQuestion({
      ...newQuestion,
      options: ["", "", "", ""],
      correct_options: null,
      explanation: "",
      is_active: true,
    });
    setEditQuestion();
    setEditQuizIndex();
  };

  /**
   * Saves the new question to the questions state.
   * Displays an error message if any required question details are missing.
   */
  const onSaveQuestion = () => {
    if (
      !newQuestion.content ||
      newQuestion?.options?.some((item) => !item) ||
      (!newQuestion?.correct_options && newQuestion?.correct_options !== 0) ||
      !newQuestion?.explanation
    ) {
      message.error("Enter valid question detail...");
      return;
    }
    setQuestions(
      questions?.concat({
        ...newQuestion,
        correct_options: newQuestion?.options[newQuestion?.correct_options],
      })
    );
    setNewQuestion({ content: "" });
    message.success("Question Added");
  };

  /**
   * Toggles the is_active property of a question at the specified index.
   * @param {number} index - The index of the question in the questions array.
   */
  const onQuestionActionChange = (index) => {
    const ques = [...questions];
    ques[index]["is_active"] = !ques[index]["is_active"];
    setQuestions(ques);
  };

  /**
   * Deletes a question at the specified index from the questions state.
   * @param {number} index - The index of the question in the questions array.
   */
  const onQuestionDelete = (index) => {
    const ques = [...questions];
    ques?.splice(index, 1);
    setQuestions(ques);
    message.success("Deleted");
  };

  /**
   * Saves the edited question at the specified index in the questions state.
   * Displays an error message if any required question details are missing.
   * @param {number} index - The index of the question in the questions array.
   */
  const onEditSave = (index) => {
    if (
      !editQuestion.content ||
      editQuestion?.options?.some((item) => !item) ||
      (!editQuestion?.correct_options && editQuestion?.correct_options !== 0) ||
      !editQuestion?.explanation
    ) {
      message.error("Enter valid question detail...");
      return;
    }
    const ques = [...questions];
    ques[index] = {
      ...editQuestion,
      correct_options: editQuestion?.options[editQuestion?.correct_options],
    };
    setQuestions(ques);
    setEditQuestion();
    setEditQuizIndex();
    message.success("Question Edited Successfully");
  };

  /**
   * Checks if the quiz data is valid by verifying required fields.
   * @returns {boolean} - True if the quiz data is valid, otherwise false.
   */
  const checkQuizDataValid = () => {
    let isValid = false;
    const isQusetionsValid = questions?.every(
      (item) =>
        item?.content &&
        item?.options?.every((item) => item) &&
        item.correct_options &&
        item?.explanation
    );
    if (
      quizCreator &&
      quizTitle &&
      chapterId &&
      quizTime &&
      questions?.length > 0 &&
      isQusetionsValid
    ) {
      isValid = true;
    }
    return isValid;
  };

  /**
   * Publishes or previews a quiz based on provided parameters.
   * Displays an error message if the quiz data is not valid.
   * @param {boolean} isDraft - Indicates whether the quiz is a draft.
   * @param {boolean} isPreview - Indicates whether the action is to preview the quiz.
   */
  const onPublishQuiz = (isDraft, isPreview = false) => {
    const isValid = checkQuizDataValid();
    if (!isValid) {
      message.error("Enter Valid Quiz Details..", 2);
      return;
    }
    let data = {
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      learning_type: "series65",
      title: quizTitle?.trimEnd(),
      chapter_id: chapterId,
      created_by: quizCreator?.trimEnd(),
      time: quizTime,
      questions: questions,
    };
    addNewQuiz(data, user?.id, (data) => {
      isPreview &&
        history.push({
          pathname: `/education/learn-series65/quiz-preview/${data?.id}`,
        });
    })
      .then(() => {
        !isPreview && message.success("Quiz Added Successfully");
        localStorage.removeItem("learnSeries65AddQuizData")
        !isPreview &&
          history.push({
            pathname: `/education/learn-series65/quizzes`,
            state: { searchPlaceholder: "Search Quiz", back: true },
          });
      })
      .catch((e) => {
        message.error(e || "Add Quiz Failed..", 2);
      });
  };

  // Modal for scheduling quiz publishing.
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => {
        onPublishQuiz(false)
        onCloseSchedulePublishModal()
      }
      }
      className="schedule-publish-modal"
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={false}
          disabledDate={(current) => current && current.valueOf() < Date.now()}
        />
      </div>
    </Modal>
  );

  return (
    <div className="add-quiz-container">
      <div className="top-add">
        <h6> Add New Quiz </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            // onClick={() => onPublishQuiz(true, true)}
            onClick={async () => {
              const quizDetails = {
                // is_draft: isDraft,
                ...(schedulePublishTime && { publish_date: schedulePublishTime }),
                learning_type: "investing",
                title: quizTitle?.trimEnd(),
                chapter_id: chapterId,
                created_by: quizCreator?.trimEnd(),
                time: quizTime,
                questions: questions,
              };
              await localStorage.setItem("learnSeries65AddQuizData", JSON.stringify(quizDetails))
              history.push("/education/learn-series65/add-quiz-preview", {
                quizDetails,
                searchPlaceholder: "Search Quiz", back: true
              })
            }}
          >
            Preview
          </Button>
          <Button className="save-btn" onClick={() => onPublishQuiz(true)}>
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => setOpenschedulePublishModal(true)}
          >
            Schedule Publish
          </Button>
          <Button className="publish-btn" onClick={() => onPublishQuiz(false)}>
            Publish
          </Button>
        </div>
      </div>
      <div className="add-quiz-wrap">
        {loading ? (
          <Spin />
        ) : (
          <div>
            <Row gutter={32}>
              <Col span={12}>
                <div className="field-box">
                  <label>Quiz Title</label>
                  <Input
                    value={quizTitle}
                    placeholder={"Quiz title"}
                    className="inputs-box"
                    onChange={(e) => setQuizTitle(e.target.value?.trimStart())}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <label>Created By</label>
                  <Input
                    prefix={
                      <img
                        src={userIcon}
                        className="prefix-icon"
                        alt="user-icon"
                      />
                    }
                    value={quizCreator}
                    placeholder={"Created By"}
                    className="inputs-box"
                    onChange={(e) =>
                      setQuizCreator(e.target.value?.trimStart())
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Row className="timer-wrap">
                  <Col span={3}>
                    <div className="clock-wrap">
                      <ClockCircleOutlined />
                    </div>
                  </Col>
                  <Col span={6}>
                    <span className="time-limit">Set time limit</span>
                  </Col>
                  <Col span={12}>
                    <TimePicker
                      showNow={false}
                      defaultValue={moment("01:30:00", "HH:mm:ss")}
                      size="middle"
                      onChange={(value) => {
                        let totalSeconds =
                          moment(value, "HH:mm:ss").hours() * 60 * 60 +
                          moment(value, "HH:mm:ss").minutes() * 60 +
                          moment(value, "HH:mm:ss").seconds();
                        setQuizTime(isNaN(totalSeconds) ? 1800 : totalSeconds);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <Select
                    placeholder="Select Chapter"
                    className="selection-option"
                    optionFilterProp="children"
                    showSearch
                    value={chapterId}
                    showArrow
                    onChange={(value) => setChapterId(value)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {chaptersList?.map((item) => {
                      return (
                        <Option
                          key={item?.id}
                          value={item?.id}
                        >{`Chapter ${item?.chapter_number}`}</Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
            {!newQuestion?.options && (
              <Row>
                <div className="add-que-wrapper">
                  <p className="label">New Question</p>
                  <Input
                    placeholder="Question content"
                    className="inputs-box"
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        content: e.target.value,
                      })
                    }
                  />
                  <Button className="create-btn" onClick={onCreateQuestion}>
                    <PlusOutlined /> Create Question
                  </Button>
                </div>
              </Row>
            )}
            {newQuestion?.options && (
              <Row className="add-que">
                <div className="add-question-content">
                  <div className="question">
                    <div className="left-section">
                      <div className="prefix">
                        <MenuOutlined />
                      </div>
                      <span className="content">{newQuestion?.content}</span>
                    </div>
                    <div className="right-section">
                      <SaveOutlined
                        color="#425a85"
                        size={"18px"}
                        onClick={onSaveQuestion}
                      />
                      <img
                        src={trash}
                        alt="delete"
                        onClick={() => setNewQuestion({ content: "" })}
                      />
                    </div>
                  </div>
                </div>
                <div className="options-wrapper">
                  <div className="correct">
                    <span>Correct</span>
                  </div>
                  {newQuestion?.options?.map((item, index) => {
                    return (
                      <div className="option-wrap">
                        <div className="left-section">
                          <div className="prefix">
                            <MenuOutlined />
                          </div>
                          <Input
                            value={item}
                            onChange={(e) => {
                              const opts = newQuestion?.options;
                              opts[index] = e.target.value?.trimStart();
                              setNewQuestion({ ...newQuestion, options: opts });
                            }}
                            placeholder={`Option ${index + 1}`}
                            maxLength={152}
                          />
                        </div>
                        <div className="right-section">
                          <div
                            className="circle-outer"
                            onClick={() =>
                              setNewQuestion({
                                ...newQuestion,
                                correct_options: index,
                              })
                            }
                          >
                            {index === newQuestion?.correct_options && (
                              <div className="circle-inner"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <TextArea
                    value={newQuestion?.explanation}
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        explanation: e.target.value?.trimStart(),
                      })
                    }
                    rows={3}
                    placeholder="Write answer explanation"
                    className="text-area-box"
                  />
                </div>
              </Row>
            )}
            <Row>
              <div className="questions-list-wrap">
                {questions?.map((question, index) => {
                  return index === editQuizIndex ? (
                    <div className="add-que">
                      <div className="add-question-content">
                        <div className="question">
                          <div className="left-section">
                            <div className="prefix">
                              <MenuOutlined />
                            </div>
                            <span className="content">
                              {editQuestion?.content}
                            </span>
                          </div>
                          <div className="right-section">
                            <SaveOutlined
                              color="#425a85"
                              size={"18px"}
                              onClick={() => onEditSave(index)}
                            />
                            <img
                              src={trash}
                              alt="delete"
                              onClick={() => onQuestionDelete(index)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="options-wrapper">
                        <div className="correct">
                          <span>Correct</span>
                        </div>
                        {editQuestion?.options?.map((item, ind) => {
                          return (
                            <div className="option-wrap">
                              <div className="left-section">
                                <div className="prefix">
                                  <MenuOutlined />
                                </div>
                                <Input
                                  value={item}
                                  onChange={(e) => {
                                    const opts = editQuestion?.options;
                                    opts[ind] = e.target.value;
                                    setEditQuestion({
                                      ...editQuestion,
                                      options: opts,
                                    });
                                  }}
                                  placeholder={`Option ${index + 1}`}
                                  maxLength={152}
                                />
                              </div>
                              <div className="right-section">
                                <div
                                  className="circle-outer"
                                  onClick={() =>
                                    setEditQuestion({
                                      ...editQuestion,
                                      correct_options: ind,
                                    })
                                  }
                                >
                                  {ind === editQuestion?.correct_options && (
                                    <div className="circle-inner"></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <TextArea
                          value={editQuestion?.explanation}
                          onChange={(e) =>
                            setEditQuestion({
                              ...editQuestion,
                              explanation: e.target.value,
                            })
                          }
                          rows={3}
                          placeholder="Write answer explanation"
                          className="text-area-box"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="add-question-content">
                      <div className="question">
                        <div className="left-section">
                          <div className="prefix">
                            <MenuOutlined />
                          </div>
                          <span className="content">{question?.content}</span>
                        </div>
                        <div className="right-section">
                          <img
                            src={question?.is_active ? pause : play}
                            alt={question?.is_active ? "pause" : "play"}
                            onClick={() => onQuestionActionChange(index)}
                          />
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => {
                              setEditQuizIndex(index);
                              setEditQuestion({
                                ...question,
                                correct_options: question?.options?.findIndex(
                                  (item) => item === question?.correct_options
                                ),
                              });
                            }}
                          />
                          <img
                            src={trash}
                            alt="delete"
                            onClick={() => onQuestionDelete(index)}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Row>
          </div>
        )}
      </div>
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.Quizzes.loading,
    chaptersList: state.VideoLecture.chaptersList,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewQuiz: (data, id, onSuccess) =>
      dispatch(quizActions.addNewQuiz(data, id, onSuccess)),
    getChaptersList: (id, data) =>
      dispatch(quizActions.getChaptersList(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewQuiz);
