import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Spin, message, Empty } from "antd";
import * as learnerActions from "../../../../Redux/Actions/LearnerActions";
import pause from "../../../../Assets/Images/pause.svg";
import play from "../../../../Assets/Images/play.svg";
import userProfile from "../../../../Assets/Images/user-profile.svg";
import "./LearnerDetails.scss";

const LearnerDetails = ({
  learnerDetails,
  getLearnerDetailsById,
  updateLearnerAction,
  loading,
  match,
}) => {
  let learnerId = match.params.learnerId;

  // Fetch learner details on component mount
  useEffect(() => {
    getLearnerDetailsById(learnerId);
  }, []);

  /**
   * Function to update the learner's status (active or inactive) and display a success or error message.
   * It toggles the 'is_active' property in the learner's details.
   */
  const updateLearner = () => {
    let data = {
      is_active: !learnerDetails?.is_active,
    };
    updateLearnerAction(data, learnerId)
      .then(() => {
        const action = learnerDetails?.is_active ? "Disabled" : "Enabled";
        message.success(`Learner ${action} successfully`);
      })
      .catch((e) => {
        const action = learnerDetails?.is_active ? "Disabled" : "Enabled";
        message.error(e || `Learner ${action} Failed...`, 2);
      });
  };

  return (
    <div className="learner-details-container">
      <div className="top-learner">
        <h6>Education</h6>
        <div className="learner-right">
          {learnerDetails?.is_active ? (
            <p onClick={() => updateLearner()}>
              <img src={pause} alt="pause" />
              <span>Pause</span>
            </p>
          ) : (
            <p onClick={() => updateLearner()}>
              <img src={play} alt="play" />
              <span>Play</span>
            </p>
          )}
        </div>
      </div>
      <div className="learner-details-wrap">
        {loading ? (
          <Spin />
        ) : (
          <>
            <Row gutter={24}>
              <Col span={4}>
                <div className="signup-status">
                  <div className="logo-wrap">
                    <img
                      src={learnerDetails?.profile_pic || userProfile}
                      alt="Profile"
                    />
                  </div>
                  <div className="sign-up">
                    <span className="left-label"> Sign Up Date </span>
                    <span className="right-label">
                      {learnerDetails?.created || "N/A"}
                    </span>
                  </div>
                  <div className="sign-up">
                    <span className="left-label">Knowledge Level</span>
                    <span className="right-label">
                      {learnerDetails?.knowledge_level || "Beginner"}
                    </span>
                  </div>
                </div>
              </Col>
              <Col span={16}>
                <div className="brief-detail">
                  <div className="cname-grade-status">
                    <Row gutter={8}>
                      <Col span={24}>
                        <h6>
                          {learnerDetails?.first_name +
                            " " +
                            learnerDetails?.last_name}
                        </h6>
                      </Col>
                    </Row>
                  </div>
                  <div className="right-boxes">
                    <span className="label">Email/Confirmed</span>
                    <span className="right-text-bottom">
                      {learnerDetails?.email}
                      <small>
                        {learnerDetails?.email_confirmed ? "Yes" : "No"}
                      </small>
                    </span>
                  </div>
                  <div className="invested">
                    <Row gutter={24}>
                      <Col span={12}>
                        <div className="startup-invested">
                          <span className="title">
                            Chapters Completed{" "}
                            <span className="count">
                              {learnerDetails?.chapters_completed?.length}
                            </span>
                          </span>
                          {learnerDetails?.chapters_completed?.map(
                            (item, index) => (
                              <div className="startup-details">
                                <span className="startup-name">
                                  Chapter {index + 1}
                                </span>
                                <span className="status">
                                  {item?.title || "N/A"}
                                </span>
                              </div>
                            )
                          )}
                          {learnerDetails?.chapters_completed?.length === 0 && (
                            <Empty description="No Chapters" />
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="startup-invested">
                          <span className="title">
                            Video Topic Completed{" "}
                            <span className="count">
                              {learnerDetails?.video_topics_completed?.length}
                            </span>
                          </span>
                          {learnerDetails?.video_topics_completed?.map(
                            (item, index) => (
                              <div className="startup-details">
                                <span className="startup-name">
                                  Video Topic {index + 1}
                                </span>
                                <span className="status">
                                  {item?.topic || "N/A"}
                                </span>
                              </div>
                            )
                          )}
                          {learnerDetails?.video_topics_completed?.length ===
                            0 && <Empty description="No Video Topic" />}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="invested">
                    <Row gutter={24}>
                      <Col span={12}>
                        <div className="startup-invested">
                          <span className="title">
                            Quizzes Completed{" "}
                            <span className="count">
                              {learnerDetails?.quizzes_completed?.length}
                            </span>
                          </span>
                          {learnerDetails?.quizzes_completed?.map(
                            (item, index) => (
                              <div className="startup-details">
                                <span className="startup-name">
                                  Quiz {index + 1}
                                </span>
                                <span className="status">
                                  {item?.title || "N/A"}
                                </span>
                              </div>
                            )
                          )}
                          {learnerDetails?.quizzes_completed?.length === 0 && (
                            <Empty description="No Quizzes" />
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="startup-invested">
                          <span className="title">
                            Exams Completed{" "}
                            <span className="count">
                              {learnerDetails?.exams_completed?.length}
                            </span>
                          </span>
                          {learnerDetails?.exams_completed?.map(
                            (item, index) => (
                              <div className="startup-details">
                                <span className="startup-name">
                                  Exam {index + 1}
                                </span>
                                <span className="status">
                                  {item?.title || "N/A"}
                                </span>
                              </div>
                            )
                          )}
                          {learnerDetails?.exams_completed?.length === 0 && (
                            <Empty description="No Exams" />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="invested">
                    <Row gutter={24}>
                      <Col span={12}>
                        <div className="startup-invested">
                          <span className="title">Quiz Grades</span>
                          {learnerDetails?.quizzes_completed?.map(
                            (item, index) => (
                              <div className="grade-detail-wrap">
                                <span className="grade-title">
                                  {item?.title || "N/A"}
                                </span>
                                <div className="grade-wrap">
                                  <span className="label">Grade</span>
                                  <span className="value">
                                    {item?.grade || "N/A"}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                          {learnerDetails?.quizzes_completed?.length === 0 && (
                            <Empty description="No Quiz Grades" />
                          )}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="startup-invested">
                          <span className="title">Exam Grades</span>
                          {learnerDetails?.exams_completed?.map(
                            (item, index) => (
                              <div className="grade-detail-wrap">
                                <span className="grade-title">
                                  {item?.title || "N/A"}
                                </span>
                                <div className="grade-wrap">
                                  <span className="label">Grade</span>
                                  <span className="value">
                                    {item?.grade || "N/A"}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                          {learnerDetails?.exams_completed?.length === 0 && (
                            <Empty description="No Exam Grades" />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    learnerDetails: state.Learners.learnerDetails,
    loading: state.Learners.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLearnerDetailsById: (learnerId) =>
      dispatch(learnerActions.getLearnerDetailsById(learnerId)),
    updateLearnerAction: (data, id) =>
      dispatch(learnerActions.updateLearn65LearnerAction(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnerDetails);
