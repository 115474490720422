// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  graphData: [],
  graphLayout: {},
  graphExportData: [],
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRACTION_APPROVALS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRACTION_APPROVALS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        graphData: action.payload?.data,
      };
    case actionTypes.GET_TRACTION_APPROVALS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        graphData: [],
        layout: {},
      };
    case actionTypes.GET_GRAPH_EXPORT_DATA_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_GRAPH_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        graphExportData: action.payload?.data,
      };
    case actionTypes.GET_GRAPH_EXPORT_DATA_FAILED:
      return {
        ...state,
        graphExportData: [],
      };
    case actionTypes.GET_TRACKING_ANALYTICS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRACKING_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        monthly_updates: action.payload?.data?.monthly_updates,
      };
    case actionTypes.GET_TRACKING_ANALYTICS_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
