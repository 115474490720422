import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";

// Action to add a new blog
export const addNewBlog = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_BLOG_INIT,
    });
    await axios
      .post("admin/post-library", data)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_BLOG_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_BLOG_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to add a new podcast
export const addNewPodcast = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PODCAST_INIT,
    });
    await axios
      .post("admin/post-library", data)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_PODCAST_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_PODCAST_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get marketing data
export const getMarketingData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_MARKETING_DATA_INIT,
    });
    await axios
      .post("admin/get-marketing-data", data)
      .then((res) => {
        // console.log("gte data", res.data);
        dispatch({
          type: actionTypes.GET_MARKETING_DATA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_MARKETING_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to toggle marketing actions
export const toggleMarketingAction = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_MARKETING_ACTION_INIT,
    });
    await axios
      .post("admin/set-library-action", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.TOGGLE_MARKETING_ACTION_SUCCESS,
          payload: res.data,
          marketingId: id,
          toggleState: data?.approved_action,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.TOGGLE_MARKETING_ACTION_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete marketing library
export const deleteLibrary = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_MARKEING_LIBRARY_INIT,
    });
    await axios
      .get("admin/library-delete", { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_MARKEING_LIBRARY_SUCCESS,
          payload: res.data,
          marketingId: id,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_MARKEING_LIBRARY_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get details of a blog by its ID
export const getBlogDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_BLOG_DETAILS_INIT,
    });
    await axios
      .post("admin/get-library", { post_id: id })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_BLOG_DETAILS_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_BLOG_DETAILS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to edit a blog
export const editBlog = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_BLOG_INIT,
    });
    await axios
      .post("admin/set-library", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_BLOG_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_BLOG_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get details of a podcast by its ID
export const getPodcastDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_PODCAST_DETAILS_INIT,
    });
    await axios
      .post("admin/get-library", { post_id: id })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_PODCAST_DETAILS_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_PODCAST_DETAILS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to edit a podcast
export const editPodcast = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_PODCAST_INIT,
    });
    await axios
      .post("admin/set-library", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_PODCAST_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_PODCAST_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get subscriber data
export const getSubscriberData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_SUBSCRIBERS_DATA_INIT,
    });
    await axios
      .post("admin/subscribers", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_SUBSCRIBERS_DATA_SUCCESS,
          payload: res.data,
        });
        // console.log("response", res.data);
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_SUBSCRIBERS_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete subscriber data
export const deleteSubscriberData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SUBSCRIBER_DATA_INIT,
    });
    await axios
      .post("admin/subscribers", data)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_SUBSCRIBER_DATA_SUCCESS,
          payload: res.data,
        });
        // console.log("response", res.data);
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_SUBSCRIBER_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to add marketing media data
export const addMarktingMediaData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_MARKETING_MEDIA_INIT,
    });
    await axios
      .post("admin/marketing-media", data)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_MARKETING_MEDIA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_MARKETING_MEDIA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get marketing media data
export const getMarktingMediaData = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_MARKETING_MEDIA_INIT,
    });
    await axios
      .get("admin/marketing-media")
      .then((res) => {
        // console.log("res get", res);
        dispatch({
          type: actionTypes.GET_MARKETING_MEDIA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_MARKETING_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to export subscribers' details
export const exportSubscribersDetails = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EXPORT_SUBSCRIBER_DATA_INIT,
    });
    await axios
      .post("admin/subscribers", { export: true })
      .then((res) => {
        dispatch({
          type: actionTypes.EXPORT_SUBSCRIBER_DATA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EXPORT_SUBSCRIBER_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};
