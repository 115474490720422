// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  useeenNotifications: [],
  notifications: [],
  unseenCount: 0,
  totalNotifications:0,
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UNSEEN_NOTIFICATIONS_COUNT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS:
      return {
        ...state,
        unseenCount: action.payload.count,
        loading: false,
      };
    case actionTypes.GET_UNSEEN_NOTIFICATIONS_COUNT_FAILED:
      return {
        ...state,
        unseenCount: 0,
        loading: false,
      };
    case actionTypes.GET_UNSEEN_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_UNSEEN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        useeenNotifications: action.payload.notification_data,
        loading: false,
      };
    case actionTypes.GET_UNSEEN_NOTIFICATIONS_FAILED:
      return {
        ...state,
        useeenNotifications: [],
        loading: false,
      };
      case actionTypes.GET_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload?.data,
        totalNotifications:action.payload?.total_count,
        loading: false,
      };
    case actionTypes.GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notifications: [],
        totalNotifications:0,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
