// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  videoLecturesData: [],
  totalVideoLecturesCount: 0,
  videoLectureDetails: {},
  chaptersList:[],
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEARN_SERIES_VIDEO_LECTURES_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARN_SERIES_VIDEO_LECTURES_DATA_SUCCESS:
      return {
        ...state,
        videoLecturesData: action.payload?.data || [],
        totalVideoLecturesCount: action.payload?.total_count || 0,
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_VIDEO_LECTURES_DATA_FAILED:
      return {
        ...state,
        videoLecturesData: [],
        totalVideoLecturesCount: 0,
        loading: false,
      };
    case actionTypes.TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_SUCCESS:
      return {
        ...state,
        videoLecturesData: state?.videoLecturesData?.map((item) => {
          if (item?.id === action?.videoId) {
            item.is_active = action?.toggleState;
          }
          return item;
        }),
        loading: false,
      };
    case actionTypes.TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_LEARN_SERIES_VIDEO_LECTURE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_LEARN_SERIES_VIDEO_LECTURE_SUCCESS:
      return {
        ...state,
        videoLecturesData: state?.videoLecturesData?.filter(
          (item) => item?.id !== action?.videoId
        ),
        loading: false,
      };
    case actionTypes.DELETE_LEARN_SERIES_VIDEO_LECTURE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_SUCCESS:
      return {
        ...state,
        chaptersList: action.payload?.data || [],
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_FAILED:
      return {
        ...state,
        chaptersList: [],
        loading: false,
      };
    case actionTypes.ADD_LEARN_SERIES65_VIDEO_LECTURE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_LEARN_SERIES65_VIDEO_LECTURE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_LEARN_SERIES65_VIDEO_LECTURE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_SUCCESS:
      return {
        ...state,
        videoLectureDetails: action?.payload?.data || {},
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_FAILED:
      return {
        ...state,
        videoLectureDetails: {},
        loading: false,
      };
    case actionTypes.EDIT_LEARN_SERIES_VIDEO_LECTURE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_LEARN_SERIES_VIDEO_LECTURE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_LEARN_SERIES_VIDEO_LECTURE_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
