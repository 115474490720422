import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Col, message, Row, Spin } from "antd";
import * as ChaptersActions from "../../../../../Redux/Actions/ChaptersActions";
import edit from "../../../../../Assets/Images/edit.svg";
import chapters from "../../../../../Assets/Images/chapters.svg";
import deleteIcon from "../../../../../Assets/Images/delete.svg";
import "./ChapterDetail.scss";

const ChapterDetail = ({
  match,
  chapterDetail,
  getChapterDetails,
  deleteChapter,
  loading,
  history,
}) => {
  const chapterId = match.params.chapterId;
  const user = JSON.parse(localStorage.getItem("userdata"));

  // fetch chapter details on component mount
  useEffect(() => {
    let data = {
      chapter_id: chapterId,
    };
    getChapterDetails(data, user?.id);
  }, []);

  // Handles the deletion of the current chapter.
  const onDeleteChapter = () => {
    let data = {
      chapter_id: chapterId,
      delete: true,
    };
    deleteChapter(data, user?.id)
      .then(() => {
        message.success("Deleted");
        history.push({
          pathname: `/education/learn-series65/chapters`,
          state: { searchPlaceholder: "Search Chapters", back: true },
        });
      })
      .catch((e) => {
        message.error(e || "Failed to delete");
      });
  };

  return (
    <div className="chapter-preview-container">
      <div className="preview-header">
        <h6>Chapter</h6>
        <div className="header-right">
          <p
            onClick={() =>
              history.push(
                `/education/learn-series65/chapter-edit/${chapterId}`
              )
            }
          >
            <img src={edit} alt="add" />
            <span>Edit</span>
          </p>
          <p onClick={() => onDeleteChapter()}>
            <img src={deleteIcon} alt="add" />
            <span>Delete</span>
          </p>
        </div>
      </div>
      <div className="chapter-preview-wrap">
        {loading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={4}>
              {chapterDetail?.header_image ? (
                <div className="header-image-wrap">
                  <img src={chapterDetail?.header_image} alt="header" />
                </div>
              ) : (
                <div className="header-default-wrap">
                  <img src={chapters} alt="header" />
                </div>
              )}
            </Col>
            <Col span={16} offset={2} className="content-wrap">
              <h2>{chapterDetail?.title}</h2>
              <p className="subtitle">{chapterDetail?.subtitle}</p>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: chapterDetail?.content }}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.Chapter.loading,
    chapterDetail: state.Chapter.chapterDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChapterDetails: (data, id) =>
      dispatch(ChaptersActions.getLearnSeriesChapterDetails(data, id)),
    deleteChapter: (data, id) =>
      dispatch(ChaptersActions.deleteChapter(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterDetail);
