// Lazy-loaded components for better performance
import { lazy } from "react";

// Public Routes
export const Login = lazy(() => import("../Container/Login/login"));
export const ForgotPassword = lazy(() =>
  import("../Container/ForgotPassword/ForgotPassword")
);

// Private Routes
export const DashBoard = lazy(() => import("../Container/Dashboard/dashboard"));
export const Welcome = lazy(() => import("../Container/Welcome/welcome"));
export const Settings = lazy(() => import("../Container/Settings/Settings"));
export const WaitList = lazy(() => import("../Container/Waitlist/Waitlist"));
export const Sidebar = lazy(() => import("../Components/Sidebar/Sidebar"));
export const Rejected = lazy(() => import("../Container/Rejected/Rejected"));
export const Tracking = lazy(() => import("../Container/Tracking/Tracking"));
export const scouts = lazy(() => import("../Container/Scouts/Scouts"));
export const Portfolio = lazy(() => import("../Container/Portfolio/Portfolio"));
export const Approved = lazy(() => import("../Container/Approved/Approved"));
export const Analytics = lazy(() => import("../Container/Analytics/Analytics"));
export const Marketing = lazy(() => import("../Container/Marketing/Marketing"));
export const Education = lazy(() => import("../Container/Education/Education"));
export const Transaction = lazy(() =>
  import("../Container/Transaction/Transaction")
);
export const Talent = lazy(() => import("../Container/Talent/Talent"));
export const Notifications = lazy(() =>
  import("../Container/Notifications/Notifications")
);
export const WaitListStartupDetails = lazy(() =>
  import("../Container/Waitlist/StartupWaitlist/StartupDetails/StartupDetails")
);
export const StartupSPVFormation = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/StartupSPVFormation/StartupSPVFormation"
  )
);
export const StartupInfoUpdate = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/StartupInfoUpdate/StartupInfoUpdate"
  )
);
export const AvailableDwollaAccount = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/AvailableDwollaAccount/AvailableDwollaAccount"
  )
);
export const BankingProfileInformation = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/BankingProfileInformation/BankingProfileInformation"
  )
);
export const WaitListStartupEdit = lazy(() =>
  import("../Container/Waitlist/StartupWaitlist/StartupEdit/StartupEdit")
);
export const WaitListInvestorDetails = lazy(() =>
  import(
    "../Container/Waitlist/InvestorWaitlist/InvestorDetails/InvestorDetails"
  )
);
export const WaitListInvestorEdit = lazy(() =>
  import("../Container/Waitlist/InvestorWaitlist/InvestorEdit/InvestorEdit")
);

export const BankingDocVerification = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/BankingDocVerification/BankingDocVerification"
  )
);
export const StartupSPVAddBankAccount = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/AddBankAccount/AddBankAccount"
  )
);
export const StartupSPVManageBankAccount = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/ManageBankAccount/ManageBankAccount"
  )
);
export const vertifiedAccount = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/vertifiedAccount/vertifiedAccount"
  )
);
export const AddDwollaAccount = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/AddDwollaAccount/AddDwollaAccount.js"
  )
);
export const NoBankAccount = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/NoBankAccount/NoBankAccount.js"
  )
);
export const AdminInviteHistory = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/InviteHistory/InviteHistory"
  )
);

//Rejected
export const RejectedStartupDetails = lazy(() =>
  import("../Container/Rejected/StartupRejected/StartupDetails/StartupDetails")
);
export const RejectedInvestorDetails = lazy(() =>
  import(
    "../Container/Rejected/InvestorRejected/InvestorDetails/InvestorDetails"
  )
);
export const RejectedStartupEdit = lazy(() =>
  import("../Container/Rejected/StartupRejected/StartupEdit/StartupEdit")
);
export const RejectedInvestorEdit = lazy(() =>
  import("../Container/Rejected/InvestorRejected/InvestorEdit/InvestorEdit")
);

//Tracking
export const TrackingStartupDetails = lazy(() =>
  import("../Container/Tracking/TrackingDetails/TrackingDetails")
);
export const TrackingStartupEdit = lazy(() =>
  import("../Container/Tracking/TrackingEdit/TrackingEdit")
);
export const TrackingStartupUpdates = lazy(() =>
  import("../Container/Tracking/TrackingUpdates/TrackingUpdates")
);
export const TrackingUpdateDetails = lazy(() =>
  import("../Container/Tracking/TrackingUpdateDetails/TrackingUpdateDetails")
);
export const TrackingHistory = lazy(() =>
  import("../Container/Tracking/TrackingHistory/TrackingHistory")
);

//scout
export const ScoutDetails = lazy(() =>
  import("../Container/Scouts/ScoutDetails/ScoutDetails")
);
export const ScoutEdit = lazy(() =>
  import("../Container/Scouts/ScoutEdit/ScoutEdit")
);
export const ScoutEditCarrySharingAgreement = lazy(() =>
  import("../Container/Scouts/ScoutCarry/ScoutCarryEdit")
);
export const ScoutAddCarrySharingAgreement = lazy(() =>
  import("../Container/Scouts/ScoutCarry/ScoutCarryAdd")
);
export const StartupMonthlyUpdates = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupMonthlyUpdates/StartupMonthlyUpdates"
  )
);
export const StartupMonthlyUpdateDetails = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupMonthlyUpdateDetails/StartupMonthlyUpdateDetails"
  )
);
export const StartupPublicQnA = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupPublicQnA/StartupPublicQnA"
  )
);

export const StartupCarryRecipients = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupCarryRecipients/StartupCarryRecipients"
  )
);

export const StartupAddCarryRecipients = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupCarryRecipients/AddCarryRecipients"
  )
);

export const StartupEditCarryRecipients = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupCarryRecipients/EditCarryRecipients"
  )
);
export const StartupViewCarryRecipients = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupCarryRecipients/ViewCarryRecipient"
  )
);

//Talent

export const TalentDetails = lazy(() =>
  import("../Container/Talent/TalentDetails/TalentDetails")
);
export const TalentEdit = lazy(() =>
  import("../Container/Talent/TalentEdit/TalentEdit")
);

//Investor
export const NotifyInvestors = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/NotifyInvestors/NotifyInvestors"
  )
);
export const EditNotifyInvestor = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/EditNotifyInvestors/NotifyInvestors"
  )
);
export const NotifyInvestorHistory = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/NotifyInvestorHistory/NotifyInvestorHistory"
  )
);

//approved
export const ApprovedStartupDetails = lazy(() =>
  import("../Container/Approved/StartupApproved/StartupDetails/StartupDetails")
);
export const MatchInvestors = lazy(() =>
  import("../Container/Approved/StartupApproved/MatchInvestors/MatchInvestors")
);

export const ApprovedInvestorDetails = lazy(() =>
  import(
    "../Container/Approved/InvestorApproved/InvestorDetails/InvestorDetails"
  )
);
export const MatchStartups = lazy(() =>
  import("../Container/Approved/InvestorApproved/MatchStartups/MatchStartups")
);
export const SimilarInvestors = lazy(() =>
  import(
    "../Container/Approved/InvestorApproved/SimilarInvestors/SimilarInvestors"
  )
);
export const ApprovedStartupEdit = lazy(() =>
  import("../Container/Approved/StartupApproved/StartupEdit/StartupEdit")
);
export const ApprovedStartupHistory = lazy(() =>
  import("../Container/Approved/StartupApproved/StartupHistory/StartupHistory")
);
export const ApprovedInvestorHistory = lazy(() =>
  import(
    "../Container/Approved/InvestorApproved/InvestorHistory/InvestorHistory"
  )
);
export const ApprovedSPVView = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/StartupDetails/components/viewSPV"
  )
);
export const monthlyUpdateHistory = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/ApprovedHistory/ApprovedHistory"
  )
);
export const ApprovedInvestorEdit = lazy(() =>
  import("../Container/Approved/InvestorApproved/InvestorEdit/InvestorEdit")
);
export const BusinessInformation = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/BusinessInformation/BusinessInformation"
  )
);
export const ControllerInformation = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/ControllerInformation/ControllerInformation"
  )
);
export const ControllerDocumentVerification = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/DocumentVerification/ControllerDocumentVerification"
  )
);
export const BusinessDocumentVerification = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/DocumentVerification/BusinessDocumentVerification"
  )
);
export const BusinessDBAVerification = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/DocumentVerification/BusinessDBAVerification"
  )
);
export const DocumentStatus = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/DocumentVerification/DocumentStatus"
  )
);
export const ApprovedInviteHistory = lazy(() =>
  import("../Container/Approved/StartupApproved/InviteHistory/InviteHistory")
);
export const ViewPreviousPost = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/ViewPreviousPosts/ViewPreviousPost.js"
  )
);
export const ViewPostDetails = lazy(() =>
  import(
    "../Container/Approved/StartupApproved/ViewPreviousPosts/ViewPreviousPostDetails"
  )
);
export const StartupHistory = lazy(() =>
  import("../Container/Approved/StartupApproved/StartupHistory/StartupHistory")
);
export const TaxsDetails = lazy(() =>
  import("../Container/Approved/InvestorApproved/InvestorTaxes/TaxsDetails")
);
export const TaxUploadDoc = lazy(() =>
  import("../Container/Approved/InvestorApproved/InvestorTaxes/TaxUploadDoc")
);
export const ViewTaxesDoc = lazy(() =>
  import("../Container/Approved/InvestorApproved/InvestorTaxes/ViewTaxesDoc")
);
export const WaitlistInviteHistory = lazy(() =>
  import(
    "../Container/Waitlist/StartupWaitlist/StartupDetails/components/InviteHistory/InviteHistory"
  )
);
