import Axios from "axios";

// Create an Axios instance with a base URL and cookie support
const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Intercept responses and handle errors globally
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    {
      // Extract the response URL from the error
      const responseURL = error?.response?.request?.responseURL;

      // Check if the error is due to an unauthorized access to the admin page
      if (responseURL?.includes("admin/login?next=%2F")) {
        // Clear local storage (assuming it holds authentication details)
        localStorage.clear();
        
        // Redirect the user to the login page
        window.location.href = "/login";
      }
    }
    // Reject the promise with the error for further handling
    return Promise.reject(error);
  }
);
export default axiosInstance;
