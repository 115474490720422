import * as actionTypes from "../Actions/ActionTypes";

const initialState = {
    loading: false,
    portfolioData: [],
    portfolioDetails: {
        co_investors: []
    },
    totalPortfolios: 0
}

const store = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PORTFOLIO_DATA_INIT:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_PORTFOLIO_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                portfolioData: action.payload?.data,
                totalPortfolios: action.payload?.total_count,
            };
        case actionTypes.GET_PORTFOLIO_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
                portfolioData: [],
                totalPortfolios: 0,
            };

        case actionTypes.UPLOAD_PORTFOLIO_STARTUP_IMAGE_INIT:
            return {
                ...state,
            };
        case actionTypes.UPLOAD_PORTFOLIO_STARTUP_IMAGE_SUCCESS:
            return {
                ...state,
                portfolioDetails: {
                    ...state.portfolioDetails,
                    startup_image: action?.payload?.url,
                },
            };
        case actionTypes.UPLOAD_PORTFOLIO_STARTUP_IMAGE_FAILED:
            return {
                ...state,
            };

        case actionTypes.UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_INIT:
            return {
                ...state,
            };
        case actionTypes.UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_SUCCESS:
            let { data, index } = action.payload
            return {
                ...state,
                portfolioDetails: {
                    ...state.portfolioDetails,
                    co_investors: [
                        ...state.portfolioDetails.co_investors.slice(0, index),
                        { ...state.portfolioDetails.co_investors[index], co_investor_image: data?.url },
                        ...state.portfolioDetails.co_investors.slice(index + 1),
                    ],
                },
            };
        case actionTypes.UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_FAILED:
            return {
                ...state,
            };

        case actionTypes.ADD_PORTFOLIO_DATA:
            let { field, value } = action.payload;
            return {
                ...state,
                portfolioDetails: {
                    ...state.portfolioDetails,
                    [field]: value
                }
            }

        case actionTypes.REMOVE_PORTFOLIO_CO_INVESTOR:
            const newState = { ...state }
            newState.portfolioDetails.co_investors.splice(action.payload, 1);
            return newState;

        case actionTypes.ADD_CO_INVESTOR_NAME:
            let { name_index, name_value } = action.payload
            console.log({ name_index, name_value })
            return {
                ...state,
                portfolioDetails: {
                    ...state.portfolioDetails,
                    co_investors: [
                        ...state.portfolioDetails.co_investors.slice(0, name_index),
                        { ...state.portfolioDetails.co_investors[name_index], co_investor_name: name_value },
                        ...state.portfolioDetails.co_investors.slice(name_index + 1),
                    ],
                },
            }

        case actionTypes.ADD_PORTFOLIO_INIT:
            return {
                ...state,
                loading: true
            }

        case actionTypes.ADD_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case actionTypes.ADD_PORTFOLIO_FAILED:
            return {
                ...state,
                loading: false
            }
        case actionTypes.DELETE_PORTFOLIO_INIT:
            return {
                ...state,
                loading: true
            }

        case actionTypes.DELETE_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case actionTypes.DELETE_PORTFOLIO_FAILED:
            return {
                ...state,
                loading: false
            }

        case actionTypes.ADD_EDIT_PORTFOLIO_DETAILS:
            return {
                ...state,
                portfolioDetails: action.payload
            }

        case actionTypes.UPDATE_PORTFOLIO_INIT:
            return {
                ...state,
                loading: true
            }

        case actionTypes.UPDATE_PORTFOLIO_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case actionTypes.UPDATE_PORTFOLIO_FAILED:
            return {
                ...state,
                loading: false
            }
        case actionTypes.RESET_PORTFOLIO_DETAILS:
            return {
                ...state,
                portfolioDetails: {
                    co_investors: []
                },
            }
        default:
            return state;
    }
}
export default store;