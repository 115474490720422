// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  approvedStartupsData: [],
  loading: false,
  getApprovedStartuploading: false,
  totalApprovedStartups: 0,
  approvedStartupDetails: {},
  teamInfo: [],
  matchInvestors: [],
  totalMatchInvestors: 0,
  suggestedInvestors: [],
  notifyInvestorRecipient: [],
  viewCount: 0,
  notViewCount: 0,
  startupNameHistory: "",
  numOfViewedHistory: 0,
  amountInvestedHistory: 0,
  averageInvHistory: 0,
  totalInvestedHistory: 0,
  totalPassedHistory: 0,
  startupHistory: [],
  reasonCount: {},
  publicQnA: [],
  publicQnACount: 0,
  currentSyndicateAmount: null,
  fundTransferStatus: null,
  co_investor_logos: [],
  carryRecipients: [],
  carryRecipientsCount: 0,
  addCarryRecipientData: []
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APPROVED_STARTUPS_DATA_INIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPROVED_STARTUPS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedStartupsData: action.payload?.data,
        totalApprovedStartups: action.payload?.total_count,
      };
    case actionTypes.GET_APPROVED_STARTUPS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        approvedStartupsData: [],
        totalApprovedStartups: 0,
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedStartupsData: state?.approvedStartupsData?.map((item) => {
          if (item?.id === action?.id) {
            return {
              ...item,
              approved_action: action?.approved_action,
            };
          }
          return { ...item };
        }),
        approvedStartupDetails: {
          ...state?.approvedStartupDetails,
          approved_action: action?.approved_action,
        },
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_APPROVED_STARTUP_BY_ID_INIT:
      return {
        ...state,
        getApprovedStartuploading: true,
        co_investor_logos: [...state.co_investor_logos],
      };
    case actionTypes.GET_APPROVED_STARTUP_BY_ID_SUCCESS:
      return {
        ...state,
        getApprovedStartuploading: false,
        approvedStartupDetails: action.payload,
        co_investor_logos:
          action.payload.co_investor_info &&
          action.payload.co_investor_info.map((info) => {
            return { logo: info?.profile_image };
          }),
      };
    case actionTypes.GET_APPROVED_STARTUP_BY_ID_FAILED:
      return {
        ...state,
        getApprovedStartuploading: false,
        approvedStartupDetails: {},
        co_investor_logos: [...state.co_investor_logos],
      };
    case actionTypes.GET_APPROVED_STARTUP_TEAM_INFO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_APPROVED_STARTUP_TEAM_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        teamInfo: action.payload,
      };
    case actionTypes.GET_APPROVED_STARTUP_TEAM_INFO_FAILED:
      return {
        ...state,
        loading: false,
        teamInfo: [],
      };
    case actionTypes.GET_APPROVED_STARTUP_MATCH_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_APPROVED_STARTUP_MATCH_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        totalMatchInvestors: action.payload?.total_data,
        viewCount: action.payload?.viewed_count,
        notViewCount: action.payload?.not_viewed_count,
        notViewInvestor: action.payload?.not_viewed_investors,
        viewedInvestor: action.payload?.viewed_investors,
      };
    case actionTypes.GET_APPROVED_STARTUP_MATCH_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
        matchInvestors: [],
        totalMatchInvestors: 0,
        viewCount: 0,
        notViewCount: 0,
      };
    case actionTypes.SET_APPROVED_STARTUP_VISIBILITY_INIT:
      return {
        ...state,
      };
    case actionTypes.SET_APPROVED_STARTUP_VISIBILITY_SUCCESS:
      return {
        ...state,
        approvedStartupDetails: {
          ...state?.approvedStartupDetails,
          show_profile: action.payload,
        },
      };
    case actionTypes.SET_APPROVED_STARTUP_VISIBILITY_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_INVESTOR_SUGGESTIONS_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_INVESTOR_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestedInvestors: action.payload?.found_data,
      };
    case actionTypes.GET_INVESTOR_SUGGESTIONS_FAILED:
      return {
        ...state,
        suggestedInvestors: [],
      };
    case actionTypes.ADD_APPROVED_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_APPROVED_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_APPROVED_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_MEMO_INIT:
      return {
        ...state,
      };
    case actionTypes.UPLOAD_MEMO_SUCCESS:
      return {
        ...state,
        approvedStartupDetails: {
          ...action?.payload?.data,
          memo: action?.payload?.successData?.url,
        },
      };
    case actionTypes.UPLOAD_MEMO_FAILED:
      return {
        ...state,
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_INIT:
      return {
        ...state,
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        approvedStartupDetails: {
          ...state.approvedStartupDetails,
          profile_pic_link: action?.payload?.url,
        },
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_FAILED:
      return {
        ...state,
      };
    case actionTypes.UPLOAD_SLIDEDECK_INIT:
      return {
        ...state,
      };
    case actionTypes.UPLOAD_SLIDEDECK_SUCCESS:
      return {
        ...state,
        approvedStartupDetails: {
          ...action?.payload?.data,
          slide_deck: action?.payload?.successData?.url,
        },
      };
    case actionTypes.UPLOAD_SLIDEDECK_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_APPROVED_STARTUP_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_APPROVED_STARTUP_HISTORY_SUCCESS:
      return {
        ...state,
        startupNameHistory: action.payload?.history_data?.startup_name,
        numOfViewedHistory: action.payload?.history_data?.total_views,
        amountInvestedHistory: action.payload?.history_data?.amount_committed,
        averageInvHistory: action.payload?.history_data?.avg_amount_committed,
        totalInvestedHistory: action.payload?.history_data?.total_invested,
        totalPassedHistory: action.payload?.history_data?.total_passed,
        startupHistory: action.payload?.history_data?.data,
        data_count: action.payload?.history_data?.data_count,
        team_count: action.payload?.history_data?.team_count,
        product_count: action.payload?.history_data?.product_count,
        other_count: action.payload?.history_data?.other_count,
        market_count: action.payload?.history_data?.market_count,
        investable_amount: action.payload?.history_data?.investable_amount,
        all_count:
          action.payload?.history_data?.team_count +
          action.payload?.history_data?.product_count +
          action.payload?.history_data?.other_count +
          action.payload?.history_data?.market_count,
        loading: false,
        fundTransferStatus: action.payload?.history_data?.fund_transfer_status
      };
    case actionTypes.GET_APPROVED_STARTUP_HISTORY_FAILED:
      return {
        ...state,
        numOfViewedHistory: 0,
        amountInvestedHistory: 0,
        averageInvHistory: 0,
        totalInvestedHistory: 0,
        totalPassedHistory: 0,
        startupHistory: [],
        reasonCount: {},
        loading: false,
      };
    case actionTypes.DELETE_MATCH_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MATCH_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_MATCH_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_APPROVED_STARTUP_PUBLIC_QA_INIT:
      return {
        ...state,

        loading: true,
      };
    case actionTypes.GET_APPROVED_STARTUP_PUBLIC_QA_SUCCESS:
      return {
        ...state,
        publicQnA: action.payload?.data,
        publicQnACount: action.payload?.count,
        loading: false,
      };
    case actionTypes.GET_APPROVED_STARTUP_PUBLIC_QA_FAILED:
      return {
        ...state,
        publicQnA: [],
        publicQnACount: 0,
        loading: false,
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_BANNER_IMAGE_INIT:
      return {
        ...state,
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        approvedStartupDetails: {
          ...state.approvedStartupDetails,
          logo: action?.payload?.url,
        },
      };
    case actionTypes.UPDATE_APPROVED_STARTUP_BANNER_IMAGE_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_SUCCESS:
      return {
        ...state,
        currentSyndicateAmount: action?.payload?.syndicate_min_req,
      };
    case actionTypes.GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_RECIPIENT_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_RECIPIENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyInvestorRecipient: action.payload?.data,
      };
    case actionTypes.GET_RECIPIENT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        notifyInvestorRecipient: [],
      };
    case actionTypes.CREATE_NOTIFY_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_NOTIFY_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.CREATE_NOTIFY_INVESTOR_FAILED:
      return {
        loading: false,
      };

    case actionTypes.GET_NOTIFY_INVESTOR_HISTORY_INIT:
      return {
        loading: true,
      };
    case actionTypes.GET_NOTIFY_INVESTOR_HISTORY_SUCCESS:
      return {
        notifyInvestorHistory: action.payload?.data,
        totalCount: action.payload?.count,
        loading: false,
      };
    case actionTypes.GET_NOTIFY_INVESTOR_HISTORY_FAILED:
      return {
        loading: false,
      };

    case actionTypes.DELETE_NOTIFY_INVESTOR_HISTORY_INIT:
      return {
        loading: true,
      };
    case actionTypes.DELETE_NOTIFY_INVESTOR_HISTORY_SUCCESS:
      return { loading: false };
    case actionTypes.DELETE_NOTIFY_INVESTOR_HISTORY_FAILED:
      return { loading: false };

    case actionTypes.EDIT_NOTIFY_INVESTOR_HISTORY_INIT:
      return { loading: true };
    case actionTypes.EDIT_NOTIFY_INVESTOR_HISTORY_SUCCESS:
      return { loading: false };
    case actionTypes.EDIT_NOTIFY_INVESTOR_HISTORY_FAILED:
      return { loading: false };
    case actionTypes.ADD_QUESTION_INIT:
      return { loading: true };
    case actionTypes.ADD_QUESTION_SUCCESS:
      return { loading: false };
    case actionTypes.ADD_QUESTION_FAILED:
      return {
        loading: false,
      };
    case actionTypes.APPROVED_PERMISSION:
      return {
        ...state,
        permissions: action.payload,
      };
    case actionTypes.UPDATE_FUND_TRANSFER_STATUS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_FUND_TRANSFER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        fundTransferStatus: action.payload,
      };
    case actionTypes.UPDATE_FUND_TRANSFER_STATUS_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.REMOVE_CO_INVESTOR:
      const coInvestorInfo =
        state.approvedStartupDetails?.co_investor_info || [];
      const coInvestorLogos = state.co_investor_logos || [];

      if (!Array.isArray(coInvestorInfo) || !Array.isArray(coInvestorLogos)) {
        // Handle the case where the data is not as expected
        return state;
      }

      const newCoInvestorInfo = [...coInvestorInfo];
      const newCoInvestorlogos = [...coInvestorLogos];

      newCoInvestorInfo.splice(action.payload, 1);

      newCoInvestorlogos.splice(action.payload, 1);

      return {
        ...state,
        loading: false,
        approvedStartupDetails: {
          ...state.approvedStartupDetails,
          co_investor_info: newCoInvestorInfo,
        },
        co_investor_logos: newCoInvestorlogos,
      };

    case actionTypes.UPDATE_INVESTOR_ICON_INIT:
      return {
        ...state,
        loading: true,
        co_investor_logos: [...state.co_investor_logos],
      };

    case actionTypes.UPDATE_INVESTOR_ICON_SUCCESS:
      const newCoInvestorLogos = [...state.co_investor_logos];
      newCoInvestorLogos.splice(action.payload.index, 1, {
        logo: action.payload.successData.url,
      });
      return {
        ...state,
        loading: false,
        co_investor_logos: newCoInvestorLogos,
      };

    case actionTypes.UPDATE_INVESTOR_ICON_FAILED:
      return {
        ...state,
        loading: false,
        co_investor_logos: [...state.co_investor_logos],
      };

    case actionTypes.ADD_NEW_CO_INVESTOR_LOCALLY:
      return {
        ...state,
        loading: false,
        approvedStartupDetails: {
          ...state.approvedStartupDetails,
          co_investor_info: action.payload
        }
      }

    case actionTypes.GET_APPROVED_STARTUP_CARRY_RECIPIENTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_APPROVED_STARTUP_CARRY_RECIPIENTS_SUCCESS:
      return {
        ...state,
        carryRecipients: action.payload?.data,
        carryRecipientsCount: action.payload?.total_count,
        loading: false,
      };
    case actionTypes.GET_APPROVED_STARTUP_CARRY_RECIPIENTS_FAILED:
      return {
        ...state,
        carryRecipients: [],
        carryRecipientsCount: 0,
        loading: false,
      };

    case actionTypes.UPLOAD_CARRY_RECIPIENT_DOC_INIT:
      return {
        ...state
      }

    case actionTypes.UPLOAD_CARRY_RECIPIENT_DOC_SUCCESS:
      let { data, index } = action.payload
      return {
        ...state,
        addCarryRecipientData: [
          ...state.addCarryRecipientData.slice(0, index),
          { ...state.addCarryRecipientData[index], agreement_url: data?.url },
          ...state.addCarryRecipientData.slice(index + 1)
        ]
      }

    case actionTypes.UPLOAD_CARRY_RECIPIENT_DOC_FAILED:
      return {
        ...state
      }

    case actionTypes.ADD_RECIPIENT_DATA:
      return {
        ...state,
        addCarryRecipientData: [
          ...state.addCarryRecipientData.slice(0, action.payload.index),
          { ...state.addCarryRecipientData[action.payload.index], [action.payload.fieldName]: action.payload.value },
          ...state.addCarryRecipientData.slice(action.payload.index + 1)
        ]
      }

    case actionTypes.REMOVE_CARRY_RECIPIENT_DOC:
      return {
        ...state,
        addCarryRecipientData: [
          ...state.addCarryRecipientData.slice(0, action.payload.index),
          { ...state.addCarryRecipientData[action.payload.index], agreement_url: '' },
          ...state.addCarryRecipientData.slice(action.payload.index + 1)
        ]
      }

      case actionTypes.REMOVE_CARRY_RECIPIENT_RECORD:
        const newAddCarryRecipientData = [...state.addCarryRecipientData]; 
        newAddCarryRecipientData.splice(action.payload.index, 1)
        return {
          ...state,
          addCarryRecipientData: newAddCarryRecipientData
        }

    default:
      return state;
  }
};

export default store;
