import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Divider, Spin } from "antd";
import ReactPlayer from "react-player";
import * as videoActions from "../../../../../Redux/Actions/VideoLecturesActions";
import edit from "../../../../../Assets/Images/edit.svg";
import "./VideoLectureDetail.scss";

const VideoLectureDetail = ({
  videoLectureDetails,
  getVideoLectureDetails,
  match,
  history,
  loading,
}) => {
  const videoId = match.params.videoId;
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [selectedVideo, setSelectedVideo] = useState();

  // Fetch video lecture details based on the specified video ID.
  useEffect(() => {
    let data = {
      video_id: videoId,
    };
    getVideoLectureDetails(data, user?.id);
  }, []);

  /**
   * Set the selected video to the first link in the video lecture details
   * when the video lecture details are available.
   */
  useEffect(() => {
    videoLectureDetails?.links &&
      setSelectedVideo(videoLectureDetails?.links[0]);
  }, [videoLectureDetails]);

  return (
    <div className="video-preview-container">
      <div className="preview-header">
        <h6>Video Lecture</h6>
        <div className="header-right">
          <p
            onClick={() =>
              history.push(
                `/education/learn-series65/video-lecture-edit/${videoId}`
              )
            }
          >
            <img src={edit} alt="add" />
            <span>Edit</span>
          </p>
        </div>
      </div>
      <div className="video-preview-wrap">
        {loading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={10}>
              <div className="left-section">
                <span className="label">Video Topic</span>
                <p className="topic">{videoLectureDetails?.topic}</p>
                <Divider />
                <div className="videos-title-wrap">
                  {videoLectureDetails?.links?.map((item) => {
                    return (
                      <p
                        className={
                          selectedVideo?.title === item?.title
                            ? "selected-video-title"
                            : "video-title"
                        }
                        onClick={() => setSelectedVideo(item)}
                      >
                        {item?.title}
                      </p>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col span={12} offset={2} className="content-wrap">
              <span className="label">Video Sumary</span>
              <p>{selectedVideo?.summary}</p>
              <ReactPlayer
                url={selectedVideo?.link}
                controls
                height={350}
                width={"100%"}
              />
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.VideoLecture.loading,
    videoLectureDetails: state.VideoLecture.videoLectureDetails,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVideoLectureDetails: (data, id) =>
      dispatch(videoActions.getVideoLectureDetails(data, id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoLectureDetail);
