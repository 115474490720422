// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  progressData: [],
  dashboardWaitlistStartup: [],
  dashboardWaitlistInvestor: [],
  dashboardApprovedStartup: [],
  dashboardApprovedInvestor: [],
  dashboardMarketing: [],
  dashboardNotification: [],
  scoutsData: [],
  talentData: [],
  investing_chapters: 0,
  series65_chapters: 0,
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_PROGRESS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_DASHBOARD_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        progressData: action.payload?.data || [],
      };
    case actionTypes.GET_DASHBOARD_PROGRESS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        progressData: [],
      };
    case actionTypes.GET_DASHBOARD_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardWaitlistStartup: action.payload?.wt_startup_data || [],
        dashboardWaitlistInvestor: action.payload?.wt_inv_data || [],
        dashboardApprovedStartup: action.payload?.ap_startup_data || [],
        dashboardApprovedInvestor: action.payload?.ap_inv_data || [],
        dashboardMarketing: action.payload?.marketing_data || [],
        dashboardNotification: action.payload?.notification_data || [],
        scoutsData: action.payload?.scouts_data || [],
        talent_incomplete: action.payload?.talent_incomplete || [],
        talent_pending: action.payload?.talent_pending || [],
        series65_chapters: action.payload?.series65_chapters || 0,
        investing_chapters: action.payload?.investing_chapters || 0,
        podcast_count: action.payload?.podcast_count || 0,
        blogs_count: action.payload?.blogs_count || 0,
      };
    case actionTypes.GET_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        loading: false,
        dashboardWaitlistStartup: [],
        dashboardWaitlistInvestor: [],
        dashboardApprovedStartup: [],
        dashboardApprovedInvestor: [],
        dashboardMarketing: [],
        dashboardNotification: [],
      };
    default:
      return state;
  }
};

export default store;
