import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";

// Action to get exams data
export const getExamsData = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_EDUCATION_EXAMS_DATA_INIT,
    });
    await axios
      .post("admin/all-exams", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_EDUCATION_EXAMS_DATA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EDUCATION_EXAMS_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to toggle exam action
export const toggleExamAction = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.TOGGLE_EDUCATION_EXAM_ACTION_INIT,
    });
    await axios
      .post("admin/disable-exam", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.TOGGLE_EDUCATION_EXAM_ACTION_SUCCESS,
          payload: res.data,
          examId: data?.exam_id,
          toggleState: data?.is_active,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.TOGGLE_EDUCATION_EXAM_ACTION_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete exam
export const deleteExam = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_EDUCATION_EXAM_INIT,
    });
    await axios
      .post("admin/delete-exam", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_EDUCATION_EXAM_SUCCESS,
          payload: res.data,
          examId: data?.exam_id,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_EDUCATION_EXAM_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to add a new exam
export const addNewExam = (data, id, onSuccess) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_EXAM_INIT,
    });
    await axios
      .post("admin/add-exam", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_EXAM_SUCCESS,
          payload: res.data,
        });
        onSuccess(res.data);
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_EXAM_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get exam details
export const getExamDetails = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_EXAM_DETAILS_INIT,
    });
    await axios
      .post("admin/exam", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_EXAM_DETAILS_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_EXAM_DETAILS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to edit exam
export const editExam = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_EXAM_INIT,
    });
    await axios
      .post("admin/edit-exam", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_EXAM_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_EXAM_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get chapters list
export const getChaptersList = (id, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_INIT,
    });
    await axios
      .post("admin/list-chapters", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LEARN_SERIES_CHAPTERS_LIST_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};
