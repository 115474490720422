// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  learn65LearnersData: [],
  totalLearn65LearnersCount: 0,
  educationCount: {
    chapter: 0,
    quiz: 0,
    video: 0,
    exam: 0,
  },
  learnerDetails:{},
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEARN65_LEARNERS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARN65_LEARNERS_DATA_SUCCESS:
      return {
        ...state,
        learn65LearnersData: action.payload?.data || [],
        totalLearn65LearnersCount: action.payload?.total_count || 0,
        educationCount: {
          chapter: action.payload?.total_chapters,
          quiz: action.payload?.total_quizzes,
          video: action.payload?.total_videos,
          exam: action.payload?.total_exams,
        },
        loading: false,
      };
    case actionTypes.GET_LEARN65_LEARNERS_DATA_FAILED:
      return {
        ...state,
        learn65LearnersData: [],
        totalLearn65LearnersCount: 0,
        loading: false,
      };
    case actionTypes.SET_LEARN65_LEARNER_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SET_LEARN65_LEARNER_ACTION_SUCCESS:
      return {
        ...state,
        learn65LearnersData: state?.learn65LearnersData?.map((item) => {
          if (item?.id === action?.learnerId) {
            item.is_active = action?.toggleState;
          }
          return item;
        }),
        learnerDetails:{...state.learnerDetails,is_active:state?.learnerDetails?.id===action?.learnerId?action?.toggleState:state?.learnerDetails?.is_active},
        loading: false,
      };
    case actionTypes.SET_LEARN65_LEARNER_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_LEARNER_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARNER_DETAILS_SUCCESS:
      return {
        ...state,
        learnerDetails:action?.payload?.data,
        loading: false,
      };
    case actionTypes.GET_LEARNER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
