import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import {
  SearchOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Dropdown,
  Input,
  Menu,
  Popover,
  Row,
  Col,
  Spin,
  Space,
  message,
} from "antd";
import ProfileDrawer from "../ProfileDrawer/ProfileDrawer";
import {
  getUnseenNotifications,
  getUnseenNotificationsCount,
} from "../../Redux/Actions/NotificationActions";
import { getUserProfile, adminLogout } from "../../Redux/Actions/AuthActions";
import { searchChangeHandle } from "../../Redux/Actions/SearchActions";
import moment from "moment";
import profile from "../../Assets/Images/user-profile.svg";
import notification from "../../Assets/Images/notification.svg";
import BackArrow from "../../Assets/Images/back-arrow.svg";
import { isSearchAble, getReverseSector, getSector } from "../../Utils/helper";
import "./navbar.scss";

function Navbar({
  unseenCount,
  getUserProfile,
  getUnseenNotificationsCount,
  adminLogout,
  history,
  handleSearchChange,
  useenNotifications,
  loading,
  location,
  userData,
  getUnseenNotifications,
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [search, setSearch] = useState("");
  const [unread, setUnread] = useState(0);

  const dwollaAccountSearchRegExp = /^\/waitlist\/startup-spv-dwolla-accounts\/[^\/]+$/;
  const startup = useSelector((state) => state.Startups);

  // Add routes that page not display search text box & back button
  const NonSearchRoutes = [
    "/settings",
    "/dashboard",
    "/analytics",
    "/welcome",
    "/invite-history",
  ];

  /** Add routes where you dontwant to show the Search field only  */
  // without params
  const NonSearchingRoutes = [
    "/approved/startup-spv-dwolla-accounts",
    "/waitlist/startup-spv-dwolla-accounts", // won't work with params
    "/approved/startup-spv-manage-bank",
    "/waitlist/startup-spv-manage-bank",
    "/waitlist/startup-info-update",
    "/waitlist/startup-spv-formation",
    "/approved/startup-spv-add-dwollaAccount",
    "/waitlist/startup-spv-add-dwollaAccount",
    "/approved/startup-details",
    "/waitlist/startup-details",
    "/rejected/startup-details",
    "/tracking/startup-details",
    "/waitlist/investor-details",
    "/approved/investor-details",
    "/rejected/investor-details",
    "/waitlist/startup-edit/",
    "/waitlist/investor-edit/",
    "/rejected/startup-edit/",
    "/rejected/investor-edit/",
    "/tracking/startup-edit/",
    "/approved/startup-edit/",
    "/approved/investor-edit/",
    "/scouts/scout-details/",
    "/scouts/scout-edit/",
    "/transactions/startup-details",
    "/transactions/investor-details",
    "/talent/talent-details/",
    "/talent/talent-edit/",
    "/approved/spv-view/",
    "/education/learner-details/",
    "/waitlist/document-verification-status/",
    "/waitlist/business-document-vertification/",
    "/waitlist/controller-document-vertification/",
    "/waitlist/business-DBA-document-vertification/",
    "/approved/document-verification-status",
    "/approved/business-document-vertification",
    "/approved/controller-document-vertification",
    "/approved/business-DBA-document-vertification",
    "/edit-portfolio",
    "/add-portfolio",
    "/education/learn-series65/chapter-detail/",
    "/education/learn-series65/add-chapter-preview",
    "/education/learn-series65/add-chapter",
    "/education/learn-series65/chapter-edit/",
    "/education/learn-series65/chapter-preview/",
    "/education/learn-investing/chapter-detail/",
    "/education/learn-investing/add-chapter-preview",
    "/education/learn-investing/add-chapter",
    "/education/learn-investing/chapter-edit/",
    "/education/learn-investing/chapter-preview/",
    "/education/learn-series65/quiz-detail/",
    "/education/learn-series65/add-quiz",
    "/education/learn-series65/add-quiz-preview",
    "/education/learn-series65/quiz-edit/",
    "/education/learn-series65/quiz-preview/",
    "/education/learn-investing/quiz-detail/",
    "/education/learn-investing/add-quiz",
    "/education/learn-investing/add-quiz-preview",
    "/education/learn-investing/quiz-edit/",
    "/education/learn-investing/quiz-preview/",
    "/education/learn-series65/video-lecture-detail/",
    "/education/learn-series65/video-lecture-edit/",
    "/education/learn-series65/video-lecture-preview/",
    "/education/learn-series65/add-video-lecture",
    "/education/learn-series65/add-video-lecture-preview",
    "/education/learn-investing/video-lecture-detail/",
    "/education/learn-investing/video-lecture-edit/",
    "/education/learn-investing/video-lecture-preview/",
    "/education/learn-investing/add-video-lecture",
    "/education/learn-investing/add-video-lecture-preview",
    "/education/learn-series65/exam-detail/",
    "/education/learn-series65/exam-edit/",
    "/education/learn-series65/exam-preview/",
    "/education/learn-series65/add-exam",
    "/education/learn-series65/add-exam-preview",
    "/education/learn-investing/exam-detail/",
    "/education/learn-investing/exam-edit/",
    "/education/learn-investing/exam-preview/",
    "/education/learn-investing/add-exam",
    "/education/learn-investing/add-exam-preview",
  ]

  // Add this constant at the top with other route constants
  const HideBackButtonRoutes = [
    "/education"
  ];

  // Fetch user profile and unseen notification count on component mount
  useEffect(() => {
    getUserProfile();
    getUnseenNotificationsCount({ is_clicked: false });
  }, []);

  // Update unread count when it changes
  useEffect(() => {
    setUnread(unseenCount);
  }, [unseenCount]);

  // Handle user logout
  const onLogout = () => {
    adminLogout().then(() => {
      history.push("/login");
      window.location.reload();
    });
  };



  // Handle notification click (marking as seen)
  const onNotificationClick = () => {
    getUnseenNotifications({ is_clicked: true });
  };

  // Reset unread count when notification popover visibility changes
  const handleVisibleChange = (visibility) => {
    if (!visibility) {
      setUnread(0);
    }
  };

  // Handle search input change
  const onSeachChange = (item) => {
    setSearch(item);
    handleSearchChange(item);
  };

  // Dropdown menu for user profile
  const menu = (
    <Menu className="menu">
      <Menu.Item onClick={() => setShowDrawer(true)}>
        <div className="menu-item">
          <UserOutlined className="item-icon" />
          <span className="menu-item-title">Profile</span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => history.push("/settings")}>
        <div className="menu-item">
          <SettingOutlined className="item-icon" />
          <span className="menu-item-title">Settings</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div className="menu-item" onClick={() => onLogout()}>
          <LogoutOutlined className="item-icon" />
          <span className="menu-item-title">Logout</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  // Notification popover content
  const notifications = (
    <div className="notifications-content-wrapper">
      {loading ? (
        <Spin />
      ) : (
        <>
          <div className="pop-header">
            <h4>
              Notifications <span>{unseenCount}</span>
            </h4>
            <img src={notification} alt="Notification" />
          </div>
          <div className="pop-content">
            {useenNotifications?.map((item) => (
              <div className="line-hr">
                <Row gutter={12}>
                  <Col span={4}>
                    <div className="alignments">
                      <div className="img-wrapper">
                        <img src={item?.profile_pic || profile} alt="Profile" />
                      </div>
                    </div>
                  </Col>
                  <Col span={14}>
                    <div className="alignments">
                      <span className="notification-msg">{item?.activity}</span>
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="alignments">
                      <span className="timing">
                        {item?.timestamp &&
                          moment(item?.timestamp).utc().local().fromNow()}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
          <div className="pop-footer">
            <span onClick={() => history.push("/notifications")}>View All</span>
          </div>
        </>
      )}
    </div>
  );

  // Set local storage based on the current page
  const onPageSet = () => {
    const page = location?.pathname?.split("/")[1];
    switch (page) {
      case "waitlist":
        localStorage.setItem("waitlistPage", 1);
        localStorage.setItem("investor_waitlist_Page", 1);
        break;
      case "rejected":
        localStorage.setItem("rejectedPage", 1);
        localStorage.setItem("investor_rejected_Page", 1);
        break;
      case "tracking":
        localStorage.setItem("trackingPage", 1);
        break;
      case "scouts":
        localStorage.setItem("scoutsPage", 1);
        break;
      case "approved":
        localStorage.setItem("approvedPage", 1);
        localStorage.setItem("investor_approved_Page", 1);
        break;
      case "marketing":
        localStorage.setItem("marketingPage", 1);
        break;
      case "transactions":
        localStorage.setItem("transactionsPage", 1);
        localStorage.setItem("investor_transactions_Page", 1);
        break;
      case "talent":
        localStorage.setItem("talentPage", 1);
        break;
      case "portfolio":
        localStorage.setItem("portfolioPage", 1);
        break;
      default:
    }
  };

  // Dropdown content for search suggestions
  const searchDrop = (
    <div className="search-drop">
      <Space size={[16, 16]} wrap>
        {[
          "Healthcare",
          "Artificial Intelligence",
          "Media / Advertising",
          "Entertainment",
          "Education",
        ].map((item) => (
          <span
            className="search-tag"
            onClick={() => {
              onPageSet();
              onSeachChange(getReverseSector(item));
            }}
          >
            {item}
          </span>
        ))}
      </Space>
    </div>
  );

  return (
    <div className="navbar-container">
      {NonSearchRoutes?.includes(location.pathname) ? (
        <div className="navbar-left" />
      ) : isSearchAble(location.pathname) ? (
        <div className="navbar-left">
          {location?.state?.back && (
            <div className="navbar-left" onClick={() => history.goBack()}>
              <img src={BackArrow} alt="BackArrow" className="back-arrow" />
              <p className="back-title" style={{ marginRight: "16px" }}>
                Back
              </p>
            </div>
          )}
          <p className="v-line"></p>
          <Popover placement="bottomLeft" trigger="click" content={searchDrop}>
            <Input
              placeholder={
                location?.state?.searchPlaceholder
                  ? location?.state?.searchPlaceholder
                  : `Search ${location?.pathname?.split("/")[1][0]?.toUpperCase() +
                  location?.pathname?.split("/")[1]?.slice(1)
                  }`
              }
              prefix={<SearchOutlined />}
              className="search-input"
              value={search && (getSector(search) ? getSector(search) : search)}
              allowClear
              onChange={(e) => {
                onPageSet();
                onSeachChange(e.target.value);
              }}
            />
          </Popover>
        </div>
      ) : (
        true && (
          <div className="navbar-left">
            {!HideBackButtonRoutes.includes(location.pathname) && (
              <div className="navbar-left" onClick={() => history.goBack()}>
                <img src={BackArrow} alt="BackArrow" className="back-arrow" />
                <span>
                  <p className="back-title">Back</p>
                </span>
              </div>
            )}
            {!NonSearchingRoutes.some(route => location.pathname.startsWith(route)) ? (
              <span>
                <Input
                  placeholder={
                    location?.state?.searchPlaceholder
                      ? location?.state?.searchPlaceholder
                      : `Search ${location?.pathname?.split("/")[1][0]?.toUpperCase() +
                      location?.pathname?.split("/")[1]?.slice(1)
                      }`
                  }
                  prefix={<SearchOutlined />}
                  className="search-input"
                  value={search && (getSector(search) ? getSector(search) : search)}
                  allowClear
                  onChange={(e) => {
                    onPageSet();
                    onSeachChange(e.target.value);
                  }}
                />
              </span>
            ) : ''}
          </div>
        )
      )}
      <div className="navbar-right">
        {unread > 0 ? (
          <Popover
            content={notifications}
            placement="bottomRight"
            trigger="click"
            onVisibleChange={(visiblity) => handleVisibleChange(visiblity)}
          >
            <Badge count={unseenCount} onClick={() => onNotificationClick()}>
              <img
                src={notification}
                alt="notification"
                className="bell-icon"
              />
            </Badge>
          </Popover>
        ) : (
          <img
            src={notification}
            alt="notification"
            className="bell-icon"
            onClick={() => history.push("/notifications")}
          />
        )}
        <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]}>
          <div className="profile-icon">
            <img
              src={userData?.profile_pic ?? profile}
              alt="profile"
              style={{ borderRadius: "50%" }}
              width="40px"
              height="40px"
            />
          </div>
        </Dropdown>
      </div>
      {userData && (
        <ProfileDrawer
          userData={userData}
          showDrawer={showDrawer}
          closeDrawer={() => setShowDrawer(false)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    error: state.Auth.error,
    message: state.Auth.message,
    userData: state.Auth.userData,
    unseenCount: state.Notifications.unseenCount,
    useenNotifications: state.Notifications.useeenNotifications,
    loading: state.Notifications.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogout: () => dispatch(adminLogout()),
    getUserProfile: () => dispatch(getUserProfile()),
    getUnseenNotificationsCount: (data) =>
      dispatch(getUnseenNotificationsCount(data)),
    getUnseenNotifications: (data) => dispatch(getUnseenNotifications(data)),
    handleSearchChange: (search) => dispatch(searchChangeHandle(search)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
