import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import "./AddNewPodcast.scss";
import { Editor } from "@tinymce/tinymce-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  addNewPodcast,
  addMarktingMediaData,
  getMarktingMediaData,
} from "../../../Redux/Actions/MarketingActions";
import {
  Button,
  Col,
  Upload,
  Popover,
  Input,
  message,
  Row,
  Spin,
  Empty,
  DatePicker,
  Select,
  Popconfirm,
} from "antd";
import axiosInstance from "../../../Utils/api";
import { CloseOutlined, CopyOutlined, UploadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {
  capitalize,
  range,
  getFileNameFromUrl,
  dummyRequest,
  IMAGE_SIZE_LIMIT,
  IMAGE_SIZE_ERR,
  getTinyMCEApiKey,
} from "../../../Utils/helper";
import userProfile from "../../../Assets/Images/user-profile.svg";
import camera from "../../../Assets/Images/camera.svg";
import upload from "../../../Assets/Images/upload.svg";
import saved from "../../../Assets/Images/saved.svg";
import Delete from "../../../Assets/Images/delete.svg";

const AddNewPodcast = ({
  mediaData,
  getMarktingMediaData,
  addMarktingMediaData,
  addNewPodcast,
  history,
  addPodcastLoading,
}) => {
  const { mediaDetails } = mediaData;
  const editorRef = useRef(null);
  const [hostProfilePicLink, setHostProfilePicLink] = useState();
  const [hostProfilePicfile, setHostProfilePicfile] = useState();
  const [guestProfilePicLink, setGuestProfilePicLink] = useState();
  const [guestProfilePicfile, setGuestProfilePicfile] = useState();
  const [bannerLink, setBannerLink] = useState();
  const [bannerImageFile, setBannerImageFile] = useState();
  const [podcastDetails, setPodcastDetails] = useState({});
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [guestDetails, setGuestDetails] = useState({});
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [editotValue, setEditorValue] = useState("");
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [content, setContent] = useState();
  const [showValidation, setShowValidation] = useState("");
  const [errors, setErrors] = useState("");
  const [savedMedia, setSaveMedia] = useState({});
  const [isDraft, setIsDraft] = useState(false);
  /**
   * Handle the selection of a user's profile picture.
   *
   * @param {File} file - The selected profile picture file.
   */
  const onProfileSelect = (file) => {
    setHostProfilePicfile(file);
  };

  // Fetch marketing media data when the component mounts.
  useEffect(() => {
    getMarktingMediaData();
  }, []);

  /**
   * Update the state with media details when the 'mediaDetails' prop changes.
   *
   * @param {Object} mediaDetails - The details of marketing media.
   */
  useEffect(() => {
    setSaveMedia(mediaDetails);
  }, [mediaDetails]);

  /**
   * Handle the selection of a guest's profile picture.
   *
   * @param {File} file - The selected guest's profile picture file.
   */

  const onGuestProfileSelect = (file) => {
    setGuestProfilePicfile(file);
  };

  /**
   * Validate the podcast details and form inputs for completeness.
   *
   * @returns {boolean} - Returns true if the form is valid; otherwise, false.
   * @description - Check and validate various fields for the podcast details
   */
  const validateForm = () => {
    let isValid = true;
    let errObj = {};
    if (!podcastDetails?.host_first_name) {
      errObj.host_first_name = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.host_last_name) {
      errObj.host_last_name = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.podcast_title) {
      errObj.podcast_title = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.sub_title) {
      errObj.sub_title = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.podcast_category) {
      errObj.podcast_category = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.summary) {
      errObj.summary = "This field is required";
      isValid = false;
    }
    if (!bannerLink) {
      errObj.bannerLink = "Banner is required";
      isValid = false;
    }
    if (!podcastDetails?.audio) {
      errObj.audio = "This field is required";
      isValid = false;
    }
    if (
      content == "<p><br></p>" ||
      content == '<p><br data-mce-bogus="1"></p>'
    ) {
      errObj.format = "This field is required";
      isValid = false;
    }
    setErrors(errObj);
    if (!isValid) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
    return isValid;
  };

  // Upload the host's profile picture to the server.
  const onUploadHostProfile = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", hostProfilePicfile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setHostProfilePicLink(res?.data?.url);
        setHostProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Profile picture uploaded successfully");
      })
      .catch((e) => {
        setHostProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Profile picture upload failed..");
      });
  };

  // Upload the guest's profile picture to the server.
  const onUploadGuestProfile = () => {
    setUploadLoading(true);
    let data = new FormData();

    data.append("type", "media");
    data.append("file_obj", guestProfilePicfile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setGuestProfilePicLink(res?.data?.url);

        setGuestProfilePicfile();
        setUploadLoading(false);
        setGuestDetails({
          ...guestDetails,
          guest_profile_image: res?.data?.url,
        });
        setEditorValue(content);
        message.success("Profile picture uploaded successfully");
      })
      .catch((e) => {
        setGuestProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Profile picture upload failed..");
      });
  };

  /**
   * Handle the selection of a banner image.
   *
   * @param {File} file - The selected banner image file.
   */
  const onBannerSelect = (file) => {
    if (file && errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "";
      setErrors(erroObj);
    }
    if (!file && !errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "This field is required";
      setErrors(erroObj);
    }
    setBannerImageFile(file);
  };

  // Upload the selected banner image to the server.
  const onUploadBanner = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", bannerImageFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setBannerLink(res?.data?.url);
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Banner uploaded successfully");
      })
      .catch((e) => {
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Banner uploaded failed..");
      });
  };

  /**
   * Update a field in the podcast details and handle validation errors.
   *
   * @param {string} field - The name of the field to update.
   * @param {string} value - The new value to assign to the field.
   */
  const onPodcastFieldUpdate = (field, value) => {
    setPodcastDetails({ ...podcastDetails, [field]: value });
    if (value && errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "";
      setErrors(erroObj);
    }
    if (!value && !errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "This field is required";
      setErrors(erroObj);
    }
  };

  /**
   * Update a field in the guest details.
   *
   * @param {string} field - The name of the field to update.
   * @param {string} value - The new value to assign to the field.
   */
  const onGuestFieldUpdate = (field, value) => {
    setGuestDetails({ ...guestDetails, [field]: value });
  };

  // Upload media details to the server and add them to the state.
  const onUploadMedia = () => {
    if (!mediaName || !mediaFile) {
      message.error("Please enter valid media details..", 2);
      return;
    }
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", mediaFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        addMarktingMediaData({
          marketing_saved_media: { ...savedMedia, [mediaName]: res.data?.url },
        });
        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setMediaFile();
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.error("Media upload failed..");
      });
  };

  /**
   * Delete a media entry from the saved media state.
   *
   * @param {string} key - The key of the media to delete.
   */
  const onDeleteMedia = (key) => {
    delete savedMedia[key];
    addMarktingMediaData({ marketing_saved_media: { ...savedMedia } });
  };

  // Close the schedule publish modal and reset the schedule publish time.
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    setSchedulePublishTime();
  };
  /**
   * Publish or save a podcast, depending on whether it's a draft or a final publication.
   *
   * @param {boolean} isDraft - True if the podcast is a draft, false for final publication.
   * @description - Check for missing or incomplete podcast details before publishing or saving as draft.
   */
  const onPublishPodcast = (isDraft) => {
    if (
      (Object.values(podcastDetails).some((item) => item == "" || !item) ||
        Object.values(podcastDetails).length < 6 ||
        !bannerLink ||
        bannerLink === "" ||
        !editotValue ||
        editotValue == "") &&
      !isDraft
    ) {
      message.error("Please complete missing podcast fields", 2);
      return;
    }

    let mediasInfo = {};
    medias?.map((item) => {
      mediasInfo[item?.name] = item?.url;
      return item;
    });
    let data = {
      type: "podcast",
      banner_image: bannerLink,
      profile_pic: hostProfilePicLink,
      title: podcastDetails?.podcast_title?.trimEnd(),
      subtitle: podcastDetails?.sub_title?.trimEnd(),
      summary: podcastDetails?.summary?.trimEnd(),
      media: mediasInfo,
      audio: podcastDetails?.audio,
      category: podcastDetails?.podcast_category,
      author_first_name: podcastDetails?.host_first_name?.trimEnd(),
      author_last_name: podcastDetails?.host_last_name?.trimEnd(),
      guest_speaker: {
        ...guestDetails,
        ...(guestProfilePicLink && {
          guest_profile_image: guestProfilePicLink,
        }),
      },
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      body: content,
    };
    if (
      !isDraft &&
      data?.guest_speaker?.guest_profile_image &&
      !data?.guest_speaker?.guest_first_name &&
      !data?.guest_speaker?.guest_last_name
    ) {
      message.error("Please add the guest’s first name and last name");
    } else if (
      !isDraft &&
      data?.guest_speaker?.guest_profile_image &&
      !data?.guest_speaker?.guest_last_name
    ) {
      message.error("Please add the guest’s last name");
    } else if (
      !isDraft &&
      data?.guest_speaker?.guest_profile_image &&
      !data?.guest_speaker?.guest_first_name
    ) {
      message.error("Please add the guest’s first name");
    } else if ((isDraft && data?.title === "") || !data?.title) {
      message.error("The podcast title is required to save the draft");
    } else {
      addNewPodcast(data)
        .then(() => {
          setIsDraft(false);
          schedulePublishTime
            ? message.success("Podcast Scheduled for Publishing")
            : isDraft
            ? message.success("Draft saved")
            : message.success("Published");
          history.push("/marketing");
        })
        .catch((e) => {
          message.error(e || "Add Podcast Failed..", 2);
        });
    }
  };

  /**
   * Define disabled time ranges for the schedule publish modal.
   *
   * @param {moment} current - The currently selected date and time.
   * @returns {Object} - Object with disabledHours and disabledMinutes functions.
   */
  const disabledRangeTime = (current) => {
    setSchedulePublishTime(current);
    const time = new Date();
    const selectedDate = moment(current).date() || new Date();
    const currDate = new Date().getDate();
    if (!current || selectedDate === currDate) {
      return {
        disabledHours: () => range(0, time.getHours()),
        disabledMinutes: () => range(0, time.getMinutes()),
      };
    }
  };

  // Modal component for uploading media.
  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => setUploadMediaModalOpen(false)}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={() => onUploadMedia()}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  //  Component to display saved media and allow users to copy or delete entries.
  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>
      {Object.keys(mediaDetails)?.map((item) => (
        <Row gutter={8} align="middle">
          <Col span={6}>
            <span className="media-name">{item}</span>
          </Col>
          <Col span={14}>
            <span className="media-url">
              {mediaDetails[item]?.slice(0, 50) + "..."}
            </span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={mediaDetails[item]}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
          <Col span={2}>
            <span onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                onConfirm={() => onDeleteMedia(item)}
                okText="Yes"
                cancelText="No"
                className="delete-popconfirm "
                okButtonProps={{ style: { marginRight: "12px" } }}
              >
                <img src={Delete} alt="trash" style={{ cursor: "pointer" }} />
              </Popconfirm>
            </span>
          </Col>
        </Row>
      ))}
      {Object.keys(mediaDetails)?.length === 0 && (
        <Empty description={"No Saved Media"} />
      )}
    </div>
  );

  // Modal component for scheduling podcast publishing.
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => {
        onPublishPodcast(false);
      }}
      className="schedule-publish-modal"
      okButtonProps={{ loading: addPodcastLoading }}
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => {
            setSchedulePublishTime(date?.toDate());
          }}
          showTime
          showNow={true}
          format={"MM-DD-YYYY HH:mm:ss"}
          defaultValue={
            schedulePublishTime
              ? moment(
                  moment(schedulePublishTime).format("YYYY-MM-DDTHH:mm:ss.SSSS")
                )
              : null
          }
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          disabledTime={(current) => disabledRangeTime(current)}
        />
      </div>
    </Modal>
  );

  //  Trigger a request to get marketing media data.
  const onSavedMedia = () => {
    getMarktingMediaData();
  };

  /**
   * Handle the change of an audio file input, validate it, and upload it to the server.
   *
   * @param {File} file - The selected audio file to upload.
   */
  const onAudioFileChange = (file) => {
    // console.log("file", file);
    if (file) {
      if (file.size > 200000000) {
        message.error("Audio file must not be more than 200 MB.");
        return;
      } else if (file.type !== "audio/mpeg" && file.type !== "audio/wav") {
        message.error("Please upload a WAV or MP3 file only.");
        return;
      }
      setUploadLoading(true);
      let data = new FormData();
      data.append("type", "media");
      data.append("file_obj", file);
      axiosInstance
        .post("admin/mime-files", data)
        .then((res) => {
          onPodcastFieldUpdate("audio", res?.data?.url);
          setEditorValue(content);
          setUploadLoading(false);
        })
        .catch((e) => {
          setUploadLoading(false);
          setEditorValue(content);
          message.error("Failed to upload.");
        });
    }
  };

  return (
    <div className="add-podcast-container">
      <div className="top-add">
        <h6> Add New Podcast</h6>
        <div className="add-right">
          <Button
            className="save-btn"
            loading={isDraft && addPodcastLoading}
            onClick={() => {
              setErrors("");
              setIsDraft(true);
              onPublishPodcast(true);
            }}
          >
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => {
              validateForm() && setOpenschedulePublishModal(true);
            }}
          >
            Schedule Publish
          </Button>
          <Button
            className="publish-btn"
            loading={!isDraft && addPodcastLoading}
            onClick={() => {
              validateForm() && onPublishPodcast(false);
            }}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="add-podcast-wrap">
        {uploadLoading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={4}>
              <div className="profile-icon">
                {hostProfilePicLink ? (
                  <img
                    src={hostProfilePicLink || userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : hostProfilePicfile ? (
                  <img
                    src={URL.createObjectURL(hostProfilePicfile)}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : (
                  <img
                    src={userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                )}

                <Upload
                  customRequest={dummyRequest}
                  onChange={async (file) => {
                    if (file?.file?.status === "uploading") {
                      if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                        message.error(IMAGE_SIZE_ERR, 2);
                        return;
                      }
                      onProfileSelect(file.file.originFileObj);
                    }
                  }}
                  accept="image/png,image/jpeg"
                >
                  <div className="camera-icon-wrap">
                    <img
                      src={camera}
                      alt="profile"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Upload>
              </div>
              {hostProfilePicfile && (
                <Button
                  className="upload-profile-btn"
                  onClick={() => onUploadHostProfile()}
                >
                  Upload
                </Button>
              )}
            </Col>
            <Col span={12}>
              <Row>
                <Col span={6} offset={12}>
                  <Popover
                    content={savedMediaContent}
                    placement={"bottomRight"}
                    trigger={"click"}
                  >
                    <Button
                      className="upload-btn"
                      icon={<img src={saved} alt="saved" />}
                      onClick={onSavedMedia}
                    >
                      Saved Media
                    </Button>
                  </Popover>
                </Col>
                <Col span={6}>
                  <Button
                    className="upload-btn"
                    onClick={() => setUploadMediaModalOpen(true)}
                    icon={<img src={upload} alt="upload" />}
                  >
                    Upload Media
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="upload-banner">
                  {bannerLink ? (
                    <>
                      <div className="banner-image-wrap">
                        <img src={bannerLink} alt="banner" />
                      </div>
                      {!bannerImageFile && (
                        <>
                          <Upload
                            customRequest={dummyRequest}
                            onChange={async (file) => {
                              if (file?.file?.status === "uploading") {
                                if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                                  message.error(IMAGE_SIZE_ERR, 2);
                                  return;
                                }
                                onBannerSelect(file.file.originFileObj);
                              }
                            }}
                            accept="image/*"
                          >
                            <Button className="upload-banner-btn">
                              Re-Upload Banner
                            </Button>
                          </Upload>
                        </>
                      )}
                    </>
                  ) : bannerImageFile ? (
                    <div className="banner-image-wrap">
                      <img
                        src={URL.createObjectURL(bannerImageFile)}
                        alt="banner"
                      />
                    </div>
                  ) : (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={async (file) => {
                        if (file?.file?.status === "uploading") {
                          if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                            message.error(IMAGE_SIZE_ERR, 2);
                            return;
                          }
                          onBannerSelect(file.file.originFileObj);
                        }
                      }}
                      accept="image/*"
                    >
                      <div className="upload-inner">
                        <span>
                          <UploadOutlined /> Upload Banner Image
                        </span>
                      </div>
                    </Upload>
                  )}
                </div>
                <div>
                  <div>
                    {showValidation && (
                      <div style={{ color: "red" }}>{errors["bannerLink"]}</div>
                    )}
                  </div>
                  <div className="desc">Banner should be 1073px by 576px</div>
                </div>
                {bannerImageFile && (
                  <Button
                    className="upload-banner-btn"
                    onClick={() => onUploadBanner()}
                  >
                    Upload Banner
                  </Button>
                )}
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Host First name</label>
                    <Input
                      value={podcastDetails?.host_first_name}
                      placeholder={"First name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "host_first_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.host_first_name?.length || 0
                        }/15`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["host_first_name"]}
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Host Last name</label>
                    <Input
                      value={podcastDetails?.host_last_name}
                      placeholder={"Last name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "host_last_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.host_last_name?.length || 0
                        }/15`}</p>
                      }
                    />

                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["host_last_name"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Podcast Title</label>
                    <Input
                      value={podcastDetails?.podcast_title}
                      placeholder={"Title"}
                      maxlength={47}
                      className="inputs-box"
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.podcast_title?.length || 0
                        }/47`}</p>
                      }
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "podcast_title",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["podcast_title"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Subtitle</label>
                    <Input
                      value={podcastDetails?.sub_title}
                      placeholder={"Subtitle"}
                      maxlength={55}
                      className="inputs-box"
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.sub_title?.length || 0
                        }/55`}</p>
                      }
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "sub_title",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["sub_title"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Category</label>
                    <Select
                      size="large"
                      placeholder="Category"
                      className="selection-option"
                      optionFilterProp="children"
                      showSearch
                      value={podcastDetails?.podcast_category}
                      showArrow
                      onChange={(value) =>
                        onPodcastFieldUpdate("podcast_category", value)
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {[
                        "Educational",
                        "News",
                        "Interview",
                        "Startup",
                        "Venture Capital",
                      ]?.map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {capitalize(item)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["podcast_category"]}
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Audio</label>
                    {podcastDetails?.audio ? (
                      <span className="file-title" style={{ display: "flex" }}>
                        {getFileNameFromUrl(podcastDetails?.audio)}
                        <CloseOutlined
                          onClick={() => onPodcastFieldUpdate("audio", null)}
                        />
                      </span>
                    ) : (
                      <Upload
                        accept="audio/*"
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={(info) => onAudioFileChange(info.file)}
                      >
                        <Button
                          className="upload-btn"
                          icon={<img src={upload} alt="upload" />}
                        >
                          Upload Audio
                        </Button>
                      </Upload>
                    )}
                  </div>
                  {showValidation && (
                    <span style={{ color: "red" }}>{errors["audio"]}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Short Summary</label>
                    <TextArea
                      rows={3}
                      value={podcastDetails?.summary}
                      placeholder={"Short summary"}
                      maxLength={217}
                      showCount
                      bordered={false}
                      className="inputs-box"
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "summary",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>{errors["summary"]}</span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Editor
                apiKey={getTinyMCEApiKey()}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(a, b) => {
                    setEditorValue(a);
                    const content = b.getContent({ format: "raw" });
                    setContent(content);
                    if (
                      content != "<p><br></p>" &&
                      content != '<p><br data-mce-bogus="1"></p>' &&
                      errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "";
                      setErrors(erroObj);
                    }
                    if (
                      (content == "<p><br></p>" ||
                        content == '<p><br data-mce-bogus="1"></p>') &&
                      !errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "This field is required";
                      setErrors(erroObj);
                    }
                  }}
                  init={{
                    height: 500,
                    selector: "textarea",
                    plugins:
                      "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc link",
                    menubar: "file edit view insert format tools table help",
                    toolbar:
                      "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                    toolbar_mode: "wrap",
                    block_formats:
                      "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    setup: function (editor) {
                      editor.addShortcut(
                        "alt+shift+1",
                        "H1 is Permanently Disabled",
                        function () {
                          editor.execCommand();
                        }
                      );
                    },
                  }}
                  value={editotValue}
                />
                {showValidation && (
                  <span style={{ color: "red" }}>{errors["format"]}</span>
                )}
              </Row>
            </Col>
            <Col span={8}>
              <div className="guest-speaker">
                <h4>Guest Speaker</h4>
                <div className="profile-icon">
                  {guestProfilePicLink ? (
                    <img
                      src={guestProfilePicLink || userProfile}
                      alt="profile"
                      style={{ borderRadius: "50%" }}
                      width="100px"
                      height="100px"
                    />
                  ) : guestProfilePicfile ? (
                    <img
                      src={URL.createObjectURL(guestProfilePicfile)}
                      alt="profile"
                      style={{ borderRadius: "50%" }}
                      width="100px"
                      height="100px"
                    />
                  ) : (
                    <img
                      src={userProfile}
                      alt="profile"
                      style={{ borderRadius: "50%" }}
                      width="100px"
                      height="100px"
                    />
                  )}

                  <Upload
                    onChange={(file) =>
                      onGuestProfileSelect(file.file.originFileObj)
                    }
                    accept="image/png,image/jpeg"
                  >
                    <div className="camera-icon-wrap">
                      <img
                        src={camera}
                        alt="profile"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Upload>
                </div>
                {guestProfilePicfile && (
                  <Button
                    className="upload-profile-btn"
                    onClick={() => onUploadGuestProfile()}
                  >
                    Upload
                  </Button>
                )}
                <div className="field-box">
                  <label>Guest First name</label>
                  <Input
                    value={guestDetails?.guest_first_name}
                    placeholder={"First name"}
                    className="inputs-box"
                    maxLength={15}
                    onChange={(e) =>
                      onGuestFieldUpdate(
                        "guest_first_name",
                        e.target.value?.trimStart()
                      )
                    }
                    suffix={
                      <p className="podcast-input">{`${
                        guestDetails?.guest_first_name?.length || 0
                      }/15`}</p>
                    }
                  />
                </div>
                <div className="field-box">
                  <label>Guest Last name</label>
                  <Input
                    value={guestDetails?.guest_last_name}
                    placeholder={"Last name"}
                    className="inputs-box"
                    maxLength={15}
                    onChange={(e) =>
                      onGuestFieldUpdate(
                        "guest_last_name",
                        e.target.value?.trimStart()
                      )
                    }
                    suffix={
                      <p className="podcast-input">{`${
                        guestDetails?.guest_last_name?.length || 0
                      }/15`}</p>
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {uploadMediaModal}
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addPodcastLoading: state.Marketing.addPodcastLoading,
    mediaData: state.Marketing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewPodcast: (data) => dispatch(addNewPodcast(data)),
    addMarktingMediaData: (data) => dispatch(addMarktingMediaData(data)),
    getMarktingMediaData: () => dispatch(getMarktingMediaData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPodcast);
