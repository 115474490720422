import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, message, Pagination, Row, Select, Empty, Spin } from "antd";
import * as videoLecturesActions from "../../../../../Redux/Actions/VideoLecturesActions";
import pause from "../../../../../Assets/Images/pause.svg";
import play from "../../../../../Assets/Images/play.svg";
import trash from "../../../../../Assets/Images/delete.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import add from "../../../../../Assets/Images/add.svg";
import left from "../../../../../Assets/Images/leftArrow.svg";
import right from "../../../../../Assets/Images/rightArrow.svg";
import videoLecture from "../../../../../Assets/Images/videoLecture.svg";
import userIcon from "../../../../../Assets/Images/user.png";
import clockIcon from "../../../../../Assets/Images/time.png";
import eyeIcon from "../../../../../Assets/Images/eye.png";
import "./VideoLectures.scss";
import moment from "moment";

const { Option } = Select;
const VideoLectures = ({
  videoLecturesData,
  totalVideoLecturesCount,
  searchText,
  getVideoLecturesData,
  toggleVideoLectureAction,
  deleteVideoLecture,
  history,
  loading,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [fetchData, setFetchData] = useState(false);

  /**
   * Fetch video lecture data based on the specified page, page size, and search criteria.
   *
   * @param {number} page - The current page number.
   * @param {number} pageSize - The number of items to display per page.
   * @param {string} searchText - The search text, if any, to filter video lectures.
   */
  useEffect(() => {
    let data = {
      page_num: page,
      page_size: pageSize,
      learning_type: "investing",
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
        is_search: true,
        search_text: searchText,
      }),
    };
    const timeoutId = setTimeout(() => {
      getVideoLecturesData(user?.id, data);
    }, 500); // Adjust delay as needed
    return () => clearTimeout(timeoutId);
  }, [page, pageSize, fetchData, searchText]);

  /**
   * Toggle the activation status of a video lecture.
   *
   * @param {Event} e - The event object.
   * @param {Object} video - The video lecture object.
   */
  const onToggleVideoLectureAction = (e, video) => {
    e.stopPropagation();
    let data = {
      is_active: !video?.is_active,
      video_id: video?.id,
    };
    toggleVideoLectureAction(data, user?.id)
      .then(() => {
        message.success("Updated..");
      })
      .catch((e) => {
        message.error(e || "Something went wrong..");
      });
  };

  /**
   * Delete a video lecture.
   *
   * @param {Event} e - The event object.
   * @param {number} id - The ID of the video lecture to delete.
   */
  const onDeleteVideoLecture = (e, id) => {
    e.stopPropagation();
    let data = {
      video_id: id,
      delete: true,
    };
    deleteVideoLecture(data, user?.id)
      .then(() => {
        setFetchData(!fetchData);
        message.success("Deleted");
      })
      .catch((e) => {
        message.error(e || "Failed to delete");
      });
  };

  /**
   * Edit a video lecture.
   *
   * @param {Event} e - The event object.
   * @param {Object} item - The video lecture item to edit.
   */
  const onEditVideoLecture = (e, item) => {
    e.stopPropagation();
    history.push(`/education/learn-investing/video-lecture-edit/${item?.id}`);
  };

  /**
   * Custom item render function for pagination control.
   *
   * @param {number} current - The current page number.
   * @param {string} type - The type of the pagination element.
   * @param {React.Element} originalElement - The original pagination element.
   * @returns {React.Element} - The custom rendered pagination element.
   */
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <img className="prev-arrow" src={left} alt="previous" />;
    }
    if (type === "next") {
      return <img className="next-arrow" src={right} alt="next" />;
    }
    return originalElement;
  };

  return (
    <div className="videos-container">
      <div className="videos-header">
        <h6>Video Lectures</h6>
        <div className="header-right">
          <p
            onClick={() =>
              history.push("/education/learn-investing/add-video-lecture")
            }
          >
            <img src={add} alt="add" />
            <span>Add New</span>
          </p>
          <Select
            defaultValue={10}
            className="selection-menu"
            onChange={(value) => setPagesize(value)}
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
          </Select>
        </div>
      </div>
      <div className="video-list-groups">
        {loading ? (
          <Spin />
        ) : (
          videoLecturesData?.map((item) => (
            <Row
              className="single-list"
              onClick={() =>
                history?.push(
                  // `/education/learn-investing/video-lecture-preview/${item?.id}`
                  `/education/learn-investing/video-lecture-detail/${item?.id}`,{
                    searchPlaceholder: "Search Video Lecture", back: true
                  }
                )
              }
            >
              <Col span={1}>
                <div className="icon-box">
                  <img src={videoLecture} alt="video" />
                </div>
              </Col>
              <Col span={16} className="title">
                <h6>{item?.topic}
                  {item?.is_draft ? <span className="draftBadge2">Draft</span> :
                    item?.publish_date && moment.utc(item?.publish_date).local().isAfter(moment()) ? <span className="publishBadge2">
                      <img src={clockIcon} alt="clock" />
                      {`Schedule Publish on: ${moment.utc(item?.publish_date).local().format('D MMMM, YYYY [at] HH:mm:ss')}`}
                    </span> : null}
                </h6>
                <Row gutter={20}>
                  <Col span={7}>
                    <span>Associated with Chapter {item?.chapter_number}</span>
                  </Col>
                  <Col span={16} className="author-details">
                    <span>
                      <img src={userIcon} alt="user" /> By {item?.created_by}
                      <img src={clockIcon} alt="clock" /> {item?.created}
                      <img src={eyeIcon} alt="eye" /> Views :
                      {item?.total_views || 0}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={3} offset={2} className="icon-group">
                <img
                  src={item?.is_active ? pause : play}
                  alt="Pause"
                  onClick={(e) => onToggleVideoLectureAction(e, item)}
                />
                <img
                  src={edit}
                  alt="Edit"
                  onClick={(e) => onEditVideoLecture(e, item)}
                />
                <img
                  src={trash}
                  alt="Delete"
                  onClick={(e) => onDeleteVideoLecture(e, item?.id)}
                />
              </Col>
            </Row>
          ))
        )}
      </div>
      {videoLecturesData?.length === 0 && !loading && (
        <Empty description="No Video Lecture" />
      )}
      {totalVideoLecturesCount > 0 && !loading && (
        <div className="pagination">
          <Pagination
            total={totalVideoLecturesCount}
            itemRender={itemRender}
            pageSize={pageSize}
            current={page}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    videoLecturesData: state.VideoLecture.videoLecturesData,
    totalVideoLecturesCount: state.VideoLecture.totalVideoLecturesCount,
    loading: state.VideoLecture.loading,
    searchText: state.Search.searchText,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVideoLecturesData: (id, data) =>
      dispatch(videoLecturesActions.getLearnSeriesVideoLecturesData(id, data)),
    toggleVideoLectureAction: (data, id) =>
      dispatch(videoLecturesActions.toggleVideoLectureAction(data, id)),
    deleteVideoLecture: (data, id) =>
      dispatch(videoLecturesActions.deleteVideoLecture(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoLectures);
