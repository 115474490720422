import * as actionTypes from './ActionTypes'
import axios from '../../Utils/api'

// Action to get data for Learn Series chapters
export const getLearnSeriesChaptersData=(id,data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN_SERIES_CHAPTERS_DATA_INIT
        })
        await axios.post("admin/all-chapters",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTERS_DATA_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTERS_DATA_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to toggle the status of a Learn Series chapter
export const toggleChapterAction=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.TOGGLE_LEARN_SERIES_CHAPTER_ACTION_INIT
        })
        await axios.post("admin/disable-chapter",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.TOGGLE_LEARN_SERIES_CHAPTER_ACTION_SUCCESS,
                payload:res.data,
                chapterId:data?.chapter_id,
                toggleState:data?.is_active
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.TOGGLE_LEARN_SERIES_CHAPTER_ACTION_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to delete a Learn Series chapter
export const deleteChapter=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.DELETE_LEARN_SERIES_CHAPTER_INIT
        })
        await axios.post("admin/delete-chapter",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.DELETE_LEARN_SERIES_CHAPTER_SUCCESS,
                payload:res.data,
                chapterId:data?.chapter_id,
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.DELETE_LEARN_SERIES_CHAPTER_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to add a new Learn Series chapter
export const addNewChapter=(data,id,onSuccess)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.ADD_LEARN_SERIES65_CHAPTER_INIT
        })
        await axios.post("admin/add-chapter",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.ADD_LEARN_SERIES65_CHAPTER_SUCCESS,
                payload:res.data
            });
            onSuccess(res.data)
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.ADD_LEARN_SERIES65_CHAPTER_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get details of a Learn Series chapter
export const getLearnSeriesChapterDetails=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_LEARN_SERIES_CHAPTER_DETAILS_INIT
        })
        await axios.post("admin/chapter",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTER_DETAILS_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_LEARN_SERIES_CHAPTER_DETAILS_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to edit a Learn Series chapter
export const editLearnSeriesChapter=(data,id)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.EDIT_LEARN_SERIES_CHAPTER_INIT
        })
        await axios.post("admin/edit-chapter",data,{params:{id}}).then((res) => {
            dispatch({
                type: actionTypes.EDIT_LEARN_SERIES_CHAPTER_SUCCESS,
                payload:res.data,
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.EDIT_LEARN_SERIES_CHAPTER_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}