// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  rejectedInvestorsData: [],
  loading: false,
  totalRejectedInvestors: 0,
  rejectedInvestorDetails: [],
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REJECTED_INVESTORS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REJECTED_INVESTORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectedInvestorsData: action.payload?.data,
        totalRejectedInvestors: action.payload?.total_count,
      };
    case actionTypes.GET_REJECTED_INVESTORS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        rejectedInvestorsData: [],
        totalRejectedInvestors: 0,
      };
    case actionTypes.DELETE_REJECTED_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_REJECTED_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectedInvestorsData: action.payload?.data,
        totalRejectedInvestors: action.payload.total_count,
      };
    case actionTypes.DELETE_REJECTED_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_REJECTED_INVESTOR_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REJECTED_INVESTOR_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectedInvestorDetails: action.payload?.data,
      };
    case actionTypes.GET_REJECTED_INVESTOR_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        rejectedInvestorDetails: [],
      };
    case actionTypes.UPDATE_REJECTED_INVESTOR_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_REJECTED_INVESTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_REJECTED_INVESTOR_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
