import React, { useEffect, useState } from "react";
import SubscribersTable from "./SubscribersTable";
import { Select } from "antd";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import {
  exportSubscribersDetails,
  deleteSubscriberData,
} from "../../../Redux/Actions/MarketingActions";
import { withRouter } from "react-router-dom";
import moment from "moment";
import "./Subscribers.scss";
import exportIcon from "../../../../src/Assets/Images/export.svg";

const Subscribers = ({
  subscribersData,
  exportSubscribersDetails,
  subscribersExportData,
}) => {
  const { Option } = Select;
  const [exportData, setExportData] = useState([]);
  const [countPerPage, setCountPerPage] = useState(10);
  const [data, setData] = useState([]);

  // transform subscriber data
  useEffect(() => {
    let obj = subscribersData?.map((data, key) => {
      return {
        created: data.created,
        email: data.email,
        name: data.name,
      };
    });
    setData({ ...data, obj });
  }, [subscribersData]);

  //  Export subscriber details
  useEffect(() => {
    exportSubscribersDetails();
  }, []);

  // Header configuration for the subscriber data table.
  const subscibeHeader = [
    {
      label: "Date",
      key: "created",
    },
    {
      label: "First Name",
      key: "name",
    },
    {
      label: "Email",
      key: "email",
    },
  ];

  // Function to transform subscriber export data for use in a table.
  const getData = () => {
    subscribersExportData?.map((data) => {
      setExportData((prev) => [
        ...prev,
        {
          ...data,
          created: data.created
            ? moment(data.created).format("MM/DD/YYYY")
            : "N/A",
          name: data?.name || "N/A",
          email: data?.email || "N/A",
        },
      ]);
    });
  };
  return (
    <div className="subscribers-container">
      <div className="subscribers-header">
        <div className="left-side">
          <p>Subscribers</p>
        </div>

        <div className="right-side">
          <CSVLink
            filename={`subscribers.csv`}
            data={exportData || []}
            target="_blank"
            headers={subscibeHeader}
            onClick={getData}
            className="export-btn"
          >
            <img src={exportIcon} alt={"export"} /> Download CSV
          </CSVLink>
          <Select
            value={countPerPage}
            onSelect={(e) => setCountPerPage(e)}
            className="selection-menu"
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
          </Select>
        </div>
      </div>

      <SubscribersTable countPerPage={countPerPage} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    subscribersData: state.Marketing.subscribersData,
    subscribersExportData: state.Marketing.subscribersExportData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    exportSubscribersDetails: () => dispatch(exportSubscribersDetails()),
    deleteSubscriberData: (data, id) =>
      dispatch(deleteSubscriberData(data, id)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Subscribers));
