import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, message, Pagination, Row, Select, Empty, Spin } from "antd";
import * as ChaptersActions from "../../../../../Redux/Actions/ChaptersActions";
import pause from "../../../../../Assets/Images/pause.svg";
import play from "../../../../../Assets/Images/play.svg";
import trash from "../../../../../Assets/Images/delete.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import add from "../../../../../Assets/Images/add.svg";
import left from "../../../../../Assets/Images/leftArrow.svg";
import right from "../../../../../Assets/Images/rightArrow.svg";
import chapters from "../../../../../Assets/Images/chapters.svg";
import userIcon from "../../../../../Assets/Images/user.png";
import clockIcon from "../../../../../Assets/Images/time.png";
import eyeIcon from "../../../../../Assets/Images/eye.png";
import "./Chapters.scss";
import moment from "moment";

const { Option } = Select;

const Chapters = ({
  chaptersData,
  totalChaptersCount,
  searchText,
  getChaptersData,
  toggleChapterAction,
  deleteChapter,
  history,
  loading,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [fetchData, setFetchData] = useState(false);

  /**
   * Fetches chapter data based on the selected page, page size, and search criteria.
   * @description - It sends a request to 'getChaptersData' with the user's ID and data containing page information and search criteria.
   */
  useEffect(() => {
    let data = {
      page_num: page,
      page_size: pageSize,
      learning_type: "investing",
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
        is_search: true,
        search_text: searchText,
      }),
    };
    const timeoutId = setTimeout(() => {
      getChaptersData(user?.id, data);
    }, 500); // Adjust delay as needed
    return () => clearTimeout(timeoutId);
  }, [page, pageSize, fetchData, searchText]);

  /**
   * Function to toggle the active status of a chapter and display a success or error message.
   * @param {Event} e - The click event object.
   * @param {object} chapter - The chapter object to be toggled.
   */
  const onToggleChapterAction = (e, chapter) => {
    e.stopPropagation();
    let data = {
      is_active: !chapter?.is_active,
      chapter_id: chapter?.id,
    };
    toggleChapterAction(data, user?.id)
      .then(() => {
        message.success("Updated..");
      })
      .catch((e) => {
        message.error(e || "Something went wrong..");
      });
  };

  /**
   * Function to delete a chapter and display a success or error message.
   * @param {Event} e - The click event object.
   * @param {number} id - The ID of the chapter to be deleted.
   */
  const onDeleteChapter = (e, id) => {
    e.stopPropagation();
    let data = {
      chapter_id: id,
      delete: true,
    };
    deleteChapter(data, user?.id)
      .then(() => {
        setFetchData(!fetchData);
        message.success("Deleted");
        // Calculate the number of items remaining on the current page
        const itemsPerPage = Math.min(pageSize, totalChaptersCount - (page - 1) * pageSize);

        // If the deleted item was the last item on the current page, go back to the previous page
        if (itemsPerPage === 1) {
          setPage(Math.max(1, page - 1));
        }
      })
      .catch((e) => {
        message.error(e || "Failed to delete");
      });
  };

  /**
   * Function to navigate to the chapter edit page.
   * @param {Event} e - The click event object.
   * @param {object} item - The chapter object to be edited.
   */
  const onEditChapter = (e, item) => {
    e.stopPropagation();
    history.push(`/education/learn-investing/chapter-edit/${item?.id}`);
  };

  /**
   * Function to customize the rendering of pagination arrows.
   * @param {number} current - The current page number.
   * @param {string} type - The type of pagination element ('prev', 'next', etc.).
   * @param {React.ReactNode} originalElement - The original pagination element.
   * @returns {React.ReactNode} - The customized pagination element.
   */
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <img className="prev-arrow" src={left} alt="previous" />;
    }
    if (type === "next") {
      return <img className="next-arrow" src={right} alt="next" />;
    }
    return originalElement;
  };

  return (
    <div className="chapters-container">
      <div className="chapters-header">
        <h6>Chapters</h6>
        <div className="header-right">
          <p
            onClick={() =>
              history.push("/education/learn-investing/add-chapter")
            }
          >
            <img src={add} alt="add" />
            <span>Add New</span>
          </p>
          <Select
            defaultValue={10}
            className="selection-menu"
            onChange={(value) => setPagesize(value)}
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
          </Select>
        </div>
      </div>
      <div className="chapters-list-groups">
        {loading ? (
          <Spin />
        ) : (
          chaptersData?.map((item) => (
            <Row
              className="single-list"
              onClick={() =>
                history?.push({
                  pathname: `/education/learn-investing/chapter-detail/${item?.id}`,
                  state: { searchPlaceholder: "Search Chapter", back: true }
                })
              }
            >
              <Col span={1}>
                <div className="icon-box">
                  <img src={chapters} alt="chapters" />
                </div>
              </Col>
              <Col span={16} className="title">
                <h6>Chapter {item?.chapter_number}
                  {item?.is_draft ? <span className="draftBadge2">Draft</span> :
                    item?.publish_date && moment.utc(item?.publish_date).local().isAfter(moment()) ? <span className="publishBadge2">
                      <img src={clockIcon} alt="clock" />
                      {`Schedule Publish on: ${moment.utc(item?.publish_date).local().format('D MMMM, YYYY [at] HH:mm:ss')}`}
                    </span> : null}
                </h6>
                <Row>
                  <Col span={24}>
                    <span>{item?.title}</span>
                  </Col>
                </Row>
                <Row gutter={16} className="author-details">
                  <Col span={12}>
                    <span>
                      <img src={userIcon} alt="user" /> By {item?.created_by}
                      <img src={clockIcon} alt="clock" /> {item?.created}
                      <img src={eyeIcon} alt="eye" /> Views :
                      {item?.total_views || 0}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={3} offset={2} className="icon-group">
                <img
                  src={item?.is_active ? pause : play}
                  alt="Pause"
                  onClick={(e) => onToggleChapterAction(e, item)}
                />
                <img
                  src={edit}
                  alt="Edit"
                  onClick={(e) => onEditChapter(e, item)}
                />
                <img
                  src={trash}
                  alt="Delete"
                  onClick={(e) => onDeleteChapter(e, item?.id)}
                />
              </Col>
            </Row>
          ))
        )}
      </div>
      {chaptersData?.length === 0 && !loading && (
        <Empty description="No Chapters" />
      )}
      {totalChaptersCount > 0 && !loading && (
        <div className="pagination">
          <Pagination
            total={totalChaptersCount}
            itemRender={itemRender}
            pageSize={pageSize}
            current={page}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    chaptersData: state.Chapter.chaptersData,
    totalChaptersCount: state.Chapter.totalChaptersCount,
    loading: state.Chapter.loading,
    searchText: state.Search.searchText,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChaptersData: (id, data) =>
      dispatch(ChaptersActions.getLearnSeriesChaptersData(id, data)),
    toggleChapterAction: (data, id) =>
      dispatch(ChaptersActions.toggleChapterAction(data, id)),
    deleteChapter: (data, id) =>
      dispatch(ChaptersActions.deleteChapter(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chapters);
