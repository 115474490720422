export const ADMIN_LOGIN_INIT = "ADMIN_LOGIN_INIT";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILED = "ADMIN_LOGIN_FAILED";

export const ADMIN_LOGOUT_INIT = "ADMIN_LOGOUT_INIT";
export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";
export const ADMIN_LOGOUT_FAILED = "ADMIN_LOGOUT_FAILED";

export const GET_USER_PROFILE_INIT = "GET_USER_PROFILE_INIT";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

export const GET_ADMIN_DATA_INIT = "GET_ADMIN_DATA_INIT";
export const GET_ADMIN_DATA_SUCCESS = "GET_ADMIN_DATA_SUCCESS";
export const GET_ADMIN_DATA_FAILED = "GET_ADMIN_DATA_FAILED";

export const PASSWORD_CHANGE_INIT = "PASSWORD_CHANGE_INIT";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAILED = "PASSWORD_CHANGE_FAILED";

export const DELETE_ADMIN_INIT = "DELETE_ADMIN_INIT";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAILED";

export const TOGGLE_ADMIN_INIT = "TOGGLE_ADMIN_INIT";
export const TOGGLE_ADMIN_SUCCESS = "TOGGLE_ADMIN_SUCCESS";
export const TOGGLE_ADMIN_FAILED = "TOGGLE_ADMIN_FAILED";

export const CHANGE_PERMISSION_INIT = "CHANGE_PERMISSION_INIT";
export const CHANGE_PERMISSION_SUCCESS = "CHANGE_PERMISSION_SUCCESS";
export const CHANGE_PERMISSION_FAILED = "CHANGE_PERMISSION_FAILED";

export const UPDATE_PROFILE_INIT = "UPDATE_PROFILE_INIT";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

export const ADD_ADMIN_INIT = "ADD_ADMIN_INIT";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILED = "ADD_ADMIN_FAILED";

export const EDIT_ADMIN_INIT = "EDIT_ADMIN_INIT";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILED = "EDIT_ADMIN_FAILED";

export const GET_STARTUPS_DATA_INIT = "GET_STARTUPS_DATA_INIT";
export const GET_STARTUPS_DATA_SUCCESS = "GET_STARTUPS_DATA_SUCCESS";
export const GET_STARTUPS_DATA_FAILED = "GET_STARTUPS_DATA_FAILED";

export const UPDATE_PROFILE_PIC_INIT = "UPDATE_PROFILE_PIC_INIT";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_FAILED = "UPDATE_PROFILE_PIC_FAILED";

export const FORGOT_PASSWORD_INIT = "FORGOT_PASSWORD_INIT";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const DELETE_STARTUP_INIT = "DELETE_STARTUP_INIT";
export const DELETE_STARTUP_SUCCESS = "DELETE_STARTUP_SUCCESS";
export const DELETE_STARTUP_FAILED = "DELETE_STARTUP_FAILED";

export const GET_STARTUP_BY_ID_INIT = "GET_STARTUP_BY_ID_INIT";
export const GET_STARTUP_BY_ID_SUCCESS = "GET_STARTUP_BY_ID_SUCCESS";
export const GET_STARTUP_BY_ID_FAILED = "GET_STARTUP_BY_ID_FAILED";

export const UPLOAD_MEMO_INIT = "UPLOAD_MEMO_INIT";
export const UPLOAD_MEMO_SUCCESS = "UPLOAD_MEMO_SUCCESS";
export const UPLOAD_MEMO_FAILED = "UPLOAD_MEMO_FAILED";

export const ASSIGN_STARTUP_INIT = "ASSIGN_STARTUP_INIT";
export const ASSIGN_STARTUP_SUCCESS = "ASSIGN_STARTUP_SUCCESS";
export const ASSIGN_STARTUP_FAILED = "ASSIGN_STARTUP_FAILED";

export const BULK_INVITE_INIT = "BULK_INVITE_INIT";
export const BULK_INVITE_SUCCESS = "BULK_INVITE_SUCCESS";
export const BULK_INVITE_FAILED = "BULK_INVITE_FAILED";

export const UPDATE_GRADE_INIT = "UPDATE_GRADE_INIT";
export const UPDATE_GRADE_SUCCESS = "UPDATE_GRADE_SUCCESS";
export const UPDATE_GRADE_FAILED = "UPDATE_GRADE_FAILED";

export const GET_GRADE_INIT = "GET_GRADE_INIT";
export const GET_GRADE_SUCCESS = "GET_GRADE_SUCCESS";
export const GET_GRADE_FAILED = "GET_GRADE_FAILED";

export const UPDATE_STARTUP_INIT = "UPDATE_STARTUP_INIT";
export const UPDATE_STARTUP_SUCCESS = "UPDATE_STARTUP_SUCCESS";
export const UPDATE_STARTUP_FAILED = "UPDATE_STARTUP_FAILED";

export const UPDATE_STATUS_INIT = "UPDATE_STATUS_INIT";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAILED = "UPDATE_STATUS_FAILED";

export const GET_INVESTORS_DATA_INIT = "GET_INVESTORS_DATA_INIT";
export const GET_INVESTORS_DATA_SUCCESS = "GET_INVESTORS_DATA_SUCCESS";
export const GET_INVESTORS_DATA_FAILED = "GET_INVESTORS_DATA_FAILED";

export const GET_INVESTORS_BY_ID_INIT = "GET_INVESTORS_BY_ID_INIT";
export const GET_INVESTORS_BY_ID_SUCCESS = "GET_INVESTORS_BY_ID_SUCCESS";
export const GET_INVESTORS_BY_ID_FAILED = "GET_INVESTORS_BY_ID_FAILED";

export const UPDATE_INVESTOR_INIT = "UPDATE_INVESTOR_INIT";
export const UPDATE_INVESTOR_SUCCESS = "UPDATE_INVESTOR_SUCCESS";
export const UPDATE_INVESTOR_FAILED = "UPDATE_INVESTOR_FAILED";

export const UPDATE_INVESTOR_STATUS_INIT = "UPDATE_INVESTOR_STATUS_INIT";
export const UPDATE_INVESTOR_STATUS_SUCCESS = "UPDATE_INVESTOR_STATUS_SUCCESS";
export const UPDATE_INVESTOR_STATUS_FAILED = "UPDATE_INVESTOR_STATUS_FAILED";

export const DELETE_INVESTOR_INIT = "DELETE_INVESTOR_INIT";
export const DELETE_INVESTOR_SUCCESS = "DELETE_INVESTOR_SUCCESS";
export const DELETE_INVESTOR_FAILED = "DELETE_INVESTOR_FAILED";

export const GET_APPROVED_STARTUPS_DATA_INIT =
  "GET_APPROVED_STARTUPS_DATA_INIT";
export const GET_APPROVED_STARTUPS_DATA_SUCCESS =
  "GET_APPROVED_STARTUPS_DATA_SUCCESS";
export const GET_APPROVED_STARTUPS_DATA_FAILED =
  "GET_APPROVED_STARTUPS_DATA_FAILED";

export const GET_APPROVED_INVESTORS_DATA_INIT =
  "GET_APPROVED_INVESTORS_DATA_INIT";
export const GET_APPROVED_INVESTORS_DATA_SUCCESS =
  "GET_APPROVED_INVESTORS_DATA_SUCCESS";
export const GET_APPROVED_INVESTORS_DATA_FAILED =
  "GET_APPROVED_INVESTORS_DATA_FAILED";

export const UPDATE_APPROVED_STARTUP_ACTION_INIT =
  "UPDATE_APPROVED_STARTUP_ACTION_INIT";
export const UPDATE_APPROVED_STARTUP_ACTION_SUCCESS =
  "UPDATE_APPROVED_STARTUP_ACTION_SUCCESS";
export const UPDATE_APPROVED_STARTUP_ACTION_FAILED =
  "UPDATE_APPROVED_STARTUP_ACTION_FAILED";

export const UPDATE_APPROVED_INVESTOR_ACTION_INIT =
  "UPDATE_APPROVED_INVESTOR_ACTION_INIT";
export const UPDATE_APPROVED_INVESTOR_ACTION_SUCCESS =
  "UPDATE_APPROVED_INVESTOR_ACTION_SUCCESS";
export const UPDATE_APPROVED_INVESTOR_ACTION_FAILED =
  "UPDATE_APPROVED_INVESTOR_ACTION_FAILED";

export const GET_APPROVED_STARTUP_BY_ID_INIT =
  "GET_APPROVED_STARTUP_BY_ID_INIT";
export const GET_APPROVED_STARTUP_BY_ID_SUCCESS =
  "GET_APPROVED_STARTUP_BY_ID_SUCCESS";
export const GET_APPROVED_STARTUP_BY_ID_FAILED =
  "GET_APPROVED_STARTUP_BY_ID_FAILED";

export const GET_APPROVED_STARTUP_TEAM_INFO_INIT =
  "GET_APPROVED_STARTUP_TEAM_INFO_INIT";
export const GET_APPROVED_STARTUP_TEAM_INFO_SUCCESS =
  "GET_APPROVED_STARTUP_TEAM_INFO_SUCCESS";
export const GET_APPROVED_STARTUP_TEAM_INFO_FAILED =
  "GET_APPROVED_STARTUP_TEAM_INFO_FAILED";

export const GET_APPROVED_STARTUP_MATCH_INVESTOR_INIT =
  "GET_APPROVED_STARTUP_MATCH_INVESTOR_INIT";
export const GET_APPROVED_STARTUP_MATCH_INVESTOR_SUCCESS =
  "GET_APPROVED_STARTUP_MATCH_INVESTOR_SUCCESS";
export const GET_APPROVED_STARTUP_MATCH_INVESTOR_FAILED =
  "GET_APPROVED_STARTUP_MATCH_INVESTOR_FAILED";

export const SET_APPROVED_STARTUP_VISIBILITY_INIT =
  "SET_APPROVED_STARTUP_VISIBILITY_INIT";
export const SET_APPROVED_STARTUP_VISIBILITY_SUCCESS =
  "SET_APPROVED_STARTUP_VISIBILITY_SUCCESS";
export const SET_APPROVED_STARTUP_VISIBILITY_FAILED =
  "SET_APPROVED_STARTUP_VISIBILITY_FAILED";

export const GET_INVESTOR_SUGGESTIONS_INIT = "GET_INVESTOR_SUGGESTIONS_INIT";
export const GET_INVESTOR_SUGGESTIONS_SUCCESS =
  "GET_INVESTOR_SUGGESTIONS_SUCCESS";
export const GET_INVESTOR_SUGGESTIONS_FAILED =
  "GET_INVESTOR_SUGGESTIONS_FAILED";

export const ADD_APPROVED_INVESTOR_INIT = "ADD_APPROVED_INVESTOR_INIT";
export const ADD_APPROVED_INVESTOR_SUCCESS = "ADD_APPROVED_INVESTOR_SUCCESS";
export const ADD_APPROVED_INVESTOR_FAILED = "ADD_APPROVED_INVESTOR_FAILED";

export const GET_APPROVED_INVESTOR_BY_ID_INIT =
  "GET_APPROVED_INVESTOR_BY_ID_INIT";
export const GET_APPROVED_INVESTOR_BY_ID_SUCCESS =
  "GET_APPROVED_INVESTOR_BY_ID_SUCCESS";
export const GET_APPROVED_INVESTOR_BY_ID_FAILED =
  "GET_APPROVED_INVESTOR_BY_ID_FAILED";

export const SET_APPROVED_INVESTOR_VISIBILITY_INIT =
  "SET_APPROVED_INVESTOR_VISIBILITY_INIT";
export const SET_APPROVED_INVESTOR_VISIBILITY_SUCCESS =
  "SET_APPROVED_INVESTOR_VISIBILITY_SUCCESS";
export const SET_APPROVED_INVESTOR_VISIBILITY_FAILED =
  "SET_APPROVED_INVESTOR_VISIBILITY_FAILED";

export const GET_APPROVED_INVESTOR_MATCH_STARTUP_INIT =
  "GET_APPROVED_INVESTOR_MATCH_STARTUP_INIT";
export const GET_APPROVED_INVESTOR_MATCH_STARTUP_SUCCESS =
  "GET_APPROVED_INVESTOR_MATCH_STARTUP_SUCCESS";
export const GET_APPROVED_INVESTOR_MATCH_STARTUP_FAILED =
  "GET_APPROVED_INVESTOR_MATCH_STARTUP_FAILED";

export const GET_STARTUP_SUGGESTIONS_INIT = "GET_STARTUP_SUGGESTIONS_INIT";
export const GET_STARTUP_SUGGESTIONS_SUCCESS =
  "GET_STARTUP_SUGGESTIONS_SUCCESS";
export const GET_STARTUP_SUGGESTIONS_FAILED = "GET_STARTUP_SUGGESTIONS_FAILED";

export const ADD_APPROVED_STARTUP_INIT = "ADD_APPROVED_STARTUP_INIT";
export const ADD_APPROVED_STARTUP_SUCCESS = "ADD_APPROVED_STARTUP_SUCCESS";
export const ADD_APPROVED_STARTUP_FAILED = "ADD_APPROVED_STARTUP_FAILED";

export const GET_SIMILAR_INVESTORS_INIT = "GET_SIMILAR_INVESTORS_INIT";
export const GET_SIMILAR_INVESTORS_SUCCESS = "GET_SIMILAR_INVESTORS_SUCCESS";
export const GET_SIMILAR_INVESTORS_FAILED = "GET_SIMILAR_INVESTORS_FAILED";

export const UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_INIT =
  "UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_INIT";
export const UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_SUCCESS =
  "UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_SUCCESS";
export const UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_FAILED =
  "UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_FAILED";

export const UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_INIT =
  "UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_INIT";
export const UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_SUCCESS =
  "UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_SUCCESS";
export const UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_FAILED =
  "UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_FAILED";

export const UPLOAD_SLIDEDECK_INIT = "UPLOAD_SLIDEDECK_INIT";
export const UPLOAD_SLIDEDECK_SUCCESS = "UPLOAD_SLIDEDECK_SUCCESS";
export const UPLOAD_SLIDEDECK_FAILED = "UPLOAD_SLIDEDECK_FAILED";

export const UPLOAD_TRACKING_DEMO_VIDEO_INIT =
  "UPLOAD_TRACKING_DEMO_VIDEO_INIT";
export const UPLOAD_TRACKING_DEMO_VIDEO_SUCCESS =
  "UPLOAD_TRACKING_DEMO_VIDEO_SUCCESS";
export const UPLOAD_TRACKING_DEMO_VIDEO_FAILED =
  "UPLOAD_TRACKING_DEMO_VIDEO_FAILED";

export const UPLOAD_TRACKING_PITCH_VIDEO_INIT =
  "UPLOAD_TRACKING_PITCH_VIDEO_INIT";
export const UPLOAD_TRACKING_PITCH_VIDEO_SUCCESS =
  "UPLOAD_TRACKING_PITCH_VIDEO_SUCCESS";
export const UPLOAD_TRACKING_PITCH_VIDEO_FAILED =
  "UPLOAD_TRACKING_PITCH_VIDEO_FAILED";

export const UPLOAD_TRACKING_HELPFUL_DOCS_INIT =
  "UPLOAD_TRACKING_HELPFUL_DOCS_INIT";
export const UPLOAD_TRACKING_HELPFUL_DOCS_SUCCESS =
  "UPLOAD_TRACKING_HELPFUL_DOCS_SUCCESS";
export const UPLOAD_TRACKING_HELPFUL_DOCS_FAILED =
  "UPLOAD_TRACKING_HELPFUL_DOCS_FAILED";

export const GET_APPROVED_INVESTOR_HISTORY_INIT =
  "GET_APPROVED_INVESTOR_HISTORY_INIT";
export const GET_APPROVED_INVESTOR_HISTORY_SUCCESS =
  "GET_APPROVED_INVESTOR_HISTORY_SUCCESS";
export const GET_APPROVED_INVESTOR_HISTORY_FAILED =
  "GET_APPROVED_INVESTOR_HISTORY_FAILED";

export const GET_APPROVED_STARTUP_HISTORY_INIT =
  "GET_APPROVED_STARTUP_HISTORY_INIT";
export const GET_APPROVED_STARTUP_HISTORY_SUCCESS =
  "GET_APPROVED_STARTUP_HISTORY_SUCCESS";
export const GET_APPROVED_STARTUP_HISTORY_FAILED =
  "GET_APPROVED_STARTUP_HISTORY_FAILED";

export const DELETE_MATCH_INVESTOR_INIT = "DELETE_MATCH_INVESTOR_INIT";
export const DELETE_MATCH_INVESTOR_SUCCESS = "DELETE_MATCH_INVESTOR_SUCCESS";
export const DELETE_MATCH_INVESTOR_FAILED = "DELETE_MATCH_INVESTOR_FAILED";

export const DELETE_MATCH_STARTUP_INIT = "DELETE_MATCH_STARTUP_INIT";
export const DELETE_MATCH_STARTUP_SUCCESS = "DELETE_MATCH_STARTUP_SUCCESS";
export const DELETE_MATCH_STARTUP_FAILED = "DELETE_MATCH_STARTUP_FAILED";

export const GET_UNSEEN_NOTIFICATIONS_COUNT_INIT =
  "GET_UNSEEN_NOTIFICATIONS_COUNT_INIT";
export const GET_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS =
  "GET_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS";
export const GET_UNSEEN_NOTIFICATIONS_COUNT_FAILED =
  "GET_UNSEEN_NOTIFICATIONS_COUNT_FAILED";

export const GET_UNSEEN_NOTIFICATIONS_INIT = "GET_UNSEEN_NOTIFICATIONS_INIT";
export const GET_UNSEEN_NOTIFICATIONS_SUCCESS =
  "GET_UNSEEN_NOTIFICATIONS_SUCCESS";
export const GET_UNSEEN_NOTIFICATIONS_FAILED =
  "GET_UNSEEN_NOTIFICATIONS_FAILED";

export const GET_NOTIFICATIONS_INIT = "GET_NOTIFICATIONS_INIT";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED";

export const UPLOAD_STARTUP_SLIDEDECK_INIT = "UPLOAD_STARTUP_SLIDEDECK_INIT";
export const UPLOAD_STARTUP_SLIDEDECK_SUCCESS =
  "UPLOAD_STARTUP_SLIDEDECK_SUCCESS";
export const UPLOAD_STARTUP_SLIDEDECK_FAILED =
  "UPLOAD_STARTUP_SLIDEDECK_FAILED";

export const ADD_BLOG_INIT = "ADD_BLOG_INIT";
export const ADD_BLOG_SUCCESS = "ADD_BLOG_SUCCESS";
export const ADD_BLOG_FAILED = "ADD_BLOG_FAILED";

export const ADD_PODCAST_INIT = "ADD_PODCAST_INIT";
export const ADD_PODCAST_SUCCESS = "ADD_PODCAST_SUCCESS";
export const ADD_PODCAST_FAILED = "ADD_PODCAST_FAILED";

export const GET_MARKETING_DATA_INIT = "GET_MARKETING_DATA_INIT";
export const GET_MARKETING_DATA_SUCCESS = "GET_MARKETING_DATA_SUCCESS";
export const GET_MARKETING_DATA_FAILED = "GET_MARKETING_DATA_FAILED";

export const ADD_MARKETING_MEDIA_INIT = "ADD_MARKETING_MEDIA_INIT";
export const ADD_MARKETING_MEDIA_SUCCESS = "ADD_MARKETING_MEDIA_SUCCESS";
export const ADD_MARKETING_MEDIA_FAILED = "ADD_MARKETING_MEDIA_FAILED";

export const GET_MARKETING_MEDIA_INIT = "GET_MARKETING_MEDIA_INIT";
export const GET_MARKETING_MEDIA_SUCCESS = "GET_MARKETING_MEDIA_SUCCESS";
export const GET_MARKETING_MEDIA_FAILED = "GET_MARKETING_MEDIA_FAILED";

export const GET_SUBSCRIBERS_DATA_INIT = "GET_SUBSCRIBERS_DATA_INIT";
export const GET_SUBSCRIBERS_DATA_SUCCESS = "GET_SUBSCRIBERS_DATA_SUCCESS";
export const GET_SUBSCRIBERS_DATA_FAILED = "GET_SUBSCRIBERS_DATA_FAILED";

export const DELETE_SUBSCRIBER_DATA_INIT = "DELETE_SUBSCRIBER_DATA_INIT";
export const DELETE_SUBSCRIBER_DATA_SUCCESS = "DELETE_SUBSCRIBER_DATA_SUCCESS";
export const DELETE_SUBSCRIBER_DATA_FAILED = "DELETE_SUBSCRIBER_DATA_FAILED";

export const EXPORT_SUBSCRIBER_DATA_INIT = "EXPORT_SUBSCRIBER_DATA_INIT";
export const EXPORT_SUBSCRIBER_DATA_SUCCESS = "EXPORT_SUBSCRIBER_DATA_SUCCESS";
export const EXPORT_SUBSCRIBER_DATA_FAILED = "EXPORT_SUBSCRIBER_DATA_FAILED";

export const TOGGLE_MARKETING_ACTION_INIT = "TOGGLE_MARKETING_ACTION_INIT";
export const TOGGLE_MARKETING_ACTION_SUCCESS =
  "TOGGLE_MARKETING_ACTION_SUCCESS";
export const TOGGLE_MARKETING_ACTION_FAILED = "TOGGLE_MARKETING_ACTION_FAILED";

export const DELETE_MARKEING_LIBRARY_INIT = "DELETE_MARKEING_LIBRARY_INIT";
export const DELETE_MARKEING_LIBRARY_SUCCESS =
  "DELETE_MARKEING_LIBRARY_SUCCESS";
export const DELETE_MARKEING_LIBRARY_FAILED = "DELETE_MARKEING_LIBRARY_FAILED";

export const GET_BLOG_DETAILS_INIT = "GET_BLOG_DETAILS_INIT";
export const GET_BLOG_DETAILS_SUCCESS = "GET_BLOG_DETAILS_SUCCESS";
export const GET_BLOG_DETAILS_FAILED = "GET_BLOG_DETAILS_FAILED";

export const EDIT_BLOG_INIT = "EDIT_BLOG_INIT";
export const EDIT_BLOG_SUCCESS = "EDIT_BLOG_SUCCESS";
export const EDIT_BLOG_FAILED = "EDIT_BLOG_FAILED";

export const GET_PODCAST_DETAILS_INIT = "GET_PODCAST_DETAILS_INIT";
export const GET_PODCAST_DETAILS_SUCCESS = "GET_PODCAST_DETAILS_SUCCESS";
export const GET_PODCAST_DETAILS_FAILED = "GET_PODCAST_DETAILS_FAILED";

export const EDIT_PODCAST_INIT = "EDIT_PODCAST_INIT";
export const EDIT_PODCAST_SUCCESS = "EDIT_PODCAST_SUCCESS";
export const EDIT_PODCAST_FAILED = "EDIT_PODCAST_FAILED";

export const SEARCH_CHANGE_HANDLE = "SEARCH_CHANGE_HANDLE";
export const CLEAR_SEARCH_HANDLE = "CLEAR_SEARCH_HANDLE";

export const GET_TRACTION_APPROVALS_DATA_INIT =
  "GET_TRACTION_APPROVALS_DATA_INIT";
export const GET_TRACTION_APPROVALS_DATA_SUCCESS =
  "GET_TRACTION_APPROVALS_DATA_SUCCESS";
export const GET_TRACTION_APPROVALS_DATA_FAILED =
  "GET_TRACTION_APPROVALS_DATA_FAILED";

export const GET_DASHBOARD_PROGRESS_DATA_INIT =
  "GET_DASHBOARD_PROGRESS_DATA_INIT";
export const GET_DASHBOARD_PROGRESS_DATA_SUCCESS =
  "GET_DASHBOARD_PROGRESS_DATA_SUCCESS";
export const GET_DASHBOARD_PROGRESS_DATA_FAILED =
  "GET_DASHBOARD_PROGRESS_DATA_FAILED";

export const GET_DASHBOARD_DATA_INIT = "GET_DASHBOARD_DATA_INIT";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_FAILED = "GET_DASHBOARD_DATA_FAILED";

export const GET_GRAPH_EXPORT_DATA_INIT = "GET_GRAPH_EXPORT_DATA_INIT";
export const GET_GRAPH_EXPORT_DATA_SUCCESS = "GET_GRAPH_EXPORT_DATA_SUCCESS";
export const GET_GRAPH_EXPORT_DATA_FAILED = "GET_GRAPH_EXPORT_DATA_FAILED";

export const GET_EXPORT_DATA_INIT = "GET_EXPORT_DATA_INIT";
export const GET_EXPORT_DATA_SUCCESS = "GET_EXPORT_DATA_SUCCESS";
export const GET_EXPORT_DATA_FAILED = "GET_EXPORT_DATA_FAILED";

export const GET_EMAIL_NOTIFICATIONS_DATA_INIT =
  "GET_EMAIL_NOTIFICATIONS_DATA_INIT";
export const GET_EMAIL_NOTIFICATIONS_DATA_SUCCESS =
  "GET_EMAIL_NOTIFICATIONS_DATA_SUCCESS";
export const GET_EMAIL_NOTIFICATIONS_DATA_FAILED =
  "GET_EMAIL_NOTIFICATIONS_DATA_FAILED";

export const SET_EMAIL_NOTIFICATIONS_DATA_INIT =
  "SET_EMAIL_NOTIFICATIONS_DATA_INIT";
export const SET_EMAIL_NOTIFICATIONS_DATA_SUCCESS =
  "SET_EMAIL_NOTIFICATIONS_DATA_SUCCESS";
export const SET_EMAIL_NOTIFICATIONS_DATA_FAILED =
  "SET_EMAIL_NOTIFICATIONS_DATA_FAILED";

export const GET_REJECTED_STARTUPS_DATA_INIT =
  "GET_REJECTED_STARTUPS_DATA_INIT";
export const GET_REJECTED_STARTUPS_DATA_SUCCESS =
  "GET_REJECTED_STARTUPS_DATA_SUCCESS";
export const GET_REJECTED_STARTUPS_DATA_FAILED =
  "GET_REJECTED_STARTUPS_DATA_FAILED";

export const DELETE_REJECTED_STARTUP_INIT = "DELETE_REJECTED_STARTUP_INIT";
export const DELETE_REJECTED_STARTUP_SUCCESS =
  "DELETE_REJECTED_STARTUP_SUCCESS";
export const DELETE_REJECTED_STARTUP_FAILED = "DELETE_REJECTED_STARTUP_FAILED";

export const GET_REJECTED_INVESTORS_DATA_INIT =
  "GET_REJECTED_INVESTORS_DATA_INIT";
export const GET_REJECTED_INVESTORS_DATA_SUCCESS =
  "GET_REJECTED_INVESTORS_DATA_SUCCESS";
export const GET_REJECTED_INVESTORS_DATA_FAILED =
  "GET_REJECTED_INVESTORS_DATA_FAILED";

export const DELETE_REJECTED_INVESTOR_INIT = "DELETE_REJECTED_INVESTOR_INIT";
export const DELETE_REJECTED_INVESTOR_SUCCESS =
  "DELETE_REJECTED_INVESTOR_SUCCESS";
export const DELETE_REJECTED_INVESTOR_FAILED =
  "DELETE_REJECTED_INVESTOR_FAILED";

export const GET_REJECTED_STARTUP_BY_ID_INIT =
  "GET_REJECTED_STARTUP_BY_ID_INIT";
export const GET_REJECTED_STARTUP_BY_ID_SUCCESS =
  "GET_REJECTED_STARTUP_BY_ID_SUCCESS";
export const GET_REJECTED_STARTUP_BY_ID_FAILED =
  "GET_REJECTED_STARTUP_BY_ID_FAILED";

export const GET_REJECTED_INVESTOR_BY_ID_INIT =
  "GET_REJECTED_INVESTOR_BY_ID_INIT";
export const GET_REJECTED_INVESTOR_BY_ID_SUCCESS =
  "GET_REJECTED_INVESTOR_BY_ID_SUCCESS";
export const GET_REJECTED_INVESTOR_BY_ID_FAILED =
  "GET_REJECTED_INVESTOR_BY_ID_FAILED";

export const UPDATE_REJECTED_STARTUP_INIT = "UPDATE_REJECTED_STARTUP_INIT";
export const UPDATE_REJECTED_STARTUP_SUCCESS =
  "UPDATE_REJECTED_STARTUP_SUCCESS";
export const UPDATE_REJECTED_STARTUP_FAILED = "UPDATE_REJECTED_STARTUP_FAILED";

export const UPDATE_REJECTED_INVESTOR_INIT = "UPDATE_REJECTED_INVESTOR_INIT";
export const UPDATE_REJECTED_INVESTOR_SUCCESS =
  "UPDATE_REJECTED_INVESTOR_SUCCESS";
export const UPDATE_REJECTED_INVESTOR_FAILED =
  "UPDATE_REJECTED_INVESTOR_FAILED";

export const GET_TRACKING_DATA_INIT = "GET_TRACKING_DATA_INIT";
export const GET_TRACKING_DATA_SUCCESS = "GET_TRACKING_DATA_SUCCESS";
export const GET_TRACKING_DATA_FAILED = "GET_TRACKING_DATA_FAILED";

export const UPDATE_TRACKING_STATUS_INIT = "UPDATE_TRACKING_STATUS_INIT";
export const UPDATE_TRACKING_STATUS_SUCCESS = "UPDATE_TRACKING_STATUS_SUCCESS";
export const UPDATE_TRACKING_STATUS_FAILED = "UPDATE_TRACKING_STATUS_FAILED";

export const DELETE_TRACKING_STARTUP_INIT = "DELETE_TRACKING_STARTUP_INIT";
export const DELETE_TRACKING_STARTUP_SUCCESS =
  "DELETE_TRACKING_STARTUP_SUCCESS";
export const DELETE_TRACKING_STARTUP_FAILED = "DELETE_TRACKING_STARTUP_FAILED";

export const GET_TRACKING_STARTUP_BY_ID_INIT =
  "GET_TRACKING_STARTUP_BY_ID_INIT";
export const GET_TRACKING_STARTUP_BY_ID_SUCCESS =
  "GET_TRACKING_STARTUP_BY_ID_SUCCESS";
export const GET_TRACKING_STARTUP_BY_ID_FAILED =
  "GET_TRACKING_STARTUP_BY_ID_FAILED";

export const UPDATE_TRACKING_STARTUP_ACTION_INIT =
  "UPDATE_TRACKING_STARTUP_ACTION_INIT";
export const UPDATE_TRACKING_STARTUP_ACTION_SUCCESS =
  "UPDATE_TRACKING_STARTUP_ACTION_SUCCESS";
export const UPDATE_TRACKING_STARTUP_ACTION_FAILED =
  "UPDATE_TRACKING_STARTUP_ACTION_FAILED";

export const UPDATE_TRACKING_STARTUP_INIT = "UPDATE_TRACKING_STARTUP_INIT";
export const UPDATE_TRACKING_STARTUP_SUCCESS =
  "UPDATE_TRACKING_STARTUP_SUCCESS";
export const UPDATE_TRACKING_STARTUP_FAILED = "UPDATE_TRACKING_STARTUP_FAILED";

export const UPLOAD_TRACKING_MEMO_INIT = "UPLOAD_TRACKING_MEMO_INIT";
export const UPLOAD_TRACKING_MEMO_SUCCESS = "UPLOAD_TRACKING_MEMO_SUCCESS";
export const UPLOAD_TRACKING_MEMO_FAILED = "UPLOAD_TRACKING_MEMO_FAILED";

export const UPLOAD_TRACKING_SLIDEDECK_INIT = "UPLOAD_TRACKING_SLIDEDECK_INIT";
export const UPLOAD_TRACKING_SLIDEDECK_SUCCESS =
  "UPLOAD_TRACKING_SLIDEDECK_SUCCESS";
export const UPLOAD_TRACKING_SLIDEDECK_FAILED =
  "UPLOAD_TRACKING_SLIDEDECK_FAILED";

export const UPDATE_TRACKING_STARTUP_BANNER_IMAGE_INIT =
  "UPDATE_TRACKING_STARTUP_BANNER_IMAGE_INIT";
export const UPDATE_TRACKING_STARTUP_BANNER_IMAGE_SUCCESS =
  "UPDATE_TRACKING_STARTUP_BANNER_IMAGE_SUCCESS";
export const UPDATE_TRACKING_STARTUP_BANNER_IMAGE_FAILED =
  "UPDATE_TRACKING_STARTUP_BANNER_IMAGE_FAILED";

export const UPDATE_TRACKING_STARTUP_COMPANY_LOGO_INIT =
  "UPDATE_TRACKING_STARTUP_COMPANY_LOGO_INIT";
export const UPDATE_TRACKING_STARTUP_COMPANY_LOGO_SUCCESS =
  "UPDATE_TRACKING_STARTUP_COMPANY_LOGO_SUCCESS";
export const UPDATE_TRACKING_STARTUP_COMPANY_LOGO_FAILED =
  "UPDATE_TRACKING_STARTUP_COMPANY_LOGO_FAILED";

export const GET_TRACKING_UPDATES_INIT = "GET_TRACKING_UPDATES_INIT";
export const GET_TRACKING_UPDATES_SUCCESS = "GET_TRACKING_UPDATES_SUCCESS";
export const GET_TRACKING_UPDATES_FAILED = "GET_TRACKING_UPDATES_FAILED";

export const GET_TRACKING_UPDATE_DETAILS_INIT =
  "GET_TRACKING_UPDATE_DETAILS_INIT";
export const GET_TRACKING_UPDATE_DETAILS_SUCCESS =
  "GET_TRACKING_UPDATE_DETAILS_SUCCESS";
export const GET_TRACKING_UPDATE_DETAILS_FAILED =
  "GET_TRACKING_UPDATE_DETAILS_FAILED";

export const GET_SCOUTS_DATA_INIT = "GET_SCOUTS_DATA_INIT";
export const GET_SCOUTS_DATA_SUCCESS = "GET_SCOUTS_DATA_SUCCESS";
export const GET_SCOUTS_DATA_FAILED = "GET_SCOUTS_DATA_FAILED";

export const UPDATE_SCOUTS_ACTION_INIT = "UPDATE_SCOUTS_ACTION_INIT";
export const UPDATE_SCOUTS_ACTION_SUCCESS = "UPDATE_SCOUTS_ACTION_SUCCESS";
export const UPDATE_SCOUTS_ACTION_FAILED = "UPDATE_SCOUTS_ACTION_FAILED";

export const GET_SCOUT_DETAILS_BY_ID_INIT = "GET_SCOUT_DETAILS_BY_ID_INIT";
export const GET_SCOUT_DETAILS_BY_ID_SUCCESS =
  "GET_SCOUT_DETAILS_BY_ID_SUCCESS";
export const GET_SCOUT_DETAILS_BY_ID_FAILED = "GET_SCOUT_DETAILS_BY_ID_FAILED";

export const DELETE_SCOUT_INIT = "DELETE_SCOUT_INIT";
export const DELETE_SCOUT_SUCCESS = "DELETE_SCOUT_SUCCESS";
export const DELETE_SCOUT_FAILED = "DELETE_SCOUT_FAILED";

export const UPDATE_SCOUT_INIT = "UPDATE_SCOUT_INIT";
export const UPDATE_SCOUT_SUCCESS = "UPDATE_SCOUT_SUCCESS";
export const UPDATE_SCOUT_FAILED = "UPDATE_SCOUT_FAILED";

export const SET_SCOUT_BULK_INVITE_INIT = "SET_SCOUT_BULK_INVITE_INIT";
export const SET_SCOUT_BULK_INVITE_SUCCESS = "SET_SCOUT_BULK_INVITE_SUCCESS";
export const SET_SCOUT_BULK_INVITE_FAILED = "SET_SCOUT_BULK_INVITE_FAILED";

export const GET_STARTUP_SCOUT_BULK_INVITE_INIT =
  "GET_STARTUP_SCOUT_BULK_INVITE_INIT";
export const GET_STARTUP_SCOUT_BULK_INVITE_SUCCESS =
  "GET_STARTUP_SCOUT_BULK_INVITE_SUCCESS";
export const GET_STARTUP_SCOUT_BULK_INVITE_FAILED =
  "GET_STARTUP_SCOUT_BULK_INVITE_FAILED";

export const GET_INVESTOR_SCOUT_BULK_INVITE_INIT =
  "GET_INVESTOR_SCOUT_BULK_INVITE_INIT";
export const GET_INVESTOR_SCOUT_BULK_INVITE_SUCCESS =
  "GET_INVESTOR_SCOUT_BULK_INVITE_SUCCESS";
export const GET_INVESTOR_SCOUT_BULK_INVITE_FAILED =
  "GET_INVESTOR_SCOUT_BULK_INVITE_FAILED";

export const GET_APPROVED_STARTUP_PUBLIC_QA_INIT =
  "GET_APPROVED_STARTUP_PUBLIC_QA_INIT";
export const GET_APPROVED_STARTUP_PUBLIC_QA_SUCCESS =
  "GET_APPROVED_STARTUP_PUBLIC_QA_SUCCESS";
export const GET_APPROVED_STARTUP_PUBLIC_QA_FAILED =
  "GET_APPROVED_STARTUP_PUBLIC_QA_FAILED";

export const UPDATE_APPROVED_STARTUP_BANNER_IMAGE_INIT =
  "UPDATE_APPROVED_STARTUP_BANNER_IMAGE_INIT";
export const UPDATE_APPROVED_STARTUP_BANNER_IMAGE_SUCCESS =
  "UPDATE_APPROVED_STARTUP_BANNER_IMAGE_SUCCESS";
export const UPDATE_APPROVED_STARTUP_BANNER_IMAGE_FAILED =
  "UPDATE_APPROVED_STARTUP_BANNER_IMAGE_FAILED";

export const GET_LEARN_SERIES_CHAPTERS_DATA_INIT =
  "GET_LEARN_SERIES_CHAPTERS_DATA_INIT";
export const GET_LEARN_SERIES_CHAPTERS_DATA_SUCCESS =
  "GET_LEARN_SERIES_CHAPTERS_DATA_SUCCESS";
export const GET_LEARN_SERIES_CHAPTERS_DATA_FAILED =
  "GET_LEARN_SERIES_CHAPTERS_DATA_FAILED";

export const TOGGLE_LEARN_SERIES_CHAPTER_ACTION_INIT =
  "TOGGLE_LEARN_SERIES_CHAPTER_ACTION_INIT";
export const TOGGLE_LEARN_SERIES_CHAPTER_ACTION_SUCCESS =
  "TOGGLE_LEARN_SERIES_CHAPTER_ACTION_SUCCESS";
export const TOGGLE_LEARN_SERIES_CHAPTER_ACTION_FAILED =
  "TOGGLE_LEARN_SERIES_CHAPTER_ACTION_FAILED";

export const DELETE_LEARN_SERIES_CHAPTER_INIT =
  "DELETE_LEARN_SERIES_CHAPTER_INIT";
export const DELETE_LEARN_SERIES_CHAPTER_SUCCESS =
  "DELETE_LEARN_SERIES_CHAPTER_SUCCESS";
export const DELETE_LEARN_SERIES_CHAPTER_FAILED =
  "DELETE_LEARN_SERIES_CHAPTER_FAILED";

export const ADD_LEARN_SERIES65_CHAPTER_INIT =
  "ADD_LEARN_SERIES65_CHAPTER_INIT";
export const ADD_LEARN_SERIES65_CHAPTER_SUCCESS =
  "ADD_LEARN_SERIES65_CHAPTER_SUCCESS";
export const ADD_LEARN_SERIES65_CHAPTER_FAILED =
  "ADD_LEARN_SERIES65_CHAPTER_FAILED";

export const GET_LEARN_SERIES_CHAPTER_DETAILS_INIT =
  "GET_LEARN_SERIES_CHAPTER_DETAILS_INIT";
export const GET_LEARN_SERIES_CHAPTER_DETAILS_SUCCESS =
  "GET_LEARN_SERIES_CHAPTER_DETAILS_SUCCESS";
export const GET_LEARN_SERIES_CHAPTER_DETAILS_FAILED =
  "GET_LEARN_SERIES_CHAPTER_DETAILS_FAILED";

export const EDIT_LEARN_SERIES_CHAPTER_INIT = "EDIT_LEARN_SERIES_CHAPTER_INIT";
export const EDIT_LEARN_SERIES_CHAPTER_SUCCESS =
  "EDIT_LEARN_SERIES_CHAPTER_SUCCESS";
export const EDIT_LEARN_SERIES_CHAPTER_FAILED =
  "EDIT_LEARN_SERIES_CHAPTER_FAILED";

export const GET_LEARN65_LEARNERS_DATA_INIT = "GET_LEARN65_LEARNERS_DATA_INIT";
export const GET_LEARN65_LEARNERS_DATA_SUCCESS =
  "GET_LEARN65_LEARNERS_DATA_SUCCESS";
export const GET_LEARN65_LEARNERS_DATA_FAILED =
  "GET_LEARN65_LEARNERS_DATA_FAILED";

export const SET_LEARN65_LEARNER_ACTION_INIT =
  "SET_LEARN65_LEARNER_ACTION_INIT";
export const SET_LEARN65_LEARNER_ACTION_SUCCESS =
  "SET_LEARN65_LEARNER_ACTION_SUCCESS";
export const SET_LEARN65_LEARNER_ACTION_FAILED =
  "SET_LEARN65_LEARNER_ACTION_FAILED";

export const GET_LEARNER_DETAILS_INIT = "GET_LEARNER_DETAILS_INIT";
export const GET_LEARNER_DETAILS_SUCCESS = "GET_LEARNER_DETAILS_SUCCESS";
export const GET_LEARNER_DETAILS_FAILED = "GET_LEARNER_DETAILS_FAILED";

export const GET_LEARN_SERIES_VIDEO_LECTURES_DATA_INIT =
  "GET_LEARN_SERIES_VIDEO_LECTURES_DATA_INIT";
export const GET_LEARN_SERIES_VIDEO_LECTURES_DATA_SUCCESS =
  "GET_LEARN_SERIES_VIDEO_LECTURES_DATA_SUCCESS";
export const GET_LEARN_SERIES_VIDEO_LECTURES_DATA_FAILED =
  "GET_LEARN_SERIES_VIDEO_LECTURES_DATA_FAILED";

export const TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_INIT =
  "TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_INIT";
export const TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_SUCCESS =
  "TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_SUCCESS";
export const TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_FAILED =
  "TOGGLE_LEARN_SERIES_VIDEO_LECTURE_ACTION_FAILED";

export const DELETE_LEARN_SERIES_VIDEO_LECTURE_INIT =
  "DELETE_LEARN_SERIES_VIDEO_LECTURE_INIT";
export const DELETE_LEARN_SERIES_VIDEO_LECTURE_SUCCESS =
  "DELETE_LEARN_SERIES_VIDEO_LECTURE_SUCCESS";
export const DELETE_LEARN_SERIES_VIDEO_LECTURE_FAILED =
  "DELETE_LEARN_SERIES_VIDEO_LECTURE_FAILED";

export const GET_LEARN_SERIES_CHAPTERS_LIST_INIT =
  "GET_LEARN_SERIES_CHAPTERS_LIST_INIT";
export const GET_LEARN_SERIES_CHAPTERS_LIST_SUCCESS =
  "GET_LEARN_SERIES_CHAPTERS_LIST_SUCCESS";
export const GET_LEARN_SERIES_CHAPTERS_LIST_FAILED =
  "GET_LEARN_SERIES_CHAPTERS_LIST_FAILED";

export const ADD_LEARN_SERIES65_VIDEO_LECTURE_INIT =
  "ADD_LEARN_SERIES65_VIDEO_LECTURE_INIT";
export const ADD_LEARN_SERIES65_VIDEO_LECTURE_SUCCESS =
  "ADD_LEARN_SERIES65_VIDEO_LECTURE_SUCCESS";
export const ADD_LEARN_SERIES65_VIDEO_LECTURE_FAILED =
  "ADD_LEARN_SERIES65_VIDEO_LECTURE_FAILED";

export const GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_INIT =
  "GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_INIT";
export const GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_SUCCESS =
  "GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_SUCCESS";
export const GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_FAILED =
  "GET_LEARN_SERIES_VIDEO_LECTURE_DETAILS_FAILED";

export const EDIT_LEARN_SERIES_VIDEO_LECTURE_INIT =
  "EDIT_LEARN_SERIES_VIDEO_LECTURE_INIT";
export const EDIT_LEARN_SERIES_VIDEO_LECTURE_SUCCESS =
  "EDIT_LEARN_SERIES_VIDEO_LECTURE_SUCCESS";
export const EDIT_LEARN_SERIES_VIDEO_LECTURE_FAILED =
  "EDIT_LEARN_SERIES_VIDEO_LECTURE_FAILED";

export const GET_EDUCATION_QUIZZES_DATA_INIT =
  "GET_EDUCATION_QUIZZES_DATA_INIT";
export const GET_EDUCATION_QUIZZES_DATA_SUCCESS =
  "GET_EDUCATION_QUIZZES_DATA_SUCCESS";
export const GET_EDUCATION_QUIZZES_DATA_FAILED =
  "GET_EDUCATION_QUIZZES_DATA_FAILED";

export const TOGGLE_EDUCATION_QUIZ_ACTION_INIT =
  "TOGGLE_EDUCATION_QUIZ_ACTION_INIT";
export const TOGGLE_EDUCATION_QUIZ_ACTION_SUCCESS =
  "TOGGLE_EDUCATION_QUIZ_ACTION_SUCCESS";
export const TOGGLE_EDUCATION_QUIZ_ACTION_FAILED =
  "TOGGLE_EDUCATION_QUIZ_ACTION_FAILED";

export const DELETE_EDUCATION_QUIZ_INIT = "DELETE_EDUCATION_QUIZ_INIT";
export const DELETE_EDUCATION_QUIZ_SUCCESS = "DELETE_EDUCATION_QUIZ_SUCCESS";
export const DELETE_EDUCATION_QUIZ_FAILED = "DELETE_EDUCATION_QUIZ_FAILED";

export const ADD_QUIZ_INIT = "ADD_QUIZ_INIT";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAILED = "ADD_QUIZ_FAILED";

export const GET_QUIZ_DETAILS_INIT = "GET_QUIZ_DETAILS_INIT";
export const GET_QUIZ_DETAILS_SUCCESS = "GET_QUIZ_DETAILS_SUCCESS";
export const GET_QUIZ_DETAILS_FAILED = "GET_QUIZ_DETAILS_FAILED";

export const EDIT_QUIZ_INIT = "EDIT_QUIZ_INIT";
export const EDIT_QUIZ_SUCCESS = "EDIT_QUIZ_SUCCESS";
export const EDIT_QUIZ_FAILED = "EDIT_QUIZ_FAILED";

export const GET_EDUCATION_EXAMS_DATA_INIT = "GET_EDUCATION_EXAMS_DATA_INIT";
export const GET_EDUCATION_EXAMS_DATA_SUCCESS =
  "GET_EDUCATION_EXAMS_DATA_SUCCESS";
export const GET_EDUCATION_EXAMS_DATA_FAILED =
  "GET_EDUCATION_EXAMS_DATA_FAILED";

export const TOGGLE_EDUCATION_EXAM_ACTION_INIT =
  "TOGGLE_EDUCATION_EXAM_ACTION_INIT";
export const TOGGLE_EDUCATION_EXAM_ACTION_SUCCESS =
  "TOGGLE_EDUCATION_EXAM_ACTION_SUCCESS";
export const TOGGLE_EDUCATION_EXAM_ACTION_FAILED =
  "TOGGLE_EDUCATION_EXAM_ACTION_FAILED";

export const DELETE_EDUCATION_EXAM_INIT = "DELETE_EDUCATION_EXAM_INIT";
export const DELETE_EDUCATION_EXAM_SUCCESS = "DELETE_EDUCATION_EXAM_SUCCESS";
export const DELETE_EDUCATION_EXAM_FAILED = "DELETE_EDUCATION_EXAM_FAILED";

export const ADD_EXAM_INIT = "ADD_EXAM_INIT";
export const ADD_EXAM_SUCCESS = "ADD_EXAM_SUCCESS";
export const ADD_EXAM_FAILED = "ADD_EXAM_FAILED";

export const GET_EXAM_DETAILS_INIT = "GET_EXAM_DETAILS_INIT";
export const GET_EXAM_DETAILS_SUCCESS = "GET_EXAM_DETAILS_SUCCESS";
export const GET_EXAM_DETAILS_FAILED = "GET_EXAM_DETAILS_FAILED";

export const EDIT_EXAM_INIT = "EDIT_EXAM_INIT";
export const EDIT_EXAM_SUCCESS = "EDIT_EXAM_SUCCESS";
export const EDIT_EXAM_FAILED = "EDIT_EXAM_FAILED";

export const GET_TALENT_DATA_INIT = "GET_TALENT_DATA_INIT";
export const GET_TALENT_DATA_SUCCESS = "GET_TALENT_DATA_SUCCESS";
export const GET_TALENT_DATA_FAILED = "GET_TALENT_DATA_FAILED";

export const UPDATE_TALENT_ACTION_INIT = "UPDATE_TALENT_ACTION_INIT";
export const UPDATE_TALENT_ACTION_SUCCESS = "UPDATE_TALENT_ACTION_SUCCESS";
export const UPDATE_TALENT_ACTION_FAILED = "UPDATE_TALENT_ACTION_FAILED";

export const GET_TALENT_DETAILS_BY_ID_INIT = "GET_TALENT_DETAILS_BY_ID_INIT";
export const GET_TALENT_DETAILS_BY_ID_SUCCESS =
  "GET_TALENT_DETAILS_BY_ID_SUCCESS";
export const GET_TALENT_DETAILS_BY_ID_FAILED =
  "GET_TALENT_DETAILS_BY_ID_FAILED";

export const DELETE_TALENT_INIT = "DELETE_TALENT_INIT";
export const DELETE_TALENT_SUCCESS = "DELETE_TALENT_SUCCESS";
export const DELETE_TALENT_FAILED = "DELETE_TALENT_FAILED";

export const UPDATE_TALENT_INIT = "UPDATE_TALENT_INIT";
export const UPDATE_TALENT_SUCCESS = "UPDATE_TALENT_SUCCESS";
export const UPDATE_TALENT_FAILED = "UPDATE_TALENT_FAILED";

export const GET_CURRENT_UPDATE_STARTUP_POSITION_INIT =
  "GET_CURRENT_UPDATE_STARTUP_POSITION_INIT";
export const GET_CURRENT_UPDATE_STARTUP_POSITION_SUCCESS =
  "GET_CURRENT_UPDATE_STARTUP_POSITION_SUCCESS";
export const GET_CURRENT_UPDATE_STARTUP_POSITION_FAILED =
  "GET_CURRENT_UPDATE_STARTUP_POSITION_FAILED";

export const GET_CURRENT_UPDATE_INVESTOR_POSITION_INIT =
  "GET_CURRENT_UPDATE_INVESTOR_POSITION_INIT";
export const GET_CURRENT_UPDATE_INVESTOR_POSITION_SUCCESS =
  "GET_CURRENT_UPDATE_INVESTOR_POSITION_SUCCESS";
export const GET_CURRENT_UPDATE_INVESTOR_POSITION_FAILED =
  "GET_CURRENT_UPDATE_INVESTOR_POSITION_FAILED";

export const GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_INIT =
  "GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_INIT";
export const GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_SUCCESS =
  "GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_SUCCESS";
export const GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_FAILED =
  "GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_FAILED";

export const GET_STARTUP_TRANSACTIONS_DATA_INIT =
  "GET_STARTUP_TRANSACTIONS_DATA_INIT";
export const GET_STARTUP_TRANSACTIONS_DATA_SUCCESS =
  "GET_STARTUP_TRANSACTIONS_DATA_SUCCESS";
export const GET_STARTUP_TRANSACTIONS_DATA_FAILED =
  "GET_STARTUP_TRANSACTIONS_DATA_FAILED";

export const GET_INVESTOR_TRANSACTIONS_DATA_INIT =
  "GET_INVESTOR_TRANSACTIONS_DATA_INIT";
export const GET_INVESTOR_TRANSACTIONS_DATA_SUCCESS =
  "GET_INVESTOR_TRANSACTIONS_DATA_SUCCESS";
export const GET_INVESTOR_TRANSACTIONS_DATA_FAILED =
  "GET_INVESTOR_TRANSACTIONS_DATA_FAILED";

export const GET_DWOLLA_ACCOUNT_DATA_INIT = "GET_DWOLLA_ACCOUNT_DATA_INIT";
export const GET_DWOLLA_ACCOUNT_DATA_SUCCESS =
  "GET_DWOLLA_ACCOUNT_DATA_SUCCESS";
export const GET_DWOLLA_ACCOUNT_DATA_FAILED = "GET_DWOLLA_ACCOUNT_DATA_FAILED";

export const GET_BANK_ACCOUNT_DATA_INIT = "GET_BANK_ACCOUNT_DATA_INIT";
export const GET_BANK_ACCOUNT_DATA_SUCCESS = "GET_BANK_ACCOUNT_DATA_SUCCESS";
export const GET_BANK_ACCOUNT_DATA_FAILED = "GET_BANK_ACCOUNT_DATA_FAILED";

export const SEND_EMAIL_VERIFICATION_REQUEST_INIT =
  "SEND_EMAIL_VERIFICATION_REQUEST_INIT";
export const SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS =
  "SEND_EMAIL_VERIFICATION_REQUEST_SUCCESS";
export const SEND_EMAIL_VERIFICATION_REQUEST_FAILED =
  "SEND_EMAIL_VERIFICATION_REQUEST_FAILED";

export const CREATE_BANKING_PROFILE_CUSTOMER_INIT =
  "CREATE_BANKING_PROFILE_CUSTOMER_INIT";
export const CREATE_BANKING_PROFILE_CUSTOMER_SUCCESS =
  "CREATE_BANKING_PROFILE_CUSTOMER_SUCCESS";
export const CREATE_BANKING_PROFILE_CUSTOMER_FAILED =
  "CREATE_BANKING_PROFILE_CUSTOMER_FAILED";

export const POST_LINK_TOKEN_INIT = "POST_LINK_TOKEN_INIT";
export const POST_LINK_TOKEN_SUCCESS = "POST_LINK_TOKEN_SUCCESS";
export const POST_LINK_TOKEN_FAILED = "POST_LINK_TOKEN_FAILED";

export const LINK_STARTUP_BANK_ACCUNT_INIT = "LINK_STARTUP_BANK_ACCUNT_INIT";
export const LINK_STARTUP_BANK_ACCUNT_SUCCESS =
  "LINK_STARTUP_BANK_ACCUNT_SUCCESS";
export const LINK_STARTUP_BANK_ACCUNT_FAILED =
  "LINK_STARTUP_BANK_ACCUNT_FAILED";

export const GET_TRACKING_ANALYTICS_DATA_INIT =
  "GET_TRACKING_ANALYTICS_DATA_INIT";
export const GET_TRACKING_ANALYTICS_DATA_SUCCESS =
  "GET_TRACKING_ANALYTICS_DATA_SUCCESS";
export const GET_TRACKING_ANALYTICS_DATA_FAILED =
  "GET_TRACKING_ANALYTICS_DATA_FAILED";

export const GET_EXPORT_STARTUP_TRANSACTIONS_DATA_INIT =
  "GET_EXPORT_STARTUP_TRANSACTIONS_DATA_INIT";
export const GET_EXPORT_STARTUP_TRANSACTIONS_DATA_SUCCESS =
  "GET_EXPORT_STARTUP_TRANSACTIONS_DATA_SUCCESS";
export const GET_EXPORT_STARTUP_TRANSACTIONS_DATA_FAILED =
  "GET_EXPORT_STARTUP_TRANSACTIONS_DATA_FAILED";

export const GET_RECIPIENT_DATA_INIT = "GET_RECIPIENT_DATA_INIT";
export const GET_RECIPIENT_DATA_SUCCESS = "GET_RECIPIENT_DATA_SUCCESS";
export const GET_RECIPIENT_DATA_FAILED = "GET_RECIPIENT_DATA_FAILDED";

export const CREATE_NOTIFY_INVESTOR_INIT = "CREATE_NOTIFY_INVESTOR_INIT";
export const CREATE_NOTIFY_INVESTOR_SUCCESS = "CREATE_NOTIFY_INVESTOR_SUCCESS";
export const CREATE_NOTIFY_INVESTOR_FAILED = "CREATE_NOTIFY_INVESTOR_FAILED";

export const GET_NOTIFY_INVESTOR_HISTORY_INIT =
  "GET_NOTIFY_INVESTOR_HISTORY_INIT";
export const GET_NOTIFY_INVESTOR_HISTORY_SUCCESS =
  "GET_NOTIFY_INVESTOR_HISTORY_SUCCESS";
export const GET_NOTIFY_INVESTOR_HISTORY_FAILED =
  "GET_NOTIFY_INVESTOR_HISTORY_FAILED";

export const DELETE_NOTIFY_INVESTOR_HISTORY_INIT =
  "DELETE_NOTIFY_INVESTOR_HISTORY_INIT";
export const DELETE_NOTIFY_INVESTOR_HISTORY_SUCCESS =
  "DELETE_NOTIFY_INVESTOR_HISTORY_SUCCESS";
export const DELETE_NOTIFY_INVESTOR_HISTORY_FAILED =
  "DELETE_NOTIFY_INVESTOR_HISTORY_FAILED";

export const EDIT_NOTIFY_INVESTOR_HISTORY_INIT =
  "EDIT_NOTIFY_INVESTOR_HISTORY_INIT";
export const EDIT_NOTIFY_INVESTOR_HISTORY_SUCCESS =
  "EDIT_NOTIFY_INVESTOR_HISTORY_SUCCESS";
export const EDIT_NOTIFY_INVESTOR_HISTORY_FAILED =
  "EDIT_NOTIFY_INVESTOR_HISTORY_FAILED";

export const ADD_QUESTION_INIT = "ADD_QUESTION_INIT";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAILED = "ADD_QUESTION_FAILED";

export const WAITLIST_PERMISSION = "WAITLIST_PERMISSION";
export const REJECTED_PERMISSION = "REJECTED_PERMISSION";
export const TRACKING_PERMISSION = "TRACKING_PERMISSION";
export const APPROVED_PERMISSION = "APPROVED_PERMISSION";

export const SET_BUSINESS_INFO = "SET_BUSINESS_INFO";

export const GET_BUSINESS_CLASSIFICATIONS_INIT =
  "GET_BUSINESS_CLASSIFICATIONS_INIT";
export const GET_BUSINESS_CLASSIFICATIONS_SUCCESS =
  "GET_BUSINESS_CLASSIFICATIONS_SUCCESS";
export const GET_BUSINESS_CLASSIFICATIONS_FAILED =
  "GET_BUSINESS_CLASSIFICATIONS_FAILED";

export const GET_INDUSTRY_CLASSIFICATIONS_INIT =
  "GET_INDUSTRY_CLASSIFICATIONS_INIT";
export const GET_INDUSTRY_CLASSIFICATIONS_SUCCESS =
  "GET_INDUSTRY_CLASSIFICATIONS_SUCCESS";
export const GET_INDUSTRY_CLASSIFICATIONS_FAILED =
  "GET_INDUSTRY_CLASSIFICATIONS_FAILED";
export const SET_FLAG_LINK = "SET_FLAG_LINK";

export const GET_INVITE_HISTORY_DATA_INIT = "GET_INVITE_HISTORY_DATA_INIT";
export const GET_INVITE_HISTORY_DATA_SUCCESS =
  "GET_INVITE_HISTORY_DATA_SUCCESS";
export const GET_INVITE_HISTORY_DATA_FAILED = "GET_INVITE_HISTORY_DATA_FAILED";

export const SEND_STARTUP_INVITE_INIT = "SEND_STARTUP_INVITE_INIT";
export const SEND_STARTUP_INVITE_SUCCESS = "SEND_STARTUP_INVITE_SUCCESS";
export const SEND_STARTUP_INVITE_FAILED = "SEND_STARTUP_INVITE_FAILED";

export const GET_PREVIOUS_POSTS_INIT = "GET_PREVIOUS_POSTS_INIT";
export const GET_PREVIOUS_POSTS_SUCCESS = "GET_PREVIOUS_POSTS_SUCCESS";
export const GET_PREVIOUS_POSTS_FAILED = "GET_PREVIOUS_POSTS_FAILED";

export const UPDATE_FUND_TRANSFER_STATUS_INIT =
  "UPDATE_FUND_TRANSFER_STATUS_INIT";
export const UPDATE_FUND_TRANSFER_STATUS_SUCCESS =
  "UPDATE_FUND_TRANSFER_STATUS_SUCCESS";
export const UPDATE_FUND_TRANSFER_STATUS_FAILED =
  "UPDATE_FUND_TRANSFER_STATUS_FAILED";

export const GET_INVESTOR_TAX_INFO_INIT = "GET_INVESTOR_TAX_INFO_INIT";
export const GET_INVESTOR_TAX_INFO_SUCCESS = "GET_INVESTOR_TAX_INFO_SUCCESS";
export const GET_INVESTOR_TAX_INFO_FAILED = "GET_INVESTOR_TAX_INFO_FAILED";

export const INDIVIDUAL_INVITE_INIT = "INDIVIDUAL_INVITE_INIT";
export const INDIVIDUAL_INVITE_SUCCESS = "INDIVIDUAL_INVITE_SUCCESS";
export const INDIVIDUAL_INVITE_FAILED = "INDIVIDUAL_INVITE_FAILED";

export const GET_ADMIN_INVITE_HISTORY_INIT = "GET_ADMIN_INVITE_HISTORY_INIT";
export const GET_ADMIN_INVITE_HISTORY_SUCCESS =
  "GET_ADMIN_INVITE_HISTORY_SUCCESS";
export const GET_ADMIN_INVITE_HISTORY_FAILED =
  "GET_ADMIN_INVITE_HISTORY_FAILED";

export const RESEND_ADMIN_INVITE_INIT = "RESEND_ADMIN_INVITE_INIT";
export const RESEND_ADMIN_INVITE_SUCCESS = "RESEND_ADMIN_INVITE_SUCCESS";
export const RESEND_ADMIN_INVITE_FAILED = "RESEND_ADMIN_INVITE_FAILED";

export const GENERAL_FILES_UPLOADER_INIT = "GENERAL_FILES_UPLOADER_INIT";
export const GENERAL_FILES_UPLOADER_SUCCESS = "GENERAL_FILES_UPLOADER_SUCCESS";
export const GENERAL_FILES_UPLOADER_FAILED = "GENERAL_FILES_UPLOADER_FAILED";

export const INVESTOR_UPLOAD_TAX_INIT = "INVESTOR_UPLOAD_TAX_INIT";
export const INVESTOR_UPLOAD_TAX_SUCCESS = "INVESTOR_UPLOAD_TAX_SUCCESS";
export const INVESTOR_UPLOAD_TAX_FAILED = "INVESTOR_UPLOAD_TAX_FAILED";

export const REMOVE_CO_INVESTOR = "REMOVE_CO_INVESTOR"

export const UPDATE_INVESTOR_ICON_INIT = "UPDATE_INVESTOR_ICON_INIT"
export const UPDATE_INVESTOR_ICON_SUCCESS = "UPDATE_INVESTOR_ICON_SUCCESS"
export const UPDATE_INVESTOR_ICON_FAILED = "UPDATE_INVESTOR_ICON_FAILED"

export const ADD_NEW_CO_INVESTOR_LOCALLY = 'ADD_NEW_CO_INVESTOR_LOCALLY'

export const UPDATE_DWOLLA_CUSTOMER_INIT = 'UPDATE_DWOLLA_CUSTOMER_INIT'
export const UPDATE_DWOLLA_CUSTOMER_SUCCESS = 'UPDATE_DWOLLA_CUSTOMER_SUCCESS'
export const UPDATE_DWOLLA_CUSTOMER_FAILED = 'UPDATE_DWOLLA_CUSTOMER_FAILED'

export const CREATE_DWOLLA_CUSTOMER_INIT = 'CREATE_DWOLLA_CUSTOMER_INIT'
export const CREATE_DWOLLA_CUSTOMER_SUCCESS = 'CREATE_DWOLLA_CUSTOMER_SUCCESS'
export const CREATE_DWOLLA_CUSTOMER_FAILED = 'CREATE_DWOLLA_CUSTOMER_FAILED'

export const DELETE_DWOLLA_CUSTOMER_INIT = 'DELETE_DWOLLA_CUSTOMER_INIT'
export const DELETE_DWOLLA_CUSTOMER_SUCCESS = 'DELETE_DWOLLA_CUSTOMER_SUCCESS'
export const DELETE_DWOLLA_CUSTOMER_FAILED = 'DELETE_DWOLLA_CUSTOMER_FAILED'

export const CANCEL_SPV_INIT = 'CANCEL_SPV_INIT'
export const CANCEL_SPV_SUCCESS = 'CANCEL_SPV_SUCCESS'
export const CANCEL_SPV_FAILED = 'CANCEL_SPV_INIT'

export const LAUNCH_STARTUP_INIT = 'LAUNCH_STARTUP_INIT'
export const LAUNCH_STARTUP_SUCCESS = 'LAUNCH_STARTUP_SUCCESS'
export const LAUNCH_STARTUP_FAILED = 'LAUNCH_STARTUP_INIT'

export const GET_PORTFOLIO_DATA_INIT = 'GET_PORTFOLIO_DATA_INIT'
export const GET_PORTFOLIO_DATA_SUCCESS = 'GET_PORTFOLIO_DATA_SUCCESS'
export const GET_PORTFOLIO_DATA_FAILED = 'GET_PORTFOLIO_DATA_FAILED'

export const UPLOAD_PORTFOLIO_STARTUP_IMAGE_INIT = 'UPLOAD_PORTFOLIO_STARTUP_IMAGE_INIT'
export const UPLOAD_PORTFOLIO_STARTUP_IMAGE_SUCCESS = 'UPLOAD_PORTFOLIO_STARTUP_IMAGE_SUCCESS'
export const UPLOAD_PORTFOLIO_STARTUP_IMAGE_FAILED = 'UPLOAD_PORTFOLIO_STARTUP_IMAGE_FAILED'

export const UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_INIT = 'UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_INIT'
export const UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_SUCCESS = 'UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_SUCCESS'
export const UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_FAILED = 'UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_FAILED'

export const REMOVE_PORTFOLIO_CO_INVESTOR = 'REMOVE_PORTFOLIO_CO_INVESTOR'
export const ADD_PORTFOLIO_DATA = 'ADD_PORTFOLIO_DATA'
export const ADD_CO_INVESTOR_NAME = 'ADD_CO_INVESTOR_NAME'

export const ADD_PORTFOLIO_INIT = 'ADD_PORTFOLIO_INIT'
export const ADD_PORTFOLIO_SUCCESS = 'ADD_PORTFOLIO_SUCCESS'
export const ADD_PORTFOLIO_FAILED = 'ADD_PORTFOLIO_FAILED'

export const DELETE_PORTFOLIO_INIT = 'DELETE_PORTFOLIO_INIT'
export const DELETE_PORTFOLIO_SUCCESS = 'DELETE_PORTFOLIO_SUCCESS'
export const DELETE_PORTFOLIO_FAILED = 'DELETE_PORTFOLIO_FAILED'

export const ADD_EDIT_PORTFOLIO_DETAILS = 'ADD_EDIT_PORTFOLIO_DETAILS'

export const UPDATE_PORTFOLIO_INIT = 'UPDATE_PORTFOLIO_INIT'
export const UPDATE_PORTFOLIO_SUCCESS = 'UPDATE_PORTFOLIO_SUCCESS'
export const UPDATE_PORTFOLIO_FAILED = 'UPDATE_PORTFOLIO_FAILED'

export const RESET_PORTFOLIO_DETAILS = 'RESET_PORTFOLIO_DETAILS'

export const GET_APPROVED_STARTUP_CARRY_RECIPIENTS_INIT = 'GET_APPROVED_STARTUP_CARRY_RECIPIENTS_INIT'
export const GET_APPROVED_STARTUP_CARRY_RECIPIENTS_SUCCESS = 'GET_APPROVED_STARTUP_CARRY_RECIPIENTS_SUCCESS'
export const GET_APPROVED_STARTUP_CARRY_RECIPIENTS_FAILED = 'GET_APPROVED_STARTUP_CARRY_RECIPIENTS_FAILED'

export const UPLOAD_CARRY_RECIPIENT_DOC_INIT = 'UPLOAD_CARRY_RECIPIENT_DOC_INIT'
export const UPLOAD_CARRY_RECIPIENT_DOC_SUCCESS = 'UPLOAD_CARRY_RECIPIENT_DOC_SUCCESS'
export const UPLOAD_CARRY_RECIPIENT_DOC_FAILED = 'UPLOAD_CARRY_RECIPIENT_DOC_FAILED'
export const ADD_RECIPIENT_DATA = 'ADD_RECIPIENT_DATA'
export const REMOVE_CARRY_RECIPIENT_DOC = 'REMOVE_CARRY_RECIPIENT_DOC'
export const REMOVE_CARRY_RECIPIENT_RECORD = 'REMOVE_CARRY_RECIPIENT_RECORD'

export const ADD_CARRY_RECIPIENT_API_INIT = 'ADD_CARRY_RECIPIENT_API_INIT'
export const ADD_CARRY_RECIPIENT_API_SUCCESS = 'ADD_CARRY_RECIPIENT_API_SUCCESS'
export const ADD_CARRY_RECIPIENT_API_FAILED = 'ADD_CARRY_RECIPIENT_API_FAILED'

export const UPLOAD_NEW_CARRY_RECIPIENT_DOC_INIT = 'UPLOAD_NEW_CARRY_RECIPIENT_DOC_INIT'
export const UPLOAD_NEW_CARRY_RECIPIENT_DOC_SUCCESS = 'UPLOAD_NEW_CARRY_RECIPIENT_DOC_SUCCESS'
export const UPLOAD_NEW_CARRY_RECIPIENT_DOC_FAILED = 'UPLOAD_NEW_CARRY_RECIPIENT_DOC_FAILED'

export const EDIT_CARRY_RECIPIENT_API_INIT = 'EDIT_CARRY_RECIPIENT_API_INIT'
export const EDIT_CARRY_RECIPIENT_API_SUCCESS = 'EDIT_CARRY_RECIPIENT_API_SUCCESS'
export const EDIT_CARRY_RECIPIENT_API_FAILED = 'EDIT_CARRY_RECIPIENT_API_FAILED'

export const DELETE_CARRY_RECIPIENT_API_INIT = 'DELETE_CARRY_RECIPIENT_API_INIT'
export const DELETE_CARRY_RECIPIENT_API_SUCCESS = 'DELETE_CARRY_RECIPIENT_API_SUCCESS'
export const DELETE_CARRY_RECIPIENT_API_FAILED = 'DELETE_CARRY_RECIPIENT_API_FAILED'

export const GET_RECIPIENT_STARTUP_LISTING_INIT = 'GET_RECIPIENT_STARTUP_LISTING_INIT'
export const GET_RECIPIENT_STARTUP_LISTING_SUCCESS = 'GET_RECIPIENT_STARTUP_LISTING_SUCCESS'
export const GET_RECIPIENT_STARTUP_LISTING_FAILED = 'GET_RECIPIENT_STARTUP_LISTING_FAILED'


export const VIEW_SCOUT_CARRY_ENTITY_API_INIT = 'VIEW_SCOUT_CARRY_ENTITY_API_INIT'
export const VIEW_SCOUT_CARRY_ENTITY_API_SUCCESS = 'VIEW_SCOUT_CARRY_ENTITY_API_SUCCESS'
export const VIEW_SCOUT_CARRY_ENTITY_API_FAILED = 'VIEW_SCOUT_CARRY_ENTITY_API_FAILED'

export const GET_SCOUT_CARRY_SHARE_GLOBAL_INIT = 'GET_SCOUT_CARRY_SHARE_GLOBAL_INIT'
export const GET_SCOUT_CARRY_SHARE_GLOBAL_SUCCESS = 'GET_SCOUT_CARRY_SHARE_GLOBAL_SUCCESS'
export const GET_SCOUT_CARRY_SHARE_GLOBAL_FAILED = 'GET_SCOUT_CARRY_SHARE_GLOBAL_FAILED'
export const SET_SCOUT_CARRY_SHARE_GLOBAL_INIT = 'SET_SCOUT_CARRY_SHARE_GLOBAL_INIT'
export const SET_SCOUT_CARRY_SHARE_GLOBAL_SUCCESS = 'SET_SCOUT_CARRY_SHARE_GLOBAL_SUCCESS'
export const SET_SCOUT_CARRY_SHARE_GLOBAL_FAILED = 'SET_SCOUT_CARRY_SHARE_GLOBAL_FAILED'