import React from 'react'
import "./AddNewChapterPreview.scss"
import chapters from "../../../../../Assets/Images/chapters.svg"
import { Col, Row } from 'antd';

const AddNewChapterPreview = ({ location }) => {
  const { chapterDetail } = location.state;
  return (
    <div className="chapter-preview-container">
      <div className="preview-header">
        <h6>Chapter</h6>
      </div>
      <div className="chapter-preview-wrap">
        <Row gutter={16}>
          <Col span={4}>
            {chapterDetail?.header_image ? (
              <div className="header-image-wrap">
                <img src={chapterDetail?.header_image} alt="header" />
              </div>
            ) : (
              <div className="header-default-wrap">
                <img src={chapters} alt="header" />
              </div>
            )}
          </Col>
          <Col span={16} offset={2} className="content-wrap">
            <h2>{chapterDetail?.title}</h2>
            <p className="subtitle">{chapterDetail?.subtitle}</p>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: chapterDetail?.content }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddNewChapterPreview