// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  scoutsData: [],
  totalScouts: 0,
  scoutDetails: {},
  startupScoutBulkInviteList: [],
  investorScoutBulkInviteList: [],
  loading: false,
  recipientStartupListing: []
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SCOUTS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SCOUTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        scoutsData: action.payload?.data?.data,
        totalScouts: action.payload?.data?.total_count,
      };
    case actionTypes.GET_SCOUTS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        scoutsData: [],
        totalScouts: 0,
      };
    case actionTypes.UPDATE_SCOUTS_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SCOUTS_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        scoutsData: state?.scoutsData?.map((item) => {
          if (item?.id === action?.id) {
            return {
              ...item,
              is_active: action?.is_active,
            };
          }
          return { ...item };
        }),
        scoutDetails: {
          ...state?.scoutDetails,
          is_active: action?.is_active,
        },
      };
    case actionTypes.UPDATE_SCOUTS_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SCOUT_DETAILS_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SCOUT_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        scoutDetails: action.payload,
      };
    case actionTypes.GET_SCOUT_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        scoutDetails: {},
      };
    case actionTypes.DELETE_SCOUT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SCOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_SCOUT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_SCOUT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SCOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_SCOUT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_STARTUP_SCOUT_BULK_INVITE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_STARTUP_SCOUT_BULK_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        startupScoutBulkInviteList: action.payload,
      };
    case actionTypes.GET_STARTUP_SCOUT_BULK_INVITE_FAILED:
      return {
        ...state,
        loading: false,
        startupScoutBulkInviteList: null,
      };
    case actionTypes.GET_INVESTOR_SCOUT_BULK_INVITE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVESTOR_SCOUT_BULK_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        investorScoutBulkInviteList: action.payload,
      };
    case actionTypes.GET_INVESTOR_SCOUT_BULK_INVITE_FAILED:
      return {
        ...state,
        loading: false,
        investorScoutBulkInviteList: null,
      };

      case actionTypes.GET_RECIPIENT_STARTUP_LISTING_INIT:
      return {
        ...state,
        loading: true,
        recipientStartupListing: []
      };

      case actionTypes.GET_RECIPIENT_STARTUP_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        recipientStartupListing: action.payload.data,
      };

      case actionTypes.GET_RECIPIENT_STARTUP_LISTING_FAILED:
      return {
        ...state,
        loading: false,
        recipientStartupListing: []
      };
    default:
      return state;
  }
};

export default store;
