import * as actionTypes from "./ActionTypes";

// Action to handle changes in the search text
export const searchChangeHandle = (searchText) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_CHANGE_HANDLE,
      payload: searchText,
    });
  };
};

// Action to clear the search text
export const clearSearch = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_SEARCH_HANDLE,
    });
  };
};
