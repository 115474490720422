// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  rejectedStartupsData: [],
  loading: false,
  totalRejectedStartups: 0,
  rejectedStartupDetails: [],
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REJECTED_STARTUPS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REJECTED_STARTUPS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectedStartupsData: action.payload?.data,
        totalRejectedStartups: action.payload?.total_count,
      };
    case actionTypes.GET_REJECTED_STARTUPS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        rejectedStartupsData: [],
        totalRejectedStartups: 0,
      };
    case actionTypes.DELETE_REJECTED_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_REJECTED_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectedStartupsData: action.payload?.data,
        totalRejectedStartups: action.payload.total_count,
      };
    case actionTypes.DELETE_REJECTED_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_REJECTED_STARTUP_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REJECTED_STARTUP_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        rejectedStartupDetails: action.payload?.data,
      };
    case actionTypes.GET_REJECTED_STARTUP_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        rejectedStartupDetails: [],
      };
    case actionTypes.UPLOAD_MEMO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_MEMO_SUCCESS:
      return {
        ...state,
        loading: false,
        memo: action?.payload?.url,
      };
    case actionTypes.UPLOAD_STARTUP_SLIDEDECK_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_STARTUP_SLIDEDECK_SUCCESS:
      return {
        ...state,
        loading: false,
        slide_deck: action?.payload?.url,
      };
    case actionTypes.UPLOAD_STARTUP_SLIDEDECK_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_MEMO_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_REJECTED_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_REJECTED_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_REJECTED_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.REJECTED_PERMISSION:
      return {
        ...state,
        Rejectedpermissions: action.payload,
      };
    default:
      return state;
  }
};

export default store;
