import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";
import { message } from "antd";


export const getPortfolioData = (data, onSuccess, onError) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.GET_PORTFOLIO_DATA_INIT,
        });
        await axios
            .post("admin/get-portfolios", data)
            .then((res) => {
                dispatch({
                    type: actionTypes.GET_PORTFOLIO_DATA_SUCCESS,
                    payload: res.data,
                });
                onSuccess && onSuccess(res);
                //   return;
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.GET_PORTFOLIO_DATA_FAILED,
                    payload: error?.response?.data?.error,
                });
                onError && onError(error?.response?.data || error?.response?.data?.error);
                throw error?.response?.data?.error;
            });
    };
};

export const deletePortfolio = (id) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.DELETE_PORTFOLIO_INIT,
        });
        await axios
            .get("admin/delete-portfolio", { params: { id } })
            .then((res) => {
                dispatch({
                    type: actionTypes.DELETE_PORTFOLIO_SUCCESS,
                });
                return;
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.DELETE_PORTFOLIO_FAILED,
                });
                throw error?.response?.data?.error;
            });
    };
};

export const uploadPortfolioStartupImage = (data) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.UPLOAD_PORTFOLIO_STARTUP_IMAGE_INIT,
        });
        await axios
            .post("admin/mime-files", data)
            .then((res) => {
                dispatch({
                    type: actionTypes.UPLOAD_PORTFOLIO_STARTUP_IMAGE_SUCCESS,
                    payload: res.data,
                });
                return;
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.UPLOAD_PORTFOLIO_STARTUP_IMAGE_FAILED,
                });
                throw error?.response?.data?.error;
            });
    };
};

export const uploadPortfolioCoInvestorImage = (data, index) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_INIT,
        });
        await axios
            .post("admin/mime-files", data)
            .then((res) => {
                console.log({ res })
                dispatch({
                    type: actionTypes.UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_SUCCESS,
                    payload: { data: res.data, index },
                });
                return;
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.UPLOAD_PORTFOLIO_CO_INVESTOR_IMAGE_FAILED,
                });
                throw error?.response?.data?.error;
            });
    };
};

export const removeCoInvestor = (index) => ({
    type: actionTypes.REMOVE_PORTFOLIO_CO_INVESTOR,
    payload: index
})

export const addCoInvestorName = (data) => ({
    type: actionTypes.ADD_CO_INVESTOR_NAME,
    payload: data
})

export const addPortfolioData = (field, value) => ({
    type: actionTypes.ADD_PORTFOLIO_DATA,
    payload: { field, value }
})

export const AddPortfolioApi = (data, onSuccess, onError) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.ADD_PORTFOLIO_INIT,
        });
        await axios
            .post("admin/add-portfolio", data)
            .then((res) => {
                dispatch({
                    type: actionTypes.ADD_PORTFOLIO_SUCCESS,
                });
                onSuccess && onSuccess(res);
                // return;
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.ADD_PORTFOLIO_FAILED,
                });
                onError && onError(error?.response?.data || error?.response?.data?.error);
                throw error?.response?.data?.error;
            });
    }
}

export const addEditPortfolioDetails = (data) => ({
    type: actionTypes.ADD_EDIT_PORTFOLIO_DETAILS,
    payload: data
})

export const resetPortfolioDetails = () => ({
    type: actionTypes.RESET_PORTFOLIO_DETAILS,
})

export const UpdatePortfolioApi = (data, id, onSuccess, onError) => {
    return async (dispatch) => {
        dispatch({
            type: actionTypes.UPDATE_PORTFOLIO_INIT,
        });
        await axios
            .post(`admin/update-portfolio?id=${id}`, data)
            .then((res) => {
                dispatch({
                    type: actionTypes.UPDATE_PORTFOLIO_SUCCESS,
                });
                onSuccess && onSuccess(res);
                // return;
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.UPDATE_PORTFOLIO_FAILED,
                });
                onError && onError(error?.response?.data || error?.response?.data?.error);
                throw error?.response?.data?.error;
            });
    }
}