import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";

// Action to handle the admin login
export const adminLogin = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADMIN_LOGIN_INIT,
    });

    try {
      const res = await axios.post("admin/login", data);
      if (res.data) {
        localStorage.setItem("exp", res?.data?.exp);
        localStorage.setItem("userdata", JSON.stringify(res.data.data[0]));
        dispatch({
          type: actionTypes.ADMIN_LOGIN_SUCCESS,
          payload: res.data,
        });
      } else {
        // handle the absence of res.data
      }
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_LOGIN_FAILED,
        payload: error?.response?.data?.error,
      });
      throw error?.response?.data?.error || "An error occurred";
    }
  };
};

// Action to handle the admin logout
export const adminLogout = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADMIN_LOGOUT_INIT,
    });

    try {
      const res = await axios.get("admin/logout");
      if (res.data) {
        dispatch({
          type: actionTypes.ADMIN_LOGOUT_SUCCESS,
          payload: res.data.message,
        });
        localStorage.clear();
      } else {
        // handle the absence of res.data
      }
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_LOGOUT_FAILED,
        payload: error?.response?.data?.error,
      });
      throw error?.response?.data?.error || "An error occurred";
    }
  };
};

// Action to get the user profile
export const getUserProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_USER_PROFILE_INIT,
    });

    try {
      const res = await axios.get("admin/profile");
      if (res.data) {
        dispatch({
          type: actionTypes.GET_USER_PROFILE_SUCCESS,
          payload: res.data.data[0],
        });
      } else {
        // handle the absence of res.data
      }
    } catch (error) {
      dispatch({
        type: actionTypes.GET_USER_PROFILE_FAILED,
        payload: error?.response?.data?.error,
      });
      throw error?.response?.data?.error || "An error occurred";
    }
  };
};

// Action to update the user profile
export const updateProfile = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_INIT,
    });
    try {
      const res = await axios.post("admin/profile", data);
      if (res.data) {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_SUCCESS,
          payload: res.data.data[0],
        });
      } else {
        // handle the absence of res.data
      }
    } catch (error) {
      dispatch({
        type: actionTypes.UPDATE_PROFILE_FAILED,
        payload: error?.response?.data?.error,
      });
      throw error?.response?.data?.error || "An error occurred";
    }
  };
};

// Action to update the user profile picture
export const updateProfilePic = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PROFILE_PIC_INIT,
    });

    try {
      const res = await axios.post("admin/mime-files", data);
      if (res.data) {
        dispatch({
          type: actionTypes.UPDATE_PROFILE_PIC_SUCCESS,
          payload: res.data?.url,
        });
      } else {
        // handle the absence of res.data
      }
    } catch (error) {
      dispatch({
        type: actionTypes.UPDATE_PROFILE_PIC_FAILED,
        payload: error?.response?.data?.error,
      });
      throw error?.response?.data?.error || "An error occurred";
    }
  };
};

// Action to handle the forgot password request
export const forgotPassword = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.FORGOT_PASSWORD_INIT,
    });
    try {
      const res = await axios.post("admin/forgot-password", data);
      if (res.data) {
        dispatch({
          type: actionTypes.FORGOT_PASSWORD_SUCCESS,
          payload: res.data,
        });
      } else {
        // handle the absence of res.data
      }
    } catch (error) {
      dispatch({
        type: actionTypes.FORGOT_PASSWORD_FAILED,
        payload: error?.response?.data?.error,
      });
      throw error?.response?.data?.error || "An error occurred";
    }
  };
};
