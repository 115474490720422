import moment from "moment";
import { isValid } from "ssn-validator";

export const IMAGE_SIZE_LIMIT = 2000000;
export const IMAGE_SIZE_ERR = "Files must be no larger than 2MB in size";

/**
 * Capitalizes each word in a string assumed to be in snake_case.
 * @param {string} input - The input string to be capitalized.
 * @returns {string} - The capitalized string.
 * @description - Check if the input is falsy (undefined, null, etc.) than return empty string
 *              - Split the input string into words using underscores as separators.
 *              - Capitalize each word by making the first character uppercase and concatenating the rest
 *              - Join the capitalized words into a single string with spaces in between
 */
export function capitalize(input) {
  if (!input) {
    return "";
  }
  const words = input.split("_");
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join(" ");
}
const numberRegex = /^\d+$/;
/**
 * Ensures that a link is clickable by adding "http://" prefix if missing.
 * @param {string} link - The input link.
 * @returns {string} - The clickable link.
 */
export const getClickableLink = (link) => {
  const hasHttpPrefix =
    link?.startsWith("http://") || link?.startsWith("https://")
      ? link
      : `http://${link}`;
  return hasHttpPrefix;
};

/**
 * Generates an array of numbers within a specified range (exclusive of the end).
 * @param {number} start - The starting value of the range.
 * @param {number} end - The ending value of the range (exclusive).
 * @returns {number[]} - An array containing numbers within the specified range.
 */
export const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

/**
 * Checks if a given path is searchable.
 * @param {string} path - The path to be checked.
 * @returns {boolean} - True if searchable, false otherwise.
 * @description - This function is used for checked serach textbox is avaiable in page or not.
 *              - Check if the given path is in the non-searchable paths list
 */
export function isSearchAble(path) {
  let paths = path.split("/");
  if (
    paths[2] === "startup-details" ||
    paths[2] === "startup-edit" ||
    paths[2] === "investor-details" ||
    paths[2] === "investor-edit" ||
    paths[2] === "add-blog" ||
    paths[2] === "add-podcast" ||
    paths[2] === "edit-blog" ||
    paths[2] === "edit-podcast" ||
    paths[2] === "scout-details" ||
    paths[2] === "scout-edit" ||
    paths[3] === "add-chapter" ||
    paths[3] === "chapter-edit" ||
    paths[3] === "chapter-preview" ||
    paths[3] === "add-video-lecture" ||
    paths[3] === "video-lecture-edit" ||
    paths[3] === "video-lecture-preview" ||
    paths[3] === "add-quiz" ||
    paths[3] === "quiz-edit" ||
    paths[3] === "quiz-preview" ||
    paths[3] === "add-exam" ||
    paths[3] === "exam-edit" ||
    paths[3] === "exam-preview" ||
    paths[2] === "business-info" ||
    paths[2] === "controller-info" ||
    paths[2] === "subscribers" ||
    paths[2] === "learner-details" ||
    paths[2] === "talent-details" ||
    paths[2] === "talent-edit" ||
    paths[2] === "startup-info-update" ||
    paths[2] === "startup-spv-dwolla-accounts" ||
    paths[2] === "startup-spv-add-bank" ||
    paths[2] === "startup-spv-manage-bank" ||
    paths[2] === "startup-spv-add-dwollaAccount" ||
    paths[2] === "talent-edit" ||
    paths[2] === "startup-spv-formation" ||
    paths[2] === "dwolla-accounts" ||
    paths[2] === "startup-spv-add-bank" ||
    paths[2] === "AddDwollaAccount" ||
    paths[2] === "update-startup-details" ||
    paths[2] === "startup-history" ||
    paths[2] === "spv-view" ||
    paths[2] === "monthly-Update-History" ||
    paths[2] === "notify-Investors" ||
    paths[2] === "notify-Investors-History" ||
    paths[2] == "edit-notify-investor" ||
    paths[2] == "controller-document-vertification" ||
    paths[2] == "business-document-vertification" ||
    paths[2] == "business-DBA-document-vertification" ||
    paths[2] == "document-verification-status" ||
    paths[2] == "previous-posts" ||
    paths[2] == "previous-posts-details" ||
    paths[2] == "invite-history" || 
    paths[1] === "edit-portfolio" ||
    paths[1] === "add-portfolio" || 
    paths[1] === "education"
  ) {
    return false;
  } else {
    return true;
  }
}

/**
 * Gets the human-readable name for a given sector.
 * @param {string} sector - The sector code.
 * @returns {string | object} - The sector name or the entire sectors map if no sector is provided.
 */
export function getSector(sector) {
  let sectors = {
    agtech: "Agriculture/Agtech",
    ai: "Artificial Intelligence",
    ar: "Augmented Reality",
    biomed: "Biomedical",
    biotech: "Biotech",
    blockchain: "Blockchain/Cryptocurrency",
    community: "Community",
    crowdfund: "Crowdfunding",
    devtools: "Developer tools",
    diversity: "Diversity",
    drones: "Drones",
    education: "Education",
    energy: "Energy",
    enterprise: "Enterprise",
    entertain: "Entertainment",
    gaming: "Esports/Online Gaming",
    banking: "Financial/Banking",
    government: "Government",
    hardware: "Hardware",
    health: "Healthcare",
    market: "Marketplace",
    media: "Media/Advertising",
    hardtech: "Moonshots/Hard Tech",
    robotics: "Robotics",
    security: "Security",
    sport: "Sport/Fitness",
    transport: "Transportation",
    travel: "Travel",
    vr: "Virtual Reality",
    other: "Other",
    proptech: "Real Estate/Proptech",
    food: "Food and Beverages",
    cleantech: "Cleantech",
    beauty: "Beauty/Cosmetics",
    fashion: "Clothing/Fashion",
    workforce: "Distributed Workforce/Future of Work",
    sex: "Sex/Relationships",
    sales: "Sales/CRM",
    saas: "SAAS",
    b2b: "B2B",
    b2b2c: "B2B2C",
    b2c: "B2C",
    ecommerce: "E-Commerce",
    subscription: "Subscription",
  };
  // If no sector is provided, return the entire sectors map
  if (!sector) {
    return sectors;
  }
  // Return the corresponding sector name
  return sectors[sector];
}

/**
 * Gets the sector code for a given human-readable sector name.
 * @param {string} sector - The human-readable sector name.
 * @returns {string | object} - The sector code or the entire reverse sectors map if no sector is provided.
 * @description - Revese of getSector function
 */
export function getReverseSector(sector) {
  let sectors = {
    Healthcare: "health",
    "Artificial Intelligence": "ai",
    "Media / Advertising": "media",
    Entertainment: "entertain",
    Education: "education",
  };
  // If no sector is provided, return the entire reverse sectors map
  if (!sector) {
    return sector;
  }
  // Return the corresponding sector code
  return sectors[sector];
}

/**
 * Gets the human-readable name for a given tag code.
 * @param {string} tag - The tag code.
 * @returns {string | object} - The tag name or the entire tags map if no tag is provided.
 */
export function getTags(tag) {
  let tags = {
    impact: "Impact Startup",
    female: "Female Founder",
    color: "Founder of Color",
    lgbtq: "LGBTQ Founder",
  };
  // If no tag is provided, return the entire tags map
  if (!tag) {
    return tag;
  }
  // Return the corresponding tag name or the original tag code if not found
  return tags[tag] ? tags[tag] : tag;
}

/**
 * Enumeration representing different types of investors.
 * @enum {string}
 */
export const InvestorsNotifyType = [
  "All",
  "Invested",
  "Neither invested nor passed",
  "Passed",
];

/**
 * Gets the human-readable name for a given investor tag code.
 * @param {string} tag - The investor tag code.
 * @returns {string | object} - The investor tag name or the entire investor tags map if no tag is provided.
 */
export function getInvestorTags(tag) {
  let tags = {
    female: "Female Investor",
    color: "Investor of Color",
    lgbtq: "LGBTQ Investor",
  };
  if (!tag) {
    return tag;
  }
  return tags[tag] ? tags[tag] : tag;
}

/**
 * Converts a startup name by replacing spaces with underscores.
 * @param {string} name - The startup name.
 * @returns {string} - The converted startup name with spaces replaced by underscores.
 */
export const getStartupName = (name) => {
  return name?.replaceAll(" ", "_");
};

/**
 * Generates a public URL link based on the provided parameters.
 * @param {string} link - The base URL link.
 * @param {string} startupName - The name of the startup.
 * @param {string} invite_code - The invite code for the startup.
 * @returns {string} - The generated public URL link.
 */
export const publicURLLink = (link, startupName, invite_code) => {
  switch (link) {
    case "localhost:3000":
      // For local development
      return link + `/startup/${startupName}/${invite_code}`;
    case "admin.stg.exitfund.com":
      // For staging environment
      return `https://stg.exitfund.com/startup/${startupName}/${invite_code}`;
    case "admin.exitfund.com":
      // For production environment
      return `https://www.exitfund.com/startup/${startupName}/${invite_code}`;
    default:
      // Default to the base URL link with the startup details
      return link + `/startup/${startupName}/${invite_code}`;
  }
};

/**
 * Generates a website URL link based on the provided base URL.
 * @param {string} link - The base URL link.
 * @returns {string} - The generated website URL link.
 */
export function websiteURLLink(link) {
  switch (link) {
    case "localhost:3000":
      // For local development
      return "https://" + link;
    case "admin.stg.exitfund.com":
      // For staging environment
      return `https://stg.exitfund.com`;
    case "admin.exitfund.com":
      // For production environment
      return `https://www.exitfund.com`;
    default:
      // Default to an empty string for unrecognized links
      return "";
  }
}

/**
 * Filters admin options based on the specified admin position.
 * @param {string} position - The admin position ("super_admin", "senior_admin", "principal_admin", "junior_admin").
 * @param {Array} adminData - The array of admin data.
 * @returns {Array} - The filtered array of admin options.
 */
export function getAdminOptions(position, adminData) {
  let filteredAdminData = [];
  // Return all admin options for super admins
  if (position === "super_admin") {
    return adminData;
  }

  // Filter out super admins for senior admins
  if (position === "senior_admin") {
    filteredAdminData = adminData.filter(
      (item) => item.position !== "super_admin"
    );
    return filteredAdminData;
  }

  // Filter out super admins and senior admins for principal admins
  if (position === "principal_admin") {
    filteredAdminData = adminData.filter(
      (item) =>
        item.position !== "super_admin" && item.position !== "senior_admin"
    );
    return filteredAdminData;
  }

  // Filter out super admins, senior admins, and principal admins for junior admins
  if (position === "junior_admin") {
    filteredAdminData = adminData.filter(
      (item) =>
        item.position !== "super_admin" &&
        item.position !== "senior_admin" &&
        item.position !== "principal_admin"
    );

    // Default to an empty array if the position is not recognized
    return filteredAdminData;
  }
}

/**
 * Gets the human-readable name for a given milestone code.
 * @param {string} milestone - The milestone code.
 * @returns {string | object} - The milestone name or the entire milestones map if no milestone is provided.
 */
export function getMilestone(milestone) {
  let milestones = {
    prototype: "Prototype/Pre-Launch",
    beta: "Beta Launched",
    product: "Product Launched",
    useracq: "Users Acquired",
    revgen: "Revenue Generated",
  };
  // If no milestone is provided, return the entire milestones map
  if (!milestone) {
    return milestones;
  }
  // Return the corresponding milestone name or the original milestone code if not found
  return milestones[milestone];
}

/**
 * Gets the human-readable investment range for a given range code.
 * @param {number} range - The range code.
 * @returns {string | undefined} - The investment range or undefined if the range is not found.
 */
export function getInvestmentRange(range) {
  let invRange = {
    0: "$0-$25,000",
    25: "$25,000-$50,000",
    50: "$50,000-$100,000",
    100: "$100,000-$250,000",
    250: "$250,000-$500,000",
    500: "$500,000+",
  };
  return invRange[range];
}

/**
 * List of skills commonly associated with software development and related fields.
 * @enum {string}
 */
export const skillList = [
  "Angular",
  "React",
  "Node.js",
  "React Native",
  "Python",
  "Swift",
  "Java",
  "Android",
  "Ruby on Rails",
  "Go",
  "Vue.js",
  "PHP",
  "DevOps",
  "Machine Learning",
  "Data Science",
  "Scala",
  "Typescript",
  "HTML",
  "CSS",
  "UI/UX",
];

/**
 * List of Time zones.
 * @enum {string}
 */
export const timeZones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Asmera",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Timbuktu",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/ComodRivadavia",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Atka",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Buenos_Aires",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Catamarca",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Coral_Harbour",
  "America/Cordoba",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Ensenada",
  "America/Fort_Nelson",
  "America/Fort_Wayne",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indianapolis",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Jujuy",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Knox_IN",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Louisville",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Mendoza",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montreal",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Acre",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Rosario",
  "America/Santa_Isabel",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Shiprock",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Virgin",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/South_Pole",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Ashkhabad",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Calcutta",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Chongqing",
  "Asia/Chungking",
  "Asia/Colombo",
  "Asia/Dacca",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Harbin",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Istanbul",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kashgar",
  "Asia/Kathmandu",
  "Asia/Katmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macao",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Saigon",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Tel_Aviv",
  "Asia/Thimbu",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ujung_Pandang",
  "Asia/Ulaanbaatar",
  "Asia/Ulan_Bator",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faeroe",
  "Atlantic/Faroe",
  "Atlantic/Jan_Mayen",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/ACT",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Canberra",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/LHI",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/NSW",
  "Australia/North",
  "Australia/Perth",
  "Australia/Queensland",
  "Australia/South",
  "Australia/Sydney",
  "Australia/Tasmania",
  "Australia/Victoria",
  "Australia/West",
  "Australia/Yancowinna",
  "Brazil/Acre",
  "Brazil/DeNoronha",
  "Brazil/East",
  "Brazil/West",
  "CET",
  "CST6CDT",
  "Canada/Atlantic",
  "Canada/Central",
  "Canada/Eastern",
  "Canada/Mountain",
  "Canada/Newfoundland",
  "Canada/Pacific",
  "Canada/Saskatchewan",
  "Canada/Yukon",
  "Chile/Continental",
  "Chile/EasterIsland",
  "Cuba",
  "EET",
  "EST",
  "EST5EDT",
  "Egypt",
  "Eire",
  "Etc/GMT",
  "Etc/GMT+0",
  "Etc/GMT+1",
  "Etc/GMT+10",
  "Etc/GMT+11",
  "Etc/GMT+12",
  "Etc/GMT+2",
  "Etc/GMT+3",
  "Etc/GMT+4",
  "Etc/GMT+5",
  "Etc/GMT+6",
  "Etc/GMT+7",
  "Etc/GMT+8",
  "Etc/GMT+9",
  "Etc/GMT-0",
  "Etc/GMT-1",
  "Etc/GMT-10",
  "Etc/GMT-11",
  "Etc/GMT-12",
  "Etc/GMT-13",
  "Etc/GMT-14",
  "Etc/GMT-2",
  "Etc/GMT-3",
  "Etc/GMT-4",
  "Etc/GMT-5",
  "Etc/GMT-6",
  "Etc/GMT-7",
  "Etc/GMT-8",
  "Etc/GMT-9",
  "Etc/GMT0",
  "Etc/Greenwich",
  "Etc/UCT",
  "Etc/UTC",
  "Etc/Universal",
  "Etc/Zulu",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belfast",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Nicosia",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Tiraspol",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "GB",
  "GB-Eire",
  "GMT",
  "GMT+0",
  "GMT-0",
  "GMT0",
  "Greenwich",
  "HST",
  "Hongkong",
  "Iceland",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Iran",
  "Israel",
  "Jamaica",
  "Japan",
  "Kwajalein",
  "Libya",
  "MET",
  "MST",
  "MST7MDT",
  "Mexico/BajaNorte",
  "Mexico/BajaSur",
  "Mexico/General",
  "NZ",
  "NZ-CHAT",
  "Navajo",
  "PRC",
  "PST8PDT",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kanton",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Ponape",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Samoa",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Truk",
  "Pacific/Wake",
  "Pacific/Wallis",
  "Pacific/Yap",
  "Poland",
  "Portugal",
  "ROC",
  "ROK",
  "Singapore",
  "Turkey",
  "UCT",
  "US/Alaska",
  "US/Aleutian",
  "US/Arizona",
  "US/Central",
  "US/East-Indiana",
  "US/Eastern",
  "US/Hawaii",
  "US/Indiana-Starke",
  "US/Michigan",
  "US/Mountain",
  "US/Pacific",
  "US/Samoa",
  "UTC",
  "Universal",
  "W-SU",
  "WET",
  "Zulu",
];

/**
 * List of round type options commonly associated with startup funding.
 */
export const roundTypeOptions = [
  { label: "Pre-Seed", value: "pre_seed" },
  { label: "Pre-Seed+", value: "pre_seed+" },
  { label: "Seed", value: "seed" },
  { label: "Seed+", value: "seed+" },
  { label: "Series A", value: "a" },
  { label: "Series A+", value: "a+" },
  { label: "Series B", value: "b" },
  { label: "Series B+", value: "b+" },
  { label: "Series C", value: "c" },
  { label: "Series C+", value: "c+" },
  { label: "Series D", value: "d" },
  { label: "Series D+", value: "d+" },
];

/**
 * Number formatter for formatting currency values in USD.
 * @type {Intl.NumberFormat}
 */
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

/**
 * List of pro-rata amount options.
 */
export const proRataAmountOptions = [
  { label: "Included", value: "included" },
  { label: "Not Included", value: "not_included" },
];

/**
 * List of raising options.
 */
export const raisingAsOptions = [
  { label: "SAFE", value: "safe" },
  { label: "Convertible Note", value: "convertible_note" },
  { label: "Priced Equity", value: "priced_equity" },
];

/**
 * Extracts a file name from a given URL.
 * @param {string} url - The URL from which to extract the file name.
 * @returns {string} - The extracted file name.
 */
export function getFileNameFromUrl(url) {
  const a = url?.split("/")?.[url?.split("/")?.length - 1];
  let b = a?.split("-");
  b.shift();
  b = b.join("-");
  return b;
}

export const handleNameFromUrl = (url) => {
  const fileNameArr = url.split('-')
  const fileName = fileNameArr[fileNameArr.length - 1]
  return fileName
}

/**
 * Clears specific page tokens from the localStorage.
 */
export const clearAllPageToken = () => {
  let keysToRemove = [
    "waitlistPage",
    "rejectedPage",
    "scoutsPage",
    "approvedPage",
    "marketingPage",
    "transactionsPage",
    "talentPage",
    "investor_waitlist_Page",
    "investor_rejected_Page",
    "investor_approved_Page",
    "investor_transactions_Page",
    "dwollaPageNo",
    "PostPage",
    "adminInviteHistory",
  ];
  keysToRemove.map((TokenKey, index) => {
    localStorage.removeItem(TokenKey);
  });
};

/**
 * Checks access permission for a given item based on the provided permission data.
 * @param {object} item - The item for which to check access permission.
 * @param {object} permissionData - The data containing permission information.
 * @returns {boolean} - Whether the user has access permission for the specified item.
 */
export const checkAccessPermission = (item, permissionData) => {
  switch (item.label) {
    case "Dashboard":
      return permissionData?.access_dashboard;
    case "Rejected":
      return (
        permissionData?.access_rejected_startups ||
        permissionData?.access_rejected_investors
      );
    case "Scouts":
      return permissionData?.access_scouts;
    case "Approved":
      return (
        permissionData?.access_approved_startups ||
        permissionData?.access_approved_investors
      );
    case "Analytics":
      return permissionData?.access_analytics;
    case "Marketing":
      return permissionData?.access_marketing;
    case "Education":
      return permissionData?.access_education;
    case "Transactions":
      return permissionData?.access_transactions;
    case "Talent":
      return permissionData?.access_talent;
    case "Notifications":
      return permissionData?.view_all_admin_users;
    default:
      return true;
  }
};

/**
 * List of sectors commonly associated with startups.
 * @type {string[]}
 */
export const SECTOR_DATA = [
  "agtech",
  "ai",
  "ar",
  "biomed",
  "biotech",
  "blockchain",
  "community",
  "crowdfund",
  "devtools",
  "diversity",
  "drones",
  "education",
  "energy",
  "enterprise",
  "entertain",
  "gaming",
  "banking",
  "government",
  "hardware",
  "health",
  "market",
  "media",
  "hardtech",
  "robotics",
  "security",
  "sport",
  "transport",
  "travel",
  "vr",
  "other",
  "proptech",
  "food",
  "cleantech",
  "beauty",
  "fashion",
  "dw",
  "sex",
  "sales",
  "saas",
  "b2b",
  "b2b2c",
  "b2c",
  "ecommerce",
  "sabscription",
];

/**
 * List of Incorporation Type
 * @type {string[]}
 */
export const INCORPORATION_TYPE = [
  "C Corporation",
  "LLC",
  "PBC",
  "S Corporation",
  "Other",
];

/**
 * List of State.
 * @type {string[]}
 */
export const STATE = [
  "AL",
  "AS",
  "CA",
  "IN",
  "MT",
  "AE",
  "HI",
  "NE",
  "KS",
  "WA",
  "GA",
  "WI",
  "MA",
  "NY",
  "CO",
  "VA",
  "NJ",
  "PA",
  "TX",
  "WY",
  "OK",
  "CT",
  "MN",
  "TN",
  "OH",
  "KY",
  "FL",
  "DC",
  "IA",
  "WV",
  "VT",
  "NC",
  "AP",
  "NH",
  "OR",
  "AA",
  "AZ",
  "MS",
  "PR",
  "DE",
  "NM",
  "MD",
  "GU",
  "SD",
  "ND",
  "UT",
  "AR",
  "UM",
  "MO",
  "MP",
  "ME",
  "ID",
  "LA",
  "SC",
  "MI",
  "AK",
  "VI",
  "IL",
  "NV",
  "RI",
];

/**
 * List of countries with corresponding labels and keys.
 * @type {Object[]}
 * Each object has a label (the country's name) and a key (the country's code).
 */
export const COUNTRY_LIST = [
  {
    label: "Afghanistan",
    key: "AF",
  },
  {
    label: "Albania",
    key: "AL",
  },
  {
    label: "Algeria",
    key: "DZ",
  },
  {
    label: "American Samoa",
    key: "AS",
  },
  {
    label: "Andorra",
    key: "AD",
  },
  {
    label: "Angola",
    key: "AO",
  },
  {
    label: "Anguilla",
    key: "AI",
  },
  {
    label: "Antarctica",
    key: "AQ",
  },
  {
    label: "Antigua and Barbuda",
    key: "AG",
  },
  {
    label: "Argentina",
    key: "AR",
  },
  {
    label: "Armenia",
    key: "AM",
  },
  {
    label: "Aruba",
    key: "AW",
  },
  {
    label: "Australia",
    key: "AU",
  },
  {
    label: "Austria",
    key: "AT",
  },
  {
    label: "Azerbaijan",
    key: "AZ",
  },
  {
    label: "Bahamas",
    key: "BS",
  },
  {
    label: "Bahrain",
    key: "BH",
  },
  {
    label: "Bangladesh",
    key: "BD",
  },
  {
    label: "Barbados",
    key: "BB",
  },
  {
    label: "Belarus",
    key: "BY",
  },
  {
    label: "Belgium",
    key: "BE",
  },
  {
    label: "Belize",
    key: "BZ",
  },
  {
    label: "Benin",
    key: "BJ",
  },
  {
    label: "Bermuda",
    key: "BM",
  },
  {
    label: "Bhutan",
    key: "BT",
  },
  {
    label: "Bolivia (Plurinational State of)",
    key: "BO",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    key: "BQ",
  },
  {
    label: "Bosnia and Herzegovina",
    key: "BA",
  },
  {
    label: "Botswana",
    key: "BW",
  },
  {
    label: "Bouvet Island",
    key: "BV",
  },
  {
    label: "Brazil",
    key: "BR",
  },
  {
    label: "British Indian Ocean Territory",
    key: "IO",
  },
  {
    label: "Brunei Darussalam",
    key: "BN",
  },
  {
    label: "Bulgaria",
    key: "BG",
  },
  {
    label: "Burkina Faso",
    key: "BF",
  },
  {
    label: "Burundi",
    key: "BI",
  },
  {
    label: "Cabo Verde",
    key: "CV",
  },
  {
    label: "Cambodia",
    key: "KH",
  },
  {
    label: "Cameroon",
    key: "CM",
  },
  {
    label: "Canada",
    key: "CA",
  },
  {
    label: "Cayman Islands",
    key: "KY",
  },
  {
    label: "Central African Republic",
    key: "CF",
  },
  {
    label: "Chad",
    key: "TD",
  },
  {
    label: "Chile",
    key: "CL",
  },
  {
    label: "China",
    key: "CN",
  },
  {
    label: "Christmas Island",
    key: "CX",
  },
  {
    label: "Cocos (Keeling) Islands",
    key: "CC",
  },
  {
    label: "Colombia",
    key: "CO",
  },
  {
    label: "Comoros",
    key: "KM",
  },
  {
    label: "Congo (the Democratic Republic of the)",
    key: "CD",
  },
  {
    label: "Congo",
    key: "CG",
  },
  {
    label: "Cook Islands",
    key: "CK",
  },
  {
    label: "Costa Rica",
    key: "CR",
  },
  {
    label: "Croatia",
    key: "HR",
  },
  {
    label: "Cuba",
    key: "CU",
  },
  {
    label: "Curaçao",
    key: "CW",
  },
  {
    label: "Cyprus",
    key: "CY",
  },
  {
    label: "Czechia",
    key: "CZ",
  },
  {
    label: "Côte d'Ivoire",
    key: "CI",
  },
  {
    label: "Denmark",
    key: "DK",
  },
  {
    label: "Djibouti",
    key: "DJ",
  },
  {
    label: "Dominica",
    key: "DM",
  },
  {
    label: "Dominican Republic",
    key: "DO",
  },
  {
    label: "Ecuador",
    key: "EC",
  },
  {
    label: "Egypt",
    key: "EG",
  },
  {
    label: "El Salvador",
    key: "SV",
  },
  {
    label: "Equatorial Guinea",
    key: "GQ",
  },
  {
    label: "Eritrea",
    key: "ER",
  },
  {
    label: "Estonia",
    key: "EE",
  },
  {
    label: "Eswatini",
    key: "SZ",
  },
  {
    label: "Ethiopia",
    key: "ET",
  },
  {
    label: "Falkland Islands",
    key: "FK",
  },
  {
    label: "Faroe Islands",
    key: "FO",
  },
  {
    label: "Fiji",
    key: "FJ",
  },
  {
    label: "Finland",
    key: "FI",
  },
  {
    label: "France",
    key: "FR",
  },
  {
    label: "French Guiana",
    key: "GF",
  },
  {
    label: "French Polynesia",
    key: "PF",
  },
  {
    label: "French Southern Territories",
    key: "TF",
  },
  {
    label: "Gabon",
    key: "GA",
  },
  {
    label: "Gambia",
    key: "GM",
  },
  {
    label: "Georgia",
    key: "GE",
  },
  {
    label: "Germany",
    key: "DE",
  },
  {
    label: "Ghana",
    key: "GH",
  },
  {
    label: "Gibraltar",
    key: "GI",
  },
  {
    label: "Greece",
    key: "GR",
  },
  {
    label: "Greenland",
    key: "GL",
  },
  {
    label: "Grenada",
    key: "GD",
  },
  {
    label: "Guadeloupe",
    key: "GP",
  },
  {
    label: "Guam",
    key: "GU",
  },
  {
    label: "Guatemala",
    key: "GT",
  },
  {
    label: "Guernsey",
    key: "GG",
  },
  {
    label: "Guinea",
    key: "GN",
  },
  {
    label: "Guinea-Bissau",
    key: "GW",
  },
  {
    label: "Guyana",
    key: "GY",
  },
  {
    label: "Haiti",
    key: "HT",
  },
  {
    label: "Heard Island and McDonald Islands",
    key: "HM",
  },
  {
    label: "Holy See",
    key: "VA",
  },
  {
    label: "Honduras",
    key: "HN",
  },
  {
    label: "Hong Kong",
    key: "HK",
  },
  {
    label: "Hungary",
    key: "HU",
  },
  {
    label: "Iceland",
    key: "IS",
  },
  {
    label: "India",
    key: "IN",
  },
  {
    label: "Indonesia",
    key: "ID",
  },
  {
    label: "Iran (Islamic Republic of)",
    key: "IR",
  },
  {
    label: "Iraq",
    key: "IQ",
  },
  {
    label: "Ireland",
    key: "IE",
  },
  {
    label: "Isle of Man",
    key: "IM",
  },
  {
    label: "Israel",
    key: "IL",
  },
  {
    label: "Italy",
    key: "IT",
  },
  {
    label: "Jamaica",
    key: "JM",
  },
  {
    label: "Japan",
    key: "JP",
  },
  {
    label: "Jersey",
    key: "JE",
  },
  {
    label: "Jordan",
    key: "JO",
  },
  {
    label: "Kazakhstan",
    key: "KZ",
  },
  {
    label: "Kenya",
    key: "KE",
  },
  {
    label: "Kiribati",
    key: "KI",
  },
  {
    label: "Korea (the Democratic People's Republic of)",
    key: "KP",
  },
  {
    label: "Korea (the Republic of)",
    key: "KR",
  },
  {
    label: "Kuwait",
    key: "KW",
  },
  {
    label: "Kyrgyzstan",
    key: "KG",
  },
  {
    label: "Lao People's Democratic Republic",
    key: "LA",
  },
  {
    label: "Latvia",
    key: "LV",
  },
  {
    label: "Lebanon",
    key: "LB",
  },
  {
    label: "Lesotho",
    key: "LS",
  },
  {
    label: "Liberia",
    key: "LR",
  },
  {
    label: "Libya",
    key: "LY",
  },
  {
    label: "Liechtenstein",
    key: "LI",
  },
  {
    label: "Lithuania",
    key: "LT",
  },
  {
    label: "Luxembourg",
    key: "LU",
  },
  {
    label: "Macao",
    key: "MO",
  },
  {
    label: "Madagascar",
    key: "MG",
  },
  {
    label: "Malawi",
    key: "MW",
  },
  {
    label: "Malaysia",
    key: "MY",
  },
  {
    label: "Maldives",
    key: "MV",
  },
  {
    label: "Mali",
    key: "ML",
  },
  {
    label: "Malta",
    key: "MT",
  },
  {
    label: "Marshall Islands",
    key: "MH",
  },
  {
    label: "Martinique",
    key: "MQ",
  },
  {
    label: "Mauritania",
    key: "MR",
  },
  {
    label: "Mauritius",
    key: "MU",
  },
  {
    label: "Mayotte",
    key: "YT",
  },
  {
    label: "Mexico",
    key: "MX",
  },
  {
    label: "Micronesia (Federated States of)",
    key: "FM",
  },
  {
    label: "Moldova (the Republic of)",
    key: "MD",
  },
  {
    label: "Monaco",
    key: "MC",
  },
  {
    label: "Mongolia",
    key: "MN",
  },
  {
    label: "Montenegro",
    key: "ME",
  },
  {
    label: "Montserrat",
    key: "MS",
  },
  {
    label: "Morocco",
    key: "MA",
  },
  {
    label: "Mozambique",
    key: "MZ",
  },
  {
    label: "Myanmar",
    key: "MM",
  },
  {
    label: "Namibia",
    key: "NA",
  },
  {
    label: "Nauru",
    key: "NR",
  },
  {
    label: "Nepal",
    key: "NP",
  },
  {
    label: "Netherlands",
    key: "NL",
  },
  {
    label: "New Caledonia",
    key: "NC",
  },
  {
    label: "New Zealand",
    key: "NZ",
  },
  {
    label: "Nicaragua",
    key: "NI",
  },
  {
    label: "Niger",
    key: "NE",
  },
  {
    label: "Nigeria",
    key: "NG",
  },
  {
    label: "Niue",
    key: "NU",
  },
  {
    label: "Norfolk Island",
    key: "NF",
  },
  {
    label: "Northern Mariana Islands",
    key: "MP",
  },
  {
    label: "Norway",
    key: "NO",
  },
  {
    label: "Oman",
    key: "OM",
  },
  {
    label: "Pakistan",
    key: "PK",
  },
  {
    label: "Palau",
    key: "PW",
  },
  {
    label: "Palestine, State of",
    key: "PS",
  },
  {
    label: "Panama",
    key: "PA",
  },
  {
    label: "Papua New Guinea",
    key: "PG",
  },
  {
    label: "Paraguay",
    key: "PY",
  },
  {
    label: "Peru",
    key: "PE",
  },
  {
    label: "Philippines",
    key: "PH",
  },
  {
    label: "Pitcairn",
    key: "PN",
  },
  {
    label: "Poland",
    key: "PL",
  },
  {
    label: "Portugal",
    key: "PT",
  },
  {
    label: "Puerto Rico",
    key: "PR",
  },
  {
    label: "Qatar",
    key: "QA",
  },
  {
    label: "Republic of North Macedonia",
    key: "MK",
  },
  {
    label: "Romania",
    key: "RO",
  },
  {
    label: "Russian Federation",
    key: "RU",
  },
  {
    label: "Rwanda",
    key: "RW",
  },
  {
    label: "Réunion",
    key: "RE",
  },
  {
    label: "Saint Barthélemy",
    key: "BL",
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    key: "SH",
  },
  {
    label: "Saint Kitts and Nevis",
    key: "KN",
  },
  {
    label: "Saint Lucia",
    key: "LC",
  },
  {
    label: "Saint Martin (French part)",
    key: "MF",
  },
  {
    label: "Saint Pierre and Miquelon",
    key: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines",
    key: "VC",
  },
  {
    label: "Samoa",
    key: "WS",
  },
  {
    label: "San Marino",
    key: "SM",
  },
  {
    label: "Sao Tome and Principe",
    key: "ST",
  },
  {
    label: "Saudi Arabia",
    key: "SA",
  },
  {
    label: "Senegal",
    key: "SN",
  },
  {
    label: "Serbia",
    key: "RS",
  },
  {
    label: "Seychelles",
    key: "SC",
  },
  {
    label: "Sierra Leone",
    key: "SL",
  },
  {
    label: "Singapore",
    key: "SG",
  },
  {
    label: "Sint Maarten (Dutch part)",
    key: "SX",
  },
  {
    label: "Slovakia",
    key: "SK",
  },
  {
    label: "Slovenia",
    key: "SI",
  },
  {
    label: "Solomon Islands",
    key: "SB",
  },
  {
    label: "Somalia",
    key: "SO",
  },
  {
    label: "South Africa",
    key: "ZA",
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    key: "GS",
  },
  {
    label: "South Sudan",
    key: "SS",
  },
  {
    label: "Spain",
    key: "ES",
  },
  {
    label: "Sri Lanka",
    key: "LK",
  },
  {
    label: "Sudan",
    key: "SD",
  },
  {
    label: "Suriname",
    key: "SR",
  },
  {
    label: "Svalbard and Jan Mayen",
    key: "SJ",
  },
  {
    label: "Sweden",
    key: "SE",
  },
  {
    label: "Switzerland",
    key: "CH",
  },
  {
    label: "Syrian Arab Republic",
    key: "SY",
  },
  {
    label: "Taiwan (Province of China)",
    key: "TW",
  },
  {
    label: "Tajikistan",
    key: "TJ",
  },
  {
    label: "Tanzania, United Republic of",
    key: "TZ",
  },
  {
    label: "Thailand",
    key: "TH",
  },
  {
    label: "Timor-Leste",
    key: "TL",
  },
  {
    label: "Togo",
    key: "TG",
  },
  {
    label: "Tokelau",
    key: "TK",
  },
  {
    label: "Tonga",
    key: "TO",
  },
  {
    label: "Trinidad and Tobago",
    key: "TT",
  },
  {
    label: "Tunisia",
    key: "TN",
  },
  {
    label: "Turkey",
    key: "TR",
  },
  {
    label: "Turkmenistan",
    key: "TM",
  },
  {
    label: "Turks and Caicos Islands",
    key: "TC",
  },
  {
    label: "Tuvalu",
    key: "TV",
  },
  {
    label: "Uganda",
    key: "UG",
  },
  {
    label: "Ukraine",
    key: "UA",
  },
  {
    label: "United Arab Emirates",
    key: "AE",
  },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    key: "GB",
  },
  {
    label: "United States Minor Outlying Islands",
    key: "UM",
  },
  {
    label: "United States of America",
    key: "US",
  },
  {
    label: "Uruguay",
    key: "UY",
  },
  {
    label: "Uzbekistan",
    key: "UZ",
  },
  {
    label: "Vanuatu",
    key: "VU",
  },
  {
    label: "Venezuela (Bolivarian Republic of)",
    key: "VE",
  },
  {
    label: "Viet Nam",
    key: "VN",
  },
  {
    label: "Virgin Islands (British)",
    key: "VG",
  },
  {
    label: "Virgin Islands (U.S.)",
    key: "VI",
  },
  {
    label: "Wallis and Futuna",
    key: "WF",
  },
  {
    label: "Western Sahara",
    key: "EH",
  },
  {
    label: "Yemen",
    key: "YE",
  },
  {
    label: "Zambia",
    key: "ZM",
  },
  {
    label: "Zimbabwe",
    key: "ZW",
  },
  {
    label: "Åland Islands",
    key: "AX",
  },
];

/**
 * Performs validation based on the provided checkObj, data, and error.
 *
 * @param {string[]} checkObj - List of fields to check.
 * @param {Object} data - Data object to validate.
 * @param {Object} error - Object to store validation errors.
 * @returns {Object} Object with validation results.
 */
export const onSubmitValidation = (checkObj, data, error) => {
  let errObj = error;
  let IsValid = true;
  let errorShow = true;
  checkObj?.map((item) => {
    if (!data?.[item]) {
      errObj = { ...errObj, [item]: "This field is required" };
      IsValid = false;
    } else if (item == "email") {
      // let emailregx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
      let emailregx = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/
      if (!emailregx.test(data?.[item])) {
        errObj = { ...errObj, [item]: 'Invalid email address' }
        IsValid = false
      }
    } else if (item == "ein") {
      if (!numberRegex.test(data?.[item])) {
        errObj = { ...errObj, [item]: "Must be numbers only" };
        IsValid = false;
      } else if (!isValid(data?.[item])) {
        errObj = { ...errObj, [item]: "Invalid EIN number" };
        IsValid = false;
      }
    } else if (item == 'dateOfBirth' || item == 'controllerDateOfBirth') {
      const enterDate = data?.[item]
      const date18yrsAgo = new Date()
      const date125yrsAgo = new Date()
      date18yrsAgo.setFullYear(date18yrsAgo.getFullYear() - 18)
      date125yrsAgo.setFullYear(date125yrsAgo.getFullYear() - 125)
      const valid =
        moment(enterDate).year() <= date18yrsAgo.getFullYear() &&
        moment(enterDate).year() >= date125yrsAgo.getFullYear()
      if (valid) {
        errObj = { ...errObj, [item]: '' }
        IsValid = true
      } else {
        errObj = { ...errObj, [item]: 'Invalid date' }
        IsValid = false
      }
    } else if (item == "postalCode") {
      let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
      if (!postalCoderegx.test(data?.[item])) {
        errObj = { ...errObj, [item]: "Invalid Zipcode" };
        IsValid = false;
      }
    }
    const filterCheck = Object.values(errObj)?.filter((item) => !!item);
    if (filterCheck?.length !== 0) {
      errorShow = true;
    } else {
      errorShow = false;
    }
  });

  if (Object.values(errObj))
    return { errors_obj: errObj, status: IsValid, showError: errorShow };
};

/**
 * Dummy function simulating a file upload request.
 *
 * @param {Object} options - Options object containing file and onSuccess callback.
 * @param {File} options.file - The file to be uploaded.
 * @param {Function} options.onSuccess - Callback function to be called on successful upload.
 */
export const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

/**
 * Validate input field on change and update the error object.
 *
 * @param {string} field - The name of the field being validated.
 * @param {any} value - The value of the field being validated.
 * @param {Object} error - The current error object.
 * @returns {Object} - Updated error object based on validation.
 */
export const onChangeValidation = (field, value, error) => {
  let erroObj = { ...error };
  if (value) {
    erroObj[field] = "";
  }
  if (value !== 0 && !value) {
    erroObj[field] = "This field is required";
  }
  if (field == 'passport' && value) {
    erroObj['postalCode'] = ''
    erroObj['controllerState'] = ''
  }
  if (field == 'controllerPassport' && value) {
    erroObj['controllerZip_code'] = ''
    erroObj['controllerState'] = ''
  }
  if (
    (field == 'firstName' || field == 'lastName') &&
    // || field == 'businessName'
    value
  ) {
    let regx = /^[a-zA-Z]+$/
    if (!regx.test(value?.trimStart())) {
      erroObj[field] = `${
        // field == 'firstName' ? 'First' : field == 'lastName' ? 'Last' : 'Business'
        field == 'firstName' ? 'First' : 'Last'
        } name must be character only`
    }
  }
  if (
    (field == 'controllerFirstName' || field == 'controllerLastName') &&
    value
  ) {
    let regx = /^[a-zA-Z]+$/
    if (!regx.test(value?.trimStart())) {
      erroObj[field] = `${
        field == 'controllerFirstName' ? 'Controller First' : 'Controller Last'
        } name must be character only`
    }
  }
  if (field == "email" && value) {
    // let emailregx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z-]+(?:\.[a-zA-Z0-9-]+)*$/
    let emailregx = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/
    if (!emailregx.test(value)) {
      erroObj[field] = "Invalid email address";
    }
  }
  if (field == "ein" && value) {
    if (!numberRegex.test(value)) {
      erroObj[field] = "Must be numbers only";
    } else if (!isValid(value)) {
      erroObj[field] = "Invalid EIN number";
    }
  }
  if ((field == 'postalCode' || field == 'controllerZip_code') && value) {
    let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
    if (!postalCoderegx.test(value)) {
      erroObj[field] = "Invalid Zipcode";
    }
  }
  if ((field == 'dateOfBirth' || field == 'controllerDateOfBirth') && value) {
    const enterDate = new Date(value) // Parse the entered date
    const date18yrsAgo = new Date()
    const date125yrsAgo = new Date()
    date18yrsAgo.setFullYear(date18yrsAgo.getFullYear() - 18)
    date125yrsAgo.setFullYear(date125yrsAgo.getFullYear() - 125)
    const valid = enterDate <= date18yrsAgo && enterDate >= date125yrsAgo

    if (!valid) {
      erroObj[field] = 'Invalid Date of Birth'
    } else {
      erroObj[field] = '' // Clear the error if the date is valid
    }
  }
  return erroObj;
};

export const ControllerOnChangeValidation = (field, value, error) => {
  let erroObj = { ...error };
  if (value) {
    erroObj = { ...erroObj, [field?.split(".")[1]]: "" };
  }
  if (!value) {
    erroObj = {
      ...erroObj,
      [field?.split(".")[1]]: "This field is required",
    };
  }
  if (
    (field == "controller.firstName" || field == "controller.lastName") &&
    value
  ) {
    let customField = [field?.split(".")[1]];
    let regx = /^[a-zA-Z]+$/;
    if (!regx.test(value?.trimStart())) {
      erroObj = {
        ...erroObj,
        [field?.split(".")[1]]: `${customField[0] === "firstName" ? "First" : "Last"
          } name must be character only`,
      };
    }
  }
  if (field == "address.postalCode" && value) {
    let postalCoderegx = /(^[0-9]{5}(?:-[0-9]{4})?$)/;
    if (!postalCoderegx.test(value)) {
      erroObj[field?.split(".")[1]] = "Invalid Zipcode";
    }
  }

  return erroObj;
};

/**
 * Check if the provided value contains only letters and spaces.
 *
 * @param {string} value - The value to be checked.
 * @returns {boolean} - Returns true if the value contains only letters and spaces, otherwise false.
 */
export const nameRegxCheck = (value) => {
  let newRegx = /^[A-Za-z\s]*$/;
  if (!newRegx.test(value)) {
    return false;
  }
  return true;
};

/**
 * Generate an array of years representing a range of 100 years, starting from the current year.
 *
 * @type {number[]} - An array of numbers representing years.
 */
export const getYearsList = Array.from(
  { length: 100 },
  (_, index) => new Date().getFullYear() - index
);

/**
 * Format a date for display in "MM-DD-YYYY" format, considering time zone adjustments.
 *
 * @param {Date|string} date - The date to be formatted.
 * @returns {string} - Formatted date string.
 */
export const displayClosingdate = (date) => {
  return moment(date).utc(true).local().format("MM-DD-YYYY");
};

/**
 * Convert a local date to UTC format.
 *
 * @param {Date|string} date - The local date to be converted.
 * @returns {string} - UTC formatted date string.
 */
export const convertLocalToUTC = (date) => {
  return moment(moment(date).format("YYYY-MM-DDT00:00:00"))
    .utc()
    .format("YYYY-MM-DDTHH:mm:ss");
};

export const getTinyMCEApiKey = () => {
  return process.env.REACT_APP_TINYMCE_API_KEY;
}

export function areAllErrorFieldsEmpty(error) {
  const fieldValues = Object.values(error)

  return fieldValues.every(value => {
    return typeof value === 'undefined' || value === ''
  })
}

export function findMinimum(arg1, arg2, arg3, arg4, arg5) {
  // Use the Math.min function to find the minimum value
  return Math.min(arg1, arg2, arg3, arg4, arg5);
}

export function getHighestCostAndIndex(data) {
  if (!data.length) {
    return null, null; // Return null if the data is empty
  }

  let highestCost = data[0].cost;
  let highestCostIndex = 0;

  for (let i = 1; i < data.length; i++) {
    if (data[i].cost > highestCost) {
      highestCost = data[i].cost;
      highestCostIndex = i;
    }
  }

  return {highestCost, highestCostIndex};
}

export function getHighestTotalAndIndex(data) {
  if (!data.length) {
    return null, null; // Return null if the data is empty
  }

  let highestTotal = data[0].total || 0;
  let highestTotalIndex = 0;

  for (let i = 1; i < data.length; i++) {
    if (data[i].total > highestTotal) {
      highestTotal = data[i].cost;
      highestTotalIndex = i;
    }
  }

  return {highestTotal, highestTotalIndex};
}

export function formatSize(bytes) {
  if (bytes < 1024) {
    return `${bytes} B`;
  } else if (bytes < 1024 * 1024) {
    return `${(bytes / 1024).toFixed(2)} KB`;
  } else {
    return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;   

  }
}

export function formatDate(dateString) {
  const [month, day, year] = dateString.split('-')
  return `${month}/${day}/${year}`
}

export const convertUTCToLocal = utcDateString => {
  // Get the user's local time zone (e.g., 'America/New_York', 'Asia/Kolkata', etc.)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // Parse the UTC datetime string to a Date object
  const utcDate = new Date(utcDateString)

  // Convert the UTC date to the user's local time zone using toLocaleString
  const localDateString = utcDate.toLocaleString('en-US', { timeZone: userTimeZone })
  const newDate = localDateString.split(',')[0]
  return newDate?.split('/').join('-')
}