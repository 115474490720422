import { capitalize } from "../../Utils/helper";
// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  trackingData: [],
  totalTrackings: 0,
  trackingStartupDetails: {},
  loading: false,
  trackingUpdates: [],
  trackingUpdateDetails: {},
  co_investor_logos: [],
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRACKING_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRACKING_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingData: action.payload?.data,
        totalTrackings: action.payload?.total_count,
      };
    case actionTypes.GET_TRACKING_DATA_FAILED:
      return {
        ...state,
        loading: false,
        trackingData: [],
        totalTrackings: 0,
      };
    case actionTypes.UPDATE_TRACKING_STATUS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TRACKING_STATUS_SUCCESS:
      return {
        ...state,
        trackingData: state?.trackingData?.map((item) => {
          if (item?._id?.$oid === action?.trackingId) {
            return {
              ...item,
              status: capitalize(action?.payload?.data?.status?.status),
            };
          } else {
            return item;
          }
        }),
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STATUS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_TRACKING_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_TRACKING_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingData: action.payload?.data,
        totalTrackings: action.payload.total_count,
      };
    case actionTypes.DELETE_TRACKING_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_TRACKING_STARTUP_BY_ID_INIT:
      return {
        ...state,
        loading: true,
        co_investor_logos: [...state.co_investor_logos],
      };
    case actionTypes.GET_TRACKING_STARTUP_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingStartupDetails: action.payload,
        co_investor_logos:
          action.payload.co_investor_info &&
          action.payload.co_investor_info.map((info) => {
            return { logo: info?.profile_image };
          }),
      };
    case actionTypes.GET_TRACKING_STARTUP_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        trackingStartupDetails: {},
        co_investor_logos: [...state.co_investor_logos],
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        trackingStartupDetails: {
          ...state?.trackingStartupDetails,
          approved_action: action?.approved_action,
        },
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_MEMO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_TRACKING_MEMO_SUCCESS:
      return {
        ...state,
        trackingStartupDetails: {
          ...action?.payload?.data,
          memo: action?.payload?.successData?.url,
        },
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_MEMO_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_COMPANY_LOGO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_COMPANY_LOGO_SUCCESS:
      return {
        trackingStartupDetails: {
          ...action?.payload?.data,
          profile_pic_link: action?.payload?.successData?.url,
        },
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_COMPANY_LOGO_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_BANNER_IMAGE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_BANNER_IMAGE_SUCCESS:
      return {
        trackingStartupDetails: {
          ...action?.payload?.data,
          logo: action?.payload?.successData?.url,
        },
        loading: false,
      };
    case actionTypes.UPDATE_TRACKING_STARTUP_BANNER_IMAGE_FAILED:
      return {
        ...state,
      };
    case actionTypes.UPLOAD_SLIDEDECK_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_SLIDEDECK_SUCCESS:
      return {
        ...state,
        trackingStartupDetails: {
          ...action?.payload?.data,
          slide_deck: action?.payload?.successData?.url,
        },
        loading: false,
      };
    case actionTypes.UPLOAD_SLIDEDECK_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_DEMO_VIDEO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_TRACKING_DEMO_VIDEO_SUCCESS:
      return {
        ...state,
        trackingStartupDetails: {
          ...action?.payload?.data,
          demo_video_link: action?.payload?.successData?.url,
        },
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_DEMO_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_PITCH_VIDEO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_TRACKING_PITCH_VIDEO_SUCCESS:
      return {
        ...state,
        trackingStartupDetails: {
          ...state.trackingStartupDetails,
          video_pitch_link: action?.payload?.successData?.url,
        },
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_PITCH_VIDEO_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_HELPFUL_DOCS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_TRACKING_HELPFUL_DOCS_SUCCESS:
      const obj = [
        ...action?.payload?.data?.other_docs,
        action?.payload?.successData?.url,
      ];
      return {
        ...state,
        trackingStartupDetails: {
          ...action?.payload?.data,

          other_docs: obj,
        },
        loading: false,
      };
    case actionTypes.UPLOAD_TRACKING_HELPFUL_DOCS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_TRACKING_UPDATE_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRACKING_UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        trackingUpdateDetails: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_TRACKING_UPDATE_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_TRACKING_UPDATES_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TRACKING_UPDATES_SUCCESS:
      return {
        ...state,
        trackingUpdates: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_TRACKING_UPDATES_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TRACKING_PERMISSION:
      return {
        ...state,
        trackingData: action.payload?.data,
        tracking_permission: true,
      };
    case actionTypes.REMOVE_CO_INVESTOR:
      const coInvestorInfo =
        state.trackingStartupDetails?.co_investor_info || [];
      const coInvestorLogos = state.co_investor_logos || [];

      if (!Array.isArray(coInvestorInfo) || !Array.isArray(coInvestorLogos)) {
        // Handle the case where the data is not as expected
        return state;
      }

      const newCoInvestorInfo = [...coInvestorInfo];
      const newCoInvestorlogos = [...coInvestorLogos];

      newCoInvestorInfo.splice(action.payload, 1);

      newCoInvestorlogos.splice(action.payload, 1);

      return {
        ...state,
        loading: false,
        trackingStartupDetails: {
          ...state.trackingStartupDetails,
          co_investor_info: newCoInvestorInfo,
        },
        co_investor_logos: newCoInvestorlogos,
      };

    case actionTypes.UPDATE_INVESTOR_ICON_INIT:
      return {
        ...state,
        loading: true,
        co_investor_logos: [...state.co_investor_logos],
      };

    case actionTypes.UPDATE_INVESTOR_ICON_SUCCESS:
      const newCoInvestorLogos = [...state.co_investor_logos];
      newCoInvestorLogos.splice(action.payload.index, 1, {
        logo: action.payload.successData.url,
      });
      return {
        ...state,
        loading: false,
        co_investor_logos: newCoInvestorLogos,
      };

    case actionTypes.UPDATE_INVESTOR_ICON_FAILED:
      return {
        ...state,
        loading: false,
        co_investor_logos: [...state.co_investor_logos],
      };

      case actionTypes.ADD_NEW_CO_INVESTOR_LOCALLY:
        return {
          ...state,
          loading: false,
          trackingStartupDetails:{
            ...state.trackingStartupDetails,
            co_investor_info: action.payload
          }
        }
    default:
      return state;
  }
};

export default store;
