import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, message, Pagination, Row, Select, Empty, Spin } from "antd";
import * as examActions from "../../../../../Redux/Actions/ReadinessExamActions";
import pause from "../../../../../Assets/Images/pause.svg";
import play from "../../../../../Assets/Images/play.svg";
import trash from "../../../../../Assets/Images/delete.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import add from "../../../../../Assets/Images/add.svg";
import left from "../../../../../Assets/Images/leftArrow.svg";
import right from "../../../../../Assets/Images/rightArrow.svg";
import exam from "../../../../../Assets/Images/readinessExam.svg";
import userIcon from "../../../../../Assets/Images/user.png";
import clockIcon from "../../../../../Assets/Images/time.png";
import eyeIcon from "../../../../../Assets/Images/eye.png";
import "./ReadinessExams.scss";
import moment from "moment";

const { Option } = Select;
const ReadinessExams = ({
  examsData,
  totalExamsCount,
  searchText,
  getExamsData,
  toggleExamAction,
  deleteExam,
  loading,
  history,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [page, setPage] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    // Fetches exam data based on the current pagination parameters and search text.
    let data = {
      page_num: page,
      page_size: pageSize,
      ...(searchText !== "" &&
        searchText !== null &&
        searchText !== undefined && {
        is_search: true,
        search_text: searchText,
      }),
      learning_type: "series65",
    };
    const timeoutId = setTimeout(() => {
      getExamsData(user?.id, data);
    }, 500); // Adjust delay as needed
    return () => clearTimeout(timeoutId);
  }, [page, pageSize, fetchData, searchText]);

  /**
   * Toggles the active state of the specified exam.
   * @param {Event} e - The click event.
   * @param {object} exam - The exam object.
   */
  const onToggleExamAction = (e, exam) => {
    e.stopPropagation();
    let data = {
      is_active: !exam?.is_active,
      exam_id: exam?.id,
    };
    toggleExamAction(data, user?.id)
      .then(() => {
        message.success("Updated..");
      })
      .catch((e) => {
        message.error(e || "Something went wrong..");
      });
  };

  /**
   * Deletes the specified exam.
   * @param {Event} e - The click event.
   * @param {string} id - The ID of the exam to be deleted.
   */
  const onDeleteExam = (e, id) => {
    e.stopPropagation();
    let data = {
      exam_id: id,
      delete: true,
    };
    deleteExam(data, user?.id)
      .then(() => {
        setFetchData(!fetchData);
        message.success("Deleted");
      })
      .catch((e) => {
        message.error(e || "Failed to delete");
      });
  };

  /**
   * Navigates to the edit page for the specified exam.
   * @param {Event} e - The click event.
   * @param {object} item - The exam object.
   */
  const onEditExam = (e, item) => {
    e.stopPropagation();
    history.push(`/education/learn-series65/exam-edit/${item?.id}`);
  };

  /**
   * Renders custom elements for the pagination component.
   * @param {number} current - The current page number.
   * @param {string} type - The type of navigation element ('prev', 'next', or 'page').
   * @param {ReactNode} originalElement - The original React element for the navigation.
   * @returns {ReactNode} - The modified React element for the navigation.
   */
  const itemRender = (current, type, originalElement) => {
    if (type === "prev") {
      return <img className="prev-arrow" src={left} alt="previous" />;
    }
    if (type === "next") {
      return <img className="next-arrow" src={right} alt="next" />;
    }
    return originalElement;
  };

  return (
    <div className="exams-container">
      <div className="exams-header">
        <h6>Readiness Exam</h6>
        <div className="header-right">
          <p onClick={() => history.push("/education/learn-series65/add-exam")}>
            <img src={add} alt="add" />
            <span>Add New</span>
          </p>
          <Select
            defaultValue={10}
            className="selection-menu"
            onChange={(value) => setPagesize(value)}
          >
            <Option value={10}>10</Option>
            <Option value={50}>50</Option>
          </Select>
        </div>
      </div>
      <div className="exam-list-groups">
        {loading ? (
          <Spin />
        ) : (
          examsData?.map((item, index) => (
            <Row
              className="single-list"
              onClick={() =>
                history?.push(
                  // `/education/learn-series65/exam-preview/${item?.id}`
                  `/education/learn-series65/exam-detail/${item?.id}`,
                  { searchPlaceholder: "Search Exam", back: true}
                )
              }
            >
              <Col span={1}>
                <div className="icon-box">
                  <img src={exam} alt="exam" />
                </div>
              </Col>
              <Col span={16} className="title">
                <span>Readiness Exam {(page - 1) * pageSize + index + 1}</span>
                <h6>{item?.title}
                  {item?.is_draft ? <span className="draftBadge">Draft</span> :
                    item?.publish_date && moment.utc(item?.publish_date).local().isAfter(moment()) ? <span className="publishBadge">
                      <img src={clockIcon} alt="clock" />
                      {`Schedule Publish on: ${moment.utc(item?.publish_date).local().format('D MMMM, YYYY [at] HH:mm:ss')}`}
                    </span> : null}
                </h6>
                <Row gutter={20}>
                  <Col span={16} className="author-details">
                    <span>
                      <img src={userIcon} alt="user" /> By {item?.created_by}
                      <img src={clockIcon} alt="clock" /> {item?.created}
                      <img src={eyeIcon} alt="eye" /> Views :
                      {item?.total_views || 0}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col span={3} offset={2} className="icon-group">
                <img
                  src={item?.is_active ? pause : play}
                  alt="Pause"
                  onClick={(e) => onToggleExamAction(e, item)}
                />
                <img
                  src={edit}
                  alt="Edit"
                  onClick={(e) => onEditExam(e, item)}
                />
                <img
                  src={trash}
                  alt="Delete"
                  onClick={(e) => onDeleteExam(e, item?.id)}
                />
              </Col>
            </Row>
          ))
        )}
      </div>
      {examsData?.length === 0 && !loading && <Empty description="No Exams" />}
      {totalExamsCount > 0 && !loading && (
        <div className="pagination">
          <Pagination
            total={totalExamsCount}
            itemRender={itemRender}
            pageSize={pageSize}
            current={page}
            onChange={(page) => setPage(page)}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    examsData: state.ReadinessExam.examsData,
    totalExamsCount: state.ReadinessExam.totalExamsCount,
    loading: state.ReadinessExam.loading,
    searchText: state.Search.searchText,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamsData: (id, data) => dispatch(examActions.getExamsData(id, data)),
    toggleExamAction: (data, id) =>
      dispatch(examActions.toggleExamAction(data, id)),
    deleteExam: (data, id) => dispatch(examActions.deleteExam(data, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadinessExams);
