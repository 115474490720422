import React, { useEffect, useRef, useState } from "react";
import "./EditPodcast.scss";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  getPodcastDetails,
  addMarktingMediaData,
  editPodcast,
  getMarktingMediaData,
} from "../../../Redux/Actions/MarketingActions";
import {
  Button,
  Col,
  Upload,
  Popover,
  Input,
  message,
  Row,
  Spin,
  Empty,
  DatePicker,
  Select,
  Popconfirm,
} from "antd";
import moment from "moment";
import axiosInstance from "../../../Utils/api";
import { CloseOutlined, CopyOutlined, UploadOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import TextArea from "antd/lib/input/TextArea";
import {
  getFileNameFromUrl,
  range,
  IMAGE_SIZE_ERR,
  IMAGE_SIZE_LIMIT,
  dummyRequest,
  getTinyMCEApiKey,
} from "../../../Utils/helper";
import Delete from "../../../Assets/Images/delete.svg";
import userProfile from "../../../Assets/Images/user-profile.svg";
import camera from "../../../Assets/Images/camera.svg";
import upload from "../../../Assets/Images/upload.svg";
import saved from "../../../Assets/Images/saved.svg";
const EditPodcast = ({
  mediaData,
  match,
  podcastDetail,
  getPodcastDetails,
  getMarktingMediaData,
  addMarktingMediaData,
  editPodcast,
  history,
  editPodcastLoading,
}) => {
  const { mediaDetails } = mediaData;
  const podcastId = match.params.podcastId;
  const editorRef = useRef(null);
  const [hostProfilePicLink, setHostProfilePicLink] = useState();
  const [hostProfilePicfile, setHostProfilePicfile] = useState();
  const [guestProfilePicLink, setGuestProfilePicLink] = useState();
  const [guestProfilePicfile, setGuestProfilePicfile] = useState();
  const [bannerLink, setBannerLink] = useState();
  const [bannerImageFile, setBannerImageFile] = useState();
  const [podcastDetails, setPodcastDetails] = useState({});
  const [uploadMediaModalOpen, setUploadMediaModalOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaName, setMediaName] = useState();
  const [mediaFile, setMediaFile] = useState();
  const [guestDetails, setGuestDetails] = useState({});
  const [uploadMediaLoading, setUploadMediaLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState();
  const [editotValue, setEditorValue] = useState("");
  const [savedMedia, setSaveMedia] = useState({});
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [content, setContent] = useState();
  const [showValidation, setShowValidation] = useState("");
  const [errors, setErrors] = useState("");
  const [isDraft, setIsDraft] = useState(false);

  // Fetch podcast details and marketing media data.
  useEffect(() => {
    getPodcastDetails(podcastId);
    getMarktingMediaData();
  }, []);

  // Update the saved media details when mediaDetails change.
  useEffect(() => {
    setSaveMedia(mediaDetails);
  }, [mediaDetails]);

  /**
   * Function to validate the podcast details form.
   * @returns {boolean} - Returns true if the form is valid, otherwise false.
   */
  const validateForm = () => {
    let isValid = true;
    let errObj = {};
    if (!podcastDetails?.host_first_name) {
      errObj.host_first_name = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.host_last_name) {
      errObj.host_last_name = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.podcast_title) {
      errObj.podcast_title = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.podcast_subtitle) {
      errObj.podcast_subtitle = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.podcast_category) {
      errObj.podcast_category = "This field is required";
      isValid = false;
    }

    if (!podcastDetails?.summary) {
      errObj.summary = "This field is required";
      isValid = false;
    }
    if (
      content == "<p><br></p>" ||
      content == '<p><br data-mce-bogus="1"></p>'
    ) {
      errObj.format = "This field is required";
      isValid = false;
    }
    if (!podcastDetails?.audio) {
      errObj.audio = "Media Upload required";
      isValid = false;
    }
    if (!bannerLink) {
      errObj.bannerLink = "Banner is required";
      isValid = false;
    }
    setErrors(errObj);
    if (!isValid) {
      setShowValidation(true);
    } else {
      setShowValidation(false);
    }
    return isValid;
  };

  // Populate podcast details, media, and guest details when podcastDetail changes.
  useEffect(() => {
    let mediasInfo = [];

    Object?.entries(podcastDetail?.media || {})?.map((item) => {
      let a = {
        name: item[0],
        url: item[1],
      };
      mediasInfo.push(a);
      return item;
    });
    setHostProfilePicLink(podcastDetail?.profile_pic);
    setBannerLink(podcastDetail?.banner_image);
    setPodcastDetails({
      podcast_category: podcastDetail?.category,
      podcast_title: podcastDetail?.title,
      podcast_subtitle: podcastDetail?.subtitle,
      summary: podcastDetail?.summary,
      host_first_name: podcastDetail?.author?.split(" ")[0],
      host_last_name: podcastDetail?.author?.split(" ")[1],
      audio: podcastDetail?.audio,
    });
    podcastDetail?.publish_date &&
      setSchedulePublishTime(moment(podcastDetail?.publish_date));
    setMedias(mediasInfo);
    setGuestDetails({ ...podcastDetail?.guest_speaker });
    setGuestProfilePicLink(podcastDetail?.guest_speaker?.guest_profile_image);
    setEditorValue(podcastDetail?.body);
  }, [podcastDetail]);

  /**
   * Function to set the selected host profile picture file.
   * @param {File} file - The selected host profile picture file.
   */
  const onProfileSelect = (file) => {
    setHostProfilePicfile(file);
  };

  /**
   * Function to set the selected guest profile picture file.
   * @param {File} file - The selected guest profile picture file.
   */
  const onGuestProfileSelect = (file) => {
    setGuestProfilePicfile(file);
  };

  /**
   * Function to upload the host's profile picture.
   * @description - It sends the selected file to the server and updates the host profile picture link.
   */
  const onUploadHostProfile = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", hostProfilePicfile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setHostProfilePicLink(res?.data?.url);
        setHostProfilePicfile();
        setUploadLoading(false);
        setGuestDetails({
          ...guestDetails,
          guest_profile_image: res?.data?.url,
        });
        message.success("Profile picture uploaded successfully");
      })
      .catch((e) => {
        setHostProfilePicfile();
        setUploadLoading(false);
        message.error("Profile picture upload failed..");
      });
  };

  /**
   * Function to upload the guest's profile picture.
   * @description - It sends the selected file to the server and updates the guest profile picture link.
   */
  const onUploadGuestProfile = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", guestProfilePicfile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setGuestProfilePicLink(res?.data?.url);
        setGuestProfilePicfile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Profile picture uploaded successfully");
      })
      .catch((e) => {
        setGuestProfilePicfile();
        setEditorValue(content);
        setUploadLoading(false);
        message.error("Profile picture upload failed..");
      });
  };

  /**
   * Function to handle the selection of a banner image file.
   * @description - Check for validation errors and update accordingly when a banner image is selected.
   * @param {File} file - The selected banner image file.
   */
  const onBannerSelect = (file) => {
    if (file && errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "";
      setErrors(erroObj);
    }
    if (!file && !errors["bannerLink"]) {
      let erroObj = { ...errors };
      erroObj["bannerLink"] = "This field is required";
      setErrors(erroObj);
    }
    setBannerImageFile(file);
  };
  /**
   * Function to upload the banner image.
   * @description - It sends the selected banner image file to the server and updates the banner image link.
   */
  const onUploadBanner = () => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", bannerImageFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setBannerLink(res?.data?.url);
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.success("Banner uploaded successfully");
      })
      .catch((e) => {
        setBannerImageFile();
        setUploadLoading(false);
        setEditorValue(content);
        message.error("Banner upload failed..");
      });
  };

  /**
   * Function to update a field in the podcast details.
   * @param {string} field - The field to be updated.
   * @param {any} value - The new value for the field.
   */
  const onPodcastFieldUpdate = (field, value) => {
    setPodcastDetails({ ...podcastDetails, [field]: value });
    if (value && errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "";
      setErrors(erroObj);
    }
    if (!value && !errors[field]) {
      let erroObj = { ...errors };
      erroObj[field] = "This field is required";
      setErrors(erroObj);
    }
  };

  /**
   * Function to update a field in the guest details.
   * @param {string} field - The field to be updated.
   * @param {any} value - The new value for the field.
   */
  const onGuestFieldUpdate = (field, value) => {
    setGuestDetails({ ...guestDetails, [field]: value });
  };

  /**
   * Function to upload media.
   * @description - It sends the selected media file to the server, updates media details, and saves it in the state.
   */
  const onUploadMedia = () => {
    setUploadMediaLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", mediaFile);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        setMedias(
          (medias || [])?.concat({ name: mediaName, url: res.data?.url })
        );
        addMarktingMediaData({
          marketing_saved_media: { ...savedMedia, [mediaName]: res.data?.url },
        });
        setMediaName();
        setMediaFile();
        setUploadMediaLoading(false);
        setEditorValue(content);
        message.success("Media uploaded successfully");
      })
      .catch((e) => {
        setMediaFile();
        setEditorValue(content);
        setUploadMediaLoading(false);
        message.error("Media upload failed..");
      });
  };

  // Function to close the schedule publish modal and clear the scheduled publish time.
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    setSchedulePublishTime();
  };

  /**
   * Function to disable the range of time options for scheduling based on the selected date.
   * @param {Date} current - The current date and time.
   * @deprecated - Calculate the disabled hours and minutes based on the selected date.
   */
  const disabledRangeTime = (current) => {
    setSchedulePublishTime(current);
    const time = new Date();
    const selectedDate = moment(current).date() || new Date();
    const currDate = new Date().getDate();
    if (!current || selectedDate === currDate) {
      return {
        disabledHours: () => range(0, time.getHours()),
        disabledMinutes: () => range(0, time.getMinutes()),
      };
    }
  };

  /**
   * Function to update the podcast details and initiate the podcast edit process.
   * @param {boolean} isDraft - Indicates whether the podcast is a draft or not.
   * @description - Call the API to edit the podcast and handle success and error cases
   */
  const onUpdatePodcast = (isDraft) => {
    if (
      (Object.values(podcastDetails).some((item) => item == "" || !item) ||
        Object.values(podcastDetails).length < 7 ||
        !bannerLink ||
        bannerLink === "" ||
        !editotValue ||
        editotValue == "") &&
      !isDraft
    ) {
      message.error("Please complete missing podcast fields", 2);
      return;
    } else if (
      !isDraft &&
      guestProfilePicLink &&
      guestDetails?.guest_profile_image &&
      !guestDetails?.guest_first_name &&
      !guestDetails?.guest_last_name
    ) {
      message.error("Please add the guest’s first name and last name");
      return;
    } else if (
      !isDraft &&
      (guestProfilePicLink || guestDetails?.guest_profile_image) &&
      !guestDetails?.guest_last_name
    ) {
      message.error("Please add the guest’s last name");
      return;
    } else if (
      !isDraft &&
      (guestProfilePicLink || guestDetails?.guest_profile_image) &&
      !guestDetails?.guest_first_name
    ) {
      message.error("Please add the guest’s first name");
      return;
    } else if (
      !isDraft &&
      guestDetails?.guest_first_name &&
      !guestDetails?.guest_last_name
    ) {
      message.error("Please add the guest’s Last name");
      return;
    } else if (
      !isDraft &&
      guestDetails?.guest_last_name &&
      !guestDetails?.guest_first_name
    ) {
      message.error("Please add the guest’s first name");
      return;
    } else if (
      (isDraft && podcastDetails?.podcast_title === "") ||
      !podcastDetails?.podcast_title
    ) {
      message.error("Podcast Title must be required to save draft");
      return;
    }
    let mediasInfo = {};
    medias?.map((item) => {
      mediasInfo[item?.name] = item?.url;
      return item;
    });
    let data = {
      banner_image: bannerLink,
      profile_pic: hostProfilePicLink,
      title: podcastDetails?.podcast_title?.trimEnd(),
      subtitle: podcastDetails?.podcast_subtitle?.trimEnd(),
      summary: podcastDetails?.summary?.trimEnd(),
      media: mediasInfo,
      audio: podcastDetails?.audio,
      category: podcastDetails?.podcast_category,
      author_first_name: podcastDetails?.host_first_name?.trimEnd(),
      author_last_name: podcastDetails?.host_last_name?.trimEnd(),
      guest_speaker: {
        ...guestDetails,
        ...(guestProfilePicLink && {
          guest_profile_image: guestProfilePicLink,
        }),
      },
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      body: content,
    };
    editPodcast(data, podcastId)
      .then(() => {
        setIsDraft(false);
        message.success("Podcast Edited Successfully");
        history.push("/marketing");
      })
      .catch((e) => {
        message.error(e || "Edit Podcast Failed", 2);
      });
  };

  // Function to fetch marketing media data.
  const onSavedMedia = () => {
    getMarktingMediaData();
  };

  /**
   * Function to handle the change of the audio file for the podcast.
   * @param {File} file - The selected audio file.
   */
  const onAudioFileChange = (file) => {
    if (file) {
      if (file.size > 200000000) {
        message.error("Audio file must not be more than 15 MB.");
        return;
      } else if (file.type !== "audio/mpeg" && file.type !== "audio/wav") {
        message.error("Please upload a WAV or MP3 file only.");
        return;
      }
      setUploadLoading(true);
      let data = new FormData();
      data.append("type", "media");
      data.append("file_obj", file);
      axiosInstance
        .post("admin/mime-files", data)
        .then((res) => {
          onPodcastFieldUpdate("audio", res?.data?.url);
          setUploadLoading(false);
        })
        .catch((e) => {
          setUploadLoading(false);
          message.error("Failed to upload.");
        });
    }
  };

  /**
   * Modal for uploading media with an option to enter the media name and select a file.
   * @description - The media file can be uploaded with the "Upload" button.
   */
  const uploadMediaModal = (
    <Modal
      visible={uploadMediaModalOpen}
      title={"Upload Media"}
      onCancel={() => setUploadMediaModalOpen(false)}
      centered
      className="upload-media-modal"
      okText="Upload"
      onOk={() => onUploadMedia()}
    >
      {uploadMediaLoading ? (
        <Spin />
      ) : (
        <>
          <div className="media-name">
            <label>Media Name</label>
            <Input
              placeholder="Enter Media Name"
              value={mediaName}
              onChange={(e) => setMediaName(e.target.value)}
            />
          </div>
          {!mediaFile ? (
            <Upload
              accept="image/*,audio/*,video/*"
              onChange={(file) => setMediaFile(file.file.originFileObj)}
            >
              <Button className="select-media-btn">Select Media File</Button>
            </Upload>
          ) : (
            <span className="filename">
              {mediaFile?.name} <CloseOutlined onClick={() => setMediaFile()} />
            </span>
          )}
        </>
      )}
    </Modal>
  );

  /**
   * Function to delete a saved media item by key.
   * @param {string} key - The key of the media item to be deleted.
   * @description - Remove the saved media item from the state and update the saved media data.
   */
  const onDeleteMedia = (key) => {
    delete savedMedia[key];
    addMarktingMediaData({ marketing_saved_media: { ...savedMedia } });
  };

  // Content for the saved media popup that displays saved media items with options to copy and delete them.
  const savedMediaContent = (
    <div className="saved-media-popup">
      <div className="header">
        <h4>Saved Media</h4>
      </div>
      {Object.keys(mediaDetails)?.map((item) => (
        <Row gutter={8} align="middle">
          <Col span={6}>
            <span className="media-name">{item}</span>
          </Col>
          <Col span={14}>
            <span className="media-url">
              {mediaDetails[item]?.slice(0, 50) + "..."}
            </span>
          </Col>
          <Col span={2}>
            <CopyToClipboard
              text={mediaDetails[item]}
              onCopy={() => message.success("Copied to clipboard")}
            >
              <CopyOutlined />
            </CopyToClipboard>
          </Col>
          <Col span={2}>
            <span onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title="Are you sure?"
                onConfirm={() => onDeleteMedia(item)}
                okText="Yes"
                cancelText="No"
                className="delete-popconfirm "
                okButtonProps={{ style: { marginRight: "12px" } }}
              >
                <img src={Delete} alt="trash" style={{ cursor: "pointer" }} />
              </Popconfirm>
            </span>
          </Col>
        </Row>
      ))}
      {Object.keys(mediaDetails)?.length === 0 && (
        <Empty description={"No Saved Media"} />
      )}
    </div>
  );

  /**
   * Modal for scheduling the publish date and time of a podcast.
   * @description - Users can select the schedule, and the podcast can be published with the "Publish" button.
   */
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => onUpdatePodcast(false)}
      className="schedule-publish-modal"
      okButtonProps={{ loading: editPodcastLoading }}
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>

        <DatePicker
          format={"MM-DD-YYYY HH:mm:ss"}
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showNow={true}
          defaultValue={
            schedulePublishTime
              ? moment(schedulePublishTime).utc(true).local()
              : null
          }
          showTime
          disabledDate={(current) =>
            current.isBefore(moment().subtract(1, "day"))
          }
          disabledTime={(current) => disabledRangeTime(current)}
        />
      </div>
    </Modal>
  );

  return (
    <div className="edit-podcast-container">
      <div className="top-add">
        <h6>Edit Podcast</h6>
        <div className="add-right">
          <Button
            className="save-btn"
            loading={isDraft && editPodcastLoading}
            onClick={() => {
              setErrors("");
              setIsDraft(true);
              onUpdatePodcast(true);
            }}
          >
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => {
              validateForm() && setOpenschedulePublishModal(true);
            }}
          >
            Schedule Publish
          </Button>
          <Button
            className="publish-btn"
            loading={!isDraft && editPodcastLoading}
            onClick={() => {
              validateForm() && onUpdatePodcast(false);
            }}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="edit-podcast-wrap">
        {uploadLoading ? (
          <Spin />
        ) : (
          <Row gutter={16}>
            <Col span={4}>
              <div className="profile-icon">
                {hostProfilePicLink ? (
                  <img
                    src={hostProfilePicLink || userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : hostProfilePicfile ? (
                  <img
                    src={URL.createObjectURL(hostProfilePicfile)}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                ) : (
                  <img
                    src={userProfile}
                    alt="profile"
                    style={{ borderRadius: "50%" }}
                    width="100px"
                    height="100px"
                  />
                )}

                <Upload
                  customRequest={dummyRequest}
                  onChange={async (file) => {
                    if (file?.file?.status === "uploading") {
                      if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                        message.error(IMAGE_SIZE_ERR, 2);
                        return;
                      }
                      onProfileSelect(file.file.originFileObj);
                    }
                  }}
                  accept="image/png,image/jpeg"
                >
                  <div className="camera-icon-wrap">
                    <img
                      src={camera}
                      alt="profile"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </Upload>
              </div>
              {hostProfilePicfile && (
                <Button
                  className="upload-profile-btn"
                  onClick={() => onUploadHostProfile()}
                >
                  Upload
                </Button>
              )}
            </Col>
            <Col span={12}>
              <Row>
                <Col span={6} offset={12}>
                  <Popover
                    content={savedMediaContent}
                    placement={"bottomRight"}
                    trigger={"click"}
                  >
                    <Button
                      className="upload-btn"
                      icon={<img src={saved} alt="saved" />}
                      onClick={onSavedMedia}
                    >
                      Saved Media
                    </Button>
                  </Popover>
                </Col>
                <Col span={6}>
                  <Button
                    className="upload-btn"
                    onClick={() => setUploadMediaModalOpen(true)}
                    icon={<img src={upload} alt="upload" />}
                  >
                    Upload Media
                  </Button>
                </Col>
              </Row>
              <Row>
                <div className="upload-banner">
                  {bannerLink ? (
                    <>
                      <div className="banner-image-wrap">
                        <img src={bannerLink} alt="banner"></img>
                      </div>
                      {!bannerImageFile && (
                        <Upload
                          customRequest={dummyRequest}
                          onChange={async (file) => {
                            if (file?.file?.status === "uploading") {
                              if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                                message.error(IMAGE_SIZE_ERR, 2);
                                return;
                              }
                              onBannerSelect(file.file.originFileObj);
                            }
                          }}
                          accept="image/*"
                        >
                          <Button className="upload-banner-btn">
                            Re-Upload Banner
                          </Button>
                        </Upload>
                      )}
                    </>
                  ) : bannerImageFile ? (
                    <div className="banner-image-wrap">
                      <img
                        src={URL.createObjectURL(bannerImageFile)}
                        alt="banner"
                      />
                    </div>
                  ) : (
                    <Upload
                      customRequest={dummyRequest}
                      onChange={async (file) => {
                        if (file?.file?.status === "uploading") {
                          if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                            message.error(IMAGE_SIZE_ERR, 2);
                            return;
                          }
                          onBannerSelect(file.file.originFileObj);
                        }
                      }}
                      accept="image/*"
                    >
                      <div className="upload-inner">
                        <span>
                          <UploadOutlined /> Upload Banner Image
                        </span>
                      </div>
                    </Upload>
                  )}
                </div>

                <div>
                  <div>
                    {showValidation && (
                      <div style={{ color: "red" }}>{errors["bannerLink"]}</div>
                    )}
                  </div>
                  <div className="desc">Banner should be 1073px by 576px</div>
                </div>
                {bannerImageFile && (
                  <Button
                    className="upload-banner-btn"
                    onClick={() => onUploadBanner()}
                  >
                    Upload Banner
                  </Button>
                )}
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Host First name</label>
                    <Input
                      value={podcastDetails?.host_first_name}
                      placeholder={"First name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "host_first_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.host_first_name?.length || 0
                        }/15`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["host_first_name"]}
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Host Last name</label>
                    <Input
                      value={podcastDetails?.host_last_name}
                      placeholder={"Last name"}
                      className="inputs-box"
                      maxLength={15}
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "host_last_name",
                          e.target.value?.trimStart()
                        )
                      }
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.host_last_name?.length || 0
                        }/15`}</p>
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["host_last_name"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Podcast Title</label>
                    <Input
                      value={podcastDetails?.podcast_title}
                      placeholder={"Title"}
                      maxlength={47}
                      className="inputs-box"
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.podcast_title?.length || 0
                        }/47`}</p>
                      }
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "podcast_title",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["podcast_title"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Subtitle</label>
                    <Input
                      value={podcastDetails?.podcast_subtitle}
                      placeholder={"Subtitle"}
                      maxlength={55}
                      className="inputs-box"
                      suffix={
                        <p className="podcast-input">{`${
                          podcastDetails?.podcast_subtitle?.length || 0
                        }/55`}</p>
                      }
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "podcast_subtitle",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["podcast_subtitle"]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="field-box">
                    <label>Category</label>
                    <Select
                      size="large"
                      placeholder="Category"
                      className="selection-option"
                      optionFilterProp="children"
                      showSearch
                      value={podcastDetails?.podcast_category}
                      showArrow
                      onChange={(value) =>
                        onPodcastFieldUpdate("podcast_category", value)
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {[
                        "Educational",
                        "News",
                        "Interview",
                        "Startup",
                        "Venture Capital",
                      ]?.map((item) => {
                        return (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {showValidation && (
                      <span style={{ color: "red" }}>
                        {errors["podcast_category"]}
                      </span>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <div className="field-box">
                    <label>Audio</label>
                    {podcastDetails?.audio ? (
                      <span className="file-title" style={{ display: "flex" }}>
                        {getFileNameFromUrl(podcastDetails?.audio)}
                        <CloseOutlined
                          onClick={() => onPodcastFieldUpdate("audio", null)}
                        />
                      </span>
                    ) : (
                      <Upload
                        accept="audio/*"
                        showUploadList={false}
                        beforeUpload={() => false}
                        customRequest={dummyRequest}
                        onChange={(info) => onAudioFileChange(info.file)}
                      >
                        <Button
                          className="upload-btn"
                          icon={<img src={upload} alt="upload" />}
                        >
                          Upload Audio
                        </Button>
                      </Upload>
                    )}
                  </div>
                  {showValidation && (
                    <span style={{ color: "red" }}>{errors["audio"]}</span>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <div className="field-box">
                    <label>Short Summary</label>
                    <TextArea
                      rows={3}
                      value={podcastDetails?.summary}
                      placeholder={"Short summary"}
                      maxLength={217}
                      showCount
                      bordered={false}
                      className="inputs-box"
                      onChange={(e) =>
                        onPodcastFieldUpdate(
                          "summary",
                          e.target.value?.trimStart()
                        )
                      }
                    />
                    {showValidation && (
                      <span style={{ color: "red" }}>{errors["summary"]}</span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Editor
                apiKey={getTinyMCEApiKey()}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(a, b) => {
                    setEditorValue(a);
                    const content = b.getContent({ format: "raw" });
                    setContent(content);
                    if (
                      content != "<p><br></p>" &&
                      content != '<p><br data-mce-bogus="1"></p>' &&
                      errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "";
                      setErrors(erroObj);
                    }
                    if (
                      (content == "<p><br></p>" ||
                        content == '<p><br data-mce-bogus="1"></p>') &&
                      !errors["format"]
                    ) {
                      let erroObj = { ...errors };
                      erroObj["format"] = "This field is required";
                      setErrors(erroObj);
                    }
                  }}
                  init={{
                    height: 500,
                    selector: "textarea",
                    plugins:
                      "print preview importcss searchreplace autosave save directionality code fullpage visualblocks visualchars fullscreen image media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons bbcode toc link",
                    menubar: "file edit view insert format tools table help",
                    toolbar:
                      "fullpage | image media link | fullscreen preview |formatselect fontsizeselect fontselect | emoticons charmap hr| bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify bullist numlist outdent indent undo redo removeformat| searchreplace wordcount | help",
                    toolbar_mode: "wrap",
                    block_formats:
                      "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    setup: function (editor) {
                      editor.addShortcut(
                        "alt+shift+1",
                        "H1 is Permanently Disabled",
                        function () {
                          editor.execCommand();
                        }
                      );
                    },
                  }}
                  value={editotValue}
                />
                {showValidation && (
                  <span style={{ color: "red" }}>{errors["format"]}</span>
                )}
              </Row>
            </Col>
            <Col span={8}>
              <div className="guest-speaker">
                <h4>Guest Speaker</h4>
                <div className="profile-icon">
                  {guestProfilePicLink ? (
                    <img
                      src={guestProfilePicLink || userProfile}
                      alt="profile"
                      style={{ borderRadius: "50%" }}
                      width="100px"
                      height="100px"
                    />
                  ) : guestProfilePicfile ? (
                    <img
                      src={URL.createObjectURL(guestProfilePicfile)}
                      alt="profile"
                      style={{ borderRadius: "50%" }}
                      width="100px"
                      height="100px"
                    />
                  ) : (
                    <img
                      src={userProfile}
                      alt="profile"
                      style={{ borderRadius: "50%" }}
                      width="100px"
                      height="100px"
                    />
                  )}

                  <Upload
                    customRequest={dummyRequest}
                    onChange={async (file) => {
                      if (file?.file?.status === "uploading") {
                        if (file?.file?.size >= IMAGE_SIZE_LIMIT) {
                          message.error(IMAGE_SIZE_ERR, 2);
                          return;
                        }
                        onGuestProfileSelect(file.file.originFileObj);
                      }
                    }}
                    accept="image/png,image/jpeg"
                  >
                    <div className="camera-icon-wrap">
                      <img
                        src={camera}
                        alt="profile"
                        width="20px"
                        height="20px"
                      />
                    </div>
                  </Upload>
                </div>
                {guestProfilePicfile && (
                  <Button
                    className="upload-profile-btn"
                    onClick={() => onUploadGuestProfile()}
                  >
                    Upload
                  </Button>
                )}
                <div className="field-box">
                  <label>Guest First name</label>
                  <Input
                    value={guestDetails?.guest_first_name}
                    placeholder={"First name"}
                    className="inputs-box"
                    maxLength={15}
                    onChange={(e) =>
                      onGuestFieldUpdate(
                        "guest_first_name",
                        e.target.value?.trimStart()
                      )
                    }
                    suffix={
                      <p className="podcast-input">{`${
                        guestDetails?.guest_first_name?.length || 0
                      }/15`}</p>
                    }
                  />
                </div>
                <div className="field-box">
                  <label>Guest Last name</label>
                  <Input
                    value={guestDetails?.guest_last_name}
                    placeholder={"Last name"}
                    className="inputs-box"
                    maxLength={15}
                    onChange={(e) =>
                      onGuestFieldUpdate(
                        "guest_last_name",
                        e.target.value?.trimStart()
                      )
                    }
                    suffix={
                      <p className="podcast-input">{`${
                        guestDetails?.guest_last_name?.length || 0
                      }/15`}</p>
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {uploadMediaModal}
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    podcastDetail: state.Marketing.podcastDetails,
    editPodcastLoading: state.Marketing.editPodcastLoading,
    mediaData: state.Marketing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPodcastDetails: (id) => dispatch(getPodcastDetails(id)),
    addMarktingMediaData: (data) => dispatch(addMarktingMediaData(data)),
    editPodcast: (data, id) => dispatch(editPodcast(data, id)),
    getMarktingMediaData: () => dispatch(getMarktingMediaData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPodcast);
