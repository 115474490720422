import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";
import { message } from "antd";

// Action to get startups data
export const getStartupsData = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_STARTUPS_DATA_INIT,
    });
    await axios
      .post("admin/filter", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_STARTUPS_DATA_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess();
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_STARTUPS_DATA_FAILED,
          payload: error?.response?.data?.error,
        });
        onFail && onFail();
        throw error?.response?.data?.error;
      });
  };
};

// Function to handle investor call for waitlist permissions
const investorCall = async (dispatch, navigate, flag) => {
  axios
    .post("admin/filter", { is_inv: true })
    .then((res1) => {
      if (res1?.data) {
        localStorage.setItem("waitlist_tab", flag ? "startups" : "investors");
        dispatch({
          type: actionTypes.WAITLIST_PERMISSION,
          payload: {
            waitlist_startup_permission: flag,
            waitlist_investor_permission: true,
          },
        });
        navigate.push("/waitlist");
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.WAITLIST_PERMISSION,
        payload: {
          waitlist_startup_permission: flag,
          waitlist_investor_permission: false,
        },
      });
      if (!flag) {
        message.error(
          "You don't have appropriate permission to access Waitlist",
          2
        );
        navigate.push("/welcome");
      }
    });
};

// Action to check user permission for waitlist
export const checkPermission = (navigate) => {
  return async (dispatch) => {
    await axios
      .post("admin/filter", { is_inv: false })
      .then((res) => {
        if (res?.data) {
          localStorage.setItem("waitlist_tab", "startups");
          investorCall(dispatch, navigate, true);
        }
        navigate.push("/waitlist");
      })
      .catch((error) => {
        investorCall(dispatch, navigate, false);
      });
  };
};

// Action to delete a startup
export const deleteStartup = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_STARTUP_INIT,
    });
    await axios
      .post("admin/delete", data)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get startup details by ID
export const getStartupDetailsById = (startupId) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_STARTUP_BY_ID_INIT,
    });
    await axios
      .get("admin/get-single-startup", { params: { id: startupId } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_STARTUP_BY_ID_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_STARTUP_BY_ID_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a memo
export const uploadMemo = (uploadMemo, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_MEMO_INIT,
    });
    await axios
      .post("admin/mime-files", uploadMemo)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_MEMO_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_MEMO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to assign a startup
export const assignStartup = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ASSIGN_STARTUP_INIT,
    });
    await axios
      .post("admin/assign", data)
      .then((res) => {
        dispatch({
          type: actionTypes.ASSIGN_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ASSIGN_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to perform a bulk invite
export const bulkInvite = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.BULK_INVITE_INIT,
    });
    try {
      const res = await axios.post("admin/bulk-invite", data);
      dispatch({
        type: actionTypes.BULK_INVITE_SUCCESS,
        payload: res.data,
      });
      return res;
    } catch (error) {
      dispatch({
        type: actionTypes.BULK_INVITE_FAILED,
      });
      throw error?.response?.data?.error;
    }
  };
};

// Action to update the grade of a startup
export const updateGrade = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_GRADE_INIT,
    });
    await axios
      .post("admin/grade", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_GRADE_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_GRADE_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get the grade of a startup
export const getGrade = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_GRADE_INIT,
    });
    await axios
      .get("admin/grade", { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_GRADE_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_GRADE_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update startup data
export const updateStartup = (data, id) => {
  // const new_data = {
  //   ...data,
  // };
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_STARTUP_INIT,
    });
    await axios
      .post("admin/set-data", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get approved startup details by ID
export const getApprovedStartupDetailsById = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_BY_ID_INIT,
    });
    await axios
      .post("admin/get-single-approved", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_BY_ID_SUCCESS,
          payload: res.data?.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_BY_ID_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update the status of a startup
export const updateStatus = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_STATUS_INIT,
    });
    await axios
      .post(`admin/set-data?id=${id}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_STATUS_SUCCESS,
          payload: res?.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_STATUS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a slide deck for a startup
export const uploadSlideDeck = (slideDeckData, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_STARTUP_SLIDEDECK_INIT,
    });
    await axios
      .post("admin/mime-files", slideDeckData)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_STARTUP_SLIDEDECK_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_STARTUP_SLIDEDECK_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get the current update position of a startup
export const getCurrentUpdatePositionValue = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_CURRENT_UPDATE_STARTUP_POSITION_INIT,
    });
    await axios
      .post("admin/set-position", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_CURRENT_UPDATE_STARTUP_POSITION_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess();
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_CURRENT_UPDATE_STARTUP_POSITION_FAILED,
        });
        onFail && onFail();
        throw error?.response?.data?.error;
      });
  };
};

// Function to get SVP (Special Purpose Vehicle) info by startup ID
export const getSVPInfoByStartupId = async (startupId) => {
  try {
    const { data } = await axios.post("admin/spv-info", {
      startup_id: startupId,
    });
    if (data?.result) {
      return data;
    }
  } catch (error) { }
};

// Action to check SVP info
export const checkSPVInfo = (payload) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("admin/spv-info", payload);
      return response?.data;
    } catch (error) { 
      return error?.response?.data;
    }
  };
};

// Action to add an email for SVP
export const addEmailForSVP = (payload) => {
  return async () => {
    try {
      const { data } = await axios.post("admin/add-email", payload);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
};

// Action to check email response
export const checkEmailResponse = (payload) => {
  return async () => {
    try {
      const { data } = await axios.post(
        "admin/check-dwolla-email-status",
        payload
      );
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
};

// Action to create a banking profile customer
export const createBankingProfileCustomer = (payload, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.CREATE_BANKING_PROFILE_CUSTOMER_INIT,
      });
      const { data } = await axios.post("admin/create-customer", payload);
      dispatch({
        type: actionTypes.CREATE_BANKING_PROFILE_CUSTOMER_SUCCESS,
      });
      onSuccess && onSuccess(data);
      return data;
    } catch (error) {
      dispatch({
        type: actionTypes.CREATE_BANKING_PROFILE_CUSTOMER_FAILED,
      });
      onError && onError(error?.response?.data);
      return error?.response?.data;
    }
  };
};

// Action to create a link token
export const createLinkToken = (data, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.POST_LINK_TOKEN_INIT,
    });
    await axios
      .post(`admin/plaid/create_link_token`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.POST_LINK_TOKEN_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.POST_LINK_TOKEN_FAILED,
        });
        onError && onError(error?.response?.data?.error || error?.message);
        throw error?.response?.data?.error || error?.message;
      });
  };
};

// Action to update a banking profile customer
export const updateBankingProfileCustomer = (payload, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("admin/update-customer", payload);
      onSuccess && onSuccess(data);
      return data;
    } catch (error) {
      onError && onError(error?.response?.data);
      return error?.response?.data;
    }
  };
};

// Action to submit an SVP document
export const submitSPVDocument = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("admin/submit-document", payload);
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
};

// Action to check user status
export const checkUserStatus = (payload) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("admin/certify-user", payload);

      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };
};

// Action to get the last saved data
export const getLastSyncData = async () => {
  try {
    const { data } = await axios.get("admin/last-saved");
    return data;
  } catch (error) {
    return error?.response?.data;
  }
};

// Action to get Dwolla accounts
export const getDwollaAccounts = (pageNo, onSuccess, onFail) => {
  return async (dispatch) => {
    let data = { page_num: pageNo, page_size: 5 };
    dispatch({
      type: actionTypes.GET_DWOLLA_ACCOUNT_DATA_INIT,
    });
    await axios
      .post("admin/dwolla-accounts", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_DWOLLA_ACCOUNT_DATA_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_DWOLLA_ACCOUNT_DATA_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

// Action to link startup accounts
export const linkStartUpAccounts = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LINK_STARTUP_BANK_ACCUNT_INIT,
    });
    await axios
      .post("admin/link-startup-bank", data)
      .then((res) => {
        dispatch({
          type: actionTypes.LINK_STARTUP_BANK_ACCUNT_SUCCESS,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.LINK_STARTUP_BANK_ACCUNT_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get bank accounts
export const getBankAccounts = (dwollaId, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_BANK_ACCOUNT_DATA_INIT,
    });
    await axios
      .get("admin/show-bank-accounts", { params: { id: dwollaId } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_BANK_ACCOUNT_DATA_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data)
        // return ;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_BANK_ACCOUNT_DATA_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

// Action to set business information
export const setBusinessInfo = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_BUSINESS_INFO, payload: data });
  };
};

// Action to get business classification categories
export const getIndustryClassificationCategory = () => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_INDUSTRY_CLASSIFICATIONS_INIT,
    });
    await axios
      .get("admin/industry-classifications")
      .then((res) => {
        dispatch({
          type: actionTypes.GET_INDUSTRY_CLASSIFICATIONS_SUCCESS,
          payload: res.data?.classifications,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_INDUSTRY_CLASSIFICATIONS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get industry classification categories
export const getBusinessClassificationCategory = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_BUSINESS_CLASSIFICATIONS_INIT,
    });
    await axios
      .post("admin/business-classifications", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_BUSINESS_CLASSIFICATIONS_SUCCESS,
          payload: res.data?.industry_classifications,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_BUSINESS_CLASSIFICATIONS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get a list of countries
export const getCountryList = () => {
  return async (dispatch) => {
    await axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => {
        dispatch({
          type: actionTypes.SET_FLAG_LINK,
          payload: res?.data?.map((i) => {
            return { name: i?.name?.common, flag: i?.flags?.svg };
          }),
        });
      })
      .catch(() => { });
  };
};

// Action to get invite history for a startup
export const getInviteHistory = (data, id, onSuccess, onFail) => {
  return async (dispatch) => {
    let payload = {
      page_num: data?.page_num,
      page_size: data?.countPerPage,
      filter_option: data?.filter,
    };
    dispatch({
      type: actionTypes.GET_INVITE_HISTORY_DATA_INIT,
    });
    await axios
      .post(`admin/invite-history-startup?id=${id}`, payload)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.GET_INVITE_HISTORY_DATA_SUCCESS,
            payload: res?.data,
          });
          onSuccess && onSuccess();
          return;
        } else {
          dispatch({
            type: actionTypes.GET_INVITE_HISTORY_DATA_FAILED,
          });
          onFail && onFail();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_INVITE_HISTORY_DATA_FAILED,
        });
        onFail && onFail();
        throw error?.response?.data?.error;
      });
  };
};

// Action to send fundraise startup invite
export const sendFundraiseStartupInvite = (data, id, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SEND_STARTUP_INVITE_INIT,
    });
    await axios
      .post(`admin/startup-fundraise-invite/?id=${id}`, data)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.SEND_STARTUP_INVITE_SUCCESS,
          });
          onSuccess && onSuccess(res?.data?.message);
          return;
        } else {
          dispatch({
            type: actionTypes.SEND_STARTUP_INVITE_FAILED,
          });
          onFail && onFail(res?.data?.error);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SEND_STARTUP_INVITE_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

// Action to get previous posts
export const getPrviousPost = (data, id, onSuccess, onFail) => {
  return async (dispatch) => {
    let payload = {
      page_num: data?.page_num,
    };
    dispatch({
      type: actionTypes.GET_PREVIOUS_POSTS_INIT,
    });
    await axios
      .post(`admin/post-history-fundraise-startup?id=${id}`, payload)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.GET_PREVIOUS_POSTS_SUCCESS,
            payload: res?.data,
          });
          onSuccess && onSuccess();
          return;
        } else {
          dispatch({
            type: actionTypes.GET_PREVIOUS_POSTS_FAILED,
          });
          onFail && onFail();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_PREVIOUS_POSTS_FAILED,
        });
        onFail && onFail();
        throw error?.response?.data?.error;
      });
  };
};

// Action to send individual invite
export const individualInvite = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.INDIVIDUAL_INVITE_INIT,
    });
    await axios
      .post(`admin/individual-invite`, data)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.INDIVIDUAL_INVITE_SUCCESS,
            payload: res?.data?.message,
          });
          onSuccess && onSuccess(res?.data?.message);
          return res?.data?.message;
        } else {
          dispatch({
            type: actionTypes.INDIVIDUAL_INVITE_FAILED,
          });
          onFail && onFail(res?.data?.error);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.INDIVIDUAL_INVITE_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

// Action to resend email invite
export const resendEmailInvite = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.RESEND_ADMIN_INVITE_INIT,
    });
    await axios
      .post(`admin/resend-invite`, data)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.RESEND_ADMIN_INVITE_SUCCESS,
            payload: res?.data?.message,
          });
          onSuccess && onSuccess(res?.data?.message);
          return res?.data?.message;
        } else {
          dispatch({
            type: actionTypes.RESEND_ADMIN_INVITE_FAILED,
          });
          onFail && onFail(res?.data?.error);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.RESEND_ADMIN_INVITE_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

// Action to get admin invite history
export const getAdminInviteHistory = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_ADMIN_DATA_INIT,
    });
    await axios
      .post(`admin/admin-invite-history`, data)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.GET_ADMIN_DATA_SUCCESS,
            payload: res?.data,
          });

          return res?.data;
        } else {
          dispatch({
            type: actionTypes.GET_ADMIN_DATA_FAILED,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_ADMIN_DATA_FAILED,
        });
      });
  };
};


// Action to send verify email
export const sendVerifyEmail = (data, onSuccess, onError) => {
  return async (dispatch) => {
    await axios.post("admin/add-email", data)
      .then(res => {
        if (res.data?.result) {
          onSuccess(res.data)
        } else {
          onError(res.data.error)
        }
      })
      .catch((error) => {
        onError(error.error)
        throw error?.response?.data?.error
      })
  }
}

// create customer
export const createDwollaCustomer = (data, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_DWOLLA_CUSTOMER_INIT,
    })
    await axios.post(`admin/create-customer`, data)
      .then((res) => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.CREATE_DWOLLA_CUSTOMER_SUCCESS,
            payload: res?.data,
          })
          onSuccess(res?.data)
        } else {
          dispatch({
            type: actionTypes.CREATE_DWOLLA_CUSTOMER_FAILED,
          })
          onError(res.error)
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE_DWOLLA_CUSTOMER_FAILED,
        })
        onError(error.error || "Something went wrong")
        throw error?.response?.data?.error || error?.message
      })
  }
}

// update customer
export const updateDwollaCustomer = (data, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_DWOLLA_CUSTOMER_INIT,
    })
    await axios.post("admin/update-customer", data)
      .then(res => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.UPDATE_DWOLLA_CUSTOMER_SUCCESS,
            payload: res?.data,
          })
          onSuccess(res?.data)
        } else {
          dispatch({
            type: actionTypes.UPDATE_DWOLLA_CUSTOMER_FAILED,
          })
          onError(res.error || "Something went wrong")
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_DWOLLA_CUSTOMER_FAILED,
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

// delete customer
export const deleteDwollaAccount = (data, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_DWOLLA_CUSTOMER_INIT,
    })
    await axios.post("admin/delete-dwolla-account", data)
      .then(res => {
        if (res?.data?.result) {
          dispatch({
            type: actionTypes.DELETE_DWOLLA_CUSTOMER_SUCCESS,
            payload: res?.data,
          })
          onSuccess(res?.data)
        } else {
          dispatch({
            type: actionTypes.DELETE_DWOLLA_CUSTOMER_FAILED,
          })
          onError(res?.data?.error)
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DELETE_DWOLLA_CUSTOMER_FAILED
        })
        onError(error.error)
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const emptyBusinessCategories = () => ({
  type: 'EMPTY_BUSINESS_CATEGORIES',
})