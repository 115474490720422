import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Upload,
  Button,
  Col,
  Row,
  Input,
  message,
  Spin,
  Modal,
  DatePicker,
  Select,
} from "antd";
import ReactPlayer from "react-player/lazy";
import TextArea from "antd/lib/input/TextArea";
import axiosInstance from "../../../../../Utils/api";
import * as videoActions from "../../../../../Redux/Actions/VideoLecturesActions";
import userIcon from "../../../../../Assets/Images/user.png";
import trash from "../../../../../Assets/Images/delete.svg";
import add from "../../../../../Assets/Images/add.svg";
import "./AddNewVideoLecture.scss";

const { Option } = Select;
const AddNewVideoLecture = ({
  getChaptersList,
  addNewVideoLecture,
  chaptersList,
  loading,
  history,
}) => {
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [uploadLoading, setUploadLoading] = useState(false);
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [videoLectureTopic, setVideoLectureTopic] = useState("");
  const [videoLectureCreator, setVideoLectureCreator] = useState("");
  const [chapterId, setChapterId] = useState();
  const [videos, setVideos] = useState([
    { title: "", link: null, summary: "" },
  ]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("learnSeries65AddVideoLectureData"));
    if (data) {
      setVideoLectureTopic(data?.topic);
      setVideoLectureCreator(data?.created_by);
      setChapterId(data?.chapter_id);
      setVideos(data?.links);
    }
  }, [])

  // fetch the list of chapters when the component mounts
  useEffect(() => {
    let data = {
      learning_type: "series65",
    };
    getChaptersList(user?.id, data);
  }, []);

  /**
   * Function to handle video upload
   * @param {number} index - Index of the video being uploaded
   */
  const onUploadVideo = (index) => {
    setUploadLoading(true);
    let data = new FormData();
    data.append("type", "media");
    data.append("file_obj", videos[index]?.file);
    axiosInstance
      .post("admin/mime-files", data)
      .then((res) => {
        const video = [...videos];
        video[index].link = res?.data?.url;
        delete video[index]?.file;
        setVideos(video);
        setUploadLoading(false);
        message.success("Video uploaded successfully");
      })
      .catch((e) => {
        setUploadLoading(false);
        message.error("Video upload failed..");
      });
  };

  //  Function to handle closing the schedule publish modal
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    // setSchedulePublishTime();
  };

  /**
   * Function to check if video data is valid
   * @returns {boolean} - Whether the video data is valid or not
   */
  const checkVideoDataValid = () => {
    let isValid = false;
    const isVideosValid = videos?.every(
      (item) => item?.title && item?.summary && item.link && !item?.file
    );
    if (
      videoLectureTopic &&
      videoLectureCreator &&
      chapterId &&
      isVideosValid &&
      videos?.length > 0
    ) {
      isValid = true;
    }
    return isValid;
  };

  /**
   * Function to handle video publishing
   * @param {boolean} isDraft - Whether the video is a draft or not
   * @param {boolean} isPreview - Whether the video is in preview mode or not
   */
  const onPublishVideo = (isDraft, isPreview = false) => {
    const isValid = checkVideoDataValid();
    if (!isValid) {
      message.error("Enter Valid Video Lecture Details..", 2);
      return;
    }
    let data = {
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      learning_type: "series65",
      topic: videoLectureTopic?.trimEnd(),
      chapter_id: chapterId,
      created_by: videoLectureCreator?.trimEnd(),
      links: videos,
    };
    addNewVideoLecture(data, user?.id, (data) => {
      isPreview &&
        history.push({
          pathname: `/education/learn-series65/video-lecture-preview/${data?.id}`,
        });
    })
      .then(() => {
        !isPreview && message.success("Video Lecture Added Successfully");
        localStorage.removeItem("learnSeries65AddVideoLectureData")
        !isPreview &&
          history.push({
            pathname: `/education/learn-series65/video-lectures`,
            state: { searchPlaceholder: "Search Video Lectures", back: true },
          });
      })
      .catch((e) => {
        message.error(e || "Add Video Lecture Failed..", 2);
      });
  };

  /**
   * Function to handle change in video field values
   * @param {string} value - New value for the video field
   * @param {string} field - Name of the video field being changed
   * @param {number} index - Index of the video in the array
   */
  const onVideoFieldChange = (value, field, index) => {
    const video = [...videos];
    video[index][field] = value;
    setVideos(video);
  };

  // Function to add a new video entry
  const addNewVideo = () => {
    const isValid = videos.every(
      (item) => item.link && !item?.file && item?.summary && item?.title
    );
    if (!isValid) {
      message.error("Enter Valid Video Fields..", 2);
      return;
    }
    setVideos([...videos, { title: "", link: null, summary: "" }]);
  };

  /**
   * Function to delete a video entry
   * @param {number} index - Index of the video to be deleted
   */
  const deleteVideo = (index) => {
    const video = [...videos];
    video.splice(index, 1);
    setVideos(video);
  };

  // Modal for scheduling exam publishing.
  const schedulePublishModal = (
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => {
        onPublishVideo(false)
        onCloseSchedulePublishModal()
      }}
      className="schedule-publish-modal"
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={false}
          disabledDate={(current) => current && current.valueOf() < Date.now()}
        />
      </div>
    </Modal>
  );

  return (
    <div className="add-video-container">
      <div className="top-add">
        <h6> Add New Video Lecture </h6>
        <div className="add-right">
          <Button
            className="save-btn"
            // onClick={() => onPublishVideo(true, true)}
            onClick={async () => {
              let videoLectureDetails = {
                // is_draft: isDraft,
                ...(schedulePublishTime && { publish_date: schedulePublishTime }),
                learning_type: "investing",
                topic: videoLectureTopic?.trimEnd(),
                chapter_id: chapterId,
                created_by: videoLectureCreator?.trimEnd(),
                links: videos,
              };
              await localStorage.setItem("learnSeries65AddVideoLectureData", JSON.stringify(videoLectureDetails))
              history.push("/education/learn-series65/add-video-lecture-preview", {
                videoLectureDetails,
                searchPlaceholder: "Search Video Lecture", back: true
              })
            }}
          >
            Preview
          </Button>
          <Button className="save-btn" onClick={() => onPublishVideo(true)}>
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => setOpenschedulePublishModal(true)}
          >
            Schedule Publish
          </Button>
          <Button className="publish-btn" onClick={() => onPublishVideo(false)}>
            Publish
          </Button>
        </div>
      </div>
      <div className="add-video-wrap">
        {uploadLoading || loading ? (
          <Spin />
        ) : (
          <div>
            <Row>
              <Col span={24}>
                <div className="field-box">
                  <label>Video lecture topic</label>
                  <Input
                    value={videoLectureTopic}
                    placeholder={"Video lecture topic"}
                    className="inputs-box"
                    onChange={(e) =>
                      setVideoLectureTopic(e.target.value?.trimStart())
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <div className="field-box">
                  <label>Which chapter is this topic associated with? </label>
                  <Select
                    placeholder="Select Chapter"
                    className="selection-option"
                    optionFilterProp="children"
                    showSearch
                    value={chapterId}
                    showArrow
                    onChange={(value) => setChapterId(value)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {chaptersList?.map((item) => {
                      return (
                        <Option
                          key={item?.id}
                          value={item?.id}
                        >{`Chapter ${item?.chapter_number}`}</Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <label>Created By</label>
                  <Input
                    prefix={
                      <img
                        src={userIcon}
                        className="prefix-icon"
                        alt="user-icon"
                      />
                    }
                    value={videoLectureCreator}
                    placeholder={"Created By"}
                    className="inputs-box"
                    onChange={(e) =>
                      setVideoLectureCreator(e.target.value?.trimStart())
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[48, 24]}>
              {videos?.map((item, index) => (
                <Col span={8}>
                  <div className="add-video-wrapper">
                    <div
                      className="delete-wrap"
                      onClick={() => deleteVideo(index)}
                    >
                      <img src={trash} alt="delete" />
                    </div>
                    <div className="content-wrap">
                      <div className="field-box">
                        <label>Video Title</label>
                        <Input
                          value={item?.title}
                          placeholder={"Video title"}
                          className="inputs-box"
                          onChange={(e) =>
                            onVideoFieldChange(
                              e.target.value?.trimStart(),
                              "title",
                              index
                            )
                          }
                        />
                      </div>
                      <div>
                        {item?.link ? (
                          <div className="add-video-upload">
                            <ReactPlayer
                              className="video-wrap"
                              width={"100%"}
                              height={"100%"}
                              controls
                              url={item?.link}
                            />
                          </div>
                        ) : (
                          <Upload
                            accept="video/*"
                            onChange={(file) => {
                              onVideoFieldChange(
                                URL.createObjectURL(file.file.originFileObj),
                                "link",
                                index
                              );
                              onVideoFieldChange(
                                file.file.originFileObj,
                                "file",
                                index
                              );
                            }}
                          >
                            <div className="add-video-upload">
                              <img
                                src={add}
                                alt="add"
                                height="24px"
                                width="24px"
                              />
                              <p>Add Video</p>
                            </div>
                          </Upload>
                        )}
                        {item?.file && (
                          <Button
                            onClick={() => onUploadVideo(index)}
                            className="upload-btn"
                          >
                            Upload Video
                          </Button>
                        )}
                      </div>
                      <Row>
                        <Col span={24}>
                          <div className="field-box">
                            <TextArea
                              rows={3}
                              spellCheck={false}
                              placeholder={"Add Video Summury here"}
                              className="text-area-box"
                              value={item?.summary}
                              onChange={(e) => {
                                onVideoFieldChange(
                                  e.target.value?.trimStart(),
                                  "summary",
                                  index
                                );
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
              <Col span={8}>
                <div className="add-video-wrapper" style={{ height: 415 }}>
                  <div className="content-wrap">
                    <div>
                      <div
                        className="add-video-upload"
                        onClick={() => addNewVideo()}
                      >
                        <img src={add} alt="add" height="24px" width="24px" />
                        <p>Add New</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.VideoLecture.loading,
    chaptersList: state.VideoLecture.chaptersList,
    userData: state.Auth.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewVideoLecture: (data, id, onSuccess) =>
      dispatch(videoActions.addNewVideoLecture(data, id, onSuccess)),
    getChaptersList: (id, data) =>
      dispatch(videoActions.getChaptersList(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewVideoLecture);
