// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  quizzesData: [],
  totalquizzesCount: 0,
  quizDetails: {},
  chaptersList: [],
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EDUCATION_QUIZZES_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_EDUCATION_QUIZZES_DATA_SUCCESS:
      return {
        ...state,
        quizzesData: action.payload?.data || [],
        totalquizzesCount: action.payload?.total_count || 0,
        loading: false,
      };
    case actionTypes.GET_EDUCATION_QUIZZES_DATA_FAILED:
      return {
        ...state,
        quizzesData: [],
        totalquizzesCount: 0,
        loading: false,
      };
    case actionTypes.TOGGLE_EDUCATION_QUIZ_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOGGLE_EDUCATION_QUIZ_ACTION_SUCCESS:
      return {
        ...state,
        quizzesData: state?.quizzesData?.map((item) => {
          if (item?.id === action?.quizId) {
            item.is_active = action?.toggleState;
          }
          return item;
        }),
        loading: false,
      };
    case actionTypes.TOGGLE_EDUCATION_QUIZ_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_EDUCATION_QUIZ_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_EDUCATION_QUIZ_SUCCESS:
      return {
        ...state,
        quizzesData: state?.quizzesData?.filter(
          (item) => item?.id !== action?.quizId
        ),
        loading: false,
      };
    case actionTypes.DELETE_EDUCATION_QUIZ_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_QUIZ_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_QUIZ_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_QUIZ_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_QUIZ_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_QUIZ_DETAILS_SUCCESS:
      return {
        ...state,
        quizDetails: action?.payload?.data || {},
        loading: false,
      };
    case actionTypes.GET_QUIZ_DETAILS_FAILED:
      return {
        ...state,
        quizDetails: {},
        loading: false,
      };
    case actionTypes.EDIT_QUIZ_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_QUIZ_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_QUIZ_FAILED:
    return {
      ...state,
      loading: false,
    };
    default:
      return state;
  }
};
export default store;
