// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  talentData: [],
  totalTalent: 0,
  talentDetails: {},
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TALENT_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TALENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        talentData: action.payload?.data?.data,
        totalTalent: action.payload?.data?.total_count,
      };
    case actionTypes.GET_TALENT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        talentData: [],
        totalTalent: 0,
      };
    case actionTypes.UPDATE_TALENT_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TALENT_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        talentData: state?.talentData?.map((item) => {
          if (item?.id === action?.id) {
            return {
              ...item,
              is_active: action?.is_active,
            };
          }
          return { ...item };
        }),
        talentDetails: {
          ...state?.talentDetails,
          is_active: action?.is_active,
        },
      };
    case actionTypes.UPDATE_TALENT_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_TALENT_DETAILS_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_TALENT_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        talentDetails: action.payload,
      };
    case actionTypes.GET_TALENT_DETAILS_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        talentDetails: {},
      };
    case actionTypes.DELETE_SCOUT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SCOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_SCOUT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_TALENT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_TALENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_TALENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
