import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";
import { message } from "antd";

// Action to get approved startups data
export const getApprovedStartupsData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUPS_DATA_INIT,
    });
    await axios
      .post("admin/get-approved-startups", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUPS_DATA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUPS_DATA_FAILED,
          payload: error?.response?.data?.error,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update an approved startup action
export const updateApprovedStartupAction = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_APPROVED_STARTUP_ACTION_INIT,
    });
    await axios
      .post("admin/set-approved-action", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_APPROVED_STARTUP_ACTION_SUCCESS,
          payload: res.data,
          approved_action: data.approved_action,
          id,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_APPROVED_STARTUP_ACTION_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get approved startup details by ID
export const getApprovedStartupDetailsById = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_BY_ID_INIT,
    });
    await axios
      .post("admin/get-single-approved", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_BY_ID_SUCCESS,
          payload: res.data?.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_BY_ID_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get approved startup team information
export const getApprovedStartupTeamInfo = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_TEAM_INFO_INIT,
    });
    await axios
      .get("admin/team-info", { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_TEAM_INFO_SUCCESS,
          payload: res.data?.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_TEAM_INFO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get matching investors for an approved startup
export const getMatchInvestors = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_MATCH_INVESTOR_INIT,
    });
    await axios
      .post("admin/investor-match", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_MATCH_INVESTOR_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_MATCH_INVESTOR_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to set the profile visibility of an approved startup
export const setProfileVisibility = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_APPROVED_STARTUP_VISIBILITY_INIT,
    });
    await axios
      .post("admin/set-profile-visibility", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.SET_APPROVED_STARTUP_VISIBILITY_SUCCESS,
          payload: data?.show_profile,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_APPROVED_STARTUP_VISIBILITY_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get investor suggestions for an approved startup
export const getInvestorSuggestions = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_INVESTOR_SUGGESTIONS_INIT,
    });
    await axios
      .post("admin/find-approved-to-add", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_INVESTOR_SUGGESTIONS_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_INVESTOR_SUGGESTIONS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to add an approved investor to an approved startup
export const addApprovedInvestor = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_APPROVED_INVESTOR_INIT,
    });

    await axios
      .post("admin/add-approved-relevant", data)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_APPROVED_INVESTOR_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_APPROVED_INVESTOR_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a memo
export const uploadMemo = (uploadMemo, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_MEMO_INIT,
    });
    await axios
      .post("admin/mime-files", uploadMemo)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_MEMO_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_MEMO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update an approved startup's profile image
export const updateApprovedStartupProfileImage = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_APPROVED_STARTUP_PROFILE_IMAGE_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update an approved startup's banner image
export const updateApprovedStartupBannerImage = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_APPROVED_STARTUP_BANNER_IMAGE_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_APPROVED_STARTUP_BANNER_IMAGE_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_APPROVED_STARTUP_BANNER_IMAGE_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a slide deck
export const uploadSlideDeck = (slidedeck, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_SLIDEDECK_INIT,
    });
    await axios
      .post("admin/mime-files", slidedeck)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_SLIDEDECK_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_SLIDEDECK_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get the history of an approved startup
export const getStartupHistory = (data, id, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_HISTORY_INIT,
    });
    await axios
      .post("admin/startup-history", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_HISTORY_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data)
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_HISTORY_FAILED,
        });
        onError(error?.response?.data?.error)
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete a matched investor from an approved startup
export const deleteMatchInvestor = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_MATCH_INVESTOR_INIT,
    });
    await axios
      .post("admin/delete-approved-relevant", data)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_MATCH_INVESTOR_SUCCESS,
          payload: res.data,
          matchId: data?.match_id,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_MATCH_INVESTOR_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get public Q&A for an approved startup
export const getPublicQnA = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_PUBLIC_QA_INIT,
    });
    await axios
      .post("admin/qna", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_PUBLIC_QA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_PUBLIC_QA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get the recipient data for a notify investor
export const getInvestorRecipient = (data, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_RECIPIENT_DATA_INIT,
    });
    await axios
      .post("admin/notify-investor-add-recipient", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_RECIPIENT_DATA_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess();
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_RECIPIENT_DATA_FAILED,
        });
        onError && onError();
        throw error?.response?.data?.error;
      });
  };
};

// Action to create a notify investor data
export const createNotifyInvestor = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_NOTIFY_INVESTOR_INIT,
    });
    await axios
      .post("admin/notify-investor-create-data", data)
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_NOTIFY_INVESTOR_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE_NOTIFY_INVESTOR_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get the history of a notify investor
export const getNotifyInvestorHistory = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_NOTIFY_INVESTOR_HISTORY_INIT,
    });
    await axios
      .post("admin/notify-investor-history", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_NOTIFY_INVESTOR_HISTORY_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_NOTIFY_INVESTOR_HISTORY_FAILED,
          payload: error?.response?.data?.error,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get the current minimum syndicate required amount
export const getCurrentSyndicateAmount = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_INIT,
    });
    await axios
      .post("admin/set-syndicate", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess();
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_CURRENT_MINIMUM_SYNDICATE_REQUIRED_FAILED,
        });
        onFail && onFail();
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete a notify investor history
export const deleteNotifyInvestorHistory = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_NOTIFY_INVESTOR_HISTORY_INIT,
    });

    await axios
      .get(`admin/notify-investor-delete?id=${id}`)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_NOTIFY_INVESTOR_HISTORY_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_NOTIFY_INVESTOR_HISTORY_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to edit a notify investor data
export const editNotifyInvestor = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_NOTIFY_INVESTOR_HISTORY_INIT,
    });
    await axios
      .post(`/admin/notify-investor-edit?id=${id}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_NOTIFY_INVESTOR_HISTORY_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_NOTIFY_INVESTOR_HISTORY_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to add a public question for an approved startup
export const addPublicQuestion = (data, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_QUESTION_INIT,
    });
    await axios
      .post(`/admin/post-question`, data)
      .then((res) => {
        if (res.data.message) {
          dispatch({
            type: actionTypes.ADD_QUESTION_SUCCESS,
            payload: res.data.message,
          });
          onSuccess && onSuccess(res.data.message);
          return;
        } else {
          dispatch({
            type: actionTypes.ADD_QUESTION_FAILED,
          });
          onFail && onFail(res.error);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_QUESTION_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

// Function to check and navigate to the "Approved" page based on user permissions
const investorCall = async (dispatch, navigate, flag) => {
  axios
    .post("admin/get-approved-investors", {})
    .then((res1) => {
      if (res1?.data) {
        localStorage.setItem("approved_tab", flag ? "startups" : "investors");
        dispatch({
          type: actionTypes.APPROVED_PERMISSION,
          payload: {
            approved_startup_permission: flag,
            approved_investor_permission: true,
          },
        });
        navigate.push("/approved");
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.APPROVED_PERMISSION,
        payload: {
          approved_startup_permission: flag,
          approved_investor_permission: false,
        },
      });
      if (!flag) {
        message.error(
          "You don't have appropriate permission to access Approved",
          2
        );
        navigate.push("/welcome");
      }
    });
};

// Action to check and navigate to the "Approved" page based on user permissions
export const checkApprovedPermission = (navigate) => {
  return async (dispatch) => {
    await axios
      .post("admin/get-approved-startups", {})
      .then((res) => {
        if (res?.data) {
          localStorage.setItem("approved_tab", "startups");
          investorCall(dispatch, navigate, true);
        }
        navigate.push("/approved");
      })
      .catch((error) => {
        investorCall(dispatch, navigate, false);
      });
  };
};

// Action to update the fund transfer status of a startup
export const startupFundTransferStatus = (data, id, onSuccess, onFail) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_FUND_TRANSFER_STATUS_INIT,
    });
    await axios
      .post(`/admin/startup-fund-transfer-status?startup_id=${id}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_FUND_TRANSFER_STATUS_SUCCESS,
          payload: res.data?.fund_transfer_status,
        });
        onSuccess && onSuccess();
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_FUND_TRANSFER_STATUS_FAILED,
        });
        onFail && onFail(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

export const handleRemoveCoInvestor = (payload) => {
  return {
    type: actionTypes.REMOVE_CO_INVESTOR,
    payload: payload,
  };
};

// Action to update co investor icon
export const updateCoInvestorIcon = (data, index) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_INVESTOR_ICON_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_INVESTOR_ICON_SUCCESS,
          payload: { successData: res.data, data: data, index: index },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_INVESTOR_ICON_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to handle new co investor info locally
export const handleNewCoInvestorAddLocally = payload => {
  return {
    type: actionTypes.ADD_NEW_CO_INVESTOR_LOCALLY,
    payload: payload,
  }
}

// Action to cancel an SPV
export const handleCancelSPV = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.CANCEL_SPV_INIT,
    });
    await axios
      .post(`admin/cancel-spv`, data, { params: { startup_id: id } })
      .then((res) => {
        dispatch({
          type: actionTypes.CANCEL_SPV_SUCCESS,
          payload: res.data?.data,
        });
        return res.data?.data;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CANCEL_SPV_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to launch startup
export const handleLaunchStartup = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.LAUNCH_STARTUP_INIT,
    });
    await axios
      .post(`admin/set-data`, data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.LAUNCH_STARTUP_SUCCESS,
          payload: res.data?.data,
        });
        return res.data?.data;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.LAUNCH_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get public Q&A for an approved startup
export const getCarryRecipients = (data, startupId, onSuccess, onError) => {
  console.log({data, startupId})
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_APPROVED_STARTUP_CARRY_RECIPIENTS_INIT,
    });
    await axios
      .post(`admin/get-scout-carry-recipients?startup_id=${startupId}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_CARRY_RECIPIENTS_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data);
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_APPROVED_STARTUP_CARRY_RECIPIENTS_FAILED,
        });
        onError && onError(error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  };
};

export const uploadCarryRecipientDoc = (data, index) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_CARRY_RECIPIENT_DOC_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        console.log({ res })
        dispatch({
          type: actionTypes.UPLOAD_CARRY_RECIPIENT_DOC_SUCCESS,
          payload: { data: res.data, index },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_CARRY_RECIPIENT_DOC_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

export const addCarryRecipientData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_RECIPIENT_DATA,
      payload: data
    })
  }
}

export const removeCarryRecipientDoc = (index) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_CARRY_RECIPIENT_DOC,
      payload: { index }
    })
  }
}

export const removeCarryRecipientRecord = (index) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_CARRY_RECIPIENT_RECORD,
      payload: { index }
    })
  }
}

export const AddCarryRecipientApi = (data, startupid, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADD_CARRY_RECIPIENT_API_INIT,
    });
    await axios
      .post(`admin/add-scout-carry-recipient?startup_id=${startupid}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_CARRY_RECIPIENT_API_SUCCESS,
        });
        onSuccess && onSuccess(res);
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_CARRY_RECIPIENT_API_FAILED,
        });
        onError && onError(error?.response?.data || error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  }
}

export const EditCarryRecipientApi = (data, recipientId, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_CARRY_RECIPIENT_API_INIT,
    });
    await axios
      .post(`admin/edit-scout-carry-recipient?carry_recipient_id=${recipientId}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.EDIT_CARRY_RECIPIENT_API_SUCCESS,
        });
        onSuccess && onSuccess(res);
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.EDIT_CARRY_RECIPIENT_API_FAILED,
        });
        onError && onError(error?.response?.data || error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  }
}

export const uploadCarryRecipientDocNew = (data, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_NEW_CARRY_RECIPIENT_DOC_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        console.log({ res })
        dispatch({
          type: actionTypes.UPLOAD_NEW_CARRY_RECIPIENT_DOC_SUCCESS,
          payload: res.data,
        });
        onSuccess && onSuccess(res.data)
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_NEW_CARRY_RECIPIENT_DOC_FAILED,
        });
        onError(error?.response?.data?.error)
        throw error?.response?.data?.error;
      });
  };
};

export const DeleteCarryRecipientApi = (recipientId, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_CARRY_RECIPIENT_API_INIT,
    });
    await axios
      .get(`admin/delete-scout-carry-recipient?carry_recipient_id=${recipientId}`)
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_CARRY_RECIPIENT_API_SUCCESS,
        });
        onSuccess && onSuccess(res);
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_CARRY_RECIPIENT_API_FAILED,
        });
        onError && onError(error?.response?.data || error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  }
}

export const ViewScoutCarryEntityApi = (id, onSuccess, onError) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.VIEW_SCOUT_CARRY_ENTITY_API_INIT,
    });
    await axios
      .get(`admin/view-scout-carry-entity?carry_entity_id=${id}`)
      .then((res) => {
        dispatch({
          type: actionTypes.VIEW_SCOUT_CARRY_ENTITY_API_SUCCESS,
        });
        onSuccess && onSuccess(res.data);
        // return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.VIEW_SCOUT_CARRY_ENTITY_API_FAILED,
        });
        onError && onError(error?.response?.data || error?.response?.data?.error);
        throw error?.response?.data?.error;
      });
  }
}