import React, { useEffect, useState } from 'react'
import "./AddNewReadinessExamPreview.scss"
import { Col, Row } from 'antd';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";

const AddNewReadinessExamPreview = ({ location }) => {
  const { examDetails } = location.state;
  const [question, setquestion] = useState();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    examDetails &&
      examDetails?.questions?.length > 0 &&
      setquestion(examDetails?.questions[0]);
  }, []);

  /**
   * Converts seconds into a formatted time display.
   * @param {number} d - The total number of seconds.
   * @returns {JSX.Element} A formatted time display.
   */
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return (
      <div className="time-wrap">
        <div>
          <p className="value">{("0" + h)?.slice(-2)} : </p>
        </div>
        <div>
          <p className="value">{("0" + m)?.slice(-2)} : </p>
        </div>
        <div>
          <p className="value">{("0" + s)?.slice(-2)} </p>
        </div>
      </div>
    );
  };

  return (
    <div className="exam-preview-container">
      <div className="preview-header">
        <h6>Readiness Exam</h6>
      </div>
      <div className="exam-preview-wrap">
        <Row gutter={16}>
          <Col span={5}>
            <div className="left-section">
              <h4>Exam Time</h4>
              <div className="time-details">
                <span>{secondsToHms(examDetails?.time)}</span>
              </div>
            </div>
          </Col>
          <Col span={18} offset={1} className="content-wrap">
            <div className="question-wrap">
              <div className="question-content">
                <span className="question">{question?.content}</span>
              </div>
              <div className="options">
                <Row gutter={[24, 24]}>
                  {question?.options?.map((option) => (
                    <Col span={12}>
                      <div
                        onClick={() => setSelectedOption(option)}
                        className={`option ${selectedOption === option
                          ? option === question?.correct_options
                            ? "correct-option"
                            : "incorrect-option"
                          : ""
                          }`}
                      >
                        <span>{option}</span>
                      </div>
                      {selectedOption === option && (
                        <div className="ans-message">
                          {selectedOption !== question?.correct_options ? (
                            <>
                              <CloseOutlined className="wrong-icon" />
                              <span className="wrong">Wrong Answer</span>
                            </>
                          ) : (
                            <>
                              <CheckOutlined className="right-icon" />
                              <span className="right">Correct Answer</span>
                            </>
                          )}
                        </div>
                      )}
                    </Col>
                  ))}
                </Row>
              </div>
              {selectedOption &&
                selectedOption !== question?.correct_options && (
                  <div className="wrong-ans-wrap">
                    <span className="label">Wrong Answer Explaination</span>
                    <div className="expl-content">
                      <span>{question?.explanation}</span>
                    </div>
                  </div>
                )}
              {examDetails?.questions?.length > 1 && (
                <div className="pre-next-wrap">
                  {questionIndex !== 0 && (
                    <div
                      className="prev-que"
                      onClick={() => {
                        setquestion(
                          examDetails?.questions[questionIndex - 1]
                        );
                        setQuestionIndex(questionIndex - 1);
                        setSelectedOption();
                      }}
                    >
                      <ArrowLeftOutlined />
                      <span className="title">Previous Question</span>
                    </div>
                  )}
                  {questionIndex !== examDetails?.questions?.length - 1 && (
                    <div
                      className="prev-que next-que"
                      onClick={() => {
                        setquestion(
                          examDetails?.questions[questionIndex + 1]
                        );
                        setQuestionIndex(questionIndex + 1);
                        setSelectedOption();
                      }}
                    >
                      <span className="title">Next Question</span>
                      <ArrowRightOutlined />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddNewReadinessExamPreview