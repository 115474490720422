import * as actionTypes from './ActionTypes'
import axios from '../../Utils/api'

// Action to get the count of unseen notifications
export const getUnseenNotificationsCount=(data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_UNSEEN_NOTIFICATIONS_COUNT_INIT
        })
        await axios.post("admin/notifications_dropdown",data).then((res) => {
            dispatch({
                type: actionTypes.GET_UNSEEN_NOTIFICATIONS_COUNT_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_UNSEEN_NOTIFICATIONS_COUNT_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get unseen notifications
export const getUnseenNotifications=(data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_UNSEEN_NOTIFICATIONS_INIT
        })
        await axios.post("admin/notifications_dropdown",data).then((res) => {
            dispatch({
                type: actionTypes.GET_UNSEEN_NOTIFICATIONS_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_UNSEEN_NOTIFICATIONS_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}

// Action to get notifications
export const getNotifications=(data)=>{
    return async dispatch =>{
        dispatch({
            type:actionTypes.GET_NOTIFICATIONS_INIT
        })
        await axios.post("admin/notifications",data).then((res) => {
            dispatch({
                type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
                payload:res.data
            });
            return
        }).catch((error) => {
            dispatch({
                type: actionTypes.GET_NOTIFICATIONS_FAILED,
            })
            throw error?.response?.data?.error
        })
    }
}