// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  searchText: "",
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_CHANGE_HANDLE:
      return {
        ...state,
        searchText: action.payload,
      };
    case actionTypes.CLEAR_SEARCH_HANDLE:
      return {
        ...state,
        searchText: '',
      };
    default:
      return state;
  }
};

export default store;
