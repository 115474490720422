import routes from "./Constants/routes";
// Importing route constants and components using lazy loading
import {
  Login,
  DashBoard,
  Welcome,
  Settings,
  WaitList,
  Rejected,
  Tracking,
  scouts,
  Approved,
  Analytics,
  Marketing,
  Education,
  Transaction,
  Talent,
  Notifications,
  WaitListStartupDetails,
  StartupSPVFormation,
  StartupInfoUpdate,
  AvailableDwollaAccount,
  BankingProfileInformation,
  ForgotPassword,
  WaitListStartupEdit,
  WaitListInvestorDetails,
  WaitListInvestorEdit,
  StartupSPVManageBankAccount,
  StartupSPVAddBankAccount,
  BankingDocVerification,
  NoBankAccount,
  vertifiedAccount,
  RejectedStartupDetails,
  RejectedInvestorDetails,
  RejectedStartupEdit,
  RejectedInvestorEdit,
  TrackingStartupDetails,
  TrackingStartupEdit,
  TrackingUpdateDetails,
  TrackingStartupUpdates,
  TrackingHistory,
  StartupMonthlyUpdates,
  StartupMonthlyUpdateDetails,
  ScoutEdit,
  ScoutDetails,
  StartupPublicQnA,
  TalentDetails,
  TalentEdit,
  NotifyInvestorHistory,
  EditNotifyInvestor,
  NotifyInvestors,
  MatchInvestors,
  ApprovedStartupDetails,
  ApprovedSPVView,
  ApprovedInvestorDetails,
  ApprovedStartupEdit,
  monthlyUpdateHistory,
  MatchStartups,
  ApprovedInvestorEdit,
  SimilarInvestors,
  BusinessInformation,
  ControllerInformation,
  ControllerDocumentVerification,
  BusinessDBAVerification,
  BusinessDocumentVerification,
  DocumentStatus,
  ApprovedInviteHistory,
  ApprovedInvestorHistory,
  ViewPreviousPost,
  ViewPostDetails,
  StartupHistory,
  TaxsDetails,
  TaxUploadDoc,
  ViewTaxesDoc,
  AdminInviteHistory,
  Portfolio,
  StartupCarryRecipients,
  StartupAddCarryRecipients,
  StartupEditCarryRecipients,
  ScoutEditCarrySharingAgreement,
  ScoutAddCarrySharingAgreement,
  StartupViewCarryRecipients,
} from "./Utils/LazyLoader";
import Subscribers from "./Container/Marketing/Subscribers/Subscribers";
import AddNewBlog from "./Container/Marketing/AddNewBlog/AddNewBlog";
import AddNewPodcast from "./Container/Marketing/AddNewPodcast/AddNewPodcast";
import EditBlog from "./Container/Marketing/EditBlog/EditBlog";
import EditPodcast from "./Container/Marketing/EditPodcast/EditPodcast";
import Chapters from "./Container/Education/LearnSeries65/Series65Chapter/Chapters/Chapters";
import AddNewChapterPreview from "./Container/Education/LearnSeries65/Series65Chapter/AddNewChapterPreview/AddNewChapterPreview";
import AddNewChapter from "./Container/Education/LearnSeries65/Series65Chapter/AddNewChapter/AddNewChapter";
import EditChapter from "./Container/Education/LearnSeries65/Series65Chapter/EditChapter/EditChapter";
import ChapterDetail from "./Container/Education/LearnSeries65/Series65Chapter/ChapterDetail/ChapterDetail";
import ChapterPreview from "./Container/Education/LearnSeries65/Series65Chapter/ChapterPreview/ChapterPreview";
import LearnerDetails from "./Container/Education/Learner/LearnerDetails/LearnerDetails";
import VideoLectures from "./Container/Education/LearnSeries65/Series65VideoLecture/VideoLectures/VideoLectures";
import AddNewVideoLecturePreview from "./Container/Education/LearnSeries65/Series65VideoLecture/AddNewVideoLecturePreview/AddNewVideoLecturePreview";
import AddNewVideoLecture from "./Container/Education/LearnSeries65/Series65VideoLecture/AddNewVideoLecture/AddNewVideoLecture";
import EditVideoLecture from "./Container/Education/LearnSeries65/Series65VideoLecture/EditVideoLecture/EditVideoLecture";
import VideoLectureDetail from "./Container/Education/LearnSeries65/Series65VideoLecture/VideoLectureDetail/VideoLectureDetail";
import VideoLecturePreview from "./Container/Education/LearnSeries65/Series65VideoLecture/VideoLecturePreview/VideoLecturePreview";
import InvestingChapters from "./Container/Education/LearnInvesting/InvestingChapter/Chapters/Chapters";
import InvestingAddNewChapterPreview from "./Container/Education/LearnInvesting/InvestingChapter/AddNewChapterPreview/AddNewChapterPreview";
import InvestingAddNewChapter from "./Container/Education/LearnInvesting/InvestingChapter/AddNewChapter/AddNewChapter";
import InvestingEditChapter from "./Container/Education/LearnInvesting/InvestingChapter/EditChapter/EditChapter";
import InvestingChapterDetail from "./Container/Education/LearnInvesting/InvestingChapter/ChapterDetail/ChapterDetail";
import InvestingChapterPreview from "./Container/Education/LearnInvesting/InvestingChapter/ChapterPreview/ChapterPreview";
import InvestingVideoLectures from "./Container/Education/LearnInvesting/InvestingVideoLecture/VideoLectures/VideoLectures";
import InvestingAddNewVideoLecturePreview from "./Container/Education/LearnInvesting/InvestingVideoLecture/AddNewVideoLecturePreview/AddNewVideoLecturePreview";
import InvestingAddNewVideoLecture from "./Container/Education/LearnInvesting/InvestingVideoLecture/AddNewVideoLecture/AddNewVideoLecture";
import InvestingEditVideoLecture from "./Container/Education/LearnInvesting/InvestingVideoLecture/EditVideoLecture/EditVideoLecture";
import InvestingPreviewVideoDetail from "./Container/Education/LearnInvesting/InvestingVideoLecture/VideoLectureDetail/VideoLectureDetail";
import InvestingPreviewVideoLecture from "./Container/Education/LearnInvesting/InvestingVideoLecture/VideoLecturePreview/VideoLecturePreview";
import Quizzes from "./Container/Education/LearnSeries65/Series65Quiz/Quizzes/Quizzes";
import AddNewQuizPreview from "./Container/Education/LearnSeries65/Series65Quiz/AddNewQuizPreview/AddNewQuizPreview";
import AddNewQuiz from "./Container/Education/LearnSeries65/Series65Quiz/AddNewQuiz/AddNewQuiz";
import EditQuiz from "./Container/Education/LearnSeries65/Series65Quiz/EditQuiz/EditQuiz";
import QuizDetail from "./Container/Education/LearnSeries65/Series65Quiz/QuizDetail/QuizDetail";
import QuizPreview from "./Container/Education/LearnSeries65/Series65Quiz/QuizPreview/QuizPreview";
import InvestingQuizzes from "./Container/Education/LearnInvesting/InvestingQuiz/Quizzes/Quizzes";
import InvestingAddNewQuizPreview from "./Container/Education/LearnInvesting/InvestingQuiz/AddNewQuizPreview/AddNewQuizPreview";
import InvestingAddNewQuiz from "./Container/Education/LearnInvesting/InvestingQuiz/AddNewQuiz/AddNewQuiz";
import InvestingEditQuiz from "./Container/Education/LearnInvesting/InvestingQuiz/EditQuiz/EditQuiz";
import InvestingQuizDetail from "./Container/Education/LearnInvesting/InvestingQuiz/QuizDetail/QuizDetail";
import InvestingQuizPreview from "./Container/Education/LearnInvesting/InvestingQuiz/QuizPreview/QuizPreview";
import ReadinessExams from "./Container/Education/LearnSeries65/Series65ReadinessExam/ReadinessExams/ReadinessExams";
import AddNewReadinessExamPreview from "./Container/Education/LearnSeries65/Series65ReadinessExam/AddNewReadinessExamPreview/AddNewReadinessExamPreview";
import AddNewReadinessExam from "./Container/Education/LearnSeries65/Series65ReadinessExam/AddNewReadinessExam/AddNewReadinessExam";
import EditReadinessExam from "./Container/Education/LearnSeries65/Series65ReadinessExam/EditReadinessExam/EditReadinessExam";
import ReadinessExamDetail from "./Container/Education/LearnSeries65/Series65ReadinessExam/ReadinessExamDetail/ReadinessExamDetail";
import ReadinessExamPreview from "./Container/Education/LearnSeries65/Series65ReadinessExam/ReadinessExamPreview/ReadinessExamPreview";
import InvestingReadinessExams from "./Container/Education/LearnInvesting/InvestingReadinessExam/ReadinessExams/ReadinessExams";
import InvestingAddNewReadinessExamPreview from "./Container/Education/LearnInvesting/InvestingReadinessExam/AddNewReadinessExamPreview/AddNewReadinessExamPreview";
import InvestingAddNewReadinessExam from "./Container/Education/LearnInvesting/InvestingReadinessExam/AddNewReadinessExam/AddNewReadinessExam";
import InvestingEditReadinessExam from "./Container/Education/LearnInvesting/InvestingReadinessExam/EditReadinessExam/EditReadinessExam";
import InvestingReadinessExamDetail from "./Container/Education/LearnInvesting/InvestingReadinessExam/ReadinessExamDetail/ReadinessExamDetail";
import InvestingReadinessExamPreview from "./Container/Education/LearnInvesting/InvestingReadinessExam/ReadinessExamPreview/ReadinessExamPreview";
import TransactionStartupDetails from "./Container/Transaction/StartupTransaction/StartupDetails/StartupDetails";
import TransactionInvestorDetails from "./Container/Transaction/InvestorTransaction/InvestorDetails/InvestorDetails";
import AddPortfolio from "./Container/Portfolio/AddPortfolio/AddPortfolio";
import EditPortfolio from "./Container/Portfolio/EditPortfolio/EditPortfolio";

const RouteConfig = {
  authenticated: [
    { path: routes.dashboard, component: DashBoard },
    { path: routes.welcome, component: Welcome },
    { path: routes.settings, component: Settings },
    { path: routes.waitlist, component: WaitList },
    { path: routes.rejected, component: Rejected },
    { path: routes.tracking, component: Tracking },
    { path: routes.scouts, component: scouts },
    { path: routes.portfolio, component: Portfolio },
    { path: routes.approved, component: Approved },
    { path: routes.analytics, component: Analytics },
    { path: routes.marketing, component: Marketing },
    { path: routes.education, component: Education },
    { path: routes.transactions, component: Transaction },
    { path: routes.talent, component: Talent },
    { path: routes.notifications, component: Notifications },
    { path: routes.documentVerificationStatus, component: DocumentStatus },
    {
      path: routes.approvedDocumentVerificationStatus,
      component: DocumentStatus,
    },
    {
      path: routes.trackingDocumentVerificationStatus,
      component: DocumentStatus,
    },
    { path: routes.startupDetails, component: WaitListStartupDetails },
    { path: routes.startupSPVFormation, component: StartupSPVFormation },
    { path: routes.startupInfoUpdate, component: StartupInfoUpdate },
    {
      path: routes.startupAvailableDwollaAccount,
      component: AvailableDwollaAccount,
    },
    {
      path: routes.startupBanknigProfileInformation,
      component: BankingProfileInformation,
    },
    {
      path: routes.startupBanknigDocVerification,
      component: BankingDocVerification,
    },
    {
      path: routes.startupSPVAddBankAccount,
      component: StartupSPVAddBankAccount,
    },
    {
      path: routes.startupSPVManageBankAccount,
      component: StartupSPVManageBankAccount,
    },
    { path: routes.vertifiedAccount, component: vertifiedAccount },
    { path: routes.NoBankAccount, component: NoBankAccount },
    { path: routes.AddDwollaAccount, component: BusinessInformation },
    { path: routes.startupEdit, component: WaitListStartupEdit },
    { path: routes.investorDetails, component: WaitListInvestorDetails },
    {
      path: routes.startupSPVManageDwollaAccount,
      component: AvailableDwollaAccount,
    },
    { path: routes.investorEdit, component: WaitListInvestorEdit },
    { path: routes.inviteHistory, component: AdminInviteHistory },
    { path: routes.ControllerInformation, component: ControllerInformation },
    { path: routes.ControllerInformation, component: ControllerInformation },
    {
      path: routes.TrackingControllerInformation,
      component: ControllerInformation,
    },
    {
      path: routes.controllerDocVerification,
      component: ControllerDocumentVerification,
    },
    {
      path: routes.approvedControllerDocVerification,
      component: ControllerDocumentVerification,
    },
    {
      path: routes.trackingControllerDocVerification,
      component: ControllerDocumentVerification,
    },
    {
      path: routes.businessDocVerification,
      component: BusinessDocumentVerification,
    },
    {
      path: routes.approvedBusinessDocVerification,
      component: BusinessDocumentVerification,
    },
    {
      path: routes.trackingBusinessDocVerification,
      component: BusinessDocumentVerification,
    },
    {
      path: routes.businessDBADocVerification,
      component: BusinessDBAVerification,
    },
    {
      path: routes.approvedBusinessDBADocVerification,
      component: BusinessDBAVerification,
    },
    {
      path: routes.trackingBusinessDBADocVerification,
      component: BusinessDBAVerification,
    },
    { path: routes.approvedStartupDetails, component: ApprovedStartupDetails },
    { path: routes.approvedStartupEdit, component: ApprovedStartupEdit },
    { path: routes.approvedStartupMatchInvestors, component: MatchInvestors },
    {
      path: routes.approvedStartupMonthlyUpdates,
      component: StartupMonthlyUpdates,
    },
    {
      path: routes.approvedStartupMonthlyUpdateDetails,
      component: StartupMonthlyUpdateDetails,
    },
    { path: routes.approvedSpvView, component: ApprovedSPVView },
    { path: routes.monthlyUpdateHistory, component: monthlyUpdateHistory },
    { path: routes.approvedStartupPublicQnA, component: StartupPublicQnA },
    { path: routes.approvedStartupCarryRecipients, component: StartupCarryRecipients },
    { path: routes.approvedStartupAddCarryRecipients, component: StartupAddCarryRecipients },
    { path: routes.approvedStartupEditCarryRecipients, component: StartupEditCarryRecipients },
    { path: routes.approvedStartupViewCarryRecipient, component: StartupViewCarryRecipients },
    {
      path: routes.approvedInvestorDetails,
      component: ApprovedInvestorDetails,
    },
    { path: routes.approvedInvestorEdit, component: ApprovedInvestorEdit },
    { path: routes.approvedInfoUpdate, component: StartupInfoUpdate },
    {
      path: routes.startupSPVManageBankAccount1,
      component: StartupSPVManageBankAccount,
    },
    { path: routes.approvedInvestorMatchStartups, component: MatchStartups },
    { path: routes.AddDwollaAccount1, component: BusinessInformation },
    { path: routes.similarInvestors, component: SimilarInvestors },
    {
      path: routes.approvedStartupInviteHistory,
      component: ApprovedInviteHistory,
    },
    { path: routes.approvedStartupInvestorHistory, component: StartupHistory },
    {
      path: routes.approvedInvestorHistory,
      component: ApprovedInvestorHistory,
    },
    { path: routes.ViewTaxesDoc, component: ViewTaxesDoc },
    { path: routes.approvedInvestorTaxes, component: TaxsDetails },
    { path: routes.approvedInvestorTaxesUploadDoc, component: TaxUploadDoc },
    { path: routes.PreviousPostUpdate, component: ViewPreviousPost },
    { path: routes.PreviousPostUpdateDetails, component: ViewPostDetails },
    { path: routes.NotifyInvestors, component: NotifyInvestors },
    { path: routes.EditNotifyInvestors, component: EditNotifyInvestor },
    { path: routes.NotifyHistory, component: NotifyInvestorHistory },
    { path: routes.subscribers, component: Subscribers },
    { path: routes.addNewMarketingBlog, component: AddNewBlog },
    { path: routes.addNewMarketingPodcast, component: AddNewPodcast },
    { path: routes.editBlog, component: EditBlog },
    { path: routes.editPodcast, component: EditPodcast },
    { path: routes.rejectedStartupDetails, component: RejectedStartupDetails },
    {
      path: routes.rejectedInvestorDetails,
      component: RejectedInvestorDetails,
    },
    { path: routes.rejectedStartupEdit, component: RejectedStartupEdit },
    { path: routes.rejectedInvestorEdit, component: RejectedInvestorEdit },
    { path: routes.trackingStartupDetails, component: TrackingStartupDetails },
    { path: routes.trackingStartupEdit, component: TrackingStartupEdit },
    { path: routes.trackingStartupUpdates, component: TrackingStartupUpdates },
    {
      path: routes.trackingStartupUpdateDetails,
      component: TrackingUpdateDetails,
    },
    {
      path: routes.startupTrackingSPVFormation,
      component: StartupSPVFormation,
    },
    { path: routes.startupTrackingInfoUpdate, component: StartupInfoUpdate },
    { path: routes.startupTrackingHistory, component: TrackingHistory },
    {
      path: routes.startupTrackingAvailableDwollaAccount,
      component: AvailableDwollaAccount,
    },
    { path: routes.AddTrackingDwollaAccount, component: BusinessInformation },
    {
      path: routes.startupTrackingManageBankAccount,
      component: StartupSPVManageBankAccount,
    },
    { path: routes.scoutDetails, component: ScoutDetails },
    { path: routes.scoutViewCarryRecipient, component: StartupViewCarryRecipients },
    { path: routes.scoutEdit, component: ScoutEdit },
    { path: routes.scoutEditCarrySharingAgreement, component: ScoutEditCarrySharingAgreement },
    { path: routes.scoutAddCarrySharingAgreement, component: ScoutAddCarrySharingAgreement },
    { path: routes.chapters, component: Chapters },
    { path: routes.addNewChapterPreview, component: AddNewChapterPreview },
    { path: routes.addNewChapter, component: AddNewChapter },
    { path: routes.editChapter, component: EditChapter },
    { path: routes.previewChapterDetail, component: ChapterDetail },
    { path: routes.previewChapter, component: ChapterPreview },
    { path: routes.videoLectures, component: VideoLectures },
    { path: routes.addNewVideoLecture, component: AddNewVideoLecture },
    { path: routes.addNewVideoLecturePreview, component: AddNewVideoLecturePreview },
    { path: routes.editVideoLecture, component: EditVideoLecture },
    { path: routes.videoLectureDetail, component: VideoLectureDetail },
    { path: routes.videoLecturePreview, component: VideoLecturePreview },
    { path: routes.investingChapters, component: InvestingChapters },
    { path: routes.investingAddNewChapterPreview, component: InvestingAddNewChapterPreview },
    { path: routes.investingAddNewChapter, component: InvestingAddNewChapter },
    { path: routes.investingEditChapter, component: InvestingEditChapter },
    {
      path: routes.investingChapterDetail,
      component: InvestingChapterDetail,
    },
    {
      path: routes.investingPreviewChapter,
      component: InvestingChapterPreview,
    },
    { path: routes.investingVideoLectures, component: InvestingVideoLectures },
    {
      path: routes.investingAddNewVideoLecturePreview,
      component: InvestingAddNewVideoLecturePreview,
    },
    {
      path: routes.investingAddNewVideoLecture,
      component: InvestingAddNewVideoLecture,
    },
    {
      path: routes.investingEditVideoLecture,
      component: InvestingEditVideoLecture,
    },
    {
      path: routes.investingVideoLectureDetail,
      component: InvestingPreviewVideoDetail,
    },
    {
      path: routes.investingVideoLecturePreview,
      component: InvestingPreviewVideoLecture,
    },
    { path: routes.quizzes, component: Quizzes },
    { path: routes.addNewQuizPreview, component: AddNewQuizPreview },
    { path: routes.addNewQuiz, component: AddNewQuiz },
    { path: routes.editQuiz, component: EditQuiz },
    { path: routes.quizDetail, component: QuizDetail },
    { path: routes.quizPreview, component: QuizPreview },
    { path: routes.investingQuizzes, component: InvestingQuizzes },
    { path: routes.investingAddNewQuizPreview, component: InvestingAddNewQuizPreview },
    { path: routes.investingAddNewQuiz, component: InvestingAddNewQuiz },
    { path: routes.investingEditQuiz, component: InvestingEditQuiz },
    { path: routes.investingQuizDetail, component: InvestingQuizDetail },
    { path: routes.investingQuizPreview, component: InvestingQuizPreview },
    { path: routes.readinessexams, component: ReadinessExams },
    { path: routes.addNewReadinessExamPreview, component: AddNewReadinessExamPreview },
    { path: routes.addNewReadinessExam, component: AddNewReadinessExam },
    { path: routes.editReadinessExam, component: EditReadinessExam },
    { path: routes.readinessExamDetail, component: ReadinessExamDetail },
    { path: routes.readinessExamPreview, component: ReadinessExamPreview },
    {
      path: routes.investingReadinessexams,
      component: InvestingReadinessExams,
    },
    {
      path: routes.investingAddNewReadinessExamPreview,
      component: InvestingAddNewReadinessExamPreview,
    },
    {
      path: routes.investingAddNewReadinessExam,
      component: InvestingAddNewReadinessExam,
    },
    {
      path: routes.investingEditReadinessExam,
      component: InvestingEditReadinessExam,
    },
    {
      path: routes.investingReadinessExamDetail,
      component: InvestingReadinessExamDetail,
    },
    {
      path: routes.investingReadinessExamPreview,
      component: InvestingReadinessExamPreview,
    },
    { path: routes.learnerDetails, component: LearnerDetails },
    { path: routes.talentDetails, component: TalentDetails },
    { path: routes.talentEdit, component: TalentEdit },
    {
      path: routes.transactionStartupDetails,
      component: TransactionStartupDetails,
    },
    {
      path: routes.transactionInvestorDetails,
      component: TransactionInvestorDetails,
    },
    {
      path: routes.addPortfolio,
      component: AddPortfolio
    },
    {
      path: routes.editPortfolio,
      component: EditPortfolio
    }
  ],
  unauthenticated: [
    { path: routes.login, component: Login },
    { path: routes.forgotPassword, component: ForgotPassword },
  ],
};

export default RouteConfig;
