import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Row,
  Input,
  message,
  Spin,
  Modal,
  DatePicker,
  Select,
  TimePicker,
} from "antd";
import {
  ClockCircleOutlined,
  MenuOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import * as examActions from "../../../../../Redux/Actions/ReadinessExamActions";
import moment from "moment";
import userIcon from "../../../../../Assets/Images/user.png";
import trash from "../../../../../Assets/Images/delete.svg";
import play from "../../../../../Assets/Images/play.svg";
import pause from "../../../../../Assets/Images/pause.svg";
import edit from "../../../../../Assets/Images/edit.svg";
import "./EditReadinessExam.scss";

const { Option } = Select;
const EditReadinessExam = ({
  match,
  examDetails,
  getExamDetails,
  editExam,
  loading,
  history,
  getChaptersList,
  chaptersList
}) => {
  const examId = match.params.examId;
  const user = JSON.parse(localStorage.getItem("userdata"));
  const [openschedulePublishModal, setOpenschedulePublishModal] =
    useState(false);
  const [schedulePublishTime, setSchedulePublishTime] = useState();
  const [examTitle, setExamTitle] = useState("");
  const [examCreator, setExamCreator] = useState("");
  const [examTime, setExamTime] = useState(1800);
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ content: "" });
  const [editExamIndex, setEditExamIndex] = useState();
  const [editQuestion, setEditQuestion] = useState();
  const [difficultyLevel, setDifficultyLevel] = useState();
  const [requiredChapters, setRequiredChapters] = useState([]);

  useEffect(() => {
    let data = {
      learning_type: "series65",
    };
    getChaptersList(user?.id, data);
  }, []);

  // Updates state variables with exam details when they are available.
  useEffect(() => {
    setExamTitle(examDetails?.title);
    setExamCreator(examDetails?.created_by);
    setExamTime(examDetails?.time);
    setQuestions(examDetails?.questions);
    setDifficultyLevel(examDetails?.difficulty);
    setRequiredChapters(examDetails?.chapters);
  }, [examDetails]);

  // Fetches exam details based on the examId.
  useEffect(() => {
    const l_data = JSON.parse(localStorage.getItem("learnSeries65EditNewExamData"));
    if (l_data && (examId == l_data?.exam_id)) {
      setExamTitle(l_data?.title);
      setExamCreator(l_data?.created_by);
      setExamTime(l_data?.time);
      setQuestions(l_data?.questions);
      setDifficultyLevel(l_data?.difficulty);
      setRequiredChapters(l_data?.chapters);
    } else {
      let examData = {
        exam_id: examId,
      };
      getExamDetails(examData, user?.id);
    }
  }, []);

  // Closes the schedule publish modal and resets the schedule publish time state.
  const onCloseSchedulePublishModal = () => {
    setOpenschedulePublishModal(false);
    setSchedulePublishTime();
  };

  // Initializes a new question object when creating a question.
  const onCreateQuestion = () => {
    if (!newQuestion?.content) {
      message.error("Enter Valid question content...");
      return;
    }
    setNewQuestion({
      ...newQuestion,
      options: ["", "", "", ""],
      correct_options: null,
      explanation: "",
      is_active: true,
    });
    setEditQuestion();
    setEditExamIndex();
  };

  /**
   * Saves the new question to the questions state and resets the new question state.
   * Displays a success message.
   */
  const onSaveQuestion = () => {
    if (
      !newQuestion.content ||
      newQuestion?.options?.some((item) => !item) ||
      (!newQuestion?.correct_options && newQuestion?.correct_options !== 0) ||
      !newQuestion?.explanation
    ) {
      message.error("Enter valid question detail...");
      return;
    }
    setQuestions(
      questions?.concat({
        ...newQuestion,
        correct_options: newQuestion?.options[newQuestion?.correct_options],
      })
    );
    setNewQuestion({ content: "" });
    message.success("Question Added");
  };

  /**
   * Toggles the activation status of a question.
   * @param {number} index - The index of the question in the questions array.
   */
  const onQuestionActionChange = (index) => {
    const ques = [...questions];
    ques[index]["is_active"] = !ques[index]["is_active"];
    setQuestions(ques);
  };

  /**
   * Marks a question as deleted in the questions state and displays a success message.
   * @param {number} index - The index of the question in the questions array.
   */
  const onQuestionDelete = (index) => {
    const ques = [...questions];
    ques[index].is_deleted = true;
    setQuestions(ques);
    message.success("Deleted");
  };

  /**
   * Saves the edited question to the questions state and resets the edit question state.
   * Displays a success message.
   * @param {number} index - The index of the question in the questions array.
   */
  const onEditSave = (index) => {
    if (
      !editQuestion.content ||
      editQuestion?.options?.some((item) => !item) ||
      (!editQuestion?.correct_options && editQuestion?.correct_options !== 0) ||
      !editQuestion?.explanation
    ) {
      message.error("Enter valid question detail...");
      return;
    }
    const ques = [...questions];
    ques[index] = {
      ...editQuestion,
      correct_options: editQuestion?.options[editQuestion?.correct_options],
    };
    setQuestions(ques);
    setEditQuestion();
    setEditExamIndex();
    message.success("Question Edited Successfully");
  };

  /**
   * Checks if the exam data is valid by verifying required fields.
   * @returns {boolean} - True if the exam data is valid, otherwise false.
   */
  const checkExamDataValid = () => {
    let isValid = false;
    const isQusetionsValid = questions?.every(
      (item) =>
        item?.content &&
        item?.options?.every((item) => item) &&
        item.correct_options &&
        item?.explanation
    );
    if (
      examCreator &&
      examTitle &&
      difficultyLevel &&
      examTime &&
      questions?.length > 0 &&
      isQusetionsValid
    ) {
      isValid = true;
    }
    return isValid;
  };

  /**
   * Publishes or previews an exam based on provided parameters.
   * Displays an error message if the exam data is not valid.
   * @param {boolean} isDraft - Indicates whether the exam is a draft.
   * @param {boolean} isPreview - Indicates whether the action is to preview the exam.
   */
  const onPublishExam = (isDraft, isPreview = false) => {
    const isValid = checkExamDataValid();
    if (!isValid) {
      message.error("Enter Valid Exam Details..", 2);
      return;
    }
    let data = {
      exam_id: examId,
      is_draft: isDraft,
      ...(schedulePublishTime && { publish_date: schedulePublishTime }),
      learning_type: "series65",
      title: examTitle?.trimEnd(),
      created_by: examCreator?.trimEnd(),
      time: examTime,
      questions: questions,
      difficulty: difficultyLevel,
      chapters: requiredChapters,
    };
    editExam(data, user?.id)
      .then(() => {
        message.success("Exam Edited Successfully");
        localStorage.removeItem("learnSeries65EditNewExamData")
        history.push({
          pathname: `/education/learn-series65/exams`,
          state: { searchPlaceholder: "Search Exam", back: true },
        });
      })
      .catch((e) => {
        message.error(e || "Edit Exam Failed..", 2);
      });
  };

  /**
   * Converts seconds to HH:MM:SS format.
   * @param {number} d - The time duration in seconds.
   * @returns {string} - The time duration in HH:MM:SS format.
   */
  const secondsToHms = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    return h + ":" + m + ":" + s;
  };

  const schedulePublishModal = (
    // Modal component for scheduling exam publish.
    <Modal
      visible={openschedulePublishModal}
      onCancel={() => onCloseSchedulePublishModal()}
      centered
      title={"Select Schedule"}
      okText={"Publish"}
      onOk={() => onPublishExam(false)}
      className="schedule-publish-modal"
    >
      <div className="modal-content-detail">
        <label>Select Publish Schedule</label>
        <DatePicker
          onChange={(date) => setSchedulePublishTime(date?.toDate())}
          showTime
          showNow={false}
          disabledDate={(current) => current && current.valueOf() < Date.now()}
        />
      </div>
    </Modal>
  );

  return (
    <div className="edit-exam-container">
      <div className="top-edit">
        <h6> Edit Readiness Exam </h6>
        <div className="edit-right">
          <Button
            className="save-btn"
            // onClick={() => onPublishExam(true, true)}
            onClick={async () => {
              let examDetails = {
                exam_id: examId,
                // is_draft: isDraft,
                ...(schedulePublishTime && { publish_date: schedulePublishTime }),
                learning_type: "series65",
                title: examTitle?.trimEnd(),
                created_by: examCreator?.trimEnd(),
                time: examTime,
                questions: questions,
                difficulty: difficultyLevel,
                chapters: requiredChapters,
              }
              await localStorage.setItem("learnSeries65EditNewExamData", JSON.stringify(examDetails))
              history.push(`/education/learn-series65/exam-preview/${examId}`, {
                examDetails
              })
            }}
          >
            Preview
          </Button>
          <Button className="save-btn" onClick={() => onPublishExam(true)}>
            Save Draft
          </Button>
          <Button
            className="save-btn"
            onClick={() => setOpenschedulePublishModal(true)}
          >
            Schedule Publish
          </Button>
          <Button className="publish-btn" onClick={() => onPublishExam(false)}>
            Publish
          </Button>
        </div>
      </div>
      <div className="edit-exam-wrap">
        {loading ? (
          <Spin />
        ) : (
          <div>
            <Row gutter={32}>
              <Col span={12}>
                <div className="field-box">
                  <label>Exam Title</label>
                  <Input
                    value={examTitle}
                    placeholder={"Exam title"}
                    className="inputs-box"
                    onChange={(e) => setExamTitle(e.target.value?.trimStart())}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <label>Created By</label>
                  <Input
                    prefix={
                      <img
                        src={userIcon}
                        className="prefix-icon"
                        alt="user-icon"
                      />
                    }
                    value={examCreator}
                    placeholder={"Created By"}
                    className="inputs-box"
                    onChange={(e) =>
                      setExamCreator(e.target.value?.trimStart())
                    }
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col span={12}>
                <Row className="timer-wrap">
                  <Col span={3}>
                    <div className="clock-wrap">
                      <ClockCircleOutlined />
                    </div>
                  </Col>
                  <Col span={6}>
                    <span className="time-limit">Set time limit</span>
                  </Col>
                  <Col span={12}>
                    <TimePicker
                      showNow={false}
                      value={moment(secondsToHms(examTime), "HH:mm:ss")}
                      size="middle"
                      onChange={(value) => {
                        let totalSeconds =
                          moment(value, "HH:mm:ss").hours() * 60 * 60 +
                          moment(value, "HH:mm:ss").minutes() * 60 +
                          moment(value, "HH:mm:ss").seconds();
                        setExamTime(isNaN(totalSeconds) ? 1800 : totalSeconds);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <Select
                    className="selection-option"
                    placeholder="Select Difficulty Level"
                    value={difficultyLevel}
                    showArrow
                    onChange={(value) => setDifficultyLevel(value)}
                  >
                    <Option key={"Fundamentals"} value={"Fundamentals"}>
                      Fundamentals
                    </Option>
                    <Option key={"Intermediate"} value={"Intermediate"}>
                      Intermediate
                    </Option>
                    <Option key={"Advanced"} value={"Advanced"}>
                      Advanced
                    </Option>
                    <Option key={"Expert"} value={"Expert"}>
                      Expert
                    </Option>
                  </Select>
                </div>
              </Col>
              <Col span={12}>
                <div className="field-box">
                  <Select
                    className="selection-option"
                    placeholder="Select Chapters"
                    optionFilterProp="children"
                    showSearch
                    mode="multiple"
                    value={requiredChapters}
                    showArrow
                    onChange={(value) => setRequiredChapters(value)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {chaptersList?.map((item) => {
                      return (
                        <Option
                          key={item?.id}
                          value={item?.id}
                        >{`Chapter ${item?.chapter_number}`}</Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
            {!newQuestion?.options && (
              <Row>
                <div className="add-que-wrapper">
                  <p className="label">New Question</p>
                  <Input
                    placeholder="Question content"
                    className="inputs-box"
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        content: e.target.value,
                      })
                    }
                  />
                  <Button className="create-btn" onClick={onCreateQuestion}>
                    <PlusOutlined /> Create Question
                  </Button>
                </div>
              </Row>
            )}
            {newQuestion?.options && (
              <Row className="add-que">
                <div className="add-question-content">
                  <div className="question">
                    <div className="left-section">
                      <div className="prefix">
                        <MenuOutlined />
                      </div>
                      <span className="content">{newQuestion?.content}</span>
                    </div>
                    <div className="right-section">
                      <SaveOutlined
                        color="#425a85"
                        size={"18px"}
                        onClick={onSaveQuestion}
                      />
                      <img
                        src={trash}
                        alt="delete"
                        onClick={() => setNewQuestion({ content: "" })}
                      />
                    </div>
                  </div>
                </div>
                <div className="options-wrapper">
                  <div className="correct">
                    <span>Correct</span>
                  </div>
                  {newQuestion?.options?.map((item, index) => {
                    return (
                      <div className="option-wrap">
                        <div className="left-section">
                          <div className="prefix">
                            <MenuOutlined />
                          </div>
                          <Input
                            value={item}
                            onChange={(e) => {
                              const opts = newQuestion?.options;
                              opts[index] = e.target.value?.trimStart();
                              setNewQuestion({ ...newQuestion, options: opts });
                            }}
                            placeholder={`Option ${index + 1}`}
                            maxLength={152}
                          />
                        </div>
                        <div className="right-section">
                          <div
                            className="circle-outer"
                            onClick={() =>
                              setNewQuestion({
                                ...newQuestion,
                                correct_options: index,
                              })
                            }
                          >
                            {index === newQuestion?.correct_options && (
                              <div className="circle-inner"></div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <TextArea
                    value={newQuestion?.explanation}
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        explanation: e.target.value?.trimStart(),
                      })
                    }
                    rows={3}
                    placeholder="Write answer explanation"
                    className="text-area-box"
                  />
                </div>
              </Row>
            )}
            <Row>
              <div className="questions-list-wrap">
                {questions?.map((question, index) => {
                  return index === editExamIndex && !question?.is_deleted ? (
                    <div className="add-que">
                      <div className="add-question-content">
                        <div className="question">
                          <div className="left-section">
                            <div className="prefix">
                              <MenuOutlined />
                            </div>
                            <span className="content">
                              {editQuestion?.content}
                            </span>
                          </div>
                          <div className="right-section">
                            <SaveOutlined
                              color="#425a85"
                              size={"18px"}
                              onClick={() => onEditSave(index)}
                            />
                            <img
                              src={trash}
                              alt="delete"
                              onClick={() => onQuestionDelete(index)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="options-wrapper">
                        <div className="correct">
                          <span>Correct</span>
                        </div>
                        {editQuestion?.options?.map((item, ind) => {
                          return (
                            <div className="option-wrap">
                              <div className="left-section">
                                <div className="prefix">
                                  <MenuOutlined />
                                </div>
                                <Input
                                  value={item}
                                  onChange={(e) => {
                                    const opts = editQuestion?.options;
                                    opts[ind] = e.target.value;
                                    setEditQuestion({
                                      ...editQuestion,
                                      options: opts,
                                    });
                                  }}
                                  placeholder={`Option ${index + 1}`}
                                  maxLength={152}
                                />
                              </div>
                              <div className="right-section">
                                <div
                                  className="circle-outer"
                                  onClick={() =>
                                    setEditQuestion({
                                      ...editQuestion,
                                      correct_options: ind,
                                    })
                                  }
                                >
                                  {ind === editQuestion?.correct_options && (
                                    <div className="circle-inner"></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <TextArea
                          value={editQuestion?.explanation}
                          onChange={(e) =>
                            setEditQuestion({
                              ...editQuestion,
                              explanation: e.target.value,
                            })
                          }
                          rows={3}
                          placeholder="Write answer explanation"
                          className="text-area-box"
                        />
                      </div>
                    </div>
                  ) : !question?.is_deleted ? (
                    <div className="add-question-content">
                      <div className="question">
                        <div className="left-section">
                          <div className="prefix">
                            <MenuOutlined />
                          </div>
                          <span className="content">{question?.content}</span>
                        </div>
                        <div className="right-section">
                          <img
                            src={question?.is_active ? pause : play}
                            alt={question?.is_active ? "pause" : "play"}
                            onClick={() => onQuestionActionChange(index)}
                          />
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => {
                              setEditExamIndex(index);
                              setEditQuestion({
                                ...question,
                                correct_options: question?.options?.findIndex(
                                  (item) => item === question?.correct_options
                                ),
                              });
                            }}
                          />
                          <img
                            src={trash}
                            alt="delete"
                            onClick={() => onQuestionDelete(index)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            </Row>
          </div>
        )}
      </div>
      {schedulePublishModal}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    examDetails: state.ReadinessExam.examDetails,
    loading: state.ReadinessExam.loading,
    userData: state.Auth.userData,
    chaptersList: state.VideoLecture.chaptersList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExamDetails: (data, id) =>
      dispatch(examActions.getExamDetails(data, id)),
    editExam: (data, id, onSuccess) =>
      dispatch(examActions.editExam(data, id, onSuccess)),
    getChaptersList: (id, data) =>
      dispatch(examActions.getChaptersList(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditReadinessExam);
