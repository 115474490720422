import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";
import { message } from "antd";

// Action to get data for rejected startups
export const getRejectedStartupsData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_REJECTED_STARTUPS_DATA_INIT,
    });
    await axios
      .post("admin/get-rejected", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_REJECTED_STARTUPS_DATA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_REJECTED_STARTUPS_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete a rejected startup by ID
export const deleteRejectedStartup = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_REJECTED_STARTUP_INIT,
    });
    await axios
      .post("admin/rejected-delete", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_REJECTED_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_REJECTED_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get details of a rejected startup by ID
export const getStartupDetailsById = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_REJECTED_STARTUP_BY_ID_INIT,
    });
    await axios
      .post("admin/get-single-rejected", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_REJECTED_STARTUP_BY_ID_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_REJECTED_STARTUP_BY_ID_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update a rejected startup's information
export const updateStartup = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_REJECTED_STARTUP_INIT,
    });
    await axios
      .post("admin/edit-rejected", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_REJECTED_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_REJECTED_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a memo related to a rejected startup
export const uploadRejectMemo = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_MEMO_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_MEMO_SUCCESS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_MEMO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a slide deck related to a rejected startup
export const uploadRejectSlideDeck = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_STARTUP_SLIDEDECK_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_STARTUP_SLIDEDECK_SUCCESS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_STARTUP_SLIDEDECK_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Helper function for checking rejected permission (startup or investor) and navigating accordingly
const investorCall = async (dispatch, navigate, flag) => {
  axios
    .post("admin/get-rejected", { is_inv: true })
    .then((res1) => {
      if (res1?.data) {
        localStorage.setItem("rejected_tab", flag ? "startups" : "investors");
        dispatch({
          type: actionTypes.REJECTED_PERMISSION,
          payload: {
            rejected_startup_permission: flag,
            rejected_investor_permission: true,
          },
        });
        navigate.push("/rejected");
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.REJECTED_PERMISSION,
        payload: {
          rejected_startup_permission: flag,
          rejected_investor_permission: false,
        },
      });
      if (!flag) {
        message.error(
          "You don't have appropriate permission to access Rejected",
          2
        );
        navigate.push("/welcome");
      }
    });
};

// Action to check and set rejected permission and navigate accordingly
export const checkRejectedPermission = (navigate) => {
  return async (dispatch) => {
    await axios
      .post("admin/get-rejected", { is_inv: false })
      .then((res) => {
        if (res?.data) {
          localStorage.setItem("rejected_tab", "startups");
          investorCall(dispatch, navigate, true);
        }
        navigate.push("/rejected");
      })
      .catch((error) => {
        investorCall(dispatch, navigate, false);
      });
  };
};
