import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./Reducers/AuthReducer";
import NotificationsReducer from "./Reducers/NotificationReducer";
import SearchReducer from "./Reducers/SearchReducer";
import SettingsReducer from "./Reducers/SettingsReducer";
import StartupsReducer from "./Reducers/StartupsReducer";
import InvestorReducer from "./Reducers/InvestorsReducer";
import ApprovedStartupsReducer from "./Reducers/ApprovedStartupsReducer";
import ApprovedInvestorsReducer from "./Reducers/ApprovedInvestorsReducer";
import MarketingReducer from "./Reducers/MarketingReducer";
import AnalyticsReducer from "./Reducers/AnalyticsReducer";
import DashboardReducer from "./Reducers/DashboardReducer";
import RejectedStartupsReducer from "./Reducers/RejectedStartupsReducer";
import RejectedInvestorsReducer from "./Reducers/RejectedInvestorsReducer";
import TrackingReducer from "./Reducers/TrackingReducer";
import ScoutsReducer from "./Reducers/ScoutsReducer";
import ChapterReducer from "./Reducers/ChaptersReducer";
import VideoLectureReducer from "./Reducers/VideoLecturesReducer";
import QuizzesReducer from "./Reducers/quizzesReducer";
import ReadinessExamReducer from "./Reducers/ReadinessExamReducer";
import LearnersReducer from "./Reducers/LearnerReducer";
import TalentReducer from "./Reducers/TalentReducer";
import TransactionReducer from "./Reducers/TransactionReducer";
import PortfolioReducer from "./Reducers/PortfolioReducer"

const rootReducer = combineReducers({
  Auth: AuthReducer,
  Notifications: NotificationsReducer,
  Settings: SettingsReducer,
  Startups: StartupsReducer,
  Investors: InvestorReducer,
  ApprovedStartups: ApprovedStartupsReducer,
  ApprovedInvestors: ApprovedInvestorsReducer,
  Search: SearchReducer,
  Marketing: MarketingReducer,
  Analytics: AnalyticsReducer,
  Dashboard: DashboardReducer,
  RejectedStartups: RejectedStartupsReducer,
  RejectedInvestors: RejectedInvestorsReducer,
  Tracking: TrackingReducer,
  Scouts: ScoutsReducer,
  Chapter: ChapterReducer,
  VideoLecture: VideoLectureReducer,
  Quizzes: QuizzesReducer,
  ReadinessExam: ReadinessExamReducer,
  Learners: LearnersReducer,
  Talent: TalentReducer,
  Transactions: TransactionReducer,
  Portfolio: PortfolioReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
