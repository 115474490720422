import * as actionTypes from "./ActionTypes";
import axios from "../../Utils/api";
import { message } from "antd";

// Action to get tracking data based on the provided 'data'.
export const getTrackingData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRACKING_DATA_INIT,
    });
    await axios
      .post("admin/get-tracking", data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TRACKING_DATA_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TRACKING_DATA_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update tracking status based on the provided 'data' and 'id'.
export const updateTrackingStatus = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRACKING_STATUS_INIT,
    });
    await axios
      .post("admin/set-data", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STATUS_SUCCESS,
          payload: res.data,
          trackingId: id,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STATUS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to delete a tracking startup based on the provided 'data' and 'id'.
export const deleteTrackingStartup = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TRACKING_STARTUP_INIT,
    });
    await axios
      .post("admin/tracking-delete", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_TRACKING_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_TRACKING_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get tracking startup details by 'id'.
export const getTrackingStartupDetailsById = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRACKING_STARTUP_BY_ID_INIT,
    });
    await axios
      .post("admin/get-single-tracking", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TRACKING_STARTUP_BY_ID_SUCCESS,
          payload: res.data?.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TRACKING_STARTUP_BY_ID_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update tracking startup action based on the provided 'data' and 'id'.
export const updateTrackingStartupAction = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRACKING_STARTUP_ACTION_INIT,
    });
    await axios
      .post("admin/set-approved-action", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_ACTION_SUCCESS,
          payload: res.data?.data,
          approved_action: data.approved_action,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_ACTION_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update tracking startup based on the provided 'data' and 'id'.
export const updateTrackingStartup = (data, id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRACKING_STARTUP_INIT,
    });
    await axios
      .post("admin/set-data", data, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a memo file.
export const uploadMemo = (uploadMemo, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_TRACKING_MEMO_INIT,
    });
    await axios
      .post("admin/mime-files", uploadMemo)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_MEMO_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_MEMO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a demo video file.
export const uploadDemoVideo = (uploadDemo, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_TRACKING_DEMO_VIDEO_INIT,
    });
    await axios
      .post("admin/mime-files", uploadDemo)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_DEMO_VIDEO_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_DEMO_VIDEO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a pitch video file.
export const uploadPitchVideo = (uploadPitchVideo, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_TRACKING_PITCH_VIDEO_INIT,
    });
    await axios
      .post("admin/mime-files", uploadPitchVideo)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_PITCH_VIDEO_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_PITCH_VIDEO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload a slide deck file.
export const uploadSlideDeck = (slideDeckData, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_SLIDEDECK_INIT,
    });
    await axios
      .post("admin/mime-files", slideDeckData)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_SLIDEDECK_SUCCESS,
          payload: { successData: res.data, data: data },
        });

        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_SLIDEDECK_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to upload helpful documents.
export const uploadHelpfuldocs = (uploadHelpfuldocs, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_TRACKING_HELPFUL_DOCS_INIT,
    });
    await axios
      .post("admin/mime-files", uploadHelpfuldocs)
      .then((res) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_HELPFUL_DOCS_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPLOAD_TRACKING_HELPFUL_DOCS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};
// Action to update the banner image for a tracking startup.
export const updateTrackingBannerImage = (bannerImg, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRACKING_STARTUP_BANNER_IMAGE_INIT,
    });
    await axios
      .post("admin/mime-files", bannerImg)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_BANNER_IMAGE_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_BANNER_IMAGE_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to update the company logo for a tracking startup.
export const updateTrackingCompanyLogo = (companyLogo, data) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRACKING_STARTUP_COMPANY_LOGO_INIT,
    });
    await axios
      .post("admin/mime-files", companyLogo)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_COMPANY_LOGO_SUCCESS,
          payload: { successData: res.data, data: data },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_TRACKING_STARTUP_COMPANY_LOGO_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get tracking updates based on a provided 'id'.
export const getTrackingUpdates = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRACKING_UPDATES_INIT,
    });
    await axios
      .post("admin/get-monthly-updates", {}, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TRACKING_UPDATES_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TRACKING_UPDATES_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to get details of a specific tracking update based on 'id'.
export const getTrackingUpdateDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRACKING_UPDATE_DETAILS_INIT,
    });
    await axios
      .post("admin/get-single-monthly-update", {}, { params: { id } })
      .then((res) => {
        dispatch({
          type: actionTypes.GET_TRACKING_UPDATE_DETAILS_SUCCESS,
          payload: res.data,
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_TRACKING_UPDATE_DETAILS_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to check tracking permission and navigate accordingly.
export const checkTrackingPermission = (navigate) => {
  return async (dispatch) => {
    await axios
      .post("admin/get-tracking", { page_num: 1, page_size: 10 })
      .then((res) => {
        dispatch({
          type: actionTypes.TRACKING_PERMISSION,
          payload: res.data,
        });
        localStorage.removeItem("trackingPage");
        navigate.push("/tracking");
      })
      .catch((error) => {
        message.error(
          "You don't have appropriate permission to access Tracking"
        );
        navigate.push("/welcome");
      });
  };
};

export const handleRemoveCoInvestor = (payload) => {
  return {
    type: actionTypes.REMOVE_CO_INVESTOR,
    payload: payload,
  };
};

// Action to update co investor icon
export const updateCoInvestorIcon = (data, index) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_INVESTOR_ICON_INIT,
    });
    await axios
      .post("admin/mime-files", data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_INVESTOR_ICON_SUCCESS,
          payload: { successData: res.data, data: data, index: index },
        });
        return;
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_INVESTOR_ICON_FAILED,
        });
        throw error?.response?.data?.error;
      });
  };
};

// Action to handle new co investor info locally
export const handleNewCoInvestorAddLocally = payload => {
  return {
    type: actionTypes.ADD_NEW_CO_INVESTOR_LOCALLY,
    payload: payload,
  }
}