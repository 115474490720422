// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  approvedInvestorsData: [],
  loading: false,
  getApprovedInvestorloading: false,
  totalApprovedInvestors: 0,
  approvedInvestorDetails: {},
  matchStartups: [],
  totalMatchStartups: 0,
  suggestedStartups: [],
  similarInvestors: [],
  totalSimilarInvestors: 0,
  viewCount: 0,
  notViewCount: 0,
  investorNameHistory: "",
  numOfViewedHistory: 0,
  amountInvestedHistory: 0,
  averageInvHistory: 0,
  totalInvestedHistory: 0,
  totalPassedHistory: 0,
  investmentHistory: [],
  reasonCount: {},
  investorTaxInfo: {},
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_APPROVED_INVESTORS_DATA_INIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_APPROVED_INVESTORS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedInvestorsData: action.payload?.data,
        totalApprovedInvestors: action.payload?.total_count,
      };
    case actionTypes.GET_APPROVED_INVESTORS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        approvedInvestorsData: [],
        totalApprovedInvestors: 0,
        error: action.payload,
      };
    case actionTypes.UPDATE_APPROVED_INVESTOR_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_APPROVED_INVESTOR_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        approvedInvestorsData: state?.approvedInvestorsData?.map((item) => {
          if (item?.id === action?.id) {
            return {
              ...item,
              approved_action: action?.approved_action,
            };
          }
          return { ...item };
        }),
        approvedInvestorDetails: {
          ...state?.approvedInvestorDetails,
          approved_action: action?.approved_action,
        },
      };
    case actionTypes.UPDATE_APPROVED_INVESTOR_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_APPROVED_INVESTOR_BY_ID_INIT:
      return {
        ...state,
        getApprovedInvestorloading: true,
      };
    case actionTypes.GET_APPROVED_INVESTOR_BY_ID_SUCCESS:
      return {
        ...state,
        getApprovedInvestorloading: false,
        approvedInvestorDetails: action.payload,
      };
    case actionTypes.GET_APPROVED_INVESTOR_BY_ID_FAILED:
      return {
        ...state,
        getApprovedInvestorloading: false,
        approvedInvestorDetails: {},
      };
    case actionTypes.SET_APPROVED_INVESTOR_VISIBILITY_INIT:
      return {
        ...state,
      };
    case actionTypes.SET_APPROVED_INVESTOR_VISIBILITY_SUCCESS:
      return {
        ...state,
        approvedInvestorDetails: {
          ...state?.approvedInvestorDetails,
          show_profile: action.payload,
        },
      };
    case actionTypes.SET_APPROVED_INVESTOR_VISIBILITY_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_APPROVED_INVESTOR_MATCH_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_APPROVED_INVESTOR_MATCH_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        matchStartups: action.payload?.investor_match_data,
        totalMatchStartups: action.payload?.total_data,
        viewCount: action.payload?.viewed_count,
        notViewCount: action.payload?.not_viewed_count,
        notViewStartups: action.payload?.not_viewed,
        viewedStartups: action.payload?.viewed,
      };
    case actionTypes.GET_APPROVED_INVESTOR_MATCH_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
        matchStartups: [],
        totalMatchStartups: 0,
        viewCount: 0,
        notViewCount: 0,
      };
    case actionTypes.GET_STARTUP_SUGGESTIONS_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_STARTUP_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestedStartups: action.payload?.found_data,
      };
    case actionTypes.GET_STARTUP_SUGGESTIONS_FAILED:
      return {
        ...state,
        suggestedStartups: [],
      };
    case actionTypes.ADD_APPROVED_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_APPROVED_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_APPROVED_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_SIMILAR_INVESTORS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_SIMILAR_INVESTORS_SUCCESS:
      return {
        ...state,
        similarInvestors: action.payload?.similar_investor_data,
        totalSimilarInvestors: action.payload?.total_data,
        loading: false,
      };
    case actionTypes.GET_SIMILAR_INVESTORS_FAILED:
      return {
        ...state,
        similarInvestors: [],
        totalSimilarInvestors: 0,
        loading: false,
      };
    case actionTypes.UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_INIT:
      return {
        ...state,
      };
    case actionTypes.UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        approvedInvestorDetails: {
          ...state.approvedInvestorDetails,
          profile_pic_link: action?.payload?.url,
        },
      };
    case actionTypes.UPDATE_APPROVED_INVESTOR_PROFILE_IMAGE_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_APPROVED_INVESTOR_HISTORY_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_APPROVED_INVESTOR_HISTORY_SUCCESS:
      return {
        ...state,
        investorNameHistory: action.payload?.history_data?.investor_name,
        averageInvHistory: action.payload?.history_data?.avg_amount_committed,
        numOfViewedHistory: action.payload?.history_data?.total_views,
        amountInvestedHistory: action.payload?.history_data?.amount_committed,
        totalInvestedHistory: action.payload?.history_data?.total_invested,
        totalPassedHistory: action.payload?.history_data?.total_passed,
        investmentHistory: action.payload?.history_data?.data,
        data_count: action.payload?.history_data?.data_count,
        team_count: action.payload?.history_data?.team_count,
        product_count: action.payload?.history_data?.product_count,
        other_count: action.payload?.history_data?.other_count,
        market_count: action.payload?.history_data?.market_count,
        all_count:
          action.payload?.history_data?.team_count +
          action.payload?.history_data?.product_count +
          action.payload?.history_data?.other_count +
          action.payload?.history_data?.market_count,
        loading: false,
      };
    case actionTypes.GET_APPROVED_INVESTOR_HISTORY_FAILED:
      return {
        ...state,
        numOfViewedHistory: 0,
        amountInvestedHistory: 0,
        averageInvHistory: 0,
        totalInvestedHistory: 0,
        totalPassedHistory: 0,
        investmentHistory: [],
        reasonCount: {},
        loading: false,
      };
    case actionTypes.DELETE_MATCH_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MATCH_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_MATCH_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_INVESTOR_TAX_INFO_INIT:
      return {
        ...state,
        investorTaxInfo: {},
      };
    case actionTypes.GET_INVESTOR_TAX_INFO_SUCCESS:
      return {
        ...state,
        investorTaxInfo: action?.payload,
      };
    case actionTypes.GET_INVESTOR_TAX_INFO_FAILED:
      return {
        ...state,
      };
    case actionTypes.GENERAL_FILES_UPLOADER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GENERAL_FILES_UPLOADER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GENERAL_FILES_UPLOADER_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.INVESTOR_UPLOAD_TAX_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.INVESTOR_UPLOAD_TAX_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.INVESTOR_UPLOAD_TAX_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default store;
