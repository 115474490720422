import React, { useEffect, useRef } from "react";
import { Input } from "antd";

const Location = ({
  location,
  setLocation,
  multipleLocations,
  setAbleToAdd,
  ableToAdd,
}) => {
  // Reference for the Google Places Autocomplete
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    /*global google*/

    // Function to create an instance of Google Places Autocomplete
    const createAutocomplete = (elementId, handler) => {
      const autoComplete = new google.maps.places.Autocomplete(
        document.getElementById(elementId),
        { types: ["(cities)"] }
      );
      autoComplete.addListener("place_changed", handler);
      return autoComplete;
    };

    autoCompleteRef.current = multipleLocations
      ? createAutocomplete("autocomplete1", handlePlaceSelect1)
      : createAutocomplete("autocomplete", handlePlaceSelect);
  }, [multipleLocations]);

  // Handle selection for Autocomplete
  const handlePlaceSelect = () => {
    const addressObject = autoCompleteRef.current.getPlace();
    if (addressObject) {
      setAbleToAdd(true);
    }
    setLocation && setLocation(addressObject.formatted_address);
  };

  // Handle selection for Autocomplete1 (if multipleLocations)
  const handlePlaceSelect1 = () => {
    const addressObject = autoCompleteRef.current.getPlace();
    if (addressObject) {
      setAbleToAdd(true);
    }
    setLocation && setLocation(addressObject.formatted_address);
  };

  // Handle change in the Input field
  const handleChange = (e) => {
    if (ableToAdd) {
      setAbleToAdd(false);
    }
    setLocation && setLocation(e.target.value);
  };

  // Determine the ID of the Input based on 'multipleLocations' prop
  const inputId = multipleLocations ? "autocomplete1" : "autocomplete";
  
  return (
    <div className="location-container">
      {/* Input component for location */}
      <Input
        id={inputId}
        onChange={handleChange}
        placeholder="Location"
        value={location}
        className="inputs-box"
      />
    </div>
  );
};

export default Location;
