// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  chaptersData: [],
  totalChaptersCount: 0,
  chapterDetails: {},
  loading: false,
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LEARN_SERIES_CHAPTERS_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTERS_DATA_SUCCESS:
      return {
        ...state,
        chaptersData: action.payload?.data || [],
        totalChaptersCount: action.payload?.total_count || 0,
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTERS_DATA_FAILED:
      return {
        ...state,
        chaptersData: [],
        totalChaptersCount: 0,
        loading: false,
      };
    case actionTypes.TOGGLE_LEARN_SERIES_CHAPTER_ACTION_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TOGGLE_LEARN_SERIES_CHAPTER_ACTION_SUCCESS:
      return {
        ...state,
        chaptersData: state?.chaptersData?.map((item) => {
          if (item?.id === action?.chapterId) {
            item.is_active = action?.toggleState;
          }
          return item;
        }),
        loading: false,
      };
    case actionTypes.TOGGLE_LEARN_SERIES_CHAPTER_ACTION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_LEARN_SERIES_CHAPTER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_LEARN_SERIES_CHAPTER_SUCCESS:
      return {
        ...state,
        chaptersData: state?.chaptersData?.filter(
          (item) => item?.id !== action?.chapterId
        ),
        loading: false,
      };
    case actionTypes.DELETE_LEARN_SERIES_CHAPTER_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_LEARN_SERIES65_CHAPTER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_LEARN_SERIES65_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_LEARN_SERIES65_CHAPTER_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTER_DETAILS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTER_DETAILS_SUCCESS:
      return {
        ...state,
        chapterDetails: action?.payload?.data || {},
        loading: false,
      };
    case actionTypes.GET_LEARN_SERIES_CHAPTER_DETAILS_FAILED:
      return {
        ...state,
        chapterDetails: {},
        loading: false,
      };
    case actionTypes.EDIT_LEARN_SERIES_CHAPTER_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EDIT_LEARN_SERIES_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_LEARN_SERIES_CHAPTER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default store;
