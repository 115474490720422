// This module defines various route paths used in the admin portal.
const routes = {
  login: "/login",
  forgotPassword: "/forgot/password",
  welcome: "/welcome",
  dashboard: "/dashboard",
  waitlist: "/waitlist",
  rejected: "/rejected",
  tracking: "/tracking",
  scouts: "/scouts",
  portfolio: "/portfolio",
  approved: "/approved",
  analytics: "/analytics",
  marketing: "/marketing",
  subscribers: "/marketing/subscribers",
  education: "/education",
  chapters: "/education/learn-series65/chapters",
  addNewChapterPreview: "/education/learn-series65/add-chapter-preview",
  addNewChapter: "/education/learn-series65/add-chapter",
  editChapter: "/education/learn-series65/chapter-edit/:chapterId",
  previewChapterDetail: "/education/learn-series65/chapter-detail/:chapterId",
  previewChapter: "/education/learn-series65/chapter-preview/:chapterId",
  videoLectures: "/education/learn-series65/video-lectures",
  addNewVideoLecturePreview: "/education/learn-series65/add-video-lecture-preview",
  addNewVideoLecture: "/education/learn-series65/add-video-lecture",
  editVideoLecture: "/education/learn-series65/video-lecture-edit/:videoId",
  videoLectureDetail:
    "/education/learn-series65/video-lecture-detail/:videoId",
    videoLecturePreview:
    "/education/learn-series65/video-lecture-preview/:videoId",
  quizzes: "/education/learn-series65/quizzes",
  addNewQuizPreview: "/education/learn-series65/add-quiz-preview",
  addNewQuiz: "/education/learn-series65/add-quiz",
  editQuiz: "/education/learn-series65/quiz-edit/:quizId",
  quizDetail: "/education/learn-series65/quiz-detail/:quizId",
  quizPreview: "/education/learn-series65/quiz-preview/:quizId",
  readinessexams: "/education/learn-series65/exams",
  addNewReadinessExamPreview: "/education/learn-series65/add-exam-preview",
  addNewReadinessExam: "/education/learn-series65/add-exam",
  editReadinessExam: "/education/learn-series65/exam-edit/:examId",
  readinessExamDetail: "/education/learn-series65/exam-detail/:examId",
  readinessExamPreview: "/education/learn-series65/exam-preview/:examId",
  investingQuizzes: "/education/learn-investing/quizzes",
  investingAddNewQuizPreview: "/education/learn-investing/add-quiz-preview",
  investingAddNewQuiz: "/education/learn-investing/add-quiz",
  investingEditQuiz: "/education/learn-investing/quiz-edit/:quizId",
  investingQuizDetail: "/education/learn-investing/quiz-detail/:quizId",
  investingQuizPreview: "/education/learn-investing/quiz-preview/:quizId",
  investingChapters: "/education/learn-investing/chapters",
  investingAddNewChapterPreview: "/education/learn-investing/add-chapter-preview",
  investingAddNewChapter: "/education/learn-investing/add-chapter",
  investingEditChapter: "/education/learn-investing/chapter-edit/:chapterId",
  investingChapterDetail:
    "/education/learn-investing/chapter-detail/:chapterId",
    investingPreviewChapter:
    "/education/learn-investing/chapter-preview/:chapterId",
  investingVideoLectures: "/education/learn-investing/video-lectures",
  investingAddNewVideoLecturePreview: "/education/learn-investing/add-video-lecture-preview",
  investingAddNewVideoLecture: "/education/learn-investing/add-video-lecture",
  investingEditVideoLecture:
    "/education/learn-investing/video-lecture-edit/:videoId",
  investingReadinessexams: "/education/learn-investing/exams",
  investingAddNewReadinessExamPreview: "/education/learn-investing/add-exam-preview",
  investingAddNewReadinessExam: "/education/learn-investing/add-exam",
  investingEditReadinessExam: "/education/learn-investing/exam-edit/:examId",
  investingReadinessExamDetail:
    "/education/learn-investing/exam-detail/:examId",
    investingReadinessExamPreview:
    "/education/learn-investing/exam-preview/:examId",
  investingVideoLectureDetail:
    "/education/learn-investing/video-lecture-detail/:videoId",
    investingVideoLecturePreview:
    "/education/learn-investing/video-lecture-preview/:videoId",
  learnerDetails: "/education/learner-details/:learnerId",
  settings: "/settings",
  notifications: "/notifications",
  startupDetails: "/waitlist/startup-details/:startupId",
  startupSPVFormation: "/waitlist/startup-spv-formation/:startupId",
  startupInfoUpdate: "/waitlist/startup-info-update/:startupId",
  startupBanknigProfileInformation:
    "/waitlist/startup-banking-profile-information/:startupId",
  startupBanknigDocVerification:
    "/waitlist/startup-banking-document-verification/:startupId",
  startupSPVAddBankAccount: "/waitlist/startup-spv-add-bank/:startupId",
  vertifiedAccount: "/waitlist/vertifiedAccount/:startupId",
  AddDwollaAccount: "/waitlist/startup-spv-add-dwollaAccount/:startupId",
  NoBankAccount: "/waitlist/NoBankAccount",
  startupSPVManageBankAccount: "/waitlist/startup-spv-manage-bank/:startupId",
  startupAvailableDwollaAccount:
    "/waitlist/startup-spv-dwolla-accounts/:startupId",
  startupEdit: "/waitlist/startup-edit/:startupId",
  investorDetails: "/waitlist/investor-details/:investorId",
  investorEdit: "/waitlist/investor-edit/:investorId",
  inviteHistory: "/waitlist/invite-history",
  approvedStartupDetails: "/approved/startup-details/:startupId",
  approvedStartupEdit: "/approved/startup-edit/:startupId",
  approvedInfoUpdate: "/approved/startup-info-update/:startupId",
  startupSPVManageDwollaAccount: "/approved/startup-spv-dwolla-accounts",
  startupSPVManageBankAccount1: "/approved/startup-spv-manage-bank/:startupId",
  AddDwollaAccount1: "/approved/startup-spv-add-dwollaAccount",
  approvedStartupInviteHistory:
    "/approved/startup-details/:startupId/invite-history",
  approvedStartupInvestorHistory:
    "/approved/startup-details/:startupId/investor-history",
  approvedStartupMatchInvestors:
    "/approved/startup-details/:startupId/investors",
  approvedStartupMonthlyUpdates: "/approved/startup-details/:startupId/updates",
  approvedStartupMonthlyUpdateDetails:
    "/approved/startup-details/:startupId/update/:updateId",
  startupApprovedAvailableDwollaAccount:
    "/approved/startup-spv-dwolla-accounts/:startupId",
  approvedStartupPublicQnA: "/approved/startup-details/:startupId/public-QnA",
  approvedStartupCarryRecipients: "/approved/startup-details/:startupId/carry-recipients",
  approvedStartupAddCarryRecipients: "/approved/startup-details/:startupId/add-carry-recipients",
  approvedStartupEditCarryRecipients: "/approved/startup-details/:startupId/edit-carry-recipients",
  approvedStartupViewCarryRecipient: "/approved/startup-details/:startupId/view-carry-recipient",
  approvedInvestorDetails: "/approved/investor-details/:investorId",
  approvedinviteHistory: "/approved/invite-history",
  PreviousPostUpdate: "/approved/previous-posts/:startupId",
  PreviousPostUpdateDetails: "/approved/previous-posts-details/:id",
  approvedInvestorEdit: "/approved/investor-edit/:investorId",
  approvedInvestorMatchStartups:
    "/approved/investor-details/:investorId/startups",
  similarInvestors: "/approved/investor-details/:investorId/similar-investors",
  approvedInvestorHistory: "/approved/investor-details/:investorId/history",
  ViewTaxesDoc: "/approved/investor-details/:investorId/upload-doc",
  approvedInvestorTaxes: "/approved/investor-details/:investorId/Taxes",
  approvedInvestorTaxesUploadDoc:
    "/approved/investor-details/:investorId/Taxes/upload-tax",
  approvedSpvView: "/approved/spv-view/:startupId",
  monthlyUpdateHistory: "/approved/monthly-Update-History/:startupId",
  NotifyInvestors: "/approved/notify-investors/:startupId",
  EditNotifyInvestors: "/approved/edit-notify-investor/:editId",
  NotifyHistory: "/approved/notify-Investors-History",
  addNewMarketingBlog: "/marketing/add-blog",
  addNewMarketingPodcast: "/marketing/add-podcast",
  editBlog: "/marketing/edit-blog/:blogId",
  editPodcast: "/marketing/edit-podcast/:podcastId",
  rejectedStartupDetails: "/rejected/startup-details/:startupId",
  rejectedInvestorDetails: "/rejected/investor-details/:investorId",
  rejectedStartupEdit: "/rejected/startup-edit/:startupId",
  rejectedInvestorEdit: "/rejected/investor-edit/:investorId",
  trackingStartupDetails: "/tracking/startup-details/:startupId",
  trackingStartupEdit: "/tracking/startup-edit/:startupId",
  trackingStartupUpdates: "/tracking/startup-details/:startupId/updates",
  trackingStartupUpdateDetails: "/tracking/update-startup-details/:startupId",
  startupTrackingSPVFormation: "/tracking/startup-spv-formation/:startupId",
  startupTrackingInfoUpdate: "/tracking/startup-info-update/:startupId",
  startupTrackingHistory: "/tracking/startup-history/:startupId",
  startupTrackingAvailableDwollaAccount:
    "/tracking/startup-spv-dwolla-accounts/:startupId",
  AddTrackingDwollaAccount:
    "/tracking/startup-spv-add-dwollaAccount/:startupId",
  startupTrackingManageBankAccount:
    "/tracking/startup-spv-manage-bank/:startupId",
  scoutDetails: "/scouts/scout-details/:scoutId",
  scoutViewCarryRecipient: "/scouts/scout-details/:scoutId/view-carry-recipient",
  scoutEdit: "/scouts/scout-edit/:scoutId",
  scoutEditCarrySharingAgreement: "/scouts/scout-edit/:scoutId/edit-carry",
  scoutAddCarrySharingAgreement: "/scouts/scout-edit/:scoutId/add-carry",
  talent: "/talent",
  talentDetails: "/talent/talent-details/:talentId",
  talentEdit: "/talent/talent-edit/:talentId",
  transactions: "/transactions",
  transactionStartupDetails: "/transactions/startup-details",
  transactionInvestorDetails: "/transactions/investor-details",
  ControllerInformation: "/waitlist/controller-info/:startupId",
  ControllerInformation1: "/approved/controller-info",
  TrackingControllerInformation: "/tracking/controller-info/:startupId",
  controllerDocVerification:
    "/waitlist/controller-document-vertification/:startupId",
  approvedControllerDocVerification:
    "/approved/controller-document-vertification",
  trackingControllerDocVerification:
    "/tracking/controller-document-vertification/:startupId",
  businessDocVerification:
    "/waitlist/business-document-vertification/:startupId",
  approvedBusinessDocVerification: "/approved/business-document-vertification",
  trackingBusinessDocVerification:
    "/tracking/business-document-vertification/:startupId",
  businessDBADocVerification:
    "/waitlist/business-DBA-document-vertification/:startupId",
  approvedBusinessDBADocVerification:
    "/approved/business-DBA-document-vertification",
  trackingBusinessDBADocVerification:
    "/tracking/business-DBA-document-vertification/:startupId",
  documentVerificationStatus:
    "/waitlist/document-verification-status/:startupId",
  approvedDocumentVerificationStatus: "/approved/document-verification-status",
  waitlistInviteHistory: "/waitlist/invite-history",
  trackingDocumentVerificationStatus:
    "/tracking/document-verification-status/:startupId",
  addPortfolio: "/add-portfolio",
  editPortfolio: "/edit-portfolio/:id"
};

export default routes;
