// Import action type from an external file
import * as actionTypes from "../Actions/ActionTypes";

// Define the initial state for the Redux store
const initialState = {
  startupsData: [],
  loading: false,
  totalStartups: 0,
  startupDetails: {},
  gradeDetails: {},
  currentUpdatePosition: 0,
  dwollaAccounts: [],
  createBankingProfileLoading: false,
  BusinessInfo: {},
  inviteHistory: [],
  totalInviteHistory: 0,
  PostHistory: [],
  totalPostHisttory: 0,
  countries: [],
};

// Define a Redux reducer function
const store = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STARTUPS_DATA_INIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    //   bankingProfileCustomerLoading: false,
    case actionTypes.GET_STARTUPS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        startupsData: action.payload?.data?.map((item) => {
          let date = new Date(item?.created?.$date);
          let mnth = ("0" + (date?.getMonth() + 1))?.slice(-2);
          let day = ("0" + date?.getDate())?.slice(-2);
          let year = date?.getFullYear();
          return {
            ...item,
            status:
              item?.status?.status?.charAt(0)?.toUpperCase() +
              item?.status?.status?.slice(1),
            first_last_name: item?.first_name + " " + item?.last_name,
            signup_date: [mnth, day, year]?.join("-"),
          };
        }),
        totalStartups: action.payload.total_count,
      };
    case actionTypes.GET_STARTUPS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        startupsData: [],
        totalStartups: 0,
        error: action.payload,
      };
    case actionTypes.DELETE_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
        startupsData: action.payload?.data?.map((item) => {
          let date = new Date(item?.created?.$date);
          let mnth = ("0" + (date?.getMonth() + 1))?.slice(-2);
          let day = ("0" + date?.getDate())?.slice(-2);
          let year = date?.getFullYear();
          return {
            ...item,
            status:
              item?.status?.status?.charAt(0)?.toUpperCase() +
              item?.status?.status?.slice(1),
            first_last_name: item?.first_name + " " + item?.last_name,
            signup_date: [mnth, day, year]?.join("-"),
          };
        }),
        totalStartups: action.payload.total_count,
      };
    case actionTypes.DELETE_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_STARTUP_BY_ID_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_STARTUP_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        startupDetails: action.payload?.data,
      };
    case actionTypes.GET_STARTUP_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        startupDetails: {},
      };
    case actionTypes.UPLOAD_MEMO_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_MEMO_SUCCESS:
      return {
        ...state,
        loading: false,
        is_upload: true,
        startupDetails: {
          ...action?.payload?.data,
          memo: action?.payload?.successData?.url,
        },
      };
    case actionTypes.UPLOAD_MEMO_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ASSIGN_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ASSIGN_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ASSIGN_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.BULK_INVITE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.BULK_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.BULK_INVITE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_GRADE_INIT:
      return {
        ...state,
      };
    case actionTypes.UPDATE_GRADE_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.UPDATE_GRADE_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_GRADE_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_GRADE_SUCCESS:
      return {
        ...state,
        gradeDetails: {
          grade: action?.payload?.grade,
          grade_data: action?.payload?.grade_data,
          gradeSummary: action?.payload?.grade_summary,
        },
      };
    case actionTypes.GET_GRADE_FAILED:
      return {
        ...state,
      };
    case actionTypes.UPDATE_STARTUP_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_STARTUP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_STARTUP_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_STATUS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_STATUS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPLOAD_STARTUP_SLIDEDECK_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_STARTUP_SLIDEDECK_SUCCESS:
      return {
        ...state,
        loading: false,
        startupDetails: {
          ...action?.payload?.data,
          slide_deck: action?.payload?.successData?.url,
        },
      };
    case actionTypes.UPLOAD_STARTUP_SLIDEDECK_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_CURRENT_UPDATE_STARTUP_POSITION_INIT:
      return {
        ...state,
      };
    case actionTypes.GET_CURRENT_UPDATE_STARTUP_POSITION_SUCCESS:
      return {
        ...state,
        currentUpdatePosition: action?.payload?.position,
      };
    case actionTypes.GET_CURRENT_UPDATE_STARTUP_POSITION_FAILED:
      return {
        ...state,
      };
    case actionTypes.GET_DWOLLA_ACCOUNT_DATA_INIT:
      return {
        ...state,
        getDwollaAccountDataLoading: true,
      };
    case actionTypes.GET_DWOLLA_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        dwollaAccounts: action?.payload?.accounts,
        getDwollaAccountDataLoading: false,
        totalCount: action?.payload?.count,
      };
    case actionTypes.GET_DWOLLA_ACCOUNT_DATA_FAILED:
      return {
        ...state,
        dwollaAccounts: [],
        getDwollaAccountDataLoading: false,
      };
    case actionTypes.CREATE_BANKING_PROFILE_CUSTOMER_INIT:
      return {
        ...state,
        createBankingProfileLoading: true,
      };
    case actionTypes.CREATE_BANKING_PROFILE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createBankingProfileLoading: false,
      };
    case actionTypes.CREATE_BANKING_PROFILE_CUSTOMER_FAILED:
      return {
        ...state,
        createBankingProfileLoading: false,
      };
    case actionTypes.POST_LINK_TOKEN_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.POST_LINK_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        create_link_token_data: action.payload,
      };
    case actionTypes.POST_LINK_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_BANK_ACCOUNT_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_BANK_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccounts: action?.payload?.bank_accounts,
      };
    case actionTypes.GET_BANK_ACCOUNT_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.LINK_STARTUP_BANK_ACCUNT_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LINK_STARTUP_BANK_ACCUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.LINK_STARTUP_BANK_ACCUNT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.WAITLIST_PERMISSION:
      return {
        ...state,
        permissions: action.payload,
      };
    case actionTypes.SET_BUSINESS_INFO:
      return {
        ...state,
        BusinessInfo: action.payload,
      };
    case actionTypes.GET_BUSINESS_CLASSIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_BUSINESS_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        business_category: action.payload,
      };
    case actionTypes.GET_BUSINESS_CLASSIFICATIONS_FAILED:
      return {
        ...state,
        loading: false,
        business_category: [],
      };
    case actionTypes.GET_INDUSTRY_CLASSIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
        industry_category: [],
      };
    case actionTypes.GET_INDUSTRY_CLASSIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        industry_category: action.payload,
      };
    case actionTypes.GET_INDUSTRY_CLASSIFICATIONS_FAILED:
      return {
        ...state,
        loading: action.payload,
        industry_category: [],
      };
    case actionTypes.GET_INVITE_HISTORY_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVITE_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        inviteHistory: action.payload?.data,
        totalInviteHistory: action.payload?.total_count,
      };
    case actionTypes.GET_INVITE_HISTORY_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SEND_STARTUP_INVITE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEND_STARTUP_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SEND_STARTUP_INVITE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_PREVIOUS_POSTS_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_PREVIOUS_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        PostHistory: action?.payload?.data,
        totalPostHistory: action.payload?.total_count,
      };
    case actionTypes.GET_PREVIOUS_POSTS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SET_FLAG_LINK:
      return {
        ...state,
        countries: action?.payload,
      };
    case actionTypes.INDIVIDUAL_INVITE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.INDIVIDUAL_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.INDIVIDUAL_INVITE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_ADMIN_DATA_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ADMIN_DATA_SUCCESS:
      return {
        ...state,
        adminInviteList: action?.payload,
        loading: false,
      };
    case actionTypes.GET_ADMIN_DATA_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESEND_ADMIN_INVITE_INIT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RESEND_ADMIN_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESEND_ADMIN_INVITE_FAILED:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.CREATE_DWOLLA_CUSTOMER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.CREATE_DWOLLA_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.CREATE_DWOLLA_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.UPDATE_DWOLLA_CUSTOMER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.UPDATE_DWOLLA_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.UPDATE_DWOLLA_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case actionTypes.DELETE_DWOLLA_CUSTOMER_INIT:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.DELETE_DWOLLA_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actionTypes.DELETE_DWOLLA_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
      }

    case 'EMPTY_BUSINESS_CATEGORIES':
      return {
        ...state,
        business_category: [],
      }

    default:
      return state;
  }
};

export default store;
