import React, { useEffect, useState } from 'react'
import "./AddNewVideoLecturePreview.scss"
import { Col, Divider, Row } from 'antd';
import ReactPlayer from "react-player";

const AddNewVideoLecturePreview = ({ location }) => {
  const { videoLectureDetails } = location.state;
  const [selectedVideo, setSelectedVideo] = useState();
  useEffect(() => {
    videoLectureDetails?.links &&
      setSelectedVideo(videoLectureDetails?.links[0]);
  }, []);
  return (
    <div className="video-preview-container">
      <div className="preview-header">
        <h6>Video Lecture</h6>
      </div>
      <div className="video-preview-wrap">
        <Row gutter={16}>
          <Col span={10}>
            <div className="left-section">
              <span className="label">Video Topic</span>
              <p className="topic">{videoLectureDetails?.topic}</p>
              <Divider />
              <div className="videos-title-wrap">
                {videoLectureDetails?.links?.map((item) => {
                  return (
                    <p
                      className={
                        selectedVideo?.title === item?.title
                          ? "selected-video-title"
                          : "video-title"
                      }
                      onClick={() => setSelectedVideo(item)}
                    >
                      {item?.title}
                    </p>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col span={12} offset={2} className="content-wrap">
            <span className="label">Video Sumary</span>
            <p>{selectedVideo?.summary}</p>
            <ReactPlayer
              url={selectedVideo?.link}
              controls
              height={350}
              width={"100%"}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AddNewVideoLecturePreview